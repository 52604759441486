import { baseApi } from "@/baseApi";

export async function getGroupAvailableServices(token, group, controller) {
  const { data } = await baseApi({
    url: `/client/administration/v1/services/${group}`,
    method: "get",
    signal: controller?.signal,
    headers: { Authorization: `Bearer ${token}` },
  });

  return data;
}
