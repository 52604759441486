<template>
  <div class="map-block" style="height: 470px">
    <ModifiedMapbox
      ref="modifiedMapRef"
      hideMapControls
      style="border-radius: 4px"
    >
      <MapboxMarker
        v-if="selectedAnomaly"
        :lng-lat="selectedAnomaly.geom.coordinates[0]"
        color="orange"
      />
      <template v-slot:sources>
        <template
          v-for="{ id, sourceOptions, layerOptions } in anomalyMapData"
          :key="id"
        >
          <MapboxSource :id="id" :options="sourceOptions" />
          <MapboxLayer
            :id="layerOptions.id"
            :options="layerOptions"
            @mb-mouseenter="onLayerMouseEnter"
            @mb-mouseleave="onLayerMouseLeave"
            @mb-click="onLayerClick"
          />
        </template>
      </template>
      <AnomaliesLegend
        v-if="anomalyFilters.selectedAnomaliesTypes?.length"
        @updateSelectedTypes="onSelectLegend"
        class="legend"
      />
      <MapInfoPopup
        v-if="selectedAnomaly && showMapInfo"
        :closePopup="closeMapInfo"
        class="map-info"
        :data="selectedAnomaly"
        type="anomaly"
      />
      <v-btn
        v-if="selectedAnomaly && !showMapInfo"
        class="open-info-btn"
        icon="mdi-open-in-new"
        size="x-small"
        @click="openMapInfo"
      ></v-btn>
    </ModifiedMapbox>
  </div>
</template>

<script>
import { ref } from "vue";
import {
  MapboxMarker,
  MapboxLayer,
  MapboxSource,
} from "@studiometa/vue-mapbox-gl";
import { MapInfoPopup, ModifiedMapbox } from "@/components";
import { AnomaliesLegend } from "@/pages/anomaly/components";
import { mapGetters, mapState, mapMutations, mapActions } from "vuex";

export default {
  name: "AnomalyMap",
  components: {
    ModifiedMapbox,
    MapboxMarker,
    MapboxLayer,
    MapboxSource,
    MapInfoPopup,
    AnomaliesLegend,
  },

  props: {
    onSelectLegend: Function,
  },

  computed: {
    ...mapGetters("anomalies", [
      "anomalyMapData",
      "anomaliesTypes",
      "selectedAnomaly",
    ]),
    ...mapState("anomalies", ["anomalyFilters"]),

    map() {
      return this.$refs.modifiedMapRef.map;
    },
  },

  setup() {
    const showMapInfo = ref(true);
    const openMapInfo = () => {
      showMapInfo.value = true;
    };
    const closeMapInfo = () => {
      showMapInfo.value = false;
    };

    return {
      showMapInfo,
      openMapInfo,
      closeMapInfo,
    };
  },

  methods: {
    ...mapMutations("anomalies", ["setFilters"]),
    ...mapActions("anomalies", ["toggleSelectedAnomaly"]),

    onLayerClick({ features }) {
      if (features && features[0].source.includes("anomalies")) {
        const selectedId = features[0].properties.id;
        this.toggleSelectedAnomaly(selectedId);
      }
    },

    onLayerMouseEnter({ features, target }) {
      if (features && features[0].source.includes("anomalies")) {
        target.getCanvas().style.cursor = "pointer";
      }
    },

    onLayerMouseLeave({ target }) {
      target.getCanvas().style.cursor = "";
    },
  },

  watch: {
    selectedAnomaly(anomaly) {
      if (anomaly) {
        this.map.flyTo({
          center: anomaly.geom.coordinates[0],
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.map-block {
  position: relative;
  margin: 0.6rem 0 1.2rem;
}
.legend {
  position: absolute;
  bottom: 6px;
  right: 6px;
  opacity: 0.9;
}

.map-info {
  z-index: 2;
  position: absolute;
  bottom: 6px;
  left: 5px;
  border-radius: 6px;
  background-color: white;
  opacity: 0.9;
}

.open-info-btn {
  position: absolute;
  bottom: 6px;
  left: 5px;
  opacity: 0.8;
  &:hover {
    opacity: 1;
  }
}
</style>
