<template>
  <div class="gradient-block">
    <div class="gradient-scale" :style="gradientScaleStyle"></div>
    <div class="gradient-ruler">
      <span>{{ `${gradientColor[0].toFixed(2)}${currentUnit}` }}</span>
      <span>{{ `${gradientColor[2].toFixed(2)}${currentUnit}` }}</span>
      <span>{{ `${gradientColor[4].toFixed(2)}${currentUnit}` }}</span>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

import { gradientColorArr } from "@/colorLegends.js";

export default {
  name: "PipePartsGradient",

  props: ["gradientColor"],

  computed: {
    ...mapState("pipeParts", ["selectedGradientMode"]),

    isSelectedWallThickness() {
      return [
        "degradated_total_mean",
        "degradated_total_max",
        "degradated_total_min",
        "degradated_total_std",
        "remaining_mean",
        "remaining_max",
        "remaining_min",
        "remaining_std",
      ].includes(this.selectedGradientMode);
    },

    isOutOfStraight() {
      return [
        "horizontal_out_of_straight",
        "vertical_out_of_straight",
      ].includes(this.selectedGradientMode);
    },

    currentUnit() {
      if (this.isSelectedWallThickness) {
        return ` ${this.$units.getAbbr("mm")}`;
      }
      if (this.isOutOfStraight) {
        return ` ${this.$units.getAbbr("m")}`;
      }
      return "";
    },

    gradientScaleStyle() {
      if (this.gradientColor[0] === this.gradientColor[2]) {
        return {
          background: gradientColorArr[0],
        };
      }
      return {
        background: `linear-gradient(to top,
                            ${gradientColorArr[0]} 0%,
                            ${gradientColorArr[1]} 50%,
                            ${gradientColorArr[2]} 100%)`,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.gradient-block {
  user-select: none;
  min-height: 250px;
  height: 100%;
  display: flex;
  margin-left: 1rem;
  padding: 1.2rem 0;
  .gradient-scale {
    width: 1.5rem;
  }
  .gradient-ruler {
    font-size: 1rem;
    padding-left: 0.5rem;
    display: flex;
    flex-direction: column-reverse;
    justify-content: space-between;
    span {
      white-space: nowrap;
    }
  }
}
</style>
