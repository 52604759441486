<template>
  <div class="wrapper">
    <p>{{ title }}</p>
    <div :class="{ flex: showSwitch }">
      <vue-speedometer
        :value="value"
        :maxValue="maxValue"
        :customSegmentStops="[0, 0, value, maxValue, maxValue]"
        :segmentColors="[
          'lightgrey',
          anyBad ? '#FF0000' : '#24e3c6',
          'lightgrey',
          'lightgrey',
        ]"
        :customSegmentLabels="[
          {
            text: '0',
            position: 'OUTSIDE',
          },
          {
            text: '',
            position: 'OUTSIDE',
          },
          {
            text: '',
            position: 'OUTSIDE',
          },
          {
            text: maxValue,
            position: 'OUTSIDE',
          },
        ]"
        :ringWidth="20"
        :needleHeightRatio="0.55"
        :width="170"
        :height="100"
        :paddingHorizontal="10"
      />
      <v-switch
        v-if="showSwitch"
        :model-value="statsSwitched"
        :value="geomName"
        density="compact"
        :disabled="value === 0"
        @click="toggleSwitches(geomName)"
      ></v-switch>
    </div>
  </div>
</template>

<script>
import VueSpeedometer from "vue-speedometer";

export default {
  name: "StatsGauge",
  inject: {
    statsSwitched: { default: [] },
    toggleSwitches: {
      default: null,
    },
  },
  components: {
    VueSpeedometer,
  },
  props: {
    title: String,
    maxValue: Number || undefined,
    value: Number,
    geomName: String,
    anyBad: Boolean,
    showSwitch: Boolean,
  },
};
</script>

<style lang="scss">
.wrapper {
  position: relative;

  p {
    width: 100%;
    position: absolute;
    top: 4px;
    text-transform: uppercase;
    text-align: center;
    font-size: 14px;
  }

  .speedometer {
    overflow: visible;
    margin-top: 7px;

    .segment-value {
      &:last-child {
        transform: rotate(0) translate(75px, 14px);
      }
      &:first-child {
        transform: rotate(0) translate(-75px, 14px);
      }
    }
    .current-value {
      fill: rgb(45, 45, 45) !important;
    }
  }

  .flex {
    align-items: flex-end;
  }
}
</style>
