<template>
  <v-card id="info-card" width="300">
    <template v-if="this.status === ANALYSIS_PENDING">
      <v-card-title>
        <span class="popup-title">{{ $t("pipe_scanner.info") }}</span>
      </v-card-title>
      <v-card-text style="font-size: 1rem">
        {{ $t("pipe_scanner.pending") }}
      </v-card-text>
    </template>

    <template v-else-if="this.status === INFO_PENDING">
      <v-card-title>
        <span class="popup-title">{{
          $t("dashboard.actions_required.title")
        }}</span>
      </v-card-title>
      <v-card-text style="font-size: 1rem">
        {{ $t("pipe_scanner.required_info") }}
        <v-btn
          v-if="openEditModel"
          size="small"
          @click="onEditScannerClick"
          color="green"
          style="margin-top: 10px; margin-left: 50%"
          >{{ $t("context_menu.edit_scanner") }}</v-btn
        >
      </v-card-text>
    </template>

    <template v-else>
      <v-card-title class="with-actions">
        <span class="popup-title">{{ $t("pipe_scanner.info") }}</span>
        <v-btn
          v-if="openDetailedModal"
          size="x-small"
          rounded="xl"
          @click="onOpenDetailedClick"
          >{{ $t("pipe_scanner.open_detailed") }}</v-btn
        >
        <template v-if="scanners.length > 1">
          <v-btn
            icon="mdi-menu-left"
            variant="text"
            size="x-small"
            style="color: white"
            @click="prevScanner"
          ></v-btn>
          {{ `${currentScannerIndex + 1}/${scanners.length}` }}
          <v-btn
            icon="mdi-menu-right"
            variant="text"
            size="x-small"
            style="color: white"
            @click="nextScanner"
          ></v-btn>
        </template>
      </v-card-title>
      <v-card-text style="padding: 0 4px 2px !important">
        <table class="popup-table">
          <tbody>
            <tr>
              <td colspan="2">{{ $t("pipe_scanner.params.tag") }}</td>
              <td>{{ currentScanner.tag }}</td>
            </tr>
            <tr>
              <td colspan="2">{{ $t("pipe_scanner.params.user") }}</td>
              <td>{{ currentScanner.user }}</td>
            </tr>
            <tr>
              <td colspan="2">{{ $t("pipe_scanner.params.material") }}</td>
              <td>{{ currentScanner.material }}</td>
            </tr>
            <tr>
              <td colspan="2">
                {{ $t("pipe_scanner.params.inspection_date") }}
              </td>
              <td>{{ currentScanner.inspection_date }}</td>
            </tr>
            <tr>
              <td colspan="2">
                {{ $t("pipe_scanner.params.construction_year") }}
              </td>
              <td>{{ currentScanner.constructionyear }}</td>
            </tr>
            <tr>
              <td colspan="2">
                {{
                  `${$t("pipe_scanner.params.diameter")} (${this.$units.getAbbr(
                    "mm"
                  )})`
                }}
              </td>
              <td>{{ currentScanner.diameter }}</td>
            </tr>
            <tr>
              <td colspan="2">
                {{
                  `${$t(
                    "pipe_scanner.params.wallthickness_expected_validation"
                  )} (${this.$units.getAbbr("mm")})`
                }}
              </td>
              <td>
                {{ currentScanner.wallthickness_expected_validation }}
              </td>
            </tr>
            <tr>
              <td rowspan="5" :style="{ width: '70px' }">
                {{
                  `${$t(
                    "pipe_scanner.params.wall_thickness"
                  )} (${this.$units.getAbbr("mm")}):`
                }}
              </td>
            </tr>
            <tr>
              <td>{{ $t("pipe_scanner.params.max") }}</td>
              <td>
                {{ currentScanner.stats.max.toFixed(2) }}
              </td>
            </tr>
            <tr>
              <td>{{ $t("pipe_scanner.params.mean") }}</td>
              <td>
                {{ currentScanner.stats.mean.toFixed(2) }}
              </td>
            </tr>
            <tr>
              <td>{{ $t("pipe_scanner.params.min") }}</td>
              <td>
                {{ currentScanner.stats.min.toFixed(2) }}
              </td>
            </tr>
            <tr>
              <td>{{ $t("pipe_scanner.params.std") }}</td>
              <td>
                {{ currentScanner.stats.std.toFixed(2) }}
              </td>
            </tr>
          </tbody>
        </table>
      </v-card-text>
    </template>
  </v-card>
</template>

<script>
import {
  RESULTS,
  ANALYSIS_PENDING,
  INFO_PENDING,
} from "@/features/pipescanner/constants";

export default {
  name: "ScannerInfoPopup",
  props: {
    status: String,
    scanners: Array,
    openDetailedModal: Function,
    openEditModel: Function,
  },
  setup() {
    return {
      RESULTS,
      ANALYSIS_PENDING,
      INFO_PENDING,
    };
  },
  data() {
    return {
      currentScannerIndex: 0,
    };
  },

  computed: {
    currentScanner() {
      return this.scanners[this.currentScannerIndex];
    },
  },

  methods: {
    nextScanner() {
      this.currentScannerIndex =
        this.currentScannerIndex + 1 === this.scanners.length
          ? 0
          : this.currentScannerIndex + 1;
    },

    prevScanner() {
      this.currentScannerIndex =
        this.currentScannerIndex - 1 < 0
          ? this.scanners.length - 1
          : this.currentScannerIndex - 1;
    },

    onOpenDetailedClick() {
      this.openDetailedModal(this.currentScanner.id, this.scanners);
    },

    onEditScannerClick() {
      this.openEditModel(this.currentScanner.id, this.status, this.scanners);
    },
  },
};
</script>

<style lang="scss" scoped>
#info-card {
  .v-card-title {
    display: flex;
    align-items: center;

    &.with-actions {
      padding: 0 14px;
      font-size: 0.9rem !important;
    }

    .popup-title {
      margin-right: 8px;
    }
  }

  .v-card-text {
    tr {
      font-size: 0.75rem;
      td:last-child {
        width: 50%;
      }
    }

    tr:nth-last-child(-n + 5) {
      td {
        padding: 2px 10px !important;
      }
    }
  }
}
</style>
