import { includesIndex, parseKeyAndIndex } from "./stringHelpers";

export const getNestedValueFromObject = (obj, propertyPath) => {
  const propertyKeys = propertyPath.split(".");

  let val = obj;
  for (const key of propertyKeys) {
    if (includesIndex(key)) {
      const { key: parsedKey, index: parsedIndex } = parseKeyAndIndex(key);
      val = val[parsedKey][parsedIndex];
    } else if (val && Object.prototype.hasOwnProperty.call(val, key)) {
      val = val[key];
    } else {
      return undefined;
    }
  }
  return val;
};
