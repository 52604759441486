import { unitsConverter } from "@/config/units";

// const convert = unitsConverter.instance.convert;

/**
 * This function searches for the coordinates of the center anomalies
 * @param {object[]} anomalies - array of anomalies
 * @return {object[]} - array of anomalies with centerGeom value
 */
export function getCenterAndConvertUnits(anomalies) {
  return anomalies.map(function (an) {
    const len = an.geom.coordinates.length;
    let latitude =
      (an.geom.coordinates[0][1] + an.geom.coordinates[len - 1][1]) / 2;
    let longitude =
      (an.geom.coordinates[0][0] + an.geom.coordinates[len - 1][0]) / 2;
    latitude = Math.round(latitude * 1e9) / 1e9;
    longitude = Math.round(longitude * 1e9) / 1e9;
    return {
      ...an,
      distance: an.distance
        ? unitsConverter.instance.convert(an.distance, "m")
        : an.distance,
      length: an.length
        ? unitsConverter.instance.convert(an.length, "m")
        : an.length,
      z_coordinate: an.z_coordinate
        ? unitsConverter.instance.convert(an.z_coordinate, "m")
        : an.z_coordinate,
      centerGeom: [longitude, latitude],
      local_degradation_spots: an.local_degradation_spots
        ? {
            ...an.local_degradation_spots,
            width: an.local_degradation_spots.width
              ? unitsConverter.instance.convert(
                  an.local_degradation_spots.width,
                  "m"
                )
              : an.local_degradation_spots.width,
            depth: an.local_degradation_spots.depth
              ? unitsConverter.instance.convert(
                  an.local_degradation_spots.depth,
                  "m"
                )
              : an.local_degradation_spots.depth,
          }
        : an.local_degradation_spots,
      width: an.width
        ? unitsConverter.instance.convert(an.width, "m")
        : an.width,
      depth: an.depth
        ? unitsConverter.instance.convert(an.depth, "m")
        : an.depth,
    };
  }, this);
}
