import { anomaliesState } from "./state";
import { anomaliesGetters } from "./getters";
import { anomaliesMutations } from "./mutations";
import { anomaliesActions } from "./actions";

export const anomaliesModule = {
  namespaced: true,
  state: anomaliesState,
  mutations: anomaliesMutations,
  getters: anomaliesGetters,
  actions: anomaliesActions,
};
