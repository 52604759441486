import { findAddedAndRemovedInArray } from "../../../utils/filterHelpers";
import {
  createLayerMapUrl,
  TILE_SIZE,
} from "@/features/wms/components/WMSWidget.config";

const layerSuffix = (source) => `${source}_layer`;

export const addWMSLayer = (map, layer) => {
  if (
    ["protection_zone", "crop_plots"].includes(layer) &&
    map.getZoom() < 12.5
  ) {
    map.flyTo({ zoom: 12.5 });
  }
  if ("urban_water" === layer && map.getZoom() < 13.5) {
    map.flyTo({ zoom: 13.5 });
  }
  if (map.getLayer("road-path")) {
    map.addSource(layer, {
      type: "raster",
      tiles: [createLayerMapUrl(layer)],
      tileSize: TILE_SIZE,
    });
    map.addLayer(
      {
        id: layerSuffix(layer),
        type: "raster",
        source: layer,
      },
      "road-path"
    );
  }
};

const removeWMSLayer = (map, layer) => {
  if (map.getLayer(layerSuffix(layer))) {
    map.removeLayer(layerSuffix(layer));
  }

  if (map.getSource(layer)) {
    map.removeSource(layer);
  }
};

export const wmsWatchHandler = (currentStyles, prevStyles, map) => {
  const [added, removed] = findAddedAndRemovedInArray(
    currentStyles,
    prevStyles
  );
  if (added) {
    addWMSLayer(map, added);
  }
  if (removed) {
    removeWMSLayer(map, removed);
  }
};
