import { baseApi } from "@/baseApi";

export async function filterInspectionData(token, id, group, controller) {
  const response = await baseApi({
    url: `/pipescanner/analyser/v1/init/${id}/${group}`,
    method: "get",
    signal: controller?.signal,
    headers: { Authorization: `Bearer ${token}` },
  });

  return response;
}

export async function getInspectionFiles(token, id, group, controller) {
  const { data } = await baseApi({
    url: `/pipescanner/analyser/v1/files/${id}/${group}`,
    method: "get",
    signal: controller?.signal,
    headers: { Authorization: `Bearer ${token}` },
  });

  return data;
}

export async function postUsedFiles(token, id, group, files, controller) {
  const response = await baseApi({
    url: `/pipescanner/analyser/v1/save-selected/${id}/${group}`,
    method: "post",
    signal: controller?.signal,
    headers: { Authorization: `Bearer ${token}` },
    data: files,
  });

  return response;
}

export async function getInspectionBScans(
  token,
  id,
  group,
  onDownloadProgress,
  controller
) {
  const { data } = await baseApi({
    url: `/pipescanner/analyser/v1/ut/get-bscans/${id}/${group}`,
    method: "get",
    onDownloadProgress,
    signal: controller?.signal,
    headers: { Authorization: `Bearer ${token}` },
  });

  return data;
}

export async function getInspectionComments(token, id, group, controller) {
  const { data } = await baseApi({
    url: `/pipescanner/analyser/v1/comments/${id}/${group}`,
    method: "get",
    signal: controller?.signal,
    headers: { Authorization: `Bearer ${token}` },
  });

  return data;
}

export async function postComments(token, id, group, comments, controller) {
  const response = await baseApi({
    url: `/pipescanner/analyser/v1/save-comments/${id}/${group}`,
    method: "post",
    signal: controller?.signal,
    headers: { Authorization: `Bearer ${token}` },
    data: comments,
  });

  return response;
}

export async function postPeakDetect(token, id, group, form, controller) {
  const { data } = await baseApi({
    url: `/pipescanner/analyser/v1/ut/peak-detect/${id}/${group}`,
    method: "post",
    signal: controller?.signal,
    headers: { Authorization: `Bearer ${token}` },
    data: form,
  });

  return data;
}

export async function getBscansCorrelated(
  token,
  id,
  group,
  onDownloadProgress,
  controller
) {
  const { data } = await baseApi({
    url: `/pipescanner/analyser/v1/ut/get-bscans-correlated/${id}/${group}`,
    method: "get",
    signal: controller?.signal,
    headers: { Authorization: `Bearer ${token}` },
    onDownloadProgress,
  });

  return data;
}

// MAPPING
export async function getMappingSettings(token, id, group, controller) {
  const { data } = await baseApi({
    url: `/pipescanner/analyser/v1/mapping/get-mapping-settings/${id}/${group}`,
    method: "get",
    signal: controller?.signal,
    headers: { Authorization: `Bearer ${token}` },
  });

  return data;
}

export async function postMappingSettings(token, id, group, files, controller) {
  const response = await baseApi({
    url: `/pipescanner/analyser/v1/mapping/save-mapping-settings/${id}/${group}`,
    method: "post",
    signal: controller?.signal,
    headers: { Authorization: `Bearer ${token}` },
    data: files,
  });

  return response;
}

export async function getDistanceOdo(token, id, group, controller) {
  const { data } = await baseApi({
    url: `/pipescanner/analyser/v1/mapping/get-distance-odo/${id}/${group}`,
    method: "get",
    signal: controller?.signal,
    headers: { Authorization: `Bearer ${token}` },
  });

  return data;
}

export async function getDistanceAlternative(token, id, group, controller) {
  const { data } = await baseApi({
    url: `/pipescanner/analyser/v1/mapping/get-distance-alternative/${id}/${group}`,
    method: "get",
    signal: controller?.signal,
    headers: { Authorization: `Bearer ${token}` },
  });

  return data;
}

//FILTER
export async function getFilterWT(token, id, group, controller) {
  const { data } = await baseApi({
    url: `/pipescanner/analyser/v1/filter/get-wt-filter/${id}/${group}`,
    method: "get",
    signal: controller?.signal,
    headers: { Authorization: `Bearer ${token}` },
  });

  return data;
}

export async function postFilterWT(token, id, group, form, controller) {
  const { data } = await baseApi({
    url: `/pipescanner/analyser/v1/filter/filter-wt/${id}/${group}`,
    method: "post",
    signal: controller?.signal,
    headers: { Authorization: `Bearer ${token}` },
    data: form,
  });

  return data;
}

//PUBLISH
export async function publishAnalyser(token, id, group, controller) {
  const { data } = await baseApi({
    url: `/pipescanner/analyser/v1/publish/${id}/${group}`,
    method: "get",
    signal: controller?.signal,
    headers: { Authorization: `Bearer ${token}` },
  });

  return data;
}
