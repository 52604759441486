import { mergeChanges } from "@/utils/objects";

import { INITIAL_ANOMALY_FILTERS } from "./state";

export const anomaliesMutations = {
  setAnomalies(state, anomalies) {
    state.anomalies = anomalies;
  },

  setSelectedAnomalyId(state, anomalyId) {
    state.selectedAnomalyId = anomalyId;
  },

  setLoadingAnomalies(state, isLoadingAnomalies) {
    state.isLoadingAnomalies = isLoadingAnomalies;
  },

  setAwaitingDigup(state, isAwaitingDigup) {
    state.isAwaitingDigup = isAwaitingDigup;
  },

  setFilters(state, anomalyFilters) {
    mergeChanges(state.anomalyFilters, anomalyFilters);
  },

  resetFilter(state) {
    state.anomalyFilters = INITIAL_ANOMALY_FILTERS;
  },
};
