<template>
  <VirtualTable
    :headers="headers"
    :items="modifiedAnomalies"
    :is-item-active="isAnomalyActive"
    @click:row="
      (anomaly) => {
        $emit('click:row', anomaly.id);
      }
    "
  >
    <template v-slot:[`item.select`]="{ item: anomaly }">
      <input
        :value="anomaly.id"
        :checked="checkedAnomaliesSelector.has(anomaly.id)"
        @input="$emit('toggle:checkbox', anomaly.id)"
        @click.stop
        type="checkbox"
      />
    </template>
  </VirtualTable>
</template>

<script>
import { mapState } from "vuex";

import { VirtualTable } from "@/components";

import { getIdColumnCaption } from "@/features/anomaly/utils";

export default {
  name: "AnomaliesTableRegular",

  props: {
    modifiedAnomalies: Array,
    isAnomalyActive: Function,
  },

  components: { VirtualTable },

  emits: ["click:row", "toggle:checkbox"],

  data() {
    return {
      headers: [
        {
          title: this.$t("anomalies.table_titles.pipe_book_id"),
          key: "pipe_book_id",
          value: (anomaly) => getIdColumnCaption(anomaly),
        },
        {
          title: this.$t("anomalies.table_titles.anomaly_type"),
          key: "anomaly_type",
          value: (anomaly) =>
            this.$t(`anomalies.types.${anomaly.anomaly_type}`),
        },
        {
          title: this.$t("anomalies.table_titles.comment"),
          key: "comment",
          value: (anomaly) => anomaly.comment,
        },
        {
          title: this.$t("anomalies.table_titles.distance"),
          key: "distance",
          value: (anomaly) => anomaly.distance,
          units: "m",
        },
        {
          title: this.$t("anomalies.table_titles.latitude"),
          key: "latitude",
          value: (anomaly) => anomaly.latitude,
        },
        {
          title: this.$t("anomalies.table_titles.longitude"),
          key: "longitude",
          value: (anomaly) => anomaly.longitude,
        },
        {
          title: this.$t("anomalies.table_titles.z_coordinate"),
          key: "z_coordinate",
          value: (anomaly) => anomaly.z_coordinate,
        },
        {
          title: this.$t("anomalies.table_titles.length"),
          key: "length",
          value: (anomaly) => anomaly.length,
          units: "m",
        },
        {
          title: this.$t("anomalies.table_titles.width"),
          key: "width",
          value: (anomaly) => anomaly.local_degradation_spots?.width || "",
          units: "m",
        },
        {
          title: this.$t("anomalies.table_titles.depth"),
          key: "depth",
          value: (anomaly) => anomaly.local_degradation_spots?.depth || "",
          units: "m",
        },
        {
          title: this.$t("anomalies.table_titles.clock"),
          key: "clock",
          value: (anomaly) =>
            anomaly.clock_positions.length && anomaly.clock_positions,
        },
      ],
    };
  },

  computed: {
    ...mapState("anomalies", ["selectedAnomalyId", "checkedAnomaliesSelector"]),

    headerSlots() {
      return ["item.select"];
    },
  },
};
</script>
