import { ScannerType } from "@/pages/scanner-control/config";

export const pipeScannersState = {
  pipeScanners: {
    [ScannerType.Results]: [],
    [ScannerType.InfoPending]: [],
    [ScannerType.AnalysisPending]: [],
  },
  isLoading: {
    [ScannerType.Results]: false,
    [ScannerType.InfoPending]: false,
    [ScannerType.AnalysisPending]: false,
  },
};
