<template>
  <div id="mapmod-container">
    <ModifiedMapbox
      ref="modifiedMapRef"
      :showLoader="isLoadingAnomalies"
      :wmsLayers="wmsLayers"
      :widgets="widgets"
      :onMaximizeWidget="maximizeWidget"
      forceHideScanners
      @mb-moveend="onMoveend"
      @click="selectedWidget = null"
    >
      <template v-if="selectedAnomaly">
        <MapboxMarker
          :lng-lat="selectedAnomaly.geom.coordinates[0]"
          :scale="0.9"
          color="orange"
        >
        </MapboxMarker>
        <MapboxPopup :lng-lat="selectedAnomaly.geom.coordinates[0]">
          <MapInfoPopup :data="selectedAnomaly" type="anomaly" />
        </MapboxPopup>
      </template>

      <template v-slot:sources="{ openDigupMenu }">
        <template
          v-for="{ id, sourceOptions, layerOptions } in anomalyMapData"
          :key="id"
        >
          <MapboxSource :id="id" :options="sourceOptions" />
          <MapboxLayer
            :id="layerOptions.id"
            :options="layerOptions"
            @mb-mouseenter="onLayerMouseEnter"
            @mb-mouseleave="onLayerMouseLeave"
            @mb-click="onLayerClick"
            @mb-contextmenu="
              (event) => onLayerContextmenu(event, openDigupMenu)
            "
          />
        </template>
      </template>

      <div :class="{ hide: isLoadingAnomalies }">
        <WidgetCard
          :class="{ zIndex: selectedWidget === 'typesLegend' }"
          :sizeProps="widgets.typesLegend"
          heightFitContent
          disableMinimize
          :isResizable="false"
          :isDraggable="false"
          :sticks="[]"
          @click="selectedWidget = 'typesLegend'"
        >
          <template v-slot:title-text>
            {{ $t(widgets.typesLegend.title) }}
          </template>
          <AnomaliesLegend />
        </WidgetCard>
        <WidgetCard
          :class="{ zIndex: selectedWidget === 'wmsWidget' }"
          :sizeProps="widgets.wmsWidget"
          :show="widgets.wmsWidget.isOpen"
          @drag="(e) => onDrag(e, 'wmsWidget')"
          @minimize="minimizeWidget('wmsWidget')"
          :isResizable="false"
          :sticks="[]"
        >
          <template v-slot:title-text>
            {{ $t(widgets.wmsWidget.title) }}
          </template>
          <WMSWidget v-model:wmsLayers="wmsLayers" />
        </WidgetCard>
        <TableWidgetCard
          :class="{ zIndex: selectedWidget === 'table' }"
          :sizeProps="widgets.table"
          :show="widgets.table.isOpen"
          @drag="(e) => onDrag(e, 'table')"
          @minimize="minimizeWidget('table')"
          @click="selectedWidget = 'table'"
        >
          <template v-slot:title-text>
            {{ $t(widgets.table.title) }}
          </template>
          <template v-slot:title-prepend>
            <FilterPopupMenu v-model:show="showFilter" />
          </template>
          <AnomaliesTable />
        </TableWidgetCard>
        <WidgetCard
          :isActive="selectedWidget === 'heightProfile'"
          :class="{ zIndex: selectedWidget === 'heightProfile' }"
          :sizeProps="widgets.heightProfile"
          :show="widgets.heightProfile.isOpen"
          @drag="(e) => onDrag(e, 'heightProfile')"
          @minimize="minimizeWidget('heightProfile')"
        >
          <template v-slot:title-text>
            {{ $t(widgets.heightProfile.title) }}
          </template>
          <AnomaliesHeightChart
            :visibleDistance="visibleDistance"
            @graph-mousedown="selectedWidget = 'heightProfile'"
          />
        </WidgetCard>
        <WidgetCard
          v-if="widgets.cylinder.isOpen"
          :class="{ zIndex: selectedWidget === 'cylinder' }"
          :sizeProps="widgets.cylinder"
          @drag="(e) => onDrag(e, 'cylinder')"
          @minimize="minimizeWidget('cylinder')"
        >
          <template v-slot:title-text>
            {{ $t(widgets.cylinder.title) }}
          </template>
          <AnomaliesCylinder />
        </WidgetCard>
        <WMSLegend
          :class="{ zIndex: selectedWidget === 'wmsLegend' }"
          v-show="wmsLayers.length && widgets.wmsLegend.isOpen"
          :isOpen="widgets.wmsLegend.isOpen"
          :offsetTop="180"
          :wmsLayers="wmsLayers"
          :currentZoom="currentZoom"
          @click="selectedWidget = 'wmsLegend'"
          @maximize-self="widgets.wmsLegend.isOpen = true"
          :onMinimize="() => minimizeWidget('wmsLegend')"
        />
      </div>
    </ModifiedMapbox>
  </div>
</template>

<script>
import { computed } from "vue";
import { mapState, mapGetters, mapActions } from "vuex";

import {
  MapboxMarker,
  MapboxPopup,
  MapboxLayer,
  MapboxSource,
} from "@studiometa/vue-mapbox-gl";
import {
  AnomaliesHeightChart,
  AnomaliesCylinder,
  AnomaliesTable,
  AnomaliesLegend,
} from "@/pages/anomaly/components";
import {
  WidgetCard,
  MapInfoPopup,
  ModifiedMapbox,
  TableWidgetCard,
} from "@/components";
import { WMSLegend, WMSWidget } from "@/features/wms/components";
import { FilterPopupMenu } from "./components";

import { initializeResizeObserver } from "@/utils/resizeObserverUtil.js";

export default {
  name: "AnomaliesMapmode",
  components: {
    MapboxMarker,
    MapboxPopup,
    MapboxLayer,
    MapboxSource,
    ModifiedMapbox,
    MapInfoPopup,
    AnomaliesCylinder,
    AnomaliesHeightChart,
    FilterPopupMenu,
    AnomaliesLegend,
    WidgetCard,
    AnomaliesTable,
    WMSLegend,
    WMSWidget,
    TableWidgetCard,
  },

  provide() {
    return {
      parentW: computed(() => this.parentW),
      parentH: computed(() => this.parentH),
    };
  },

  data() {
    return {
      visibleDistance: [],
      parentH: null,
      parentW: null,

      widgets: {
        table: {
          title: "anomalies.widgets.table.maximize_title",
          width: 600,
          height: 385,
          left: 1,
          top: 100,
          isOpen: true,
        },
        heightProfile: {
          title: "dashboard.widgets.heightProfile.maximize_title",
          width: 700,
          height: 400,
          left: 5,
          top: 150,
          isOpen: true,
        },
        cylinder: {
          title: "anomalies.details.cylinder.title",
          width: 500,
          height: 500,
          left: 500,
          top: 350,
          isOpen: false,
        },
        typesLegend: {
          title: "anomalies.details.anomaly_type",
          height: 74,
          width: 200,
          left: 5,
          top: 50,
          isOpen: true,
        },
        wmsWidget: {
          title: "dashboard.widgets.color.maximize_title",
          width: 350,
          height: 100,
          left: 400,
          top: 3,
          isOpen: true,
        },
        wmsLegend: {
          title: "wms_layers.layers",
          isOpen: true,
        },
      },
      selectedWidget: null,
      resizeObserver: null,
      showFilter: false,
      currentZoom: null,
      wmsLayers: [],
    };
  },

  mounted() {
    this.resizeObserver = initializeResizeObserver.call(this, [
      "table",
      "heightProfile",
      "cylinder",
      "wmsWidget",
    ]);
    this.resizeObserver.observe(this.$el);

    this.widgets.table.left =
      this.$el.offsetWidth - this.widgets.table.width - 5;

    this.widgets.heightProfile.top =
      this.$el.offsetHeight - this.widgets.heightProfile.height - 5;

    this.widgets.cylinder.top =
      this.$el.offsetHeight - this.widgets.cylinder.height - 5;
    this.widgets.cylinder.left =
      this.$el.offsetWidth - this.widgets.cylinder.width - 5;

    this.widgets.wmsWidget.left =
      (this.$el.offsetWidth - this.widgets.wmsWidget.width) / 2;
  },

  beforeUnmount() {
    this.resizeObserver?.disconnect();
  },

  computed: {
    ...mapState(["mapStyle"]),
    ...mapState("anomalies", [
      "checkedAnomaliesSelector",
      "isLoadingAnomalies",
    ]),
    ...mapGetters("anomalies", ["selectedAnomaly", "anomalyMapData"]),

    map() {
      return this.$refs.modifiedMapRef.map;
    },
  },

  methods: {
    ...mapActions("anomalies", ["toggleSelectedAnomaly"]),

    onMoveend({ target: map }) {
      this.currentZoom = map.getZoom();
      if (map.getLayer("layer_distance_points")) {
        this.visibleDistance = map
          .queryRenderedFeatures({ layers: ["layer_distance_points"] })
          .map((l) => l.properties.distance);
      }
    },

    onDrag({ left, top }, key) {
      this.widgets[key].left = left;
      this.widgets[key].top = top;
    },

    onLayerContextmenu({ features }, callback) {
      const { source, properties } = features[0];
      if (source.includes("anomalies") && properties.isDigupSelected) {
        callback();
      }
    },

    onLayerClick({ features, originalEvent }) {
      const { ctrlKey, shiftKey } = originalEvent;

      if (features[0].source.includes("anomalies")) {
        const selectedId = features[0].properties.id;
        if (ctrlKey || shiftKey) {
          this.checkedAnomaliesSelector.set([selectedId]);
        } else {
          this.toggleSelectedAnomaly(null);
          Promise.resolve().then(() => this.toggleSelectedAnomaly(selectedId));
        }
      }
    },

    onLayerMouseEnter({ features, target }) {
      if (features && features[0].source.includes("anomalies")) {
        target.getCanvas().style.cursor = "pointer";
        this.map.boxZoom.disable();
      }
    },

    onLayerMouseLeave({ target }) {
      target.getCanvas().style.cursor = "";
      this.map.boxZoom.enable();
    },

    minimizeWidget(key) {
      this.widgets[key].isOpen = false;
    },

    maximizeWidget(key) {
      this.selectedWidget = key;
      this.widgets[key].isOpen = true;
    },
  },

  watch: {
    selectedAnomaly(anomaly) {
      if (anomaly) {
        this.map.flyTo({
          center: anomaly.geom.coordinates[0],
          zoom: this.currentZoom < 17 ? 17 : this.currentZoom,
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
#mapmod-container {
  padding: 0;
  margin: 0;
  width: 100%;
  height: 100%;
}

.hide {
  opacity: 0;
}

.zIndex {
  z-index: 5 !important;
}

.inactive {
  opacity: 0.8;
}
</style>
