import resultsMarker from "@/features/pipescanner/assets/icons/pipescanner_location_icon.png";
import infoPendingMarker from "@/features/pipescanner/assets/icons/pipescanner_location_icon_addinfo.png";
import analysisPendingMarker from "@/features/pipescanner/assets/icons/pipescanner_location_icon_analysis_2.png";

import {
  RESULTS,
  INFO_PENDING,
  ANALYSIS_PENDING,
} from "@/features/pipescanner/constants";

const SOURCE_ID = "scanners-source";
const SCANNERS_MARKERS_LAYER_ID = "scanner-markers";
const CLUSTERS_LAYER_ID = "clusters";

const groupScannersByCoordinates = (scanners) => {
  const groupedScanners = new Map();
  scanners.forEach((scanner) => {
    const coordinates =
      scanner.longitude.toFixed(6) + scanner.latitude.toFixed(6);
    const scannersWithSameCoordinates = groupedScanners.get(coordinates) || [];
    scannersWithSameCoordinates.push(scanner);
    groupedScanners.set(coordinates, scannersWithSameCoordinates);
  });

  return groupedScanners;
};

const scannersToGeoData = (scanners, scannerStatus) => {
  const groupedScanners = groupScannersByCoordinates(scanners);

  const features = scanners.map((scanner) => {
    const coordinates =
      scanner.longitude.toFixed(6) + scanner.latitude.toFixed(6);
    const scannersOnMarker = groupedScanners.get(coordinates);
    return {
      type: "Feature",
      properties: { ...scanner, status: scannerStatus, scannersOnMarker },
      geometry: {
        type: "Point",
        coordinates: [scanner.longitude, scanner.latitude],
      },
    };
  });

  return { type: "FeatureCollection", features };
};

export const getScannersClusterSourceAndLayers = (scanners, scannerStatus) => {
  const scannersGeoData = scannersToGeoData(scanners, scannerStatus);
  const sourceOptions = {
    type: "geojson",
    data: scannersGeoData,
    cluster: true,
    clusterMaxZoom: 17,
    clusterRadius: 50,
  };
  const layersOptions = [];

  layersOptions.push({
    id: CLUSTERS_LAYER_ID,
    type: "circle",
    source: SOURCE_ID,
    filter: ["has", "point_count"],
    paint: {
      "circle-color": [
        "step",
        ["get", "point_count"],
        "#51bbd6",
        10,
        "#f1f075",
        50,
        "#f28cb1",
      ],
      "circle-radius": ["step", ["get", "point_count"], 20, 10, 30, 50, 40],
    },
  });

  layersOptions.push({
    id: "cluster-count",
    type: "symbol",
    source: SOURCE_ID,
    filter: ["has", "point_count"],
    layout: {
      "text-field": ["get", "point_count_abbreviated"],
      "text-font": ["DIN Offc Pro Medium", "Arial Unicode MS Bold"],
      "text-size": 12,
    },
  });

  layersOptions.push({
    id: SCANNERS_MARKERS_LAYER_ID,
    type: "symbol",
    source: SOURCE_ID,
    filter: ["!", ["has", "point_count"]],
    layout: {
      "icon-image": [
        "match",
        ["get", "status"],
        [INFO_PENDING],
        `${INFO_PENDING}_icon`,
        [ANALYSIS_PENDING],
        `${ANALYSIS_PENDING}_icon`,
        `${RESULTS}_icon`,
      ],
      "icon-allow-overlap": true,
      "icon-size": 0.3,
      "icon-anchor": "bottom",
      "text-field": [
        "case",
        ["<=", ["length", ["get", "scannersOnMarker"]], 1],
        "",
        ["to-string", ["length", ["get", "scannersOnMarker"]]],
      ],
      "text-offset": [0, -1.1],
      "text-size": 15,
    },
  });

  return { sourceId: SOURCE_ID, sourceOptions, layersOptions };
};

const markers = {
  [RESULTS]: resultsMarker,
  [INFO_PENDING]: infoPendingMarker,
  [ANALYSIS_PENDING]: analysisPendingMarker,
};

const addMapScannerIcons = (map) => {
  Object.getOwnPropertyNames(markers).forEach((status) => {
    map.loadImage(markers[status], (error, image) => {
      if (error) throw error;
      map.addImage(`${status}_icon`, image);
    });
  });
};

export const addScannersSourceAndLayers = (
  map,
  { sourceId, sourceOptions, layersOptions },
  onClick,
  onContextClick,
  isFirstLaunch = true
) => {
  addMapScannerIcons(map);

  map.addSource(sourceId, sourceOptions);
  layersOptions.forEach((layer) => map.addLayer(layer));

  if (isFirstLaunch) {
    map.on("click", SCANNERS_MARKERS_LAYER_ID, onClick);
    map.on("contextmenu", SCANNERS_MARKERS_LAYER_ID, onContextClick);
    map.on("click", CLUSTERS_LAYER_ID, (e) => {
      const features = map.queryRenderedFeatures(e.point, {
        layers: [CLUSTERS_LAYER_ID],
      });
      const clusterId = features[0].properties.cluster_id;
      map
        .getSource(SOURCE_ID)
        .getClusterExpansionZoom(clusterId, (err, zoom) => {
          if (err) return;

          map.easeTo({
            center: features[0].geometry.coordinates,
            zoom: zoom,
          });
        });
    });

    map.on("mouseenter", [SCANNERS_MARKERS_LAYER_ID, CLUSTERS_LAYER_ID], () => {
      map.getCanvas().style.cursor = "pointer";
    });
    map.on("mouseleave", [SCANNERS_MARKERS_LAYER_ID, CLUSTERS_LAYER_ID], () => {
      map.getCanvas().style.cursor = "";
    });
  }
};
