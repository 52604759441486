<template>
  <v-list density="compact">
    <v-list-item @click="onAddCommentClick">
      <v-list-item-title style="display: flex; align-items: flex-end">
        <v-icon icon="mdi-plus"></v-icon>
        {{ $t("context_menu.add_comment") }}
      </v-list-item-title>
    </v-list-item>
  </v-list>
</template>

<script>
export default {
  name: "CommentMenu",
  props: {
    contextMenuParams: Object,
    openCommentEdit: Function,
  },

  methods: {
    onAddCommentClick() {
      this.openCommentEdit(this.contextMenuParams.lngLat);
    },
  },
};
</script>
