import { baseApi } from "@/baseApi";

import CancaledAPI from "@/utils/CanceledApi.js";

export default class AdministrationAPI extends CancaledAPI {
  constructor(getAccessTokenSilently, groupSelected) {
    super(getAccessTokenSilently, groupSelected);
  }

  async getGroupAvailableServices() {
    if (!this.token) {
      await this.updateToken();
    }
    const { signal } = this.refrershController("getGroupAvailableServices");

    const { data } = await baseApi({
      url: `/client/administration/v1/services/${this.group}`,
      method: "get",
      signal: signal,
      headers: { Authorization: `Bearer ${this.token}` },
    });

    return data;
  }

  async getServiceClients(service) {
    if (!this.token) {
      await this.updateToken();
    }

    const { data } = await baseApi({
      url: `/client/administration/v1/groups-complete/${service}`,
      method: "get",
      headers: { Authorization: `Bearer ${this.token}` },
    });

    return data;
  }

  async postInlineInspections(form) {
    if (!this.token) {
      await this.updateToken();
    }

    const { data } = await baseApi({
      url: `/client/administration/v1/inline-inspections/`,
      method: "post",
      headers: {
        Authorization: `Bearer ${this.token}`,
        "Content-Type": "application/json",
      },
      data: form,
    });

    return data;
  }

  async postPipescannerInspections(form) {
    if (!this.token) {
      await this.updateToken();
    }

    const { data } = await baseApi({
      url: `/client/administration/v1/pipescanner-inspections/`,
      method: "post",
      headers: {
        Authorization: `Bearer ${this.token}`,
        "Content-Type": "application/json",
      },
      data: form,
    });

    return data;
  }

  async editPipeline(form) {
    if (!this.token) {
      await this.updateToken();
    }

    const { data } = await baseApi({
      url: `/inline-inspections/edit-pipeline`,
      method: "post",
      headers: {
        Authorization: `Bearer ${this.token}`,
        "Content-Type": "application/json",
      },
      data: form,
    });

    return data;
  }
}
