<template>
  <div>
    <MapboxMap
      ref="mapRef"
      :accessToken="accessToken"
      :map-style="mapStyle"
      :center="initialCenter"
      :zoom="16"
      :style="{
        height: '450px',
        borderRadius: '4px',
      }"
    >
      <MapboxMarker
        :lng-lat="[longitude, latitude]"
        color="orange"
        draggable
        @mb-dragend="onMoveMarker"
      />
      <MapboxGeocoder
        :accessToken="accessToken"
        :marker="false"
        @mb-result="onSelectGeocoderResult"
      />
    </MapboxMap>
    <v-card class="handle-inputs">
      <v-text-field
        type="number"
        :label="$t('location.longitude')"
        :model-value="longitude"
        @update:modelValue="updateLongitude"
        :step="0.00002"
        hide-details
        variant="underlined"
      />
      <v-text-field
        type="number"
        :label="$t('location.latitude')"
        :model-value="latitude"
        @update:modelValue="updateLatitude"
        :step="0.00002"
        hide-details
        variant="underlined"
      />
    </v-card>
  </div>
</template>

<script>
import {
  MapboxMap,
  MapboxMarker,
  MapboxGeocoder,
} from "@studiometa/vue-mapbox-gl";
import { ACCESS_KEY, STYLE_MAP } from "@/constants/mapBox";
import "@mapbox/mapbox-gl-geocoder/lib/mapbox-gl-geocoder.css";

export default {
  name: "LocationUpdate",
  emits: ["update:longitude", "update:latitude"],
  components: {
    MapboxMap,
    MapboxMarker,
    MapboxGeocoder,
  },
  props: {
    longitude: {
      type: Number,
      default: 0,
    },
    latitude: {
      type: Number,
      default: 0,
    },
  },
  setup() {
    return {
      mapStyle: STYLE_MAP,
      accessToken: ACCESS_KEY,
    };
  },

  data() {
    return {
      initialCenter: [this.longitude, this.latitude],
      timer: undefined,
    };
  },

  methods: {
    updateLongitude(longitude) {
      this.$emit("update:longitude", +longitude);
    },

    updateLatitude(latitude) {
      this.$emit("update:latitude", +latitude);
    },

    onMoveMarker({ target }) {
      const { lng, lat } = target.getLngLat();
      this.updateLongitude(lng);
      this.updateLatitude(lat);
    },

    onSelectGeocoderResult({ result }) {
      const [lng, lat] = result.center;
      this.updateLongitude(lng);
      this.updateLatitude(lat);
    },

    delayedFlyToPipe() {
      if (this.timer) {
        clearTimeout(this.timer);
      }
      this.timer = setTimeout(() => {
        this.$refs.mapRef.map.flyTo({
          center: [this.longitude, this.latitude],
        });
      }, 500);
    },
  },

  watch: {
    longitude() {
      this.delayedFlyToPipe();
    },

    latitude() {
      this.delayedFlyToPipe();
    },
  },
};
</script>

<style lang="scss" scoped>
.handle-inputs {
  margin-top: 1rem;
  padding: 1rem 1.5rem 1.5rem;
  display: flex;
  .v-text-field {
    margin-right: 1.5rem;
  }
}


</style>
