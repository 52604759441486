export default class CancaledAPI {
  constructor(getAccessTokenSilently, groupSelected) {
    this.$auth0 = getAccessTokenSilently;
    this.token = null;
    this.group = groupSelected;
    this.controllers = new Map();
  }

  updateGroup(newGroup) {
    this.group = newGroup;
    this.abortAll();
  }

  async updateToken() {
    this.token = await this.$auth0();
  }

  refrershController(controllerName) {
    if (this.controllers.has(controllerName)) {
      this.controllers.get(controllerName).abort();
    }

    const controller = new AbortController();
    this.controllers.set(controllerName, controller);

    return controller;
  }

  abort(controllerName) {
    const controller = this.controllers.get(controllerName);
    if (controller) {
      controller.abort();
    }
  }

  abortAll() {
    for (const controller of this.controllers.values()) {
      controller.abort();
    }
  }
}
