import { dayjs } from "@/config/dayjs";
import { ScannerType } from "@/pages/scanner-control/config";

import { rangeFiltering } from "@/utils/filterHelpers";

export function filterScanners(scanners, scannerType, filters) {
  const {
    search,
    inspectionDateRange: [dateFrom, dateTo],
    materials,
    diameterRange,
    constuctionYearRange: [constuctionFrom, constuctionTo],
  } = filters;

  let filteredScanners = scanners.filter(
    (scanner) =>
      dayjs(scanner?.inspection_date).isSameOrAfter(dayjs(dateFrom)) &&
      dayjs(scanner?.inspection_date).isSameOrBefore(dayjs(dateTo))
  );

  if (materials.length) {
    filteredScanners = filteredScanners.filter((scanner) =>
      materials.includes(scanner?.material)
    );
  }

  if (search.length > 2 && scannerType !== ScannerType.InfoPending) {
    const normalizedSearch = search.toLowerCase().trim();

    filteredScanners = filteredScanners.filter((scanner) => {
      const tag = scanner.tag.toLowerCase().trim();
      const address = scanner.address?.toLowerCase().trim();

      return (
        tag.includes(normalizedSearch) || address?.includes(normalizedSearch)
      );
    });
  }

  if (scannerType === ScannerType.Results) {
    filteredScanners = rangeFiltering(
      filteredScanners,
      "diameter",
      diameterRange
    );

    if (constuctionFrom.length === 4) {
      filteredScanners = filteredScanners.filter(
        (scanner) => constuctionFrom <= scanner?.constructionyear
      );
    }

    if (constuctionTo.length === 4) {
      filteredScanners = filteredScanners.filter(
        (scanner) => constuctionTo >= scanner?.constructionyear
      );
    }
  }

  return filteredScanners;
}
