<template>
  <AdminProvider>
    <MainLayoutVue v-if="isAuthenticated">
      <router-view />
    </MainLayoutVue>
  </AdminProvider>
</template>

<script>
import { useAuth0 } from "@auth0/auth0-vue";

import { AdminProvider } from "@/features/administration/providers";
import MainLayoutVue from "./layouts/MainLayout/MainLayout.vue";

export default {
  components: {
    AdminProvider,
    MainLayoutVue,
  },
  data() {
    return {
      isAuthenticated: useAuth0().isAuthenticated,
    };
  },
};
</script>
