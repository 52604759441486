<template>
  <v-card rounded="lg">
    <v-card-title class="font-weight-thin">
      {{ $t("dashboard.pipelines.table_name") }}
    </v-card-title>
    <v-card-text>
      <div v-if="isLoading" class="text-center">
        <v-progress-circular
          color="dark-blue"
          indeterminate
          :size="52"
          :width="5"
        ></v-progress-circular>
      </div>
      <div v-else class="container-table max-height-70">
        <table class="table">
          <thead>
            <tr>
              <THead title="client_pipeline_id"></THead>
              <THead title="pipeline_name"></THead>
              <THead title="diameters"></THead>
              <THead title="materials"></THead>
              <THead title="construction_year"></THead>
            </tr>
          </thead>
          <tbody>
            <v-tooltip
              v-for="item in sortedPipelines"
              :key="item.id"
              :open-delay="450"
              location="bottom"
            >
              <template v-slot:default>
                <MapboxMap
                  :accessToken="accessToken"
                  :map-style="mapStyle"
                  @mb-created="
                    (map) => fitMapToCoordinates(map, item.geom.coordinates)
                  "
                  style="
                    width: 400px;
                    height: 250px;
                    margin: 2px 0;
                    border-radius: 4px;
                  "
                >
                  <MapboxSource
                    :id="item.id"
                    :options="{
                      type: 'geojson',
                      data: {
                        type: 'Feature',
                        properties: {
                          name: item.pipeline_name,
                        },
                        geometry: {
                          type: item.geom.type,
                          coordinates: item.geom.coordinates,
                        },
                      },
                    }"
                  />
                  <MapboxLayer
                    :id="`layer_${item.id}`"
                    :options="{
                      type: 'line',
                      source: item.id,
                      layout: { visibility: 'visible' },
                      paint: {
                        'line-color': '#24e3c6',
                        'line-width': 5,
                        'line-opacity': 1,
                      },
                    }"
                  />
                </MapboxMap>
              </template>
              <template v-slot:activator="{ props }">
                <tr
                  v-bind="props"
                  @click="onSelect(item.id)"
                  :class="{
                    active: selectedPipelineId === item.id,
                  }"
                >
                  <td>{{ item.client_pipeline_id }}</td>
                  <td>{{ item.pipeline_name }}</td>
                  <td>{{ item.diameters.join(", ") }}</td>
                  <td>{{ materialsCellValue(item.materials) }}</td>
                  <td>{{ item.construction_year }}</td>
                </tr>
              </template>
            </v-tooltip>
          </tbody>
        </table>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import { computed } from "vue";
import {
  MapboxMap,
  MapboxLayer,
  MapboxSource,
} from "@studiometa/vue-mapbox-gl";
import { THead } from "@/components";
import { getSortedArray, getNextSortingOrder } from "@/utils/tableHelpers.js";
import { fitMapToCoordinates } from "@/utils/mapHelpers.js";
import { ACCESS_KEY, STYLE_MAP } from "@/constants/mapBox";

export default {
  name: "PipelinesTable",

  components: { MapboxMap, MapboxSource, MapboxLayer, THead },

  props: {
    pipelines: Array,
    onSelect: Function,
    selectedPipelineId: String,
    isLoading: Boolean,
  },

  provide() {
    return {
      sortedBy: computed(() => this.sortedBy),
      sortFunction: this.setSortedBy,
      localePath: "dashboard.pipelines.table_titles.",
    };
  },

  setup() {
    return {
      mapStyle: STYLE_MAP,
      accessToken: ACCESS_KEY,
    };
  },

  data: () => ({
    sortedBy: {
      keyName: null,
      order: "asc",
    },
  }),

  computed: {
    sortedPipelines() {
      return getSortedArray(
        this.pipelines,
        this.sortedBy.order,
        this.sortedBy.keyName
      );
    },
  },

  methods: {
    setSortedBy(key) {
      const sortedBy = getNextSortingOrder(this.sortedBy, key);
      this.sortedBy = sortedBy;
    },

    materialsCellValue(materials) {
      return materials.map((m) => this.$t(`materials.${m}`)).join(", ");
    },

    fitMapToCoordinates(map, coordinates) {
      fitMapToCoordinates(map, coordinates, { linear: true });
    },
  },
};
</script>
