<template>
  <div @click.stop="$emit('click', !isExpanded)">
    <img
      v-if="isExpanded"
      src="@/assets/img/horizontal-line.png"
      class="show-icon"
    />
    <img
      v-else
      src="@/assets/img/horizontal-line-collapse.png"
      class="show-icon"
    />
  </div>
</template>

<script>
export default {
  name: "ExpandButton",

  props: ["isExpanded"],

  emits: ["click"],
};
</script>

<style scoped lang="scss">
.show-icon {
  width: 25px;
  height: 25px;
}
</style>
