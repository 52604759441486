import { getUncertaintiesWithColors, soilTypeColors } from "@/colorLegends";
import {
  findMainUncertainty,
  getSafetyLevel,
} from "@/pages/safety-factor/helpers";
import { unitsConverter } from "@/config/units";
import {
  getMinMaxValue,
  rangeFiltering,
  valueFiltering,
} from "@/utils/filterHelpers";
import { i18n } from "@/config/i18n";

import { VIEW_FACTORS_WITHOUT_GRADIENT } from "@/pages/safety-factor/config";

export const safetyFactorGetters = {
  hasActiveFilters(state, getters) {
    return Object.entries(state.filters).some(([key, value]) => {
      if (["selectedPipePartsTypes", "selectedMaterials"].includes(key)) {
        return value.length > 0;
      }

      if (key === "selectedDistance") {
        return (
          getters.minMaxDistance[0] !== value[0] ||
          getters.minMaxDistance[1] !== value[1]
        );
      }
    });
  },

  selectedPipePart(state) {
    if (!state.selectedPipePartId) return;

    return state.pipeParts.find(({ id }) => id === state.selectedPipePartId);
  },

  filteredPipeParts(state) {
    const {
      plotlyFilter,
      selectedSafetyRange,
      selectedLowerSafetyRange,
      selectedUpperSafetyRange,
      selectedMainUncertainties,
    } = state.filters;
    const uncertaintiesWithColors = getUncertaintiesWithColors(i18n.global.t);

    let filtered = state.pipeParts.map((part) => {
      const converted = {
        ...part,
        safety_factor_calculation: { ...part.safety_factor_calculation },
      };

      converted.distance = unitsConverter.instance.convert(
        converted.distance,
        "m"
      );
      converted.length = unitsConverter.instance.convert(converted.length, "m");

      if (!converted.safety_factor_calculation) {
        return converted;
      }

      [
        "ground_level_mean",
        "ground_level_std",
        "ground_water_level_min",
        "ground_water_level_max",
        "road_distance_mean",
        "road_distance_std",
      ].forEach((key) => {
        converted.safety_factor_calculation[key] =
          unitsConverter.instance.convert(
            converted.safety_factor_calculation[key],
            "m"
          );
      });

      const mainUncertainty = findMainUncertainty(converted);

      converted.safety_factor_calculation.main_uncertainty =
        uncertaintiesWithColors[mainUncertainty];

      return converted;
    });

    filtered = rangeFiltering(
      filtered,
      "safety_factor_calculation.safety_factor",
      selectedSafetyRange
    );

    filtered = rangeFiltering(
      filtered,
      "safety_factor_calculation.safety_factor_lower",
      selectedLowerSafetyRange
    );

    filtered = rangeFiltering(
      filtered,
      "safety_factor_calculation.safety_factor_upper",
      selectedUpperSafetyRange
    );

    filtered = valueFiltering(
      filtered,
      "safety_factor_calculation.main_uncertainty.text",
      selectedMainUncertainties
    );

    if (plotlyFilter.property !== "") {
      const { property, range } = plotlyFilter;

      filtered = rangeFiltering(
        filtered,
        "safety_factor_calculation." + property,
        range
      );
    }

    return filtered;
  },

  minMaxSafetyRange(state) {
    return getMinMaxValue(
      state.pipeParts,
      "safety_factor_calculation.safety_factor",
      true
    );
  },

  minMaxLowerSafetyRange(state) {
    return getMinMaxValue(
      state.pipeParts,
      "safety_factor_calculation.safety_factor_lower",
      true
    );
  },

  minMaxUpperSafetyRange(state) {
    return getMinMaxValue(
      state.pipeParts,
      "safety_factor_calculation.safety_factor_upper",
      true
    );
  },

  gradientMinMaxValues(state, getters) {
    if (VIEW_FACTORS_WITHOUT_GRADIENT.includes(state.selectedViewFactor)) {
      return [];
    }

    return getMinMaxValue(
      getters.filteredPipeParts,
      "safety_factor_calculation." + state.selectedViewFactor
    );
  },

  uniqueSafetyLevels(state) {
    if (!state.selectedViewFactor.includes("safety_factor")) {
      return [];
    }

    const uniqueList = new Set();

    state.pipeParts.forEach((part) => {
      if (part.safety_factor_calculation) {
        const level = getSafetyLevel(
          part.safety_factor_calculation[state.selectedViewFactor]
        );

        uniqueList.add(level);
      }
    });

    const orderedLevelsList = Array.from(uniqueList).sort((a, b) =>
      a.index > b.index ? 1 : -1
    );

    return orderedLevelsList;
  },

  uniqueUncertaintiesOptions(state) {
    const allMainUncertainties = state.pipeParts.map((part) =>
      findMainUncertainty(part)
    );

    const uniqueList = Array.from(new Set(allMainUncertainties));
    const uncertaintiesWithColors = getUncertaintiesWithColors(i18n.global.t);

    return uniqueList.map((key) => uncertaintiesWithColors[key]);
  },

  uniqueSoilTypes(state) {
    const uniqueList = new Set();

    state.pipeParts.forEach((part) => {
      if (part.safety_factor_calculation) {
        uniqueList.add(part.safety_factor_calculation.soil_type);
      }
    });

    const uniqueSoilTypesWithColors = Array.from(uniqueList).map(
      (type) => soilTypeColors[type]
    );

    return uniqueSoilTypesWithColors;
  },

  legendList(state, getters) {
    if (state.selectedViewFactor.includes("safety_factor")) {
      return getters.uniqueSafetyLevels;
    }

    if (state.selectedViewFactor === "main_contributor") {
      return getters.uniqueUncertaintiesOptions;
    }

    if (state.selectedViewFactor === "soil_type") {
      return getters.uniqueSoilTypes;
    }

    return [];
  },
};
