<template>
  <ResizableVirtualTable
    :headers="displayedHeaders"
    :items="filteredJoints"
    :header-slots="headerSlots"
    :item-slots="itemSlots"
    :is-item-active="isJointActive"
    @click:row="(joint) => $emit('click:row', joint.id)"
  >
    <template v-slot:[`header.select`]>
      <v-icon
        v-if="checkedLength > 0"
        icon="mdi-minus-box-outline"
        size="small"
        @click.stop="jointsSelector.clear()"
      />
    </template>

    <template v-slot:[`item.select`]="{ item: joint }">
      <input
        :value="joint.id"
        :disabled="
          shouldDisableCheckbox(joint.id, filteredJoints, jointsSelector)
        "
        :checked="jointsSelector.has(joint.id)"
        @input="jointsSelector.setByCtrl(joint.id)"
        @click.stop
        type="checkbox"
      />
    </template>
  </ResizableVirtualTable>
</template>

<script>
import { mapState, mapGetters } from "vuex";

import { ResizableVirtualTable } from "@/components";

import { shouldDisableCheckbox } from "@/features/dig-up/helpers";
import { getDegradationColorJoints } from "@/colorLegends";

import { MODES_WITHOUT_ADDITIONAL_COLUMNS } from "./config";

export default {
  name: "JointsTableRegular",

  components: { ResizableVirtualTable },

  props: {
    checkedLength: Number,
    isJointActive: Function,
  },

  emits: ["click:row"],

  setup() {
    return {
      shouldDisableCheckbox,
    };
  },

  data() {
    return {
      headers: [
        {
          title: this.$t("joints.table_titles.joint_id"),
          key: "joint_id",
          value: "joint_id",
        },
        {
          title: this.$t("joints.table_titles.joint_type"),
          key: "joint_type",
          value: "joint_type",
        },
        {
          title: this.$t("joints.table_titles.material"),
          key: "material",
          value: "material",
        },
      ],
    };
  },

  computed: {
    ...mapGetters("joints", ["filteredJoints"]),
    ...mapState("joints", ["jointsSelector", "selectedGradientMode"]),

    additionalHeaders() {
      if (
        MODES_WITHOUT_ADDITIONAL_COLUMNS.includes(this.selectedGradientMode)
      ) {
        return [];
      }

      return [
        {
          title: this.$t(`joints.table_titles.${this.selectedGradientMode}`),
          key: "selected_gradient",
          value: (joint) => this.getSelectedGradientCellValue(joint),
        },
      ];
    },

    displayedHeaders() {
      return this.headers.concat(this.additionalHeaders);
    },

    headerSlots() {
      return ["header.select"];
    },

    itemSlots() {
      return ["item.select"];
    },
  },

  methods: {
    getSelectedGradientCellValue(joint) {
      switch (this.selectedGradientMode) {
        case "angle_vertical":
        case "angle_horizontal":
          return Number(joint[this.selectedGradientMode]).toFixed(2);

        case "degradation_level":
          return getDegradationColorJoints(this.$t)[
            joint[this.selectedGradientMode]
          ].text;

        default:
          return null;
      }
    },
  },
};
</script>
