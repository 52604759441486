import { i18n } from "@/config/i18n";
import { unitsConverter } from "@/config/units";

import { LOCALE_LS_KEY, UNITS_LS_KEY } from "@/store/settings/config";

export const settingsActions = {
  setLocale({ commit }, locale) {
    commit("setLocale", locale);
    localStorage.setItem(LOCALE_LS_KEY, locale);
    i18n.global.locale = locale;
  },

  setUnits({ commit }, units) {
    commit("setUnits", units);
    localStorage.setItem(UNITS_LS_KEY, units);
    unitsConverter.instance.current.value = units;
  },
};
