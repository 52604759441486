export function getPipePartsOptions(hasRemainingLife, translate, converter) {
  const localePath = "pipe_parts.details.map_select";
  const gradientArr = [
    {
      value: "degradation_level",
      title: translate(`${localePath}.degradation_level`),
    },
    {
      value: "material",
      title: translate(`${localePath}.inspection_results.list.material`),
    },
    {
      value: "quality",
      title: translate(`${localePath}.inspection_results.list.quality`),
    },
    {
      value: "quality_anomaly",
      title: translate(`${localePath}.inspection_results.list.quality_anomaly`),
    },
    {
      value: "remaining_mean",
      title:
        translate(`${localePath}.inspection_results.list.remaining_mean`) +
        ` (${converter.getAbbr("mm")})`,
    },
    {
      value: "remaining_min",
      title:
        translate(`${localePath}.inspection_results.list.remaining_min`) +
        ` (${converter.getAbbr("mm")})`,
    },
    {
      value: "remaining_max",
      title:
        translate(`${localePath}.inspection_results.list.remaining_max`) +
        ` (${converter.getAbbr("mm")})`,
    },
    {
      value: "remaining_std",
      title:
        translate(`${localePath}.inspection_results.list.remaining_std`) +
        ` (${converter.getAbbr("mm")})`,
    },
    {
      value: "degradated_total_mean",
      title:
        translate(
          `${localePath}.inspection_results.list.degradated_total_mean`
        ) + ` (${converter.getAbbr("mm")})`,
    },
    {
      value: "degradated_total_min",
      title:
        translate(
          `${localePath}.inspection_results.list.degradated_total_min`
        ) + ` (${converter.getAbbr("mm")})`,
    },
    {
      value: "degradated_total_max",
      title:
        translate(
          `${localePath}.inspection_results.list.degradated_total_max`
        ) + ` (${converter.getAbbr("mm")})`,
    },
    {
      value: "degradated_total_std",
      title:
        translate(
          `${localePath}.inspection_results.list.degradated_total_std`
        ) + ` (${converter.getAbbr("mm")})`,
    },
    {
      value: "ovality_mean",
      title: translate(`${localePath}.inspection_results.list.ovality_mean`),
    },
    {
      value: "ovality_max",
      title: translate(`${localePath}.inspection_results.list.ovality_max`),
    },
    {
      value: "horizontal_out_of_straight",
      title:
        translate(
          `${localePath}.inspection_results.list.horizontal_out_of_straight`
        ) + ` (${converter.getAbbr("m")})`,
    },
    {
      value: "vertical_out_of_straight",
      title:
        translate(
          `${localePath}.inspection_results.list.vertical_out_of_straight`
        ) + ` (${converter.getAbbr("m")})`,
    },
    {
      value: "diameter_class",
      title:
        translate(`${localePath}.inspection_results.list.diameter_class`) +
        ` (${converter.getAbbr("mm")})`,
    },
    {
      value: "wall_thickness_original",
      title:
        translate(
          `${localePath}.inspection_results.list.wall_thickness_original`
        ) + ` (${converter.getAbbr("mm")})`,
    },
  ];

  if (hasRemainingLife) {
    gradientArr.unshift(
      {
        value: "remaining_life_calculation.remaining_life_group",
        title: translate(`${localePath}.remaining_life_group`),
      },
      {
        value: "remaining_life_calculation.soil_type",
        title: translate(
          `${localePath}.remaining_life_calculation.list.soil_type`
        ),
      },
      {
        value: "remaining_life_calculation.road_type",
        title: translate(
          `${localePath}.remaining_life_calculation.list.road_type`
        ),
      },
      {
        value: "remaining_life_calculation.road_distance",
        title:
          translate(
            `${localePath}.remaining_life_calculation.list.road_distance`
          ) + ` (${converter.getAbbr("m")})`,
      },
      {
        value: "remaining_life_calculation.degradation_rate",
        title: translate(
          `${localePath}.remaining_life_calculation.list.degradation_rate`
        ),
      },
      {
        value: "remaining_life_calculation.ground_water_level",
        title:
          translate(
            `${localePath}.remaining_life_calculation.list.ground_water_level`
          ) + ` (${converter.getAbbr("m")})`,
      }
    );
  }

  return gradientArr;
}
