<template>
    <div>
      <slot></slot>
    </div>
  </template>
  
  <script>
  import { computed } from "vue";
  import { mapState } from "vuex";
    
  import AdministrationAPI from "@/features/administration/api";
  
  export default { 
    provide() {
      return {
        administrationAPI: computed(() => this.administrationAPI),
        isAdmin: computed(() => this.isAdmin),
      }
    },
  
    computed: {
      ...mapState(["selectedGroup", "userPermissions"]),
  
      isAdmin() {
        return this.userPermissions.includes("client:admin");
      },
  
      administrationAPI() {
        if (!this.isAdmin) {
          return undefined;
        }
        return new AdministrationAPI(
          this.$auth0.getAccessTokenSilently,
          this.selectedGroup
        );
      },
    },
  };
  </script>
  