import { createAuth0 } from "@auth0/auth0-vue";
import AuthConfig from "../../auth_config.json";

export const auth0 = createAuth0({
  domain: AuthConfig.domain,
  clientId: AuthConfig.clientId,
  authorizationParams: {
    redirect_uri: window.location.origin,
    audience: AuthConfig.audience,
  },
});
