<template>
  <div style="display: flex; align-items: center">
    <v-menu v-model="menu" :transition="false" :close-on-content-click="false">
      <template v-slot:activator="{ props }">
        <v-btn
          class="date-picker"
          v-bind="props"
          density="compact"
          variant="text"
          icon="mdi-calendar"
        ></v-btn>
      </template>
      <v-date-picker
        v-model="datePickerValue"
        color="primary"
        :max="max"
        :min="min"
      ></v-date-picker>
      <div class="date-picker-actions">
        <v-btn @click="menu = false">Cancel</v-btn>
        <v-btn @click="saveValue">Ok</v-btn>
      </div>
    </v-menu>
    <v-text-field
      :model-value="textFieldValue"
      @update:modelValue="manualInput"
      :rules="rules"
      :label="label"
      placeholder="YYYY-MM-DD"
      clearable
      variant="underlined"
    >
    </v-text-field>
  </div>
</template>

<script>
import dayjs from "dayjs";

const dateRegex = /^\d{4}-(0[1-9]|1[0-2])-(0[1-9]|[12][0-9]|3[01])$/;
const dashRegex = /^(?=(?:[^-]*-){2}[^-]*$).+$/;

export default {
  name: "DatePicker",
  emits: ["update:dateValue"],
  props: {
    dateValue: String,
    label: String,
    max: Object,
    min: Object,
  },
  data() {
    return {
      menu: false,
      datePickerValue: this.dateValue ? new Date(this.dateValue) : new Date(),
      textFieldValue: this.dateValue || dayjs().format("YYYY-MM-DD"),
    };
  },
  computed: {
    rules() {
      return [
        (value) => {
          return !!value || "Required";
        },
        (value) => {
          if (value.length < 10 || dateRegex.test(value)) return true;
          return "Please enter correct date (yyyy-mm-dd)";
        },
      ];
    },
  },
  methods: {
    saveValue() {
      this.menu = false;
      if (this.datePickerValue) {
        const formatted = dayjs(this.datePickerValue).format("YYYY-MM-DD");
        this.textFieldValue = formatted;
      }
    },

    manualInput(value) {
      let text = value || "";
      if (
        (text.length === 4 && !dashRegex.test(text)) ||
        (text.length === 7 && !dashRegex.test(text))
      ) {
        text += "-";
      }
      this.textFieldValue = text;
    },
  },

  watch: {
    textFieldValue(newDate) {
      if (dateRegex.test(newDate)) {
        this.datePickerValue = new Date(newDate);
        this.$emit("update:dateValue", newDate);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.v-btn.date-picker {
  opacity: 0.5;
  margin-right: 8px;
}

.date-picker-actions {
  display: flex;
  position: absolute;

  .v-btn:first-child {
    margin-right: 6px;
  }

  top: 8px;
  right: 12px;
}

.v-text-field {
  min-width: 130px;
}
</style>
