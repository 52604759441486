export function getMinMaxWallThickness(pipeParts) {
  if (!pipeParts || !pipeParts.length) return null;

  const pipePartsKeys = [
    "remaining_mean",
    "remaining_min",
    "remaining_max",
    "remaining_std",
    "degradated_total_mean",
    "degradated_total_min",
    "degradated_total_max",
    "degradated_total_std",
    "quality",
  ];

  const initialAccumulator = {};

  pipePartsKeys.forEach((key) => {
    initialAccumulator[key] = [Infinity, -Infinity];
  });

  return pipeParts.reduce((result, el) => {
    pipePartsKeys.forEach((key) => {
      result[key] = [
        Math.min(result[key][0], el[key]),
        Math.max(result[key][1], el[key]),
      ];
    });

    return result;
  }, initialAccumulator);
}
