<template>
  <div style="position: relative">
    <v-progress-circular
      color="#24e3c6"
      indeterminate
      :size="36"
    ></v-progress-circular>
    <v-icon
      icon="mdi-shovel"
      color="#24e3c6"
      style="position: absolute; left: 7px; top: 6px"
    ></v-icon>
  </div>
</template>

<script>
export default {
  name: "DigUpLoader",
};
</script>
