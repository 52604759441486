<template>
  <div class="wrap">
    <div class="map-block">
      <v-select
        :model-value="selectedViewFactor"
        @update:modelValue="(value) => $emit('select-safety-factor', value)"
        :items="safetyFactorOptions"
        variant="underlined"
        hide-details
      >
        <template v-slot:item="{ item, props }">
          <v-list-subheader
            v-if="props.header"
            :style="{
              paddingInlineStart: '10px !important',
            }"
          >
            {{ props.header }}
          </v-list-subheader>
          <v-divider v-else-if="props.divider" />
          <v-list-item
            v-else
            v-bind="props"
            :disabled="item.raw.disabled"
          ></v-list-item>
        </template>
      </v-select>
      <div style="height: 450px">
        <ModifiedMapbox
          ref="modifiedMapRef"
          hideMapControls
          style="border-radius: 4px"
        >
          <MapboxMarker
            v-if="selectedPipePart"
            :lng-lat="selectedPipePart.geom.coordinates[0]"
            color="orange"
          />
          <template v-slot:sources>
            <template
              v-for="{ id, sourceOptions, layerOptions } in mapData"
              :key="id"
            >
              <MapboxSource :id="id" :options="sourceOptions" />
              <MapboxLayer
                :id="layerOptions.id"
                :options="layerOptions"
                @mb-mouseenter="onLayerMouseEnter"
                @mb-mouseleave="onLayerMouseLeave"
                @mb-click="onLayerClick"
              />
            </template>
          </template>
          <SafetyColorsLegend v-if="!showGradient" class="legend" />
        </ModifiedMapbox>
      </div>
    </div>
    <div v-if="showGradient">
      <SafetyGradient />
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapState } from "vuex";

import {
  MapboxMarker,
  MapboxLayer,
  MapboxSource,
} from "@studiometa/vue-mapbox-gl";
import {
  SafetyColorsLegend,
  SafetyGradient,
} from "@/pages/safety-factor/components";
import { ModifiedMapbox } from "@/components";

import { VIEW_FACTORS_WITHOUT_GRADIENT } from "@/pages/safety-factor/config";

export default {
  name: "SafetyFactorMap",

  props: {
    mapData: Array,
    safetyFactorOptions: Array,
  },

  components: {
    ModifiedMapbox,
    MapboxLayer,
    MapboxSource,
    MapboxMarker,
    SafetyGradient,
    SafetyColorsLegend,
  },

  computed: {
    ...mapState("safetyFactor", ["selectedViewFactor"]),

    ...mapGetters("safetyFactor", ["selectedPipePart"]),

    showGradient() {
      return !VIEW_FACTORS_WITHOUT_GRADIENT.includes(this.selectedViewFactor);
    },

    map() {
      return this.$refs.modifiedMapRef.map;
    },
  },

  methods: {
    ...mapMutations("safetyFactor", ["setSelectedPipePartId"]),

    onLayerClick({ features }) {
      if (features && features[0].source.includes("pipe_parts")) {
        this.setSelectedPipePartId(features[0].properties.id);
      }
    },

    onLayerMouseEnter({ features, target }) {
      if (features && features[0].source.includes("pipe_parts")) {
        target.getCanvas().style.cursor = "pointer";
      }
    },

    onLayerMouseLeave({ target }) {
      target.getCanvas().style.cursor = "";
    },
  },

  watch: {
    selectedPipePart(selected, prevSelected) {
      if (selected && selected.id !== prevSelected?.id) {
        this.map.flyTo({
          center: selected.geom.coordinates[0],
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.wrap {
  display: flex;

  .map-block {
    width: 100%;
    margin-bottom: 1.2rem;
    position: relative;

    .v-select {
      padding: 0 0.4rem;
      margin-bottom: 0.8rem;
    }

    .legend {
      position: absolute;
      z-index: 5;
      bottom: 6px;
      right: 5px;
      opacity: 0.9;
      user-select: none;
    }

    .map-info {
      position: absolute;
      bottom: 6px;
      left: 5px;
      border-radius: 6px;
      background-color: white;
      opacity: 0.9;
    }

    .open-info-btn {
      position: absolute;
      bottom: 6px;
      left: 5px;
      opacity: 0.8;

      &:hover {
        opacity: 1;
      }
    }
  }
}
</style>
