<template>
  <div class="header-container">
    <div>
      <slot></slot>
    </div>
    <VIcon class="v-data-table-header__sort-icon" :icon="getSortIcon(column)" />
  </div>
</template>

<script>
export default {
  name: "SortableCustomHeader",

  props: {
    column: Object,
    getSortIcon: Function,
    toggleSort: Function,
  },
};
</script>

<style scoped lang="scss">
.header-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 3px;
}
</style>
