import mapboxGl from "mapbox-gl";

const DEFAULT_OPTIONS = {
  padding: 50,
};

export function fitMapToCoordinates(
  map,
  coordinates,
  options = DEFAULT_OPTIONS
) {
  const boundingBox = new mapboxGl.LngLatBounds(coordinates[0], coordinates[0]);

  for (const coordinatesPair of coordinates) {
    boundingBox.extend(coordinatesPair);
  }

  map.fitBounds(boundingBox, options);
}
