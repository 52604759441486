<template>
  <div style="height: 100%" :id="plotlyId" ref="chartContainer"></div>
</template>

<script>
import { v4 as uuidv4 } from "uuid";
import Plotly from "plotly.js-dist";
import events from "./events";

export default {
  name: "PlotlyGraph",

  data() {
    return {
      resizeObserverCallbackId: undefined,
      resizeObserver: null,
      datarevision: 1,
    };
  },

  setup() {
    return {
      plotlyId: `plotly-${uuidv4()}`,
    };
  },

  computed: {
    internalLayout() {
      const internalLayout = {
        ...this.layout,
        autosize: true,
        datarevision: this.datarevision,
      };

      return internalLayout;
    },
  },

  props: ["data", "layout", "config"],

  mounted() {
    this.setGraph();
    const chartContainer = this.$refs.chartContainer;

    const resizeObserver = new ResizeObserver((entries) => {
      clearTimeout(this.resizeObserverCallbackId);
      this.resizeObserverCallbackId = setTimeout(
        () => this.handleResize(entries),
        200
      ); // debounce resize
    });

    this.resizeObserver = resizeObserver;
    resizeObserver.observe(chartContainer);
  },

  beforeUnmount() {
    this.resizeObserver.disconnect();
    events.forEach((e) => this.$el.removeAllListeners(e.completeName));
  },

  methods: {
    setGraph() {
      const chartContainer = this.$refs.chartContainer;

      if (!chartContainer) {
        return;
      }

      Plotly.newPlot(
        this.plotlyId,
        this.data,
        this.internalLayout,
        this.config
      );

      events.forEach((e) => {
        this.$el.on(e.completeName, e.handler(this));
      });
    },

    reactToChanges() {
      Plotly.react(this.plotlyId, this.data, this.internalLayout, this.config);
    },

    handleResize(entries) {
      const entry = entries.at(0);

      if (entry.contentRect.width === 0) {
        // the chart is not visible on the screen, trying to resize it will throw an error
        return;
      }

      // since plot's config has "autosize" set to true, its height and width would be recalculated by the Plotly itself on eahch relayout
      // no need to update plot's dimensions manually
      Plotly.relayout(this.plotlyId, {});
    },
  },

  watch: {
    data() {
      this.datarevision = this.datarevision + 1;
      this.reactToChanges();
    },

    layout() {
      this.reactToChanges();
    },

    config() {
      this.reactToChanges();
    },
  },
};
</script>
