<template>
  <div class="container-table" :class="{ 'max-height-72': !isMapMode }">
    <table class="table">
      <thead>
        <tr>
          <th>
            <v-checkbox
              :model-value="isAllResultsScannersSelected"
              :indeterminate="isCheckboxIndeterminate"
              color="info"
              density="compact"
              @update:modelValue="toggleAllResultsScanners"
              style="margin-left: 1px; height: 20px"
            ></v-checkbox>
          </th>
          <th v-if="showAnalyser"></th>
          <THead title="id"></THead>
          <THead title="inspection_date"></THead>
          <THead
            title="construction_year"
            sortingKeys="constructionyear"
          ></THead>
          <THead title="material"></THead>
          <THead title="diameter" unit="mm"></THead>
          <THead title="exp_wt"></THead>
          <THead title="max_wt" sortingKeys="stats.max"></THead>
          <THead title="min_wt" sortingKeys="stats.min"></THead>
          <THead title="avg_wt" sortingKeys="stats.mean"></THead>
          <THead title="std_wt" sortingKeys="stats.std"></THead>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="scanner in sortedScanners"
          :key="scanner.id"
          :class="{ active: activeScannerId === scanner.id }"
          @click="handleTableRowClick(scanner)"
          @dblclick="$emit('scanner-details', scanner.id)"
        >
          <td>
            <div class="td-actions">
              <v-checkbox
                :model-value="selectedResultScannerIds.includes(scanner.id)"
                @update:modelValue="toggleResultsScannerSelection(scanner.id)"
                color="info"
                density="compact"
                hide-details
                @click.stop
              />
              <button
                v-if="showEdit"
                @click="$emit('scanner-edit', scanner.id)"
              >
                <v-icon icon="mdi-square-edit-outline"></v-icon>
              </button>
            </div>
          </td>
          <td v-if="showAnalyser">
            <v-btn
              size="x-small"
              variant="flat"
              color="#24e3c6"
              @click="goToAnalyser(scanner.id)"
              >Analyser</v-btn
            >
          </td>
          <td>{{ scanner.tag }}</td>
          <td>{{ scanner.inspection_date }}</td>
          <td>{{ scanner.constructionyear }}</td>
          <td>{{ scanner.material }}</td>
          <td>{{ scanner.diameter }}</td>
          <td>{{ scanner.wallthickness_expected_validation.toFixed(2) }}</td>
          <td>{{ scanner.stats.max.toFixed(2) }}</td>
          <td>{{ scanner.stats.min.toFixed(2) }}</td>
          <td>{{ scanner.stats.mean.toFixed(2) }}</td>
          <td>{{ scanner.stats.std.toFixed(2) }}</td>
        </tr>
      </tbody>
    </table>
    <v-badge
      class="xcell-button"
      :class="{ 'map-mode': isMapMode }"
      :color="selectedResultsScannersCount > 0 ? 'red' : undefined"
      :content="selectedResultsScannersCount"
      offsetX="3"
      offsetY="3"
      :style="{
        cursor: selectedResultsScannersCount > 0 ? 'pointer' : 'default',
      }"
    >
      <v-btn
        @click="exportResultsScanners"
        :disabled="selectedResultsScannersCount === 0"
        :loading="isAwaitingInspectionExport"
        color="green"
        icon="mdi-microsoft-excel"
        title="export to Excel file"
      ></v-btn>
    </v-badge>
    <div v-if="sortedScanners.length === 0" class="blank">
      {{ $t("common.no_items") }}
    </div>
  </div>
</template>

<script>
import { computed } from "vue";
import { mapState, mapMutations, mapGetters, mapActions } from "vuex";

import { THead } from "@/components";

import { getSortedArray, getNextSortingOrder } from "@/utils/tableHelpers.js";

import { ScannerType } from "@/pages/scanner-control/config";

export default {
  name: "ResultsScannersTable",

  emits: ["scanner-edit", "scanner-details", "scanner-select"],

  components: { THead },

  provide() {
    return {
      sortedBy: computed(() => this.sortedBy),
      sortFunction: this.setSortedBy,
      localePath: "pipe_scanner.table_titles.",
    };
  },

  data: () => ({
    sortedBy: {
      keyName: null,
      order: "asc",
    },
  }),

  computed: {
    ...mapState(["userPermissions", "isMapMode"]),

    ...mapState("scannerControl", [
      "selectedResultScannerIds",
      "isAwaitingInspectionExport",
    ]),

    ...mapGetters("scannerControl", [
      "selectedResultsScannersCount",
      "isAllResultsScannersSelected",
      "filteredResultsScanners",
      "activeScannerId",
    ]),

    showEdit() {
      return this.userPermissions.includes("edit:upload");
    },

    showAnalyser() {
      return this.userPermissions.includes("use:analyser");
    },

    sortedScanners() {
      return getSortedArray(
        this.filteredResultsScanners,
        this.sortedBy.order,
        this.sortedBy.keyName
      );
    },

    isCheckboxIndeterminate() {
      return (
        this.selectedResultsScannersCount > 0 &&
        !this.isAllResultsScannersSelected
      );
    },
  },

  methods: {
    ...mapMutations("scannerControl", ["setActiveScannerIds"]),

    ...mapActions("scannerControl", [
      "toggleResultsScannerSelection",
      "exportResultsScanners",
      "toggleAllResultsScanners",
    ]),

    setSortedBy(key) {
      const sortedBy = getNextSortingOrder(this.sortedBy, key);
      this.sortedBy = sortedBy;
    },

    goToAnalyser(id) {
      this.$router.push(`/scanner-control/${id}/analyser`);
    },

    handleTableRowClick(scanner) {
      this.setActiveScannerIds({ [ScannerType.Results]: scanner.id });
      this.$emit("scanner-select", scanner);
    },
  },
};
</script>

<style lang="scss" scoped>
.container-table {
  &.max-height-72 {
    max-height: 72vh !important;
  }

  .v-checkbox {
    width: 26px;
  }

  td:first-child {
    padding-right: 0 !important;
    cursor: default;

    .td-actions {
      display: flex;

      .mdi-square-edit-outline {
        opacity: 0.5;
        &:hover {
          opacity: 1;
        }
      }
    }
  }

  .xcell-button {
    position: absolute;
    right: 70px;
    bottom: 40px;
    &.map-mode {
      right: 30px;
      bottom: 40px;
    }
  }

  .blank {
    text-align: center;
    letter-spacing: 0.5px;
    padding: 15px 0;
    width: 100%;
    cursor: default;
    font-weight: 700;
    color: gray;
  }
}
</style>
