<template>
  <VirtualTable
    :headers="displayedHeaders"
    :items="filteredPipeParts"
    :header-slots="headerSlots"
    :is-item-active="isPipePartActive"
    @click:row="(pipePart) => $emit('click:row', pipePart.id)"
  >
    <template v-slot:[`header.expand_uncertainty_contribution`]>
      <ExpandButton
        :is-expanded="uncertaintyContributionsExpanded"
        @click="
          uncertaintyContributionsExpanded = !uncertaintyContributionsExpanded
        "
      />
    </template>

    <template v-slot:[`header.expand_input_parameters`]>
      <ExpandButton
        :is-expanded="inputParametersExpanded"
        @click="inputParametersExpanded = !inputParametersExpanded"
      />
    </template>
  </VirtualTable>
</template>

<script>
import { VirtualTable, ExpandButton } from "@/components";

import { filterExpandableHeaders } from "@/utils/tableHelpers";

export default {
  name: "SafetyTableRegular",

  components: { VirtualTable, ExpandButton },

  props: {
    filteredPipeParts: Array,
    isPipePartActive: Function,
  },

  emits: ["click:row"],

  data() {
    return {
      uncertaintyContributionsExpanded: false,
      inputParametersExpanded: false,

      headers: [
        {
          title: this.$t("safety_factor.table_titles.pipe_book_id"),
          key: "pipe_book_id",
          value: "pipe_book_id",
        },
        {
          title: this.$t("safety_factor.table_titles.distance"),
          key: "distance",
          value: "distance",
          units: "m",
        },
        {
          title: this.$t("safety_factor.table_titles.pipe_part_type"),
          key: "pipe_part_type",
          value: (pipePart) =>
            this.$t(`pipe_parts.types.${pipePart.pipe_part_type}`),
        },
        {
          title: this.$t("safety_factor.table_titles.material"),
          key: "material",
          value: (pipePart) => this.$t(`materials.${pipePart.material}`),
        },
        {
          title: this.$t("safety_factor.table_titles.length"),
          key: "length",
          value: "length",
          units: "m",
        },

        {
          title: this.$t("safety_factor.table_titles.safety_factor_group"),
          key: "safety_factor_parent",
          children: [
            {
              title: this.$t("safety_factor.table_titles.safety_factor"),
              key: "safety_factor",
              value: (pipePart) =>
                pipePart.safety_factor_calculation?.safety_factor?.toFixed(2),
            },
            {
              title: this.$t("safety_factor.table_titles.safety_factor_lower"),
              key: "safety_factor_lower",
              value: (pipePart) =>
                pipePart.safety_factor_calculation?.safety_factor_lower?.toFixed(
                  2
                ),
            },
            {
              title: this.$t("safety_factor.table_titles.safety_factor_upper"),
              key: "safety_factor_upper",
              value: (pipePart) =>
                pipePart.safety_factor_calculation?.safety_factor_upper?.toFixed(
                  2
                ),
            },
          ],
        },

        {
          title: this.$t("safety_factor.table_titles.main_uncertainty"),
          key: "main_uncertainty",
          value: (pipePart) =>
            pipePart.safety_factor_calculation?.main_uncertainty.text,
        },

        {
          title: this.$t(
            "safety_factor.table_titles.uncertainty_contribution_group"
          ),
          key: "uncertainty_contribution_parent",
          children: [
            {
              title: this.$t("safety_factor.table_titles.ground_level"),
              key: "ground_level",
              value: (pipePart) =>
                pipePart.safety_factor_calculation
                  ?.ground_level_uncertainty_contribution,
            },
            {
              title: this.$t("safety_factor.table_titles.soil_density_dry"),
              key: "soil_density_dry",
              value: (pipePart) =>
                pipePart.safety_factor_calculation
                  ?.soil_density_dry_uncertainty_contribution,
            },
            {
              title: this.$t("safety_factor.table_titles.soil_density_wet"),
              key: "soil_density_wet",
              value: (pipePart) =>
                pipePart.safety_factor_calculation
                  ?.soil_density_wet_uncertainty_contribution,
            },
            {
              title: this.$t("safety_factor.table_titles.ground_water_level"),
              key: "ground_water_level",
              value: (pipePart) =>
                pipePart.safety_factor_calculation
                  ?.ground_water_level_uncertainty_contribution,
            },
            {
              title: this.$t("safety_factor.table_titles.material_density"),
              key: "material_density",
              value: (pipePart) =>
                pipePart.safety_factor_calculation
                  ?.material_density_uncertainty_contribution,
              expandable: true,
            },
            {
              title: this.$t("safety_factor.table_titles.tensile_strength"),
              key: "tensile_strength",
              value: (pipePart) =>
                pipePart.safety_factor_calculation
                  ?.tensile_strength_uncertainty_contribution,
              expandable: true,
            },
            {
              title: this.$t("safety_factor.table_titles.pressure"),
              key: "pressure",
              value: (pipePart) =>
                pipePart.safety_factor_calculation
                  ?.pressure_uncertainty_contribution,
              expandable: true,
            },
            {
              title: this.$t("safety_factor.table_titles.joint_angle_vertical"),
              key: "joint_angle_vertical",
              value: (pipePart) =>
                pipePart.safety_factor_calculation
                  ?.joint_angle_vertical_uncertainty_contribution,
              expandable: true,
            },
            {
              title: this.$t("safety_factor.table_titles.road_distance"),
              key: "road_distance",
              value: (pipePart) =>
                pipePart.safety_factor_calculation
                  ?.road_distance_uncertainty_contribution,
              expandable: true,
            },
            {
              title: this.$t("safety_factor.table_titles.road_wheel_load"),
              key: "road_wheel_load",
              value: (pipePart) =>
                pipePart.safety_factor_calculation
                  ?.road_wheel_load_uncertainty_contribution,
              expandable: true,
            },
            {
              title: this.$t("safety_factor.table_titles.wall_thickness"),
              key: "wall_thickness",
              value: (pipePart) =>
                pipePart.safety_factor_calculation
                  ?.wall_thickness_uncertainty_contribution,
              expandable: true,
            },
          ],
        },

        {
          title: "",
          key: "expand_uncertainty_contribution",
        },

        {
          title: this.$t("safety_factor.table_titles.input_parameters_group"),
          key: "input_parameters_parent",
          children: [
            {
              title: this.$t("safety_factor.table_titles.ground_level_mean"),
              key: "ground_level_mean",
              value: (pipePart) =>
                pipePart.safety_factor_calculation?.ground_level_mean?.toFixed(
                  3
                ),
              units: "m",
            },
            {
              title: this.$t("safety_factor.table_titles.ground_level_std"),
              key: "ground_level_std",
              value: (pipePart) =>
                pipePart.safety_factor_calculation?.ground_level_std?.toFixed(
                  3
                ),
              units: "m",
            },
            {
              title: this.$t(
                "safety_factor.table_titles.ground_water_level_min"
              ),
              key: "ground_water_level_min",
              value: (pipePart) =>
                pipePart.safety_factor_calculation?.ground_water_level_min?.toFixed(
                  3
                ),
              units: "m",
            },
            {
              title: this.$t(
                "safety_factor.table_titles.ground_water_level_max"
              ),
              key: "ground_water_level_max",
              value: (pipePart) =>
                pipePart.safety_factor_calculation?.ground_water_level_max?.toFixed(
                  3
                ),
              units: "m",
            },
            {
              title: this.$t(
                "safety_factor.table_titles.soil_density_dry_mean"
              ),
              key: "soil_density_dry_mean",
              value: (pipePart) =>
                pipePart.safety_factor_calculation?.soil_density_dry_mean,
              expandable: true,
              units: "kg/m³",
            },
            {
              title: this.$t("safety_factor.table_titles.soil_density_dry_std"),
              key: "soil_density_dry_std",
              value: (pipePart) =>
                pipePart.safety_factor_calculation?.soil_density_dry_std,
              expandable: true,
              units: "kg/m³",
            },
            {
              title: this.$t(
                "safety_factor.table_titles.soil_density_wet_mean"
              ),
              key: "soil_density_wet_mean",
              value: (pipePart) =>
                pipePart.safety_factor_calculation?.soil_density_wet_mean,
              expandable: true,
              units: "kg/m³",
            },
            {
              title: this.$t("safety_factor.table_titles.soil_density_wet_std"),
              key: "soil_density_wet_std",
              value: (pipePart) =>
                pipePart.safety_factor_calculation?.soil_density_wet_std,
              expandable: true,
              units: "kg/m³",
            },
            {
              title: this.$t(
                "safety_factor.table_titles.material_density_mean"
              ),
              key: "road_distance",
              value: (pipePart) =>
                pipePart.safety_factor_calculation?.material_density_mean,
              expandable: true,
              units: "kg/m³",
            },
            {
              title: this.$t("safety_factor.table_titles.material_density_std"),
              key: "material_density_std",
              value: (pipePart) =>
                pipePart.safety_factor_calculation?.material_density_std,
              expandable: true,
              units: "kg/m³",
            },
            {
              title: this.$t(
                "safety_factor.table_titles.tensile_strength_mean"
              ),
              key: "tensile_strength_mean",
              value: (pipePart) =>
                pipePart.safety_factor_calculation?.tensile_strength_mean,
              expandable: true,
              units: "Pa",
            },
            {
              title: this.$t("safety_factor.table_titles.tensile_strength_std"),
              key: "tensile_strength_std",
              value: (pipePart) =>
                pipePart.safety_factor_calculation?.tensile_strength_std,
              expandable: true,
              units: "Pa",
            },
            {
              title: this.$t("safety_factor.table_titles.pressure_mean"),
              key: "pressure_mean",
              value: (pipePart) =>
                pipePart.safety_factor_calculation?.pressure_mean,
              expandable: true,
              units: "Pa",
            },
            {
              title: this.$t("safety_factor.table_titles.pressure_std"),
              key: "pressure_std",
              value: (pipePart) =>
                pipePart.safety_factor_calculation?.pressure_std,
              expandable: true,
              units: "Pa",
            },
            {
              title: this.$t(
                "safety_factor.table_titles.joint_angle_vertical_mean"
              ),
              key: "joint_angle_vertical_mean",
              value: (pipePart) =>
                pipePart.safety_factor_calculation?.joint_angle_vertical_mean ||
                "",
              expandable: true,
              units: "°",
            },
            {
              title: this.$t(
                "safety_factor.table_titles.joint_angle_vertical_std"
              ),
              key: "joint_angle_vertical_std",
              value: (pipePart) =>
                pipePart.safety_factor_calculation?.joint_angle_vertical_std?.toFixed(
                  3
                ),
              expandable: true,
              units: "°",
            },
            {
              title: this.$t("safety_factor.table_titles.road_distance_mean"),
              key: "road_distance_mean",
              value: (pipePart) =>
                pipePart.safety_factor_calculation?.road_distance_mean?.toFixed(
                  3
                ),
              expandable: true,
              units: "m",
            },
            {
              title: this.$t("safety_factor.table_titles.road_distance_std"),
              key: "road_distance_std",
              value: (pipePart) =>
                pipePart.safety_factor_calculation?.road_distance_std?.toFixed(
                  3
                ),
              expandable: true,
              units: "m",
            },
            {
              title: this.$t("safety_factor.table_titles.road_wheel_load_mean"),
              key: "road_wheel_load_mean",
              value: (pipePart) =>
                pipePart.safety_factor_calculation?.road_wheel_load_mean,
              expandable: true,
              units: "N",
            },
            {
              title: this.$t("safety_factor.table_titles.road_wheel_load_std"),
              key: "road_wheel_load_std",
              value: (pipePart) =>
                pipePart.safety_factor_calculation?.road_wheel_load_std,
              expandable: true,
              units: "N",
            },
            {
              title: this.$t("safety_factor.table_titles.soil_type"),
              key: "soil_type",
              value: (pipePart) =>
                pipePart.safety_factor_calculation?.soil_type,
              expandable: true,
            },
          ],
        },

        {
          title: "",
          key: "expand_input_parameters",
        },
      ],
    };
  },

  computed: {
    displayedHeaders() {
      const expandableHeadersState = {
        uncertainty_contribution_parent: this.uncertaintyContributionsExpanded,
        input_parameters_parent: this.inputParametersExpanded,
      };

      return filterExpandableHeaders(this.headers, expandableHeadersState);
    },

    headerSlots() {
      return [
        "header.expand_uncertainty_contribution",
        "header.expand_input_parameters",
      ];
    },
  },
};
</script>
