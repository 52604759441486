<template>
  <v-badge
    class="dig-up-button"
    :class="{ 'map-mode': isMapMode }"
    color="red"
    :content="badgeCount"
    offsetX="3"
    offsetY="3"
  >
    <v-btn
      @click="onGenerateDigUp"
      :loading="isAwaitingDigup"
      color="green"
      icon="mdi-shovel"
      title="generate dig up sheet"
    ></v-btn>
  </v-badge>
</template>

<script>
export default {
  name: "DigUpTableBtn",
  inject: ["onGenerateDigUp", "isAwaitingDigup"],
  props: ["badgeCount", "isMapMode"],
};
</script>

<style lang="scss" scoped>
.dig-up-button {
  position: absolute;
  right: 50px;
  bottom: 50px;
  &.map-mode {
    right: 30px;
    bottom: 35px;
  }
}
</style>
