<template>
  <div id="mapmod-container">
    <ModifiedMapbox
      ref="modifiedMapRef"
      :showLoader="isLoading"
      :wmsLayers="wmsLayers"
      :widgets="widgets"
      :onMaximizeWidget="maximizeWidget"
      forceHideScanners
      @mb-moveend="onMoveend"
      @click="selectedWidget = null"
    >
      <MapboxMarker
        v-if="selectedPipePart"
        :lng-lat="selectedPipePart.geom.coordinates[0]"
        :scale="0.9"
        color="orange"
      />

      <template v-slot:sources>
        <template
          v-for="{ id, sourceOptions, layerOptions } in mapData"
          :key="id"
        >
          <MapboxSource :id="id" :options="sourceOptions" />
          <MapboxLayer
            :id="layerOptions.id"
            :options="layerOptions"
            @mb-mouseenter="onLayerMouseEnter"
            @mb-mouseleave="onLayerMouseLeave"
            @mb-click="onLayerClick"
          />
        </template>
      </template>

      <div :class="{ hide: isLoading }">
        <WidgetCard
          :isActive="selectedWidget === 'safetyChart'"
          :class="{ zIndex: selectedWidget === 'safetyChart' }"
          :sizeProps="widgets.safetyChart"
          :show="widgets.safetyChart.isOpen"
          @drag="(e) => onDrag(e, 'safetyChart')"
          @minimize="minimizeWidget('safetyChart')"
        >
          <template v-slot:title-text>
            {{ safetyChartTitle }}
          </template>
          <SafetyChart
            :visibleParts="visibleParts"
            @graph-mousedown="selectedWidget = 'safetyChart'"
          />
        </WidgetCard>
        <TableWidgetCard
          :class="{ zIndex: selectedWidget === 'table' }"
          :sizeProps="widgets.table"
          :show="widgets.table.isOpen"
          @drag="(e) => onDrag(e, 'table')"
          @minimize="minimizeWidget('table'), (showFilter = false)"
        >
          <template v-slot:title-text>
            {{ $t(widgets.table.title) }}
          </template>
          <template v-slot:title-prepend>
            <FilterPopupMenu
              v-model:show="showFilter"
              :uncertaintiesOptions="uncertaintiesOptions"
            />
          </template>
          <SafetyTable />
        </TableWidgetCard>
        <WidgetCard
          v-if="widgets.contributionsChart.isOpen"
          :isActive="selectedWidget === 'contributionsChart'"
          :class="{ zIndex: selectedWidget === 'contributionsChart' }"
          :sizeProps="widgets.contributionsChart"
          @drag="(e) => onDrag(e, 'contributionsChart')"
          @minimize="minimizeWidget('contributionsChart')"
        >
          <template v-slot:title-text>
            {{ $t(widgets.contributionsChart.title) }}
          </template>
          <UncertaintyContributionsChart
            :visibleParts="visibleParts"
            @graph-mousedown="selectedWidget = 'contributionsChart'"
          />
        </WidgetCard>
        <WidgetCard
          v-if="widgets.heightProfile.isOpen"
          :isActive="selectedWidget === 'heightProfile'"
          :class="{ zIndex: selectedWidget === 'heightProfile' }"
          :sizeProps="widgets.heightProfile"
          @drag="(e) => onDrag(e, 'heightProfile')"
          @minimize="minimizeWidget('heightProfile')"
        >
          <template v-slot:title-text>
            {{ $t(widgets.heightProfile.title) }}
          </template>
          <SafetyHeightChart
            :visibleParts="visibleParts"
            @graph-mousedown="selectedWidget = 'heightProfile'"
          />
        </WidgetCard>
        <WidgetCard
          :class="{ zIndex: selectedWidget === 'wmsWidget' }"
          :sizeProps="widgets.wmsWidget"
          :show="widgets.wmsWidget.isOpen"
          @drag="(e) => onDrag(e, 'wmsWidget')"
          @minimize="minimizeWidget('wmsWidget')"
          :isResizable="false"
          :sticks="[]"
        >
          <template v-slot:title-text>
            {{ $t(widgets.wmsWidget.title) }}
          </template>
          <WMSWidget
            v-model:wmsLayers="wmsLayers"
            :colorMode="selectedViewFactor"
            :colorOptions="safetyFactorOptions"
            @select-color-mode="setSelectedViewFactor"
          />
        </WidgetCard>
        <WidgetCard
          v-if="!showGradient"
          :class="{ zIndex: selectedWidget === 'colorsLegend' }"
          :sizeProps="widgets.colorsLegend"
          heightFitContent
          disableMinimize
          :isResizable="false"
          :isDraggable="false"
          :sticks="[]"
          @click="selectedWidget = 'colorsLegend'"
        >
          <template v-slot:title-text>
            <div style="font-size: 0.9rem; line-height: 1.3">
              {{ $t(legendTitle) }}
            </div>
          </template>
          <SafetyColorsLegend />
        </WidgetCard>
        <WidgetCard
          v-if="showGradient"
          :class="{ zIndex: selectedWidget === 'gradientLegend' }"
          :sizeProps="widgets.gradientLegend"
          heightFitContent
          disableMinimize
          :isResizable="false"
          :isDraggable="false"
          :sticks="[]"
          @click="selectedWidget = 'gradientLegend'"
        >
          <template v-slot:title-text>
            <div class="pr-2">{{ $t(widgets.gradientLegend.title) }}</div>
          </template>
          <SafetyGradient />
        </WidgetCard>
        <WMSLegend
          :class="{ zIndex: selectedWidget === 'wmsLegend' }"
          v-show="wmsLayers.length && widgets.wmsLegend.isOpen"
          :isOpen="widgets.wmsLegend.isOpen"
          :offsetTop="240"
          :wmsLayers="wmsLayers"
          :currentZoom="currentZoom"
          @click="selectedWidget = 'wmsLegend'"
          @maximize-self="widgets.wmsLegend.isOpen = true"
          :onMinimize="() => minimizeWidget('wmsLegend')"
        />
      </div>
    </ModifiedMapbox>
  </div>
</template>

<script>
import { ref, computed } from "vue";
import { mapState, mapMutations, mapGetters } from "vuex";

import {
  MapboxMarker,
  MapboxLayer,
  MapboxSource,
} from "@studiometa/vue-mapbox-gl";
import {
  SafetyColorsLegend,
  SafetyHeightChart,
  SafetyGradient,
  SafetyTable,
  SafetyChart,
  UncertaintyContributionsChart,
} from "@/pages/safety-factor/components";
import { ModifiedMapbox, WidgetCard, TableWidgetCard } from "@/components";
import { WMSLegend, WMSWidget } from "@/features/wms/components";
import { FilterPopupMenu } from "./components";

import { initializeResizeObserver } from "@/utils/resizeObserverUtil";
import { VIEW_FACTORS_WITHOUT_GRADIENT } from "@/pages/safety-factor/config";

export default {
  name: "SafetyFactorMapmode",

  components: {
    MapboxMarker,
    MapboxLayer,
    MapboxSource,
    WMSLegend,
    WMSWidget,
    WidgetCard,
    UncertaintyContributionsChart,
    SafetyColorsLegend,
    SafetyHeightChart,
    SafetyGradient,
    SafetyChart,
    SafetyTable,
    FilterPopupMenu,
    ModifiedMapbox,
    TableWidgetCard,
  },

  props: {
    mapData: Array,
    safetyFactorOptions: Array,
    uncertaintiesOptions: Array,
    safetyChartTitle: String,
    legendTitle: String,
  },

  provide() {
    return {
      parentW: computed(() => this.parentW),
      parentH: computed(() => this.parentH),
    };
  },

  setup() {
    const resizeObserver = ref(null);

    return {
      resizeObserver,
    };
  },

  data() {
    return {
      visibleParts: [],
      parentH: null,
      parentW: null,
      widgets: {
        table: {
          title: "safety_factor.widgets.table",
          width: 840,
          height: 355,
          left: 500,
          top: 150,
          isOpen: true,
        },
        safetyChart: {
          title: this.safetyChartTitle, // not used
          width: 655,
          height: 400,
          left: 5,
          top: 400,
          isOpen: true,
        },
        contributionsChart: {
          title: "safety_factor.widgets.contributions_chart",
          width: 655,
          height: 400,
          left: 5,
          top: 350,
          isOpen: false,
        },
        heightProfile: {
          title: "safety_factor.widgets.height_profile",
          width: 655,
          height: 400,
          left: 5,
          top: 150,
          isOpen: false,
        },
        colorsLegend: {
          title: this.legendTitle, // not used
          height: "auto",
          width: 180,
          left: 5,
          top: 50,
        },
        gradientLegend: {
          title: "joints.widgets.gradient.maximize_title",
          height: "auto",
          width: 130,
          left: 5,
          top: 50,
        },
        wmsWidget: {
          title: "safety_factor.widgets.color",
          width: 350,
          height: 150,
          left: 400,
          top: 3,
          isOpen: true,
        },
        wmsLegend: {
          title: "wms_layers.layers",
          isOpen: true,
        },
      },

      selectedWidget: null,
      currentZoom: null,
      wmsLayers: [],
      showFilter: false,
    };
  },

  mounted() {
    this.resizeObserver = initializeResizeObserver.call(this, [
      "table",
      "safetyChart",
      "contributionsChart",
      "heightProfile",
      "wmsWidget",
    ]);

    this.resizeObserver.observe(this.$el);

    this.widgets.table.left =
      this.$el.offsetWidth - this.widgets.table.width - 10;

    this.widgets.contributionsChart.top =
      this.$el.offsetHeight - this.widgets.contributionsChart.height - 35;
    this.widgets.contributionsChart.left =
      this.$el.offsetWidth - this.widgets.contributionsChart.width - 30;

    this.widgets.heightProfile.top =
      this.$el.offsetHeight - this.widgets.heightProfile.height - 180;
    this.widgets.safetyChart.top =
      this.$el.offsetHeight - this.widgets.safetyChart.height - 35;
    this.widgets.wmsWidget.left =
      (this.$el.offsetWidth - this.widgets.wmsWidget.width) / 2;
  },

  beforeUnmount() {
    this.resizeObserver?.disconnect();
  },

  computed: {
    ...mapState(["mapStyle"]),

    ...mapState("safetyFactor", [
      "pipeParts",
      "isLoading",
      "selectedViewFactor",
    ]),

    ...mapGetters("safetyFactor", ["selectedPipePart"]),

    map() {
      return this.$refs.modifiedMapRef.map;
    },

    showGradient() {
      return !VIEW_FACTORS_WITHOUT_GRADIENT.includes(this.selectedViewFactor);
    },
  },

  methods: {
    ...mapMutations("safetyFactor", [
      "setSelectedPipePartId",
      "setSelectedViewFactor",
    ]),

    onDrag({ left, top }, key) {
      this.selectedWidget = key;
      this.widgets[key].left = left;
      this.widgets[key].top = top;
    },

    minimizeWidget(key) {
      this.widgets[key].isOpen = false;
    },

    maximizeWidget(key) {
      this.selectedWidget = key;
      this.widgets[key].isOpen = true;
    },

    onMoveend({ target: map }) {
      this.currentZoom = map.getZoom();
      const layerId = this.mapData.find(({ layerOptions }) =>
        layerOptions.id.includes("layer_pipe_parts")
      )?.layerOptions.id;

      if (map.getLayer(layerId)) {
        const visiblePartsIds = map
          .queryRenderedFeatures({ layers: [layerId] })
          .map((part) => part.properties.id);
        const sorted = [];
        this.pipeParts.forEach((part, index) => {
          if (visiblePartsIds.includes(part.id)) {
            sorted.push({ ...part, order_index: index });
          }
        });

        this.visibleParts = sorted;
      }
    },

    onLayerClick({ features }) {
      if (features && features[0].source.includes("pipe_parts")) {
        this.setSelectedPipePartId(features[0].properties.id);
      }
    },

    onLayerMouseEnter({ features, target }) {
      if (features && features[0].source.includes("pipe_parts")) {
        target.getCanvas().style.cursor = "pointer";
      }
    },

    onLayerMouseLeave({ target }) {
      target.getCanvas().style.cursor = "";
    },
  },

  watch: {
    selectedPipePart(selected, prevSelected) {
      if (selected && selected.id !== prevSelected?.id) {
        this.map.flyTo({
          center: selected.geom.coordinates[0],
          zoom: this.currentZoom < 17 ? 17 : this.currentZoom,
        });
      }
    },

    safetyChartTitle(next) {
      this.widgets.safetyChart.title = next;
    },

    legendTitle(next) {
      this.widgets.colorsLegend.title = next;
    },
  },
};
</script>

<style lang="scss" scoped>
#mapmod-container {
  padding: 0;
  margin: 0;
  width: 100%;
  height: 100%;
}

.hide {
  opacity: 0;
}

.zIndex {
  z-index: 5 !important;
}

.inactive {
  opacity: 0.8;
}
</style>
