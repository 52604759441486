import { getPipeSegmentIdsBetweenElements } from "@/features/dig-up/helpers";
import { useToast } from "vue-toast-notification";

export class DigUpSelector {
  constructor(lang) {
    this.value = [];
    this.lang = lang;
    this.toast = useToast();
  }

  set(featuresIds) {
    this.value = featuresIds;
  }

  setByCtrl(id) {
    if (this.value.includes(id)) {
      this.value = this.value.filter((checkedId) => checkedId !== id);
    } else {
      if (this.value.length === 3) {
        this.showLimitToast();
        this.value.shift();
      }
      this.value.push(id);
    }
  }

  setByShift(id, filteredPipeParts) {
    if (this.value.length !== 1) {
      this.value = [];
      this.value.push(id);
    } else {
      this.value.push(id);
      const segmentIds = getPipeSegmentIdsBetweenElements(
        filteredPipeParts,
        this.value,
        this.showLimitToast.bind(this)
      );
      this.value = segmentIds;
    }
  }

  showLimitToast() {
    const message =
      this.lang === "en"
        ? "Maximum length of digging elements reached (3)"
        : "Maximale lengte van graafelementen bereikt (3)";

    this.toast.default(message, {
      position: "top-right",
    });
  }

  has(elementId) {
    return this.value.includes(elementId);
  }

  clear() {
    this.value = [];
  }

  get size() {
    return this.value.length;
  }
}
