<template>
  <v-dialog v-model="dialog">
    <v-card class="mx-auto" elevation="16" width="500px">
      <template v-slot:title>
        <div style="display: flex; align-items: flex-end; margin-bottom: 12px">
          <v-icon icon="mdi-check-circle" color="green"></v-icon>
          <span style="margin-left: 8px">{{
            $t("analyser.dialog.title")
          }}</span>
          <v-btn
            style="position: absolute; right: 16px; top: 4px"
            icon="$close"
            variant="text"
            @click="dialog = false"
          ></v-btn>
        </div>
      </template>

      <v-divider></v-divider>

      <div class="py-12 text-center">
        <v-progress-circular
          :model-value="countdown"
          :size="56"
          :width="6"
        ></v-progress-circular>
        <div class="mt-4 font-weight-bold">
          {{ $t("analyser.dialog.content") }}
        </div>
      </div>

      <v-divider></v-divider>

      <div class="pa-4 text-end d-flex justify-space-between">
        <v-btn
          class="font-weight-bold"
          color="red"
          min-width="92"
          rounded
          variant="outlined"
          @click="dialog = false"
        >
        {{ $t("analyser.dialog.cancel") }}
        </v-btn>

        <v-btn
          color="green"
          rounded
          @click="toScannersPage"
        >
        {{ $t("analyser.dialog.to_scanners") }}
        </v-btn>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "ReturnDialog",
  data() {
    return {
      dialog: false,
      countdown: 100,
      interval: undefined,
    };
  },

  methods: {
    open() {
      this.dialog = true;
    },

    startCountdown(delay) {
      if (this.interval) {
        clearInterval(this.interval);
      }
      this.countdown = 100;

      this.interval = setInterval(() => {
        if (this.countdown > 0) {
          this.countdown -= 1;
        } else {
          if (this.interval) {
            clearInterval(this.interval);
          }
          setTimeout(() => {
            this.toScannersPage();
          }, 400);
        }
      }, delay);
    },

    toScannersPage(){
      this.$router.push({ name: "scanner-control" });
    }
  },

  watch: {
    dialog(isOpen) {
      if (isOpen) {
        this.startCountdown(40);
      } else {
        if (this.interval) {
          clearInterval(this.interval);
        }
      }
    },
  },
};
</script>
