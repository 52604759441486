import CommentMarker from "@/features/comments/assets/pipescanner_text_bubble_icon_better.png";

const COMMNETS_SOURCE_ID = "comments-source";
const COMMENTS_MARKERS_LAYER_ID = "comments-markers-layer";

const addMapCommentIcons = (map) => {
  map.loadImage(CommentMarker, (error, image) => {
    if (error) throw error;
    map.addImage("comment_icon", image);
  });
};

export const getCommentsClusterSourceAndLayers = (comments) => {
  const sourceOptions = {
    type: "geojson",
    data: {
      type: "FeatureCollection",
      features: comments.map((comment) => {
        return {
          type: "Feature",
          properties: { ...comment },
          geometry: {
            type: "Point",
            coordinates: comment.geom.coordinates,
          },
        };
      }),
    },
  };

  const layersOptions = [
    {
      id: COMMENTS_MARKERS_LAYER_ID,
      type: "symbol",
      source: COMMNETS_SOURCE_ID,
      layout: {
        "icon-image": "comment_icon",
        "icon-allow-overlap": true,
        "icon-size": 0.25,
        "icon-anchor": "bottom",
      },
    },
  ];
  return { sourceId: COMMNETS_SOURCE_ID, sourceOptions, layersOptions };
};

export const addCommentsSourceAndLayers = (
  map,
  { sourceId, sourceOptions, layersOptions },
  onCommentClick,
  isFirstLaunch = true
) => {
  addMapCommentIcons(map);

  map.addSource(sourceId, sourceOptions);
  layersOptions.forEach((layer) => map.addLayer(layer));

  if (isFirstLaunch) {
    map.on("click", COMMENTS_MARKERS_LAYER_ID, onCommentClick);

    map.on("mouseenter", [COMMENTS_MARKERS_LAYER_ID], () => {
      map.getCanvas().style.cursor = "pointer";
    });
    map.on("mouseleave", [COMMENTS_MARKERS_LAYER_ID], () => {
      map.getCanvas().style.cursor = "";
    });
  }
};
