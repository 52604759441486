<template>
  <v-card>
    <v-card-text>
      <div class="select">
        <v-select
          v-model="selectedMeasure"
          :label="$t('pipe_scanner.details_modal.wall_thickness.select')"
          :items="measureOptions"
          variant="outlined"
          density="compact"
          hide-details
        >
        </v-select>
      </div>
      <PlotlyGraph
        :data="linePlotlyData"
        :layout="linePlotlyLayout"
        :config="{ displayModeBar: false }"
      />
      <PlotlyGraph
        v-if="showPolarPlot"
        :data="polarPlotlyData"
        :layout="polarPlotlyLayout"
        :config="{ displayModeBar: false }"
      />
      <div class="flex-center">
        <div style="width: 100%; max-width: 1000px">
          <h3>{{ $t("pipe_scanner.details_modal.spots.title") }}</h3>
          <div class="degradation-spots">
            <div class="sliders-block">
              <div>
                <p>{{ $t("pipe_scanner.details_modal.spots.minRelDepth") }}</p>
                <RangeSlider
                  v-model="localSpotsMinRelDepth"
                  :step="1"
                  :max="100"
                  :min="0"
                  withInput
                >
                </RangeSlider>
              </div>
              <div>
                <p>{{ $t("pipe_scanner.details_modal.spots.minWidth") }}</p>
                <RangeSlider
                  v-model="localSpotsMinWidth"
                  :step="1"
                  :max="100"
                  :min="0"
                  withInput
                ></RangeSlider>
              </div>
            </div>
            <v-card>
              <v-table fixed-header height="250px" style="overflow-y: auto">
                <thead>
                  <tr>
                    <th class="text-left">
                      {{
                        `${$t(
                          "pipe_scanner.details_modal.spots.depth"
                        )} (${this.$units.getAbbr("mm")})`
                      }}
                    </th>
                    <th class="text-left">
                      {{
                        `${$t(
                          "pipe_scanner.details_modal.spots.width"
                        )} (${this.$units.getAbbr("mm")})`
                      }}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(spot, index) in degradationSpots" :key="index">
                    <td>{{ spot.depthToThreshold.toFixed(2) }}</td>
                    <td>{{ spot.width.toFixed(2) }}</td>
                  </tr>
                </tbody>
              </v-table>
            </v-card>
          </div>
        </div>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import { PlotlyGraph, RangeSlider } from "@/components";
import { LocalDegradationSpots } from "@/features/pipescanner/helpers";

export default {
  name: "WallThicknessCard",
  props: ["detailed"],
  components: {
    PlotlyGraph,
    RangeSlider,
  },
  data() {
    return {
      selectedMeasure: null,
      localSpotsMinRelDepth: 10,
      localSpotsMinWidth: 15,
    };
  },
  mounted() {
    this.selectedMeasure = this.measureOptions[0];
  },

  computed: {
    measureOptions() {
      if (!this.detailed || !this.detailed.results) return [];

      return Object.keys(this.detailed.results);
    },

    showPolarPlot() {
      return (
        this.detailed.results[this.selectedMeasure]?.type === "circumferential"
      );
    },

    linePlotlyData() {
      if (!this.selectedMeasure) {
        return [];
      }

      const result = this.detailed.results[this.selectedMeasure];
      const expectedThickness = this.detailed.wallthickness_expected_validation;
      const xLineData = result.dist;
      const yLineData = result.wt_remaining;

      const plotData = [
        {
          x: xLineData,
          y: yLineData,
          mode: "markers",
          name: this.$t("pipe_scanner.details_modal.wall_thickness.measured"),
          type: "scatter",
        },
        {
          x: xLineData,
          y: yLineData.map(() => expectedThickness),
          mode: "markers",
          name: this.$t("pipe_scanner.details_modal.wall_thickness.original"),
          type: "scatter",
        },
      ];

      const offset = this.$units.convert(2, "mm");
      const yDegradation = [0, expectedThickness + offset];

      this.degradationSpots.forEach(({ distanceStart, distanceStop }) => {
        plotData.push({
          x: [distanceStart, distanceStart],
          y: yDegradation,
          fill: "tozeroy",
          type: "scatter",
          mode: "none",
          fillcolor: "rgba(214, 39, 40, 0.2)",
          line: {
            color: "rgba(214, 39, 40, 0.2)",
          },
          opacity: 0.5,
          fillopacity: 0.5,
          showlegend: false,
        });
        plotData.push({
          x: [distanceStop, distanceStop],
          y: yDegradation,
          fill: "tonexty",
          type: "scatter",
          fillcolor: "rgba(214, 39, 40, 0.2)",
          line: {
            color: "rgba(214, 39, 40, 0.2)",
          },
          opacity: 0.5,
          fillopacity: 0.5,
          mode: "none",
          showlegend: false,
        });
      });

      return plotData;
    },

    degradationSpots() {
      if (this.detailed.material !== "AC" || !this.selectedMeasure) {
        return [];
      }

      const measure = this.selectedMeasure;
      const { dist, wt_remaining: wtRaw } = this.detailed.results[measure];
      const sortingArray = dist.map((distance, index) => ({
        distance,
        wallthickness: wtRaw[index],
      }));

      sortingArray.sort((a, b) => a.distance - b.distance);

      const distance = sortingArray.map((el) => el.distance);
      const wallthickness = sortingArray.map((el) => el.wallthickness);

      const localSpots = new LocalDegradationSpots(
        distance,
        wallthickness,
        this.detailed.wallthickness_expected_validation
      );
      const spots = localSpots.filter_spots(
        parseFloat(this.localSpotsMinRelDepth) / 100.0,
        this.$units.convert(1, "mm"),
        parseFloat(this.localSpotsMinWidth)
      ).filteredSpots;

      return spots;
    },

    linePlotlyLayout() {
      let yAxisRange;
      if (this.detailed.material === "AC") {
        const wtOrg = this.detailed.wallthickness_expected_validation;
        const offset = this.$units.convert(2, "mm");
        yAxisRange = [0, wtOrg + offset];
      }

      return {
        xaxis: {
          title: `${this.$t(
            "pipe_scanner.details_modal.wall_thickness.distance"
          )} (${this.$units.getAbbr("mm")})`,
          showline: true,
          zeroline: false,
        },
        yaxis: {
          title: `${this.$t(
            "pipe_scanner.details_modal.wall_thickness.thickness"
          )} (${this.$units.getAbbr("mm")})`,
          showline: true,
          zeroline: false,
          range: yAxisRange,
        },
        hovermode: "x",
        margin: {
          t: 25,
          b: 70,
          l: 70,
          r: 70,
        },
      };
    },

    polarPlotlyData() {
      if (!this.selectedMeasure) {
        return [];
      }
      const result = this.detailed.results[this.selectedMeasure];
      if (result.type !== "circumferential") {
        return [];
      }

      const theta = result.roll.map((x) => (x * 180.0) / Math.PI);
      const rData = result.wt_remaining;

      return [
        {
          theta,
          r: rData,
          mode: "markers",
          name: this.$t("details.graphs.mesWT"),
          type: "scatterpolar",
        },
        {
          theta,
          r: rData.map(() => this.detailed.wallthickness_expected_validation),
          mode: "markers",
          name: this.$t("details.graphs.orgWT"),
          type: "scatterpolar",
        },
      ];
    },

    polarPlotlyLayout() {
      return {
        polar: {
          angularaxis: {
            rotation: 90,
            direction: "counterclockwise",
          },
        },
        height: 330,
        margin: {
          t: 20,
          b: 40,
          l: 70,
          r: 70,
        },
      };
    },
  },
};
</script>

<style lang="scss" scoped>
h3 {
  margin: 0.6rem 0 0.8rem;
}

.flex-center {
  display: flex;
  justify-content: center;
}

.select {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  .v-select {
    flex-grow: 0;
    width: 30% !important;
  }
}

.degradation-spots {
  display: flex;
  & > * {
    flex-grow: 1;
  }

  .sliders-block {
    p {
      margin: 0.3rem 0.5rem 0;
    }
    padding-right: 8px;
    margin-right: 8px;
    border-right: 1px solid rgb(209, 209, 209);
  }

  th {
    font-size: 1rem !important;
    background: gainsboro !important;
  }
  th,
  td {
    width: 50%;
    height: 40px !important;
    color: black !important;
    border-bottom: 1px solid rgb(209, 209, 209);
    &:first-child {
      border-right: 1px solid rgb(209, 209, 209);
    }
  }
}
</style>
