import { v4 as uuidv4 } from "uuid";

import {
  materialColors,
  roadTypeColors,
  soilTypeColors,
  getDegradationColorPP,
  getRemainingLifeColor,
  qualityInfluenceColors,
} from "@/colorLegends.js";

export function getPipeSegmentsMapData(
  pipeParts,
  checkedPartsSelector,
  selectedGradientMode,
  selectedLegend,
  gradientColor,
  translate
) {
  const degradationColors = getDegradationColorPP(translate);
  const remainingLifeColors = getRemainingLifeColor(translate);

  if (selectedGradientMode === "quality_anomaly") {
    return getQualityMapData(
      pipeParts,
      selectedLegend,
      checkedPartsSelector,
      translate
    );
  }

  const features = pipeParts
    .filter(({ id }) => !checkedPartsSelector.has(id))
    .map((pipePart) => {
      let color = pipePart[selectedGradientMode];
      let value = pipePart[selectedGradientMode];

      if (selectedGradientMode === "material") {
        const materialName = pipePart[selectedGradientMode];
        color = materialColors[materialName].color;
        value = translate(`materials.${materialName}`);
      }

      if (selectedGradientMode === "degradation_level") {
        if (["PVC", "HDPE", "PE"].includes(pipePart.material)) {
          const colorIndex = pipePart["degradation_level_deformation"];
          color = degradationColors[colorIndex].color;
        }
        value = degradationColors[pipePart.degradation_level].text;
        color = degradationColors[pipePart.degradation_level].color;
      }

      if (selectedGradientMode.includes("remaining_life_calculation.")) {
        const { remaining_life_calculation } = pipePart;
        const selectedKey = selectedGradientMode.split(".")[1];
        value = remaining_life_calculation[selectedKey];

        if (selectedKey === "remaining_life_group") {
          const colorIndex = remaining_life_calculation[selectedKey];
          color = remainingLifeColors[colorIndex].color;
          value = remainingLifeColors[colorIndex].text;
        }

        if (
          ["road_distance", "degradation_rate", "ground_water_level"].includes(
            selectedKey
          )
        ) {
          color = remaining_life_calculation[selectedKey];
        }

        if (selectedKey === "road_type") {
          color = roadTypeColors[value]?.color || roadTypeColors["other"].color;
        }

        if (selectedKey === "soil_type") {
          color = soilTypeColors[value].color;
        }
      }

      return {
        type: "Feature",
        properties: {
          color,
          id: pipePart.id,
          value,
        },
        geometry: pipePart.geom,
      };
    });

  const pipe_parts_id = `pipe_parts_${uuidv4()}`;

  const isGradient = ![
    "material",
    "degradation_level",
    "quality_anomaly",
    "remaining_life_calculation.remaining_life_group",
    "remaining_life_calculation.road_type",
    "remaining_life_calculation.soil_type",
  ].includes(selectedGradientMode);

  const lineColorConfig = isGradient
    ? ["interpolate", ["linear"], ["get", "color"], ...gradientColor]
    : ["get", "color"];

  return {
    id: pipe_parts_id,
    type: "linestring",
    sourceOptions: {
      type: "geojson",
      tolerance: 0,
      data: {
        type: "FeatureCollection",
        features,
      },
    },
    layerOptions: {
      id: "layer_" + pipe_parts_id,
      source: pipe_parts_id,
      layout: {
        visibility: "visible",
      },
      type: "line",
      paint: {
        "line-color": lineColorConfig,
        "line-width": selectedLegend
          ? ["case", ["==", ["get", "value"], selectedLegend], 10, 5]
          : 5,
        "line-opacity": selectedLegend
          ? ["case", ["==", ["get", "value"], selectedLegend], 1, 0.5]
          : 1,
      },
    },
  };
}

function getQualityMapData(pipeParts, selectedLegend, checkedPartsSelector) {
  const features = pipeParts
    .filter(({ id }) => !checkedPartsSelector.has(id))
    .map((pipePart) => {
      const { id, quality_anomaly } = pipePart;
      const influences = quality_anomaly.split(", ");

      const layers = influences.map((influence, index) => ({
        type: "Feature",
        properties: {
          color: qualityInfluenceColors[influence.toLowerCase()],
          id,
          value: influence,
          layers: influences.length,
          layer: index + 1,
        },
        geometry: pipePart.geom,
      }));

      return layers;
    });

  const pipe_parts_id = `pipe_parts_${uuidv4()}`;

  return {
    id: pipe_parts_id,
    type: "linestring",
    sourceOptions: {
      type: "geojson",
      tolerance: 0,
      data: {
        type: "FeatureCollection",
        features: features.flat(),
      },
    },
    layerOptions: {
      id: "layer_" + pipe_parts_id,
      source: pipe_parts_id,
      layout: {
        visibility: "visible",
      },
      type: "line",
      paint: {
        "line-color": ["get", "color"],
        "line-width": [
          "case",
          ["==", ["get", "layers"], 3],
          ["interpolate", ["linear"], ["get", "layer"], 1, 15, 2, 10, 3, 5],
          ["==", ["get", "layers"], 2],
          ["interpolate", ["linear"], ["get", "layer"], 1, 10, 2, 5],
          5,
        ],
        "line-opacity": selectedLegend
          ? [
              "case",
              ["==", ["get", "value"], selectedLegend],
              1,
              0.3,
            ]
          : 1,
      },
    },
  };
}
