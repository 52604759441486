import { useToast } from "vue-toast-notification";

import {
  getScannersResult,
  getScannersInfoPending,
  getScannersAnalysisPending,
} from "@/features/pipescanner/api";

import { ScannerType } from "@/pages/scanner-control/config";
import { auth0 } from "@/config/auth0";

const requests = new Map();
const toast = useToast();

export const pipeScannersActions = {
  // TODO: There should be a single endpoint for fetching pipe scanners
  async fetchResultsScanners({ commit, rootState }) {
    try {
      commit("setIsLoading", { [ScannerType.Results]: true });

      const token = await auth0.getAccessTokenSilently();
      const controller = new AbortController();

      requests.set("resultsScanners", controller);

      const scanners = await getScannersResult(
        token,
        rootState.selectedGroup,
        controller
      );

      commit("setPipeScanners", { [ScannerType.Results]: scanners });
    } catch (error) {
      if (error.name !== "CanceledError") {
        console.error(error);
        toast.error(`Read results scanner - ${error.message}`, {
          position: "top-right",
        });
      }
    } finally {
      commit("setIsLoading", { [ScannerType.Results]: false });
    }
  },

  async fetchInfoScanners({ commit, rootState }) {
    try {
      commit("setIsLoading", { [ScannerType.InfoPending]: true });

      const token = await auth0.getAccessTokenSilently();
      const controller = new AbortController();

      requests.set("infoScanners", controller);

      const scanners = await getScannersInfoPending(
        token,
        rootState.selectedGroup,
        controller
      );

      commit("setPipeScanners", { [ScannerType.InfoPending]: scanners });
    } catch (error) {
      if (error.name !== "CanceledError") {
        console.error(error);
        toast.error(`Read pending_info scanners - ${error.message}`, {
          position: "top-right",
        });
      }
    } finally {
      commit("setIsLoading", { [ScannerType.InfoPending]: false });
    }
  },

  async fetchAnalysisPendingScanners({ commit, rootState }) {
    try {
      commit("setIsLoading", { [ScannerType.AnalysisPending]: true });

      const token = await auth0.getAccessTokenSilently();
      const controller = new AbortController();

      requests.set("analysisPendingScanners", controller);

      const scanners = await getScannersAnalysisPending(
        token,
        rootState.selectedGroup,
        controller
      );

      commit("setPipeScanners", { [ScannerType.AnalysisPending]: scanners });
    } catch (error) {
      if (error.name !== "CanceledError") {
        console.error(error);
        toast.error(`Read pending_analysis scanners - ${error.message}`, {
          position: "top-right",
        });
      }
    } finally {
      commit("setIsLoading", { [ScannerType.AnalysisPending]: false });
    }
  },

  cancelAllRequests() {
    for (const request of requests.values()) {
      request.abort();
    }
  },
};
