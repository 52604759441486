<template>
  <JointsMapmode
    v-if="isMapMode"
    :jointsMapData="jointsMapData"
    :gradientColor="gradientColor"
    :gradientOptions="gradientOptions"
  />
  <Joints
    v-else
    :jointsMapData="jointsMapData"
    :gradientColor="gradientColor"
    :gradientOptions="gradientOptions"
  />
</template>

<script>
import { computed } from "vue";
import { mapActions, mapState, mapMutations, mapGetters } from "vuex";

import { Joints, JointsMapmode } from "./views";

import { v4 as uuidv4 } from "uuid";
import {
  materialColors,
  jointTypeColors,
  getDegradationColorJoints,
  gradientColorArr,
  gradientColorArrAngle,
  gradientColorArrDegr,
} from "@/colorLegends.js";
import { getQuantile } from "./helpers";
import { getMinMaxValue, getMaxAngle } from "@/utils/filterHelpers.js";
import { getPipeMapData } from "@/pages/dashboard/helpers/getPipeMapData";

import {
  GradientType,
  GRADIENT_TYPES_DICTIONARY_KEY,
  GRADIENT_TYPES_DISPLAY_DECORATORS,
} from "@/pages/joints/config";

export default {
  name: "JointsPage",

  components: {
    Joints,
    JointsMapmode,
  },

  provide() {
    return {
      hasActiveFilters: computed(() => this.hasActiveFilters), // ContextMenu
      onGenerateDigUp: this.getDigupSheet, // ContextMenu + DigUpTableBtn
      cancelDigUp: () => {
        this.jointsSelector.clear();
      }, // ContextMenu
      isAwaitingDigup: computed(() => this.isAwaitingDigup), // DigUpTableBtn
      histogramTitle: computed(() => this.histogramTitle),
    };
  },

  setup() {
    return {
      getMaxAngle,
    };
  },

  created() {
    if (!this.selectedInspectionId) {
      this.$router.push({ name: "dashboard" });
    } else {
      this.getJoints();
    }
  },

  beforeUnmount() {
    this.cancellAllRequests();
  },

  computed: {
    ...mapState([
      "isMapMode",
      "selectedInspectionId",
      "selectedInspectionStats",
      "selectedGroup",
      "jointFilters",
    ]),

    ...mapState("joints", [
      "jointsSelector",
      "isAwaitingDigup",
      "selectedGradientMode",
      "selectedLegend",
      "isLoading",
    ]),

    ...mapGetters("joints", ["jointsWithIds", "filteredJoints"]),

    gradientTypeDisplayValues() {
      function getTypeDisplayValue(key, t) {
        const translatedCaption = t(`${GRADIENT_TYPES_DICTIONARY_KEY}.${key}`);

        if (GRADIENT_TYPES_DISPLAY_DECORATORS[key]) {
          const decorator = GRADIENT_TYPES_DISPLAY_DECORATORS[key];
          return decorator(translatedCaption);
        }

        return translatedCaption;
      }

      const options = {};

      for (const gradientType of Object.values(GradientType)) {
        options[gradientType] = getTypeDisplayValue(gradientType, this.$i18n.t);
      }

      return options;
    },

    gradientOptions() {
      const gradientOptions = Object.values(GradientType).map((type) => ({
        value: type,
        title: this.gradientTypeDisplayValues[type],
      }));

      return gradientOptions;
    },

    jointsMapData() {
      const mapData = [];

      const pipeMapData = getPipeMapData(this.selectedInspectionStats);
      mapData.push(pipeMapData);

      if (this.filteredJoints?.length) {
        const joints_id = `joints_${uuidv4()}`;
        mapData.push({
          id: joints_id,
          sourceOptions: {
            type: "geojson",
            data: {
              type: "FeatureCollection",
              features: this.filteredJoints
                .filter(({ id }) => !this.jointsSelector.has(id))
                .map((j) => {
                  let color = j[this.selectedGradientMode];
                  let value = j[this.selectedGradientMode];
                  if (this.selectedGradientMode === "material") {
                    const materialName = j[this.selectedGradientMode];
                    value = this.$t(`materials.${materialName}`);
                    color = materialColors[materialName].num;
                  }
                  if (this.selectedGradientMode === "joint_type") {
                    const typeName = j[this.selectedGradientMode];
                    value = this.$t(`joints.types.${typeName}`);
                    color = jointTypeColors[typeName].num;
                  }
                  if (this.selectedGradientMode === "degradation_level") {
                    value = getDegradationColorJoints(this.$t)[
                      j[this.selectedGradientMode]
                    ].text;
                  }
                  return {
                    type: "Feature",
                    properties: {
                      color,
                      id: j.id,
                      distance: j.distance,
                      value,
                    },
                    geometry: j.geom,
                  };
                }),
            },
          },
          layerOptions: {
            id: "layer_joints",
            source: joints_id,
            layout: {
              visibility: "visible",
            },
            type: "circle",
            paint: {
              "circle-color": [
                "interpolate",
                ["linear"],
                ["get", "color"],
                ...this.gradientColor,
              ],

              "circle-radius": this.selectedLegend
                ? [
                    "interpolate",
                    ["linear"],
                    ["zoom"],
                    10,
                    [
                      "case",
                      ["==", ["get", "value"], this.selectedLegend],
                      1,
                      2,
                    ],
                    22,
                    [
                      "case",
                      ["==", ["get", "value"], this.selectedLegend],
                      14,
                      12,
                    ],
                  ]
                : ["interpolate", ["linear"], ["zoom"], 10, 1, 22, 12],

              "circle-opacity": this.selectedLegend
                ? [
                    "case",
                    ["==", ["get", "value"], this.selectedLegend],
                    0.8,
                    0.1,
                  ]
                : 0.7,
            },
          },
        });
      }

      const selected_joints_id = "selected_joints";

      const selectedElementsFeatures = this.jointsWithIds
        .filter(({ id }) => this.jointsSelector.has(id))
        .map(({ id, geom }) => ({
          type: "Feature",
          properties: {
            id,
          },
          geometry: geom,
        }));

      mapData.push({
        id: selected_joints_id,
        sourceOptions: {
          type: "geojson",
          tolerance: 0,
          data: {
            type: "FeatureCollection",
            features: selectedElementsFeatures,
          },
        },
        layerOptions: {
          id: "layer_" + selected_joints_id,
          source: selected_joints_id,
          layout: {
            visibility: "visible",
          },
          type: "circle",
          paint: {
            "circle-color": "#02fae1",
            "circle-radius": 8,
          },
        },
      });

      return mapData;
    },

    gradientColor() {
      let step = null;
      const arr = [];
      let middle = 0;

      switch (this.selectedGradientMode) {
        case "angle_vertical": {
          const minMaxVertical = this.getMaxAngle(
            this.filteredJoints,
            "angle_vertical"
          );
          const quantileVertical = this.filteredJoints
            ? getQuantile(
                this.filteredJoints.map((j) => j["angle_vertical"]),
                0.99
              )
            : 0;

          return [
            minMaxVertical * -1,
            gradientColorArrAngle[0],
            quantileVertical * -1,
            gradientColorArrAngle[0],
            (quantileVertical / 2) * -1,
            gradientColorArrAngle[1],
            0,
            gradientColorArrAngle[2],
            quantileVertical / 2,
            gradientColorArrAngle[3],
            quantileVertical,
            gradientColorArrAngle[4],
            minMaxVertical,
            gradientColorArrAngle[4],
          ];
        }

        case "angle_horizontal": {
          const minMaxHorizontal = this.getMaxAngle(
            this.filteredJoints,
            "angle_horizontal"
          );
          const quantileHorizontal = this.filteredJoints
            ? getQuantile(
                this.filteredJoints.map((j) => j["angle_horizontal"]),
                0.99
              )
            : 0;
          return [
            minMaxHorizontal * -1,
            gradientColorArrAngle[0],
            quantileHorizontal * -1,
            gradientColorArrAngle[0],
            (quantileHorizontal / 2) * -1,
            gradientColorArrAngle[1],
            0,
            gradientColorArrAngle[2],
            quantileHorizontal / 2,
            gradientColorArrAngle[3],
            quantileHorizontal,
            gradientColorArrAngle[4],
            minMaxHorizontal,
            gradientColorArrAngle[4],
          ];
        }

        case "gap_width_mean": {
          const [min, max] = getMinMaxValue(
            structuredClone(this.filteredJoints),
            "gap_width_mean"
          );
          step = (max - min) / 2;
          if (this.$units.current.value === "metric") {
            middle = Math.round(min + step);
          } else {
            middle = min + step;
            middle = Number(middle.toFixed(2));
          }

          return [
            min,
            gradientColorArr[0],
            middle,
            gradientColorArr[1],
            max,
            gradientColorArr[2],
          ];
        }

        case "gap_width_max": {
          const minMaxGapMax = getMinMaxValue(
            structuredClone(this.filteredJoints),
            "gap_width_max"
          );
          step = (minMaxGapMax[1] - minMaxGapMax[0]) / 2;
          if (this.$units.current.value === "metric") {
            middle = Math.round(minMaxGapMax[0] + step);
          } else {
            middle = minMaxGapMax[0] + step;
            middle = Number(middle.toFixed(2));
          }
          return [
            minMaxGapMax[0],
            gradientColorArr[0],
            middle,
            gradientColorArr[1],
            minMaxGapMax[1],
            gradientColorArr[2],
          ];
        }

        case "degradation_level":
          return [
            0,
            gradientColorArrDegr[0],
            1,
            gradientColorArrDegr[1],
            2,
            gradientColorArrDegr[2],
            3,
            gradientColorArrDegr[3],
            4,
            gradientColorArrDegr[4],
          ];

        case "material":
          for (const key in materialColors) {
            const { num, color } = materialColors[key];
            arr.push(num, color);
          }
          return arr;

        case "joint_type":
          for (const key in jointTypeColors) {
            const { num, color } = jointTypeColors[key];
            arr.push(num, color);
          }
          return arr;

        default:
          return [];
      }
    },

    histogramTitle() {
      return this.gradientTypeDisplayValues[this.selectedGradientMode];
    },
  },

  methods: {
    ...mapMutations(["CHANGE_FILTERS"]),
    ...mapActions("joints", [
      "getJoints",
      "cancellAllRequests",
      "getDigupSheet",
    ]),

    resetPlotlyFilter() {
      const value = { property: "", range: [] };
      this.CHANGE_FILTERS({
        key: "jointsFilters",
        filter: "plotlyFilter",
        value,
      });
    },
  },

  watch: {
    selectedGradientMode() {
      this.resetPlotlyFilter();
    },
  },
};
</script>
