<template>
  <SafetyFactorMapmode
    v-if="isMapMode"
    :mapData="mapData"
    :safetyFactorOptions="safetyFactorOptions"
    :uncertaintiesOptions="uncertaintiesOptions"
    :safetyChartTitle="safetyChartTitle"
    :legendTitle="legendTitle"
  />
  <SafetyFactor
    v-else
    :mapData="mapData"
    :safetyFactorOptions="safetyFactorOptions"
    :uncertaintiesOptions="uncertaintiesOptions"
    :safetyChartTitle="safetyChartTitle"
  />
</template>

<script>
import { mapMutations, mapState, mapActions, mapGetters } from "vuex";

import { SafetyFactor, SafetyFactorMapmode } from "./views";

import { getPipeMapData } from "@/pages/dashboard/helpers/getPipeMapData";
import { v4 as uuidv4 } from "uuid";
import {
  getUncertaintiesWithColors,
  gradientColorArr,
  soilTypeColors,
} from "@/colorLegends.js";
import { getSafetyFactorOptions } from "./config";
import { findMainUncertainty, getSafetyLevel } from "./helpers";

const nonGradientOptions = [
  "safety_factor",
  "safety_factor_lower",
  "safety_factor_upper",
  "main_contributor",
  "soil_type",
];

export default {
  name: "SafetyFactorPage",

  components: {
    SafetyFactorMapmode,
    SafetyFactor,
  },

  created() {
    if (!this.selectedInspectionId) {
      this.$router.push({ name: "dashboard" });
    } else {
      this.getPipeParts();
    }
  },

  beforeUnmount() {
    this.cancelAllRequests();
  },

  computed: {
    ...mapState([
      "isMapMode",
      "selectedInspectionId",
      "selectedInspectionStats",
      "safetyFactorFilters",
      "selectedGroup",
    ]),

    ...mapState("safetyFactor", [
      "selectedViewFactor",
      "selectedLegend",
      "pipeParts",
    ]),

    ...mapGetters("safetyFactor", [
      "filteredPipeParts",
      "gradientMinMaxValues",
      "selectedPipePart",
      "hasActiveFilters",
    ]),

    safetyFactorOptions() {
      return getSafetyFactorOptions(this.$t);
    },

    uncertaintiesOptions() {
      const allMainUncertainties = this.pipeParts.map((part) =>
        findMainUncertainty(part)
      );
      const uniqueList = Array.from(new Set(allMainUncertainties));
      const uncertaintiesWithColors = getUncertaintiesWithColors(this.$t);
      return uniqueList.map((key) => uncertaintiesWithColors[key]);
    },

    safetyChartTitle() {
      return (
        this.safetyFactorOptions.find(
          (opt) => opt.value === this.selectedViewFactor
        )?.title || ""
      );
    },

    legendTitle() {
      switch (this.selectedViewFactor) {
        case "main_contributor":
          return "safety_factor.widgets.uncertainty_legend";
        case "soil_type":
          return "safety_factor.widgets.soil_type_legend";
        case "safety_factor":
        case "safety_factor_lower":
        case "safety_factor_upper":
          return "safety_factor.widgets.safety_level_legend";
        default:
          return "";
      }
    },

    mapData() {
      const mapData = [];

      const pipeMapData = getPipeMapData(this.selectedInspectionStats);
      mapData.push(pipeMapData);

      if (
        this.filteredPipeParts.length &&
        this.selectedViewFactor.includes("safety_factor")
      ) {
        const pipe_parts_id = `pipe_parts_${uuidv4()}`;
        mapData.push({
          id: pipe_parts_id,
          sourceOptions: {
            type: "geojson",
            tolerance: 0,
            data: {
              type: "FeatureCollection",
              features: this.filteredPipeParts.map((part) => {
                if (!part.safety_factor_calculation) return;
                const { color, text } = getSafetyLevel(
                  part.safety_factor_calculation[this.selectedViewFactor],
                  this.$t
                );
                return {
                  type: "Feature",
                  properties: {
                    color,
                    text,
                    id: part.id,
                  },
                  geometry: part.geom,
                };
              }),
            },
          },
          layerOptions: {
            id: "layer_" + pipe_parts_id,
            source: pipe_parts_id,
            layout: {
              visibility: "visible",
            },
            type: "line",
            paint: {
              "line-color": ["get", "color"],
              "line-width": this.selectedLegend
                ? ["case", ["==", ["get", "text"], this.selectedLegend], 10, 3]
                : ["case", ["==", ["get", "color"], "green"], 4, 10],
              "line-opacity": this.selectedLegend
                ? ["case", ["==", ["get", "text"], this.selectedLegend], 1, 0.5]
                : 1,
            },
          },
        });
      }

      if (
        this.filteredPipeParts.length &&
        ["main_contributor", "soil_type"].includes(this.selectedViewFactor)
      ) {
        let features = [];
        if (this.selectedViewFactor === "main_contributor") {
          features = this.filteredPipeParts.map((part) => {
            if (!part.safety_factor_calculation) return;
            const { text, color } =
              part.safety_factor_calculation.main_uncertainty;

            return {
              type: "Feature",
              properties: {
                color,
                text,
                id: part.id,
              },
              geometry: part.geom,
            };
          });
        }
        if (this.selectedViewFactor === "soil_type") {
          features = this.filteredPipeParts.map((part) => {
            if (!part.safety_factor_calculation) return;
            const { text, color } =
              soilTypeColors[part.safety_factor_calculation.soil_type];
            return {
              type: "Feature",
              properties: {
                color,
                text,
                id: part.id,
              },
              geometry: part.geom,
            };
          });
        }
        const pipe_parts_id = `pipe_parts_${uuidv4()}`;

        mapData.push({
          id: pipe_parts_id,
          sourceOptions: {
            type: "geojson",
            tolerance: 0,
            data: {
              type: "FeatureCollection",
              features,
            },
          },
          layerOptions: {
            id: "layer_" + pipe_parts_id,
            source: pipe_parts_id,
            layout: {
              visibility: "visible",
            },
            type: "line",
            paint: {
              "line-color": ["get", "color"],
              "line-width": this.selectedLegend
                ? ["case", ["==", ["get", "text"], this.selectedLegend], 10, 3]
                : 3,
              "line-opacity": this.selectedLegend
                ? ["case", ["==", ["get", "text"], this.selectedLegend], 1, 0.5]
                : 1,
            },
          },
        });
      }

      if (!nonGradientOptions.includes(this.selectedViewFactor)) {
        const features = this.filteredPipeParts.map((part) => {
          if (!part.safety_factor_calculation) return;
          const value = part.safety_factor_calculation[this.selectedViewFactor];
          return {
            type: "Feature",
            properties: {
              value,
              id: part.id,
            },
            geometry: part.geom,
          };
        });

        const [min, max] = this.gradientMinMaxValues;

        let lineColor = gradientColorArr[0];
        if (min !== max) {
          const mid = min + max / 2;

          lineColor = [
            "interpolate",
            ["linear"],
            ["get", "value"],
            min,
            gradientColorArr[0],
            mid,
            gradientColorArr[1],
            max,
            gradientColorArr[2],
          ];
        }

        const pipe_parts_id = `pipe_parts_${uuidv4()}`;

        mapData.push({
          id: pipe_parts_id,
          sourceOptions: {
            type: "geojson",
            tolerance: 0,
            data: {
              type: "FeatureCollection",
              features,
            },
          },
          layerOptions: {
            id: "layer_" + pipe_parts_id,
            source: pipe_parts_id,
            layout: {
              visibility: "visible",
            },
            type: "line",
            paint: {
              "line-color": lineColor,
              "line-width": 3,
              "line-opacity": 1,
            },
          },
        });
      }
      return mapData;
    },
  },
  methods: {
    ...mapMutations(["CHANGE_FILTERS"]),

    ...mapMutations("safetyFactor", ["setSelectedLegend"]),

    ...mapActions("safetyFactor", [
      "getPipeParts",
      "resetPlotlyFilter",
      "cancelAllRequests",
    ]),
  },

  watch: {
    selectedViewFactor() {
      this.resetPlotlyFilter();
      this.setSelectedLegend(null);
    },
  },
};
</script>
