import { unitsConverter } from "@/config/units";

export const includesIndex = (str) => {
  const openingBracketIndex = str.indexOf("[");
  const closingBracketIndex = str.indexOf("]");
  return (
    openingBracketIndex !== -1 &&
    closingBracketIndex !== -1 &&
    closingBracketIndex > openingBracketIndex
  );
};

export const parseKeyAndIndex = (str) => {
  const matches = str.match(/^(\w+)\[(\d+)\]$/);
  if (matches && matches.length === 3) {
    return { key: matches[1], index: parseInt(matches[2]) };
  } else {
    return false;
  }
};

export function withUnits(string, units) {
  return `${string} (${unitsConverter.instance.getAbbr(units)})`;
}
