import { createI18n } from "vue-i18n";

import enLocale from "@/locales/en.json";
import nlLocale from "@/locales/nl.json";
import { LOCALE_LS_KEY } from "@/store/settings/config";

const persistedLocale = localStorage.getItem(LOCALE_LS_KEY);

export const i18n = createI18n({
  locale: persistedLocale || "en",
  messages: { en: enLocale, nl: nlLocale },
});
