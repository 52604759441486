<template>
  <tr
    @click="vuetifyProps.onClick"
    @contextmenu="vuetifyProps.onContextmenu"
    @dblclick="vuetifyProps.onDblclick"
    v-bind="rowProps"
  >
    <slot :name="`item.prepend`" :item="item" />

    <td v-for="column in columns" :key="column.key">
      <slot
        :name="getSlotName(column)"
        v-if="$slots[getSlotName(column)]"
        v-bind="getSlotProps(column, item)"
      />

      <template v-else>
        {{ getColumnValue(column, item) }}
      </template>
    </td>

    <slot :name="`item.append`" :item="item" />
  </tr>
</template>

<script>
export default {
  name: "TableRow",

  inheritAttrs: false,

  props: {
    columns: Array,
    item: Object,
    vuetifyProps: Object,
    rowProps: Object,
  },

  methods: {
    getColumnValue(column, item) {
      if (typeof column.value === "function") {
        return column.value(item);
      }

      return item[column.value];
    },

    getSlotName(column) {
      return `item.${column.key}`;
    },

    getSlotProps(column, item) {
      return {
        column,
        item,
      };
    },
  },
};
</script>
