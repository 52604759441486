<template>
  <div class="wrapper">
    <span v-if="!isMapMode" class="title">{{
      $t("safety_factor.widgets.safety_level_legend")
    }}</span>
    <div
      v-for="safetyLevel in legendList"
      :class="computeClassName(safetyLevel.text)"
      :key="safetyLevel"
      @click="toggleSelectedLegend(safetyLevel.text)"
    >
      <div
        class="dot"
        :style="{
          backgroundColor: safetyLevel.color,
        }"
      ></div>
      <div>{{ safetyLevel.text }}</div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from "vuex";

export default {
  name: "SafetyColorsLegend",

  emits: ["selectItem"],

  computed: {
    ...mapState(["isMapMode"]),

    ...mapState("safetyFactor", ["selectedLegend", "selectedViewFactor"]),

    ...mapGetters("safetyFactor", [
      "uniqueSafetyLevels",
      "uniqueUncertaintiesOptions",
      "uniqueSoilTypes",
    ]),

    legendList() {
      if (this.selectedViewFactor.includes("safety_factor")) {
        return this.uniqueSafetyLevels;
      }

      if (this.selectedViewFactor === "main_contributor") {
        return this.uniqueUncertaintiesOptions;
      }

      if (this.selectedViewFactor === "soil_type") {
        return this.uniqueSoilTypes;
      }

      return [];
    },
  },

  methods: {
    ...mapActions("safetyFactor", ["toggleSelectedLegend"]),

    computeClassName(currentLevel) {
      const isSelected =
        !this.selectedLegend || this.selectedLegend === currentLevel;
      return ["type", "pointer", { selected: isSelected }];
    },
  },
};
</script>

<style lang="scss" scoped>
.wrapper {
  padding: 8px 12px 12px;
  border-radius: 6px;
  background-color: white;

  .title {
    font-size: 1rem;
  }

  .type {
    opacity: 0.3;
    user-select: none;
    display: flex;
    align-items: center;
    margin-top: 6px;

    &.pointer {
      cursor: pointer;
    }

    &.selected {
      opacity: 1;
    }

    .dot {
      margin-right: 6px;
      margin-bottom: 3px;
      width: 10px;
      height: 10px;
      border-radius: 5px;
    }
  }
}
</style>
