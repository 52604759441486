<template>
  <ResizableVirtualTable
    :headers="headers"
    :items="filteredPipeParts"
    :is-item-active="isPipePartActive"
    @click:row="(pipePart) => $emit('click:row', pipePart.id)"
  />
</template>

<script>
import { ResizableVirtualTable } from "@/components";

export default {
  name: "SafetyTableMapmode",

  components: { ResizableVirtualTable },

  props: {
    filteredPipeParts: Array,
    isPipePartActive: Function,
  },

  emits: ["click:row"],

  data() {
    return {
      headers: [
        {
          title: this.$t("safety_factor.table_titles.pipe_book_id"),
          key: "pipe_book_id",
          value: "pipe_book_id",
        },
        {
          title: this.$t("safety_factor.table_titles.distance"),
          key: "distance",
          value: "distance",
          units: "m",
        },
        {
          title: this.$t("safety_factor.table_titles.pipe_part_type"),
          key: "pipe_part_type",
          value: (pipePart) =>
            this.$t(`pipe_parts.types.${pipePart.pipe_part_type}`),
        },
        {
          title: this.$t("safety_factor.table_titles.material"),
          key: "material",
          value: (pipePart) => this.$t(`materials.${pipePart.material}`),
        },
        {
          title: this.$t("safety_factor.table_titles.length"),
          key: "length",
          value: "length",
          units: "m",
        },

        {
          title: this.$t("safety_factor.table_titles.safety_factor_group"),
          key: "safety_factor_parent",
          children: [
            {
              title: this.$t("safety_factor.table_titles.safety_factor"),
              key: "safety_factor",
              value: (pipePart) =>
                pipePart.safety_factor_calculation?.safety_factor?.toFixed(2),
            },
            {
              title: this.$t("safety_factor.table_titles.safety_factor_lower"),
              key: "safety_factor_lower",
              value: (pipePart) =>
                pipePart.safety_factor_calculation?.safety_factor_lower?.toFixed(
                  2
                ),
            },
            {
              title: this.$t("safety_factor.table_titles.safety_factor_upper"),
              key: "safety_factor_upper",
              value: (pipePart) =>
                pipePart.safety_factor_calculation?.safety_factor_upper?.toFixed(
                  2
                ),
            },
          ],
        },

        {
          title: this.$t("safety_factor.table_titles.main_uncertainty"),
          key: "main_uncertainty",
          value: (pipePart) =>
            pipePart.safety_factor_calculation?.main_uncertainty.text,
        },
      ],
    };
  },
};
</script>
