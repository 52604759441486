<template>
  <v-menu
    :model-value="show"
    @update:modelValue="(value) => $emit('update:show', value)"
    :close-on-content-click="false"
    location="bottom"
  >
    <template v-slot:activator="{ props }">
      <v-badge
        dot
        :color="hasActiveFilters ? '#d9534f' : '#ffffff00'"
        :offset-x="6"
      >
        <v-btn
          v-bind="props"
          variant="text"
          density="compact"
          append-icon="mdi-tune"
          >{{ $t("anomalies.filters.title") }}
        </v-btn>
      </v-badge>
    </template>
    <v-card class="filter-content" :width="300" @mousedown.stop>
      <v-card-text>
        <v-select
          :label="$t('anomalies.filters.all_types')"
          :model-value="jointFilters.selectedJointTypes"
          @update:modelValue="
            (value) => setFilters({ selectedJointTypes: value })
          "
          :items="jointTypeOptions"
          multiple
          variant="underlined"
        ></v-select>
        <v-select
          :label="$t('joints.filters.materials')"
          :model-value="jointFilters.selectedMaterials"
          @update:modelValue="
            (value) => setFilters({ selectedMaterials: value })
          "
          :items="materialOptions"
          multiple
          variant="underlined"
        >
        </v-select>
      </v-card-text>
    </v-card>
  </v-menu>
</template>

<script>
import { mapState, mapGetters, mapMutations } from "vuex";

export default {
  name: "FilterPopupMenu",

  emits: ["update:show"],

  inject: {
    hasActiveFilters: { default: false },
  },

  props: {
    show: Boolean,
  },

  computed: {
    ...mapState("joints", ["jointFilters"]),

    ...mapGetters("joints", ["jointTypes", "materials"]),

    jointTypeOptions() {
      return this.jointTypes.map((type) => ({
        value: type,
        title: this.$t(`joints.types.${type}`),
      }));
    },

    materialOptions() {
      return this.materials.map((material) => ({
        value: material,
        title: this.$t(`materials.${material}`),
      }));
    },
  },

  methods: {
    ...mapMutations("joints", ["setFilters"]),
  },
};
</script>
