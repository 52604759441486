import { mergeChanges } from "@/utils/objects";

export const jointsMutations = {
  setJoints(state, joints) {
    state.joints = joints;
  },

  setSelectedJointId(state, jointId) {
    state.selectedJointId = jointId;
  },

  setIsLoading(state, isLoading) {
    state.isLoading = isLoading;
  },

  setIsAwaitingDigup(state, isAwaitingDigup) {
    state.isAwaitingDigup = isAwaitingDigup;
  },

  setSelectedGradientMode(state, gradientMode) {
    state.selectedGradientMode = gradientMode;
  },

  setSelectedLegend(state, legend) {
    state.selectedLegend = legend;
  },

  setFilters(state, filters) {
    mergeChanges(state.jointFilters, filters);
  },
};
