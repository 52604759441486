<template>
  <div v-if="selectedPipeline" class="container">
    <v-card rounded="lg">
      <v-card-title class="font-weight-thin">
        {{ $t("anomalies.table_name") }}
        <i>
          {{ inspectionTitle }}
        </i>
      </v-card-title>
      <v-card-text :class="[isLoadingAnomalies ? '' : 'pt-6px']">
        <div v-if="isLoadingAnomalies" class="text-center">
          <v-progress-circular
            color="dark-blue"
            indeterminate
            :size="52"
            :width="5"
          ></v-progress-circular>
        </div>
        <div v-else>
          <div class="filters-containers">
            <v-select
              :label="$t('anomalies.filters.all_types')"
              :model-value="anomalyFilters.selectedAnomaliesTypes"
              @update:modelValue="
                (value) => setFilters({ selectedAnomaliesTypes: value })
              "
              :items="anomaliesTypesOptions"
              item-title="title"
              item-value="value"
              multiple
              variant="underlined"
              hide-details
            >
            </v-select>
            <v-select
              :label="$t('anomalies.filters.materials')"
              :model-value="anomalyFilters.selectedMaterials"
              @update:modelValue="
                (value) => setFilters({ selectedMaterials: value })
              "
              :items="materialsOptions"
              item-title="title"
              item-value="value"
              multiple
              variant="underlined"
              hide-details
            >
            </v-select>
            <div class="slider">
              <RangeSlider
                :title="$t('anomalies.filters.distance')"
                :model-value="anomalyFilters.selectedDistance"
                @update:modelValue="
                  (value) => setFilters({ selectedDistance: value })
                "
                :min="minMaxDistance[0]"
                :max="minMaxDistance[1]"
                :step="0.01"
              ></RangeSlider>
            </div>
            <div class="slider">
              <RangeSlider
                :title="$t('anomalies.filters.length')"
                :model-value="anomalyFilters.selectedLength"
                @update:modelValue="
                  (value) => setFilters({ selectedLength: value })
                "
                :min="minMaxLength[0]"
                :max="minMaxLength[1]"
                :step="0.01"
              ></RangeSlider>
            </div>
          </div>
          <AnomaliesTable />
        </div>
      </v-card-text>
    </v-card>

    <v-card v-if="!isLoadingAnomalies" rounded="lg">
      <v-card-title class="font-weight-thin">
        {{ $t("anomalies.details.title") }}
      </v-card-title>
      <v-card-text>
        <p class="text-h6">{{ $t("anomalies.details.map") }}</p>
        <AnomalyMap
          :onSelectLegend="
            (value) => $emit('update:filterSelectedTypes', value)
          "
        />
        <p class="text-h6">
          {{ $t("anomalies.details.height_profile.title") }}
        </p>
        <div class="anomaly-charts">
          <AnomaliesHeightChart class="height-chart" />
          <AnomaliesCylinder />
        </div>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { mapState, mapGetters, mapMutations } from "vuex";
import { AnomalyMap } from "./components";
import {
  AnomaliesHeightChart,
  AnomaliesCylinder,
  AnomaliesTable,
} from "@/pages/anomaly/components";
import { RangeSlider } from "@/components";

export default {
  name: "PipelineAnomalies",
  components: {
    AnomaliesTable,
    AnomalyMap,
    RangeSlider,
    AnomaliesHeightChart,
    AnomaliesCylinder,
  },
  emits: ["update:filterSelectedTypes", "update:filterSelectedMaterials"],

  computed: {
    ...mapState(["selectedInspectionStats", "selectedInspectionId"]),
    ...mapGetters(["selectedPipeline"]),
    ...mapState("anomalies", ["isLoadingAnomalies", "anomalyFilters"]),
    ...mapGetters("anomalies", [
      "minMaxDistance",
      "minMaxLength",
      "anomaliesTypes",
      "filterMaterials",
    ]),

    inspectionTitle() {
      if (!this.selectedPipeline) return "";
      const inspectionName = this.selectedPipeline.inspections.find(
        (ins) => ins.id === this.selectedInspectionId
      )?.project_reference;
      return `- ${this.selectedPipeline.client_pipeline_id} : ${this.selectedPipeline.pipeline_name} - ${inspectionName}`;
    },

    anomaliesTypesOptions() {
      return this.anomaliesTypes.map((type) => ({
        value: type,
        title: this.$t(`anomalies.types.${type}`),
      }));
    },

    materialsOptions() {
      return this.filterMaterials.map((material) => ({
        value: material,
        title: this.$t(`materials.${material}`),
      }));
    },
  },

  methods: {
    ...mapMutations("anomalies", ["setFilters"]),
  },
};
</script>

<style lang="scss" scoped>
.pt-6px {
  padding-top: 6px !important;
}

.filters-containers {
  display: flex;
  align-items: flex-end;
  margin-bottom: 1rem;
  .v-select,
  .slider {
    width: 25%;
  }
  .v-select {
    margin-inline: 0.6rem;
  }
  .slider {
    text-align: center;
  }
}

.anomaly-charts {
  display: flex;
  flex-wrap: wrap;
  .height-chart {
    width: 800px;
  }
}
</style>
