<template>
  <div
    style="width: 100%; height: 100%; position: relative"
    :id="MAP_CONTAINER_ID"
  >
    <MapStyleSelect />
    <MapboxMap
      v-bind="$attrs"
      ref="mapRef"
      :access-token="accessToken"
      :map-style="mapStyle"
      :center="center"
      style="width: 100%; height: 100%"
      @mb-created="onMapCreated"
      @mb-contextmenu="openCommentContext"
    >
      <template v-slot:loader>
        <AcqLoader />
      </template>
      <AcqLoader v-if="showLoader" />
      <MapboxPopup
        v-if="popupScanner.isOpen"
        :lng-lat="popupScanner.lngLat"
        @mb-close="popupScanner.isOpen = false"
      >
        <ScannerInfoPopup
          :status="popupScanner.status"
          :scanners="popupScanner.scanners"
          :openDetailedModal="openDetailedModal"
          :openEditModel="openEditModel"
        />
      </MapboxPopup>
      <MapboxPopup
        v-if="contextmenu.isOpen"
        :lng-lat="contextmenu.lngLat"
        @mb-close="contextmenu.isOpen = false"
        :closeButton="false"
        :anchor="contextmenu.mode === 'scanner' ? 'top' : 'left'"
      >
        <v-card @click="contextmenu.isOpen = false">
          <CommentMenu
            v-if="contextmenu.mode === 'comment'"
            :contextMenuParams="contextmenu"
            :openCommentEdit="openCommentEdit"
          />
          <ScannerMenu
            v-if="contextmenu.mode === 'scanner'"
            :contextMenuParams="contextmenu"
            :openDetailedModal="openDetailedModal"
            :openEditModel="openEditModel"
          />
          <DigUpMenu v-if="contextmenu.mode === 'dig-up'" />
        </v-card>
      </MapboxPopup>
      <MapboxNavigationControl v-if="!hideMapControls" position="top-left" />
      <slot
        v-if="showSourceAndLayers"
        name="sources"
        :openDigupMenu="openDigupMenu"
      ></slot>
      <slot></slot>
      <v-btn
        v-if="!hideBackToPipeButton && selectedPipeline"
        class="fly-to-pipe-btn"
        size="x-small"
        @click="flyToPipe"
        >{{ $t("move_to_pipe") }}</v-btn
      >
      <WidgetsBar
        v-if="widgets"
        :widgets="widgets"
        @maximize="onMaximizeWidget"
      />
    </MapboxMap>
    <CommentEditModal ref="refCommentModal" />
    <DetailedModal ref="refDetailedModal" />
    <EditModal ref="refEditModal" />

    <DigUpLoader
      v-if="isAwaitingDigup"
      style="position: absolute; left: 5px; bottom: 10px"
    />
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from "vuex";
import {
  MapboxMap,
  MapboxPopup,
  MapboxNavigationControl,
} from "@studiometa/vue-mapbox-gl";

import MapStyleSelect from "./MapStyleSelect.vue";
import WidgetsBar from "./WidgetsBar.vue";
import AcqLoader from "../AcqLoader.vue";
import { CommentEditModal, CommentMenu } from "@/features/comments/components";
import { ScannerMenu } from "@/features/pipescanner/components";
import { DigUpMenu, DigUpLoader } from "@/features/dig-up/components";

import {
  addScannersSourceAndLayers,
  getScannersClusterSourceAndLayers,
  convertResultsScanners,
} from "@/features/pipescanner/helpers";
import {
  addCommentsSourceAndLayers,
  getCommentsClusterSourceAndLayers,
} from "@/features/comments/helpers";
import {
  ScannerInfoPopup,
  DetailedModal,
  EditModal,
} from "@/features/pipescanner/components";

import { ACCESS_KEY, DEFAULT_CENTER } from "@/constants/mapBox";
import { RESULTS } from "@/features/pipescanner/constants";
import { fitMapToCoordinates } from "@/utils/mapHelpers.js";
import { addWMSLayer, wmsWatchHandler } from "@/features/wms/helpers";

import { MAP_CONTAINER_ID } from "@/constants/mapBox";

export default {
  inheritAttrs: false,

  name: "ModifiedMapbox",
  inject: { isAwaitingDigup: { default: false } },
  components: {
    AcqLoader,
    MapStyleSelect,
    MapboxMap,
    MapboxPopup,
    MapboxNavigationControl,
    DigUpMenu,
    DigUpLoader,
    CommentMenu,
    ScannerMenu,
    ScannerInfoPopup,
    CommentEditModal,
    DetailedModal,
    EditModal,
    WidgetsBar,
  },

  props: {
    showLoader: Boolean,
    filteredScannersMapData: Object,
    hideMapControls: Boolean,
    forceHideScanners: Boolean,
    hideBackToPipeButton: Boolean,
    wmsLayers: Array,
    widgets: Object,
    onMaximizeWidget: Function,
    center: {
      default: DEFAULT_CENTER,
    },
  },

  setup() {
    return {
      accessToken: ACCESS_KEY,
      MAP_CONTAINER_ID,
    };
  },

  data() {
    return {
      showSourceAndLayers: true,

      popupScanner: {
        isOpen: false,
        lngLat: [0, 0],
        status: null,
        scanners: [],
      },
      contextmenu: {
        isOpen: false,
        lngLat: [0, 0],
        status: null,
        scanners: [],
        mode: null,
      },
    };
  },

  computed: {
    ...mapState(["mapStyle", "comments", "isMapMode", "showMapScanners"]),

    ...mapState("pipeScanners", ["pipeScanners"]),

    ...mapGetters(["selectedPipeline"]),

    map() {
      return this.$refs.mapRef.map;
    },

    hideScanners() {
      return !this.isMapMode || !this.showMapScanners || this.forceHideScanners;
    },

    scannersMapData() {
      if (this.filteredScannersMapData) {
        return this.filteredScannersMapData;
      }

      const convertedResultsScanners = this.hideScanners
        ? []
        : convertResultsScanners(this.pipeScanners[RESULTS], this.$units);

      return getScannersClusterSourceAndLayers(
        convertedResultsScanners,
        RESULTS
      );
    },

    commentsMapData() {
      return getCommentsClusterSourceAndLayers(this.comments);
    },
  },

  methods: {
    ...mapActions(["CLEAR_SELECTED_PIPE_DATA"]),

    flyToPipe() {
      fitMapToCoordinates(this.map, this.selectedPipeline.geom.coordinates);
    },

    openCommentEdit(lngLat, edited) {
      this.$refs.refCommentModal.editComment(lngLat, edited);
    },

    openDigupMenu() {
      this.contextmenu.mode = "dig-up";
      this.contextmenu.isOpen = true;
    },

    openDetailedModal(id, scannersOnMarker) {
      this.$refs.refDetailedModal.showScannerDetailed(id, scannersOnMarker);
    },

    openEditModel(id, status, scannersOnMarker) {
      this.$refs.refEditModal.showScannerEditor(id, status, scannersOnMarker);
    },

    onMapCreated() {
      let isFirstLaunch = true;

      if (this.selectedPipeline) {
        fitMapToCoordinates(this.map, this.selectedPipeline.geom.coordinates, {
          linear: true,
        });
      }

      this.map.on("style.load", () => {
        this.wmsLayers?.forEach((layer) => {
          addWMSLayer(this.map, layer);
        });
        this.showSourceAndLayers = true;

        addScannersSourceAndLayers(
          this.map,
          this.scannersMapData,
          this.onScannerClick,
          this.openScannerContext,
          isFirstLaunch
        );

        addCommentsSourceAndLayers(
          this.map,
          this.commentsMapData,
          this.onCommentClick,
          isFirstLaunch
        );

        isFirstLaunch = false;
      });
    },

    openCommentContext(event) {
      if (this.selectedPipeline) {
        const { lng, lat } = event.lngLat;
        this.contextmenu.mode = "comment";
        this.contextmenu.lngLat = [lng, lat];
        this.contextmenu.isOpen = true;
      }
    },

    openScannerContext(event) {
      const { longitude, latitude, status, scannersOnMarker } =
        event.features[0].properties;

      this.contextmenu.mode = "scanner";
      this.contextmenu.status = status;
      this.contextmenu.scanners = JSON.parse(scannersOnMarker);
      this.contextmenu.lngLat = [longitude, latitude];
      this.contextmenu.isOpen = true;
    },

    onScannerClick(event) {
      if (this.popupScanner.isOpen) {
        this.popupScanner.isOpen = false;
      } else {
        const { longitude, latitude, status, scannersOnMarker } =
          event.features[0].properties;

        this.popupScanner.status = status;
        this.popupScanner.scanners = JSON.parse(scannersOnMarker);
        this.popupScanner.lngLat = [longitude, latitude];
        this.popupScanner.isOpen = true;
      }
    },

    onCommentClick({ features }) {
      const geom = JSON.parse(features[0].properties.geom);
      const commentData = {
        ...features[0].properties,
        geom,
      };
      this.openCommentEdit(geom.coordinates, commentData);
    },

    updateSourceData({ sourceId, sourceOptions }) {
      this.map.getSource(sourceId)?.setData(sourceOptions.data);
    },
  },

  watch: {
    mapStyle() {
      this.showSourceAndLayers = false;
    },

    wmsLayers(newStyles, prevStyles) {
      wmsWatchHandler(newStyles, prevStyles, this.map);
    },

    selectedPipeline() {
      this.popupScanner.isOpen = false;
      this.contextmenu.isOpen = false;
    },

    scannersMapData(newData, prevData) {
      const isDifferent =
        JSON.stringify(newData.sourceOptions) !==
        JSON.stringify(prevData.sourceOptions);
      if (isDifferent) {
        this.updateSourceData(newData);
        this.popupScanner.isOpen = false;
        this.contextmenu.isOpen = false;
      }
    },

    commentsMapData(newData, prevData) {
      const isDifferent =
        JSON.stringify(newData.sourceOptions) !==
        JSON.stringify(prevData.sourceOptions);
      if (isDifferent) {
        this.updateSourceData(newData);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.fly-to-pipe-btn {
  position: absolute;
  top: 5px;
  right: 5px;
  opacity: 0.6;
  &:hover {
    opacity: 1;
  }
}
</style>
