export function initializeResizeObserver(widgetNames) {
  return new ResizeObserver((entries) => {
    for (const e of entries) {
      const diff = e.contentRect.width - this.parentW;

      widgetNames.forEach((widgetName) => {
        const isNoSpace =
          this.widgets[widgetName].left + this.widgets[widgetName].width >
          e.contentRect.width;
        if (isNoSpace) {
          this.widgets[widgetName].left = this.widgets[widgetName].left + diff;
        }
      });

      this.parentW = e.contentRect.width;
      this.parentH = e.contentRect.height - 5;
    }
  });
}
