<template>
  <div class="bar">
    <v-btn
      v-for="[key, widget] in minimizedWidgets"
      :key="widget.title"
      color="#1c243d"
      size="small"
      :ripple="false"
      @click="$emit('maximize', key)"
    >
      <v-icon icon="mdi-open-in-new"></v-icon>
      {{ $t(widget.title) }}
      <v-tooltip
        v-if="checkActiveFilterIndicator(key)"
        :text="$t('common.has_active_filters')"
        location="top"
      >
        <template v-slot:activator="{ props }">
          <div class="filter-indicator" v-bind="props"></div>
        </template>
      </v-tooltip>
    </v-btn>
  </div>
</template>
<script>
export default {
  name: "WidgetBar",
  emits: ["maximize"],
  inject: {
    hasActiveFilters: { default: false },
  },
  props: {
    widgets: Object,
  },
  computed: {
    minimizedWidgets() {
      return Object.entries(this.widgets).filter(
        ([, widget]) => widget.isOpen === false && widget.title
      );
    },
  },
  methods: {
    checkActiveFilterIndicator(key) {
      if (!this.hasActiveFilters) return false;

      const hasFiltersWidget = Object.getOwnPropertyNames(this.widgets).some(
        (widgetName) => widgetName === "filters"
      );
      return hasFiltersWidget ? key === "filters" : key === "table";
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/scss/variables.scss";

.bar {
  display: flex;
  position: absolute;
  padding: 4px 10px;
  bottom: 0;
  right: 0;
  z-index: 25;

  & button {
    margin-left: 4px;
    text-transform: none;
    padding: 0 9px;
    font-size: 0.9rem;
    color: $cyan-main !important;
    .v-icon {
      color: white;
      transform: scaleX(-1) !important;
      margin-right: 6px;
    }
    &:hover {
      .v-icon {
        color: inherit;
      }
    }
    box-shadow: 0px 1px 7px 0px rgba(255, 255, 255, 0.26),
      0px 3px 6px 0px rgba(0, 0, 0, 0.215);

    .filter-indicator {
      margin-left: 4px;
      width: 8px;
      height: 8px;
      border-radius: 50%;
      background-color: #d9534f;
    }
  }
}
</style>
