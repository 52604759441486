<template>
  <v-card class="files-card" :elevation="4">
    <div class="title">
      <h4>{{ $t("analyser.mapping.title") }}</h4>
      <v-btn rounded="lg" color="deep-orange" @click="onSave">
        {{ $t("analyser.mapping.save") }}
      </v-btn>
    </div>
    <div class="container-table">
      <div v-if="isLoading" class="loader">
        <v-progress-circular
          color="dark-blue"
          indeterminate
          :size="52"
          :width="5"
        ></v-progress-circular>
      </div>
      <table v-else class="table">
        <thead>
          <tr>
            <th>{{ $t("analyser.mapping.name") }}</th>
            <th style="text-align: left">
              {{ $t("analyser.mapping.use_odo") }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, index) in mappingSettings" :key="item.name">
            <td>{{ item.name }}</td>
            <td>
              <v-checkbox
                :model-value="item.use_odo"
                @update:modelValue="onCheck(index)"
                :label="$t('analyser.mapping.use_odo')"
                color="info"
                density="compact"
                hide-details
              />
            </td>
          </tr>
        </tbody>
      </table></div
  ></v-card>
</template>

<script>
export default {
  name: "MappingSelectTable",
  props: {
    mappingSettings: Array,
    onCheck: Function,
    onSave: Function,
    isLoading: Boolean,
  },
};
</script>

<style lang="scss" scoped>
.files-card {
  width: 400px;
  height: 600px;
  .title {
    padding: 0.5rem 1rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .loader {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
</style>
