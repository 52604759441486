<template>
  <vue-drag-resize
    :w="sizeProps.width"
    :h="sizeProps.height"
    :parentW="parentW"
    :parentH="parentH"
    :y="sizeProps.top"
    :x="sizeProps.left"
    :isDraggable="isDraggable"
    @dragging="handleDragging"
    @click="(e) => $emit('click', e)"
    v-show="show"
    parentLimitation
    v-bind="$attrs"
  >
    <v-card :class="heightFitContent ? '' : 'full-height'">
      <v-card-title v-if="showTitle" :class="isDraggable ? 'move' : ''">
        <div class="title-text font-weight-thin">
          <slot name="title-text"></slot>
        </div>
        <div class="title-prepend">
          <slot name="title-prepend"></slot>
          <v-divider
            v-if="$slots['title-prepend'] && !disableMinimize"
            color="white"
            vertical
          ></v-divider>
          <v-btn
            v-if="!disableMinimize"
            variant="text"
            density="compact"
            icon="mdi-arrow-down-drop-circle-outline"
            @click="handleMinimize"
            @mousedown.stop
            title="minimize"
          ></v-btn>
        </div>
      </v-card-title>
      <v-card-text :style="cardTextStyles">
        <slot></slot>
      </v-card-text>
    </v-card>
  </vue-drag-resize>
</template>

<script>
export default {
  name: "WidgetCard",
  emits: ["drag", "minimize", "click"],
  inject: {
    parentW: { default: 1000 },
    parentH: { default: 800 },
  },
  props: {
    sizeProps: {
      type: Object,
      default() {
        return { width: 500, height: 670, top: 15, left: 500 };
      },
    },
    show: {
      type: Boolean,
      default: true,
    },
    isDraggable: {
      type: Boolean,
      default: true,
    },
    heightFitContent: Boolean,
    disableMinimize: Boolean,
  },
  computed: {
    showTitle() {
      return this.$slots["title-text"] || !this.disableMinimize;
    },

    cardTextStyles() {
      return {
        padding: "0 !important",
        "overflow-y": "auto",
        "overflow-x": "hidden",
        height: this.showTitle ? "calc(100% - 48px)" : "100%",
      };
    },
  },
  methods: {
    handleDragging(event) {
      this.$emit("drag", event);
    },
    handleMinimize() {
      this.$emit("minimize");
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/variables.scss";

.v-card {
  &.full-height {
    height: 100%;
  }

  .v-card-title {
    display: flex;
    justify-content: space-between;
    user-select: none;
    text-wrap: wrap;

    &.move {
      cursor: move;
    }

    .title-prepend {
      display: flex;
      align-items: center;
      color: white;

      .v-divider {
        opacity: 0.4;
        margin: 5px 8px;
      }

      .v-btn:hover {
        color: $cyan-main;
      }
    }
  }
}
</style>
