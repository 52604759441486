<template>
  <v-card id="scanner-form-card" :max-width="696">
    <v-card-text>
      <div style="padding: 0 0.8rem 0.5rem; font-size: 1rem">
        <p>{{ `${tForm("adding")}: ${detailed.id}` }}</p>
        <p>{{ `${tForm("date")}: ${detailed.inspection_date}` }}</p>
        <p>
          {{ `${tForm("last_edit")}: ${lastEditDate}` }}
        </p>
      </div>
      <v-form ref="form" @submit.prevent="onSubmit">
        <v-row no-gutters>
          <v-col class="mx-3">
            <v-text-field
              v-model="form.tag"
              :rules="[(v) => !!v || 'Tag is required']"
              :label="tForm('tag', '*')"
              variant="underlined"
            ></v-text-field>
          </v-col>
          <v-col class="mx-3">
            <v-select
              v-model="form.medium"
              :items="mediumOptions"
              :rules="[(v) => !!v || 'Medium is required']"
              :label="tForm('medium', '*')"
              variant="underlined"
            ></v-select>
          </v-col>
        </v-row>

        <v-row no-gutters>
          <v-col class="mx-3">
            <v-select
              v-model="form.coating"
              :items="coatingOptions"
              :rules="[(v) => !!v || `${tForm('coating')} is required`]"
              :label="tForm('coating', '*')"
              variant="underlined"
            ></v-select>
          </v-col>
          <v-col class="mx-3">
            <v-text-field
              v-model="form.client_ref"
              :label="tForm('clientRef')"
              variant="underlined"
            ></v-text-field>
          </v-col>
        </v-row>

        <v-row no-gutters>
          <v-col class="mx-3">
            <v-text-field
              type="number"
              v-model="form.constructionyear"
              :rules="[
                (v) => !!v || `${tForm('construction_year')} is required`,
              ]"
              :label="tForm('construction_year', '*')"
              variant="underlined"
            ></v-text-field>
          </v-col>
          <v-col class="mx-3">
            <v-text-field
              type="number"
              v-model="form.wallthickness_expected"
              :rules="[(v) => !!v || `${tForm('exp_wt')} is required`]"
              :label="tForm('exp_wt') + getUnit('mm', '*')"
              variant="underlined"
            ></v-text-field>
          </v-col>
        </v-row>

        <v-row no-gutters>
          <v-col class="mx-3">
            <v-text-field
              type="number"
              v-model="form.diameter"
              :rules="[(v) => !!v || `${tForm('diameter')} is required`]"
              :label="tForm('diameter') + getUnit('mm', '*', 'GIS, ')"
              variant="underlined"
              disabled
            ></v-text-field>
          </v-col>
          <v-col class="mx-3">
            <v-text-field
              type="number"
              :model-value="form.circumference"
              @update:modelValue="handleCircumferenceInput"
              :rules="[(v) => !!v || `${tForm('circumference')} is required`]"
              :label="tForm('circumference') + getUnit('mm', '*')"
              variant="underlined"
            ></v-text-field>
          </v-col>
        </v-row>

        <v-row no-gutters>
          <v-col class="mx-3">
            <v-text-field
              type="number"
              v-model="form.wallthickness_expected_validation"
              :rules="[(v) => !!v || `${tForm('val_wt')} is required`]"
              :label="tForm('val_wt') + getUnit('mm', '*')"
              variant="underlined"
              disabled
            ></v-text-field>
          </v-col>
          <v-col class="mx-3">
            <v-text-field
              type="number"
              v-model="form.coverage"
              :rules="[(v) => !!v || `${tForm('coverage')} is required`]"
              :label="tForm('coverage') + getUnit('m', '*')"
              variant="underlined"
            ></v-text-field>
          </v-col>
        </v-row>

        <v-row no-gutters>
          <v-col class="mx-3">
            <v-select
              v-model="form.material"
              :items="['HDPE', 'STL', 'NGIJ', 'PVC', 'GGIJ', 'AC']"
              :rules="[(v) => !!v || `${tForm('material')} is required`]"
              :label="tForm('material', '*')"
              variant="underlined"
            ></v-select>
          </v-col>
          <v-col class="mx-3">
            <v-select
              v-model="form.reason"
              :items="reasonOptions"
              :rules="[(v) => !!v || `${tForm('reason')} is required`]"
              :label="tForm('reason', '*')"
              variant="underlined"
            ></v-select>
          </v-col>
        </v-row>

        <v-row no-gutters>
          <v-col class="mx-3">
            <v-text-field
              v-model="form.address"
              :label="tForm('address')"
              density="compact"
              variant="underlined"
              clearable
            ></v-text-field>
          </v-col>
        </v-row>

        <v-row no-gutters>
          <v-col class="mx-3">
            <v-textarea
              v-model="form.comments"
              :label="tForm('comments')"
              variant="outlined"
              auto-grow
              rows="1"
              hide-details
            ></v-textarea>
          </v-col>
        </v-row>

        <div style="display: flex; align-items: center; margin: 1rem 0.7rem 0">
          <v-btn
            :disabled="isSaving || isNothingChanged"
            :loading="isSaving"
            type="submit"
            color="#1c243d"
            style="color: white; width: 120px; height: 40px"
          >
            {{ $t("common.save") }}
          </v-btn>
          <v-alert
            v-if="alert.type"
            :type="alert.type"
            :text="alert.text"
            closable
          ></v-alert>
        </div>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapState, mapMutations } from "vuex";
import { postScannerInspection } from "@/features/pipescanner/api";

export default {
  name: "FormCard",
  props: {
    detailed: Object,
    scannerStatus: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      oldForm: {},
      form: {
        tag: "",
        medium: null,
        coating: null,
        client_ref: "",
        constructionyear: 1900,
        wallthickness_expected: 0,
        diameter: 0,
        circumference: 0,
        wallthickness_expected_validation: 0,
        coverage: 0,
        material: "",
        reason: "",
        address: "",
        comments: "",
      },
      isSaving: false,
      alert: { type: null, text: "" },
    };
  },

  mounted() {
    const converted = this.convertScannerUnits(this.detailed);
    const formFields = Object.getOwnPropertyNames(this.form);
    formFields.forEach((field) => {
      this.form[field] = converted[field];
      // oldForm always uses values without units converting
      this.oldForm[field] = this.detailed[field];
    });
  },

  computed: {
    ...mapState(["selectedGroup"]),

    lastEditDate() {
      if (this.oldForm.edit_date) {
        return this.oldForm.edit_date.toString();
      }
      if (this.detailed.edit_date) {
        return this.detailed.edit_date.toString();
      }
      if (this.detailed._ts) {
        return new Date(this.detailed._ts * 1000).toString();
      }
      return "none";
    },

    mediumOptions() {
      return [
        { title: this.tForm("treated"), value: "drinking-water" },
        { title: this.tForm("untreated"), value: "non-drinking-water" },
        { title: this.tForm("waste"), value: "waste-water" },
      ];
    },

    coatingOptions() {
      return [
        { title: this.tForm("coatings.none"), value: "none" },
        { title: this.tForm("coatings.bitumen"), value: "bitumen" },
        { title: this.tForm("coatings.epoxy"), value: "epoxy" },
        { title: this.tForm("coatings.pe"), value: "pe" },
        { title: this.tForm("coatings.zinc"), value: "zinc" },
        { title: this.tForm("coatings.yes"), value: "yes" },
        { title: this.tForm("coatings.no"), value: "no" },
      ];
    },

    reasonOptions() {
      return [
        { title: this.tForm("reasons.newproject"), value: "newproject" },
        { title: this.tForm("reasons.failure"), value: "failure" },
        { title: this.tForm("reasons.eol"), value: "eol" },
      ];
    },

    changedFields() {
      let form = this.form;
      if (this.$units.current.value === "imperial") {
        form = this.convertScannerUnits(this.form, true);
      }

      // oldForm always uses values without units converting
      const changedFields = this.findChangedFields(form, this.oldForm);
      return changedFields;
    },

    isNothingChanged() {
      return Object.getOwnPropertyNames(this.changedFields).length === 0;
    },
  },

  methods: {
    ...mapMutations(["UPDATE_SCANNER"]),

    handleCircumferenceInput(value) {
      this.form.circumference = value;
      // wall thickness validation = ((circumference / pi)- inside diameter)/2
      const wallthicknessValidation =
        (value / Math.PI - this.form.diameter) / 2;
      this.form.wallthickness_expected_validation =
        +wallthicknessValidation.toFixed(3);
    },

    async onSubmit() {
      const { valid } = await this.$refs.form.validate();

      if (valid) {
        this.saveScannerDetails(this.changedFields);
      }
    },

    async saveScannerDetails(changedFields) {
      this.isSaving = true;
      this.alert = { type: null, text: "" };
      try {
        const token = await this.$auth0.getAccessTokenSilently();
        const response = await postScannerInspection(
          token,
          this.selectedGroup,
          this.detailed.id,
          changedFields
        );

        this.oldForm = { ...this.oldForm, ...changedFields };
        this.oldForm.edit_date = new Date(response._ts * 1000);
        this.UPDATE_SCANNER({
          key: this.scannerStatus,
          scannerId: this.detailed.id,
          updateForm: changedFields,
        });

        this.isSaving = false;
        this.alert = {
          type: "success",
          text: this.tForm("message"),
        };
      } catch (error) {
        if (error.name !== "CanceledError") {
          console.error(error);
          this.alert = {
            type: "error",
            text: error.response.data?.detail[0],
          };
          this.isSaving = false;
        }
      }
    },

    convertScannerUnits(scanner, reverse) {
      const converted = { ...scanner };
      [
        "diameter",
        "wallthickness_expected",
        "wallthickness_expected_validation",
        "circumference",
      ].forEach((key) => {
        converted[key] = this.$units.convert(
          converted[key],
          reverse ? "in" : "mm"
        );
      });

      converted.coverage = this.$units.convert(
        converted.coverage,
        reverse ? "ft" : "m"
      );
      return converted;
    },
    /**
     * function compare new and previous detailed forms
     * @param {object} newForm - new form object
     * @param {object} prevForm - previous detailed form object
     * @return {object} an object with fields that have been changed
     */
    findChangedFields(newForm, prevForm) {
      const changed = {};

      for (const field in newForm) {
        if (
          newForm.hasOwnProperty(field) &&
          newForm[field] != prevForm[field]
        ) {
          changed[field] = newForm[field];
        }
      }
      return changed;
    },

    getUnit(unit, append, prepend) {
      const prependString = prepend ? prepend : "";
      const appendString = append ? append : "";
      return `(${prependString}${this.$units.getAbbr(unit)})${appendString}`;
    },

    tForm(field, append) {
      const appendString = append ? append : "";
      return this.$t(`pipe_scanner.edit_modal.form.${field}`) + appendString;
    },
  },
};
</script>

<style lang="scss" scoped>
.v-alert {
  padding: 4px;
  margin-left: 1rem;
}
</style>
