export const getQuantile = (arr, quantile) => {
  if (!arr || arr.length === 0) return 0;
  const abc = (array) => array.map((x) => Math.abs(x));
  const asc = (array) => array.sort((a, b) => a - b);
  const absolute = abc(arr);
  const sorted = asc(absolute);
  const pos = (sorted.length - 1) * quantile;
  const base = Math.floor(pos);
  const rest = pos - base;
  if (sorted[base + 1] !== undefined) {
    return sorted[base] + rest * (sorted[base + 1] - sorted[base]);
  } else {
    return sorted[base];
  }
};
