import { createRouter, createWebHistory } from "vue-router";
import Dashboard from "@/pages/dashboard/DashboardPage.vue";
import Anomalies from "@/pages/anomaly/AnomaliesPage.vue";
import Joints from "@/pages/joints/JointsPage.vue";
import PipeParts from "@/pages/pipe-parts/PipePartsPage.vue";
import SafetyFactor from "@/pages/safety-factor/SafetyFactorPage.vue";
import ScannerControl from "@/pages/scanner-control/ScannerControlPage.vue";
import { ScannerAnalyser } from "@/features/analyser/components";
// import HomeAuth from "../views/HomeAuth.vue";
import { authGuard } from "@auth0/auth0-vue";

const ScannerControlView = {
  template: `
      <router-view></router-view>
  `,
};

const routes = [
  {
    path: "/",
    name: "dashboard",
    beforeEnter: authGuard,
    component: Dashboard,
  },
  {
    path: "/anomalies",
    name: "anomalies",
    beforeEnter: authGuard,
    component: Anomalies,
  },
  {
    path: "/joints",
    name: "joints",
    beforeEnter: authGuard,
    component: Joints,
  },
  {
    path: "/pipe-parts",
    name: "pipe-parts",
    beforeEnter: authGuard,
    component: PipeParts,
  },
  {
    path: "/safety-factor",
    name: "safety-factor",
    beforeEnter: authGuard,
    component: SafetyFactor,
  },
  {
    path: "/scanner-control",
    beforeEnter: authGuard,
    component: ScannerControlView,
    children: [
      {
        path: "",
        name: "scanner-control",
        component: ScannerControl,
      },
      {
        path: ":id/analyser",
        name: "scanner-control-analyser",
        component: ScannerAnalyser,
      },
    ],
  },
  {
    path: "/settings",
    name: "settings",
    beforeEnter: authGuard,
    component: () => import("../pages/settings/SettingsPage.vue"),
  },
  {
    path: "/client-administration",
    name: "client-administration",
    beforeEnter: authGuard,
    component: () =>
      import("@/features/administration/pages/client-admin/ClientAdminPage.vue"),
  },
  {
    path: "/project-administration",
    name: "project-administration",
    beforeEnter: authGuard,
    component: () =>
      import("@/features/administration/pages/project-admin/ProjectAdminPage.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
