<template>
  <div class="gradient-block">
    <div class="gradient-scale" :style="gradientScaleStyle"></div>
    <div class="gradient-ruler">
      <template
        v-if="
          gradientMode === 'angle_vertical' ||
          gradientMode === 'angle_horizontal'
        "
      >
        <span>{{ `&#60;${gradientColor[2].toFixed(2)}` }}</span>
        <span>{{ gradientColor[6] }}</span>
        <span>{{ `&#62;${gradientColor[10].toFixed(2)}` }}</span>
      </template>
      <template
        v-else-if="
          gradientMode === 'gap_width_max' || gradientMode === 'gap_width_mean'
        "
      >
        <span>{{ `${gradientColor[0]} ${$units.getAbbr("mm")}` }}</span>
        <span>{{ `${gradientColor[2]} ${$units.getAbbr("mm")}` }}</span>
        <span>{{ `${gradientColor[4]} ${$units.getAbbr("mm")}` }}</span>
      </template>
      <template v-else>
        <span>{{ gradientColor[0] }}</span>
        <span>{{ gradientColor[2] }}</span>
        <span>{{ gradientColor[4] }}</span>
      </template>
    </div>
  </div>
</template>

<script>
import { gradientColorArr, gradientColorArrAngle } from "@/colorLegends.js";

export default {
  name: "JointsGradient",
  props: ["gradientMode", "gradientColor"],
  computed: {
    gradientScaleStyle() {
      if (
        this.gradientMode === "angle_vertical" ||
        this.gradientMode === "angle_horizontal"
      ) {
        return {
          background: `linear-gradient(to top,
            ${gradientColorArrAngle[0]} 0%,
            ${gradientColorArrAngle[1]} 25%,
            ${gradientColorArrAngle[2]} 50%,
            ${gradientColorArrAngle[3]} 75%,
            ${gradientColorArrAngle[4]} 100%)`,
        };
      } else {
        return {
          background: `linear-gradient(to top,
                            ${gradientColorArr[0]} 0%,
                            ${gradientColorArr[1]} 50%,
                            ${gradientColorArr[2]} 100%)`,
        };
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.gradient-block {
  user-select: none;
  min-height: 250px;
  height: 100%;
  display: flex;
  margin-left: 1rem;
  padding: 1.2rem 0;
  .gradient-scale {
    width: 1.5rem;
  }
  .gradient-ruler {
    font-size: 1rem;
    padding-left: 0.5rem;
    display: flex;
    flex-direction: column-reverse;
    justify-content: space-between;
    span {
      white-space: nowrap;
    }
  }
}
</style>
