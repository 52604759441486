<template>
  <div class="popup-container">
    <div class="content">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  name: "SubtablePopupContainer",
};
</script>

<style scoped lang="scss">
@use "~@/assets/scss/custom-style.scss" as styles;

.popup-container {
  position: absolute;
  top: 120%;
  left: 20px;
  border-radius: 5px;
  box-shadow: 0px 0px 13px 2px #c4c4c4;
  z-index: 1;

  &::after {
    bottom: 100%;
    left: 27px;
    border: solid transparent;
    content: "";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    border-color: rgba(255, 255, 255, 0);
    border-bottom-color: #ffffff;
    border-width: 8px;
    margin-left: -8px;
  }

  &::before {
    content: "";
    position: absolute;
    transform: rotate(45deg);
    width: 14px;
    height: 14px;
    top: -7px;
    left: 20px;
    z-index: -1;
    box-shadow: 0px 0px 13px 0px #c4c4c4;
  }
}

.content {
  @include styles.scrollbar;

  max-height: 130px;
  min-width: 300px;
  overflow-y: auto;
  border-radius: 5px;
  background-color: #ffffff;

  p {
    margin: 10px 20px;
  }
}
</style>
