<template>
  <div v-if="isLoading" class="loader">
    <v-progress-circular
      color="dark-blue"
      indeterminate
      :size="52"
      :width="5"
    ></v-progress-circular>
  </div>
  <PlotlyGraph v-else :data="plotlyData" :layout="plotlyLayout" />
</template>

<script>
import { PlotlyGraph } from "@/components";

export default {
  name: "FilterRollChart",
  components: {
    PlotlyGraph,
  },
  props: {
    isLoading: Boolean,
    currentFile: String,
  },

  computed: {
    plotlyData() {
      if (!this.currentFile) {
        return [];
      }

      const { roll, wt } = this.currentFile.results;

      const theta = roll.map((x) => (x * 180) / Math.PI);

      return [
        {
          theta: theta,
          r: wt,
          mode: "markers",
          name: "Wallthickness",
          type: "scatterpolar",
        },
      ];
    },

    plotlyLayout() {
      return {
        height: 450,
        showlegend: true,
        margin: {
          t: 50,
          b: 60,
          l: 70,
          r: 30,
        },
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.loader {
  height: 450px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
