<template>
  <div
    v-if="!selectedPipePart"
    :class="['blank', isMapMode ? 'full-height' : 'size-limit']"
  >
    <p>{{ $t("pipe_parts.details.radar.no_selected") }}</p>
  </div>
  <PlotlyGraph
    v-else
    :data="plotlyData"
    :layout="plotlyLayout"
    :config="{ displayModeBar: false }"
    @mousedown.stop="$emit('graph-mousedown')"
  />
</template>

<script>
import { mapState, mapGetters } from "vuex";

import { PlotlyGraph } from "@/components";

export default {
  name: "PipePartsRadar",

  props: ["title"],

  components: {
    PlotlyGraph,
  },

  emits: ["graph-mousedown"],

  computed: {
    ...mapState(["isMapMode"]),

    ...mapState("pipeParts", [
      "wallThicknessRemaining",
      "selectedGradientMode",
    ]),

    ...mapGetters("pipeParts", ["selectedPipePart"]),

    plotlyData() {
      const data = [];
      if (this.selectedPipePart) {
        let wallThicknessList = [];

        if (this.selectedGradientMode.includes("remaining_")) {
          const key = `wall_thickness_${this.selectedGradientMode}`;
          const [, remainingType] = this.selectedGradientMode.split("_");
          const { layerNum } = this.wallThicknessRemaining[remainingType];
          wallThicknessList = this.selectedPipePart[key].map((el) => {
            let item = 0;
            if (el.length > 1) {
              item =
                el[layerNum] === null
                  ? 0
                  : this.$units.convert(el[layerNum], "mm");
            } else {
              item = el[0] === null ? 0 : this.$units.convert(el[0], "mm");
            }
            return item;
          });
        } else if (this.selectedGradientMode.includes("degradated_total_")) {
          const key = `wall_thickness_${this.selectedGradientMode}`;
          wallThicknessList = this.selectedPipePart[key].map((el) =>
            el === null ? 0 : this.$units.convert(el, "mm")
          );
        } else {
          const { layerNum } = this.wallThicknessRemaining.mean;
          wallThicknessList =
            this.selectedPipePart.wall_thickness_remaining_mean.map((el) => {
              let item = 0;
              if (el.length > 1) {
                item =
                  el[layerNum] === null
                    ? 0
                    : this.$units.convert(el[layerNum], "mm");
              } else {
                item = el[0] === null ? 0 : this.$units.convert(el[0], "mm");
              }
              return item;
            });
        }

        data.push({
          type: "scatterpolar",
          mode: "markers",
          r: [...wallThicknessList.slice(1)],
        });
      }

      return data;
    },

    plotlyLayout() {
      return {
        width: this.isMapMode ? undefined : 400,
        polar: {
          radialaxis: {
            visible: true,
            direction: "clockwise",
          },
          angularaxis: {
            direction: "clockwise",
          },
        },
        title: this.title,
        margin: {
          t: 60,
          b: 40,
          l: 70,
          r: 50,
        },
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.blank {
  display: flex;
  justify-content: center;
  align-items: center;

  text-align: center;
  font-size: 1.2rem;
  font-weight: bold;
  color: darkgrey;
  background-color: whitesmoke;
  border-radius: 10px;
}

.size-limit {
  margin: 2rem 0;
  width: 400px;
}

.full-height {
  height: 100%;
}
</style>
