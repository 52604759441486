<template>
  <PlotlyGraph
    :data="plotlyData"
    :layout="plotlyLayout"
    :config="{ displayModeBar: false }"
    @mousedown.stop="$emit('graph-mousedown')"
  />
</template>

<script>
import { mapState } from "vuex";

import { PlotlyGraph } from "@/components";

export default {
  name: "SafetyHeightChart",

  props: ["visibleParts"],

  components: {
    PlotlyGraph,
  },

  emits: ["graph-mousedown"],

  computed: {
    ...mapState([
      "selectedInspectionStats",
      "pipelineDepth",
      "pipelineElevation",
    ]),

    ...mapState("safetyFactor", ["pipeParts"]),

    plotlyData() {
      const data = [];

      if (this.selectedInspectionStats) {
        const distance =
          this.selectedInspectionStats.height_profile.distance.map((d) =>
            this.$units.convert(d, "m")
          );
        const zHeightProfile =
          this.selectedInspectionStats.height_profile.z.map((zH) =>
            this.$units.convert(zH, "m")
          );
        const plElevation = this.pipelineElevation.length
          ? this.pipelineElevation.map((e) => this.$units.convert(e, "m"))
          : [];

        if (plElevation.length) {
          data.push({
            x: distance,
            y: plElevation,
            type: "scatter",
            name: this.$t("safety_factor.height_profile.elevation"),
            line: {
              color: "#6e4f00",
            },
          });

          if (this.pipeParts) {
            const groundWaterLower = [];
            const groundWaterUpper = [];

            this.pipeParts.forEach((part, i) => {
              if (part.safety_factor_calculation) {
                const lowerLevel =
                  plElevation[i] -
                  this.$units.convert(
                    part.safety_factor_calculation.ground_water_level_min,
                    "m"
                  );
                const upperLevel =
                  plElevation[i] -
                  this.$units.convert(
                    part.safety_factor_calculation.ground_water_level_max,
                    "m"
                  );
                groundWaterLower.push(lowerLevel);
                groundWaterUpper.push(upperLevel);
              }
            });

            data.push({
              x: distance,
              y: groundWaterUpper,
              type: "scatter",
              name: this.$t("safety_factor.height_profile.water_upper"),
              line: {
                color: "CornflowerBlue",
              },
            });

            data.push({
              x: distance,
              y: groundWaterLower,
              type: "scatter",
              name: this.$t("safety_factor.height_profile.water_lower"),
              line: {
                color: "DarkTurquoise",
              },
            });
          }
        }

        data.push({
          x: distance,
          y: zHeightProfile,
          type: "scatter",
          name: this.$t("safety_factor.height_profile.profile"),
          line: {
            color: "#1c243d",
          },
        });
      }

      return data;
    },

    plotlyLayout() {
      const dupe = {
        xaxis: {
          title: `${this.$t(
            "pipe_parts.details.height_profile.xaxis_name"
          )} (${this.$units.getAbbr("m")})
              `,
          showline: true,
        },
        yaxis: {
          title: `${this.$t(
            "pipe_parts.details.height_profile.yaxis_name"
          )} (${this.$units.getAbbr("m")})`,
          showline: true,
          range: [0, 1],
        },
        margin: {
          t: 30,
          b: 70,
          l: 70,
          r: 50,
        },
      };
      if (this.selectedInspectionStats) {
        const zHeightProfile =
          this.selectedInspectionStats.height_profile.z.map((val) =>
            this.$units.convert(val, "m")
          );
        const plElevation = this.pipelineElevation.length
          ? this.pipelineElevation.map((e) => this.$units.convert(e, "m"))
          : [];
        const plDepth = this.pipelineDepth.length
          ? this.pipelineDepth.map((e) => this.$units.convert(e, "m"))
          : [];
        const hMax = Math.max(...zHeightProfile, ...plElevation, ...plDepth);
        const hMin = Math.min(...zHeightProfile, ...plElevation, ...plDepth);
        dupe.yaxis["range"] = [hMin - 5.0, hMax + 2.0];
      }

      if (this.visibleParts && this.visibleParts.length) {
        const visibleDistance = this.visibleParts.map((pp) => pp.distance);
        const dMax = Math.max(...visibleDistance);
        const dMin = Math.min(...visibleDistance);
        dupe.xaxis["range"] = [dMin, dMax];
      }

      return dupe;
    },
  },
};
</script>
