import { baseApi } from "@/baseApi";

export async function getScannersResult(token, group, controller) {
  const { data } = await baseApi({
    url: `/pipescanner/inspections/v1/results/${group}`,
    method: "get",
    signal: controller?.signal,
    headers: { Authorization: `Bearer ${token}` },
  });

  return data;
}

export async function getScannersInfoPending(token, group, controller) {
  const { data } = await baseApi({
    url: `/pipescanner/inspections/v1/info-pending/${group}`,
    method: "get",
    signal: controller?.signal,
    headers: { Authorization: `Bearer ${token}` },
  });

  return data;
}

export async function getScannersAnalysisPending(token, group, controller) {
  const { data } = await baseApi({
    url: `/pipescanner/inspections/v1/analysis-pending/${group}`,
    method: "get",
    signal: controller?.signal,
    headers: { Authorization: `Bearer ${token}` },
  });

  return data;
}

export async function getScannerDetailed(token, group, id, controller) {
  const { data } = await baseApi({
    url: `/pipescanner/inspections/v1/results-detailed/${group}/${id}`,
    method: "get",
    signal: controller?.signal,
    headers: { Authorization: `Bearer ${token}` },
  });

  return data;
}

export async function getMultipleScannersDetails(
  token,
  group,
  selectedInspections,
  controller
) {
  const queryIds = selectedInspections.map((id) => `"${id}"`).join();
  const { data } = await baseApi({
    url: "/pipescanner/inspections/v1/custom-query/",
    method: "post",
    signal: controller?.signal,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data: {
      query: `SELECT * FROM c WHERE c.id IN (${queryIds})`,
    },
  });

  return data[`${group}-pipescanner-inspections`];
}

export async function getInspectionData(token, id, group, controller) {
  const { data } = await baseApi({
    url: "/pipescanner/inspections/v1/custom-query/",
    method: "post",
    signal: controller?.signal,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data: {
      query: `SELECT c.id, c.tag, c.user, c.material, c.coating, c.coating_inner, c.wallthickness_expected, c.wallthickness_expected_validation FROM c WHERE c.id = '${id}'`,
    },
  });

  return data[`${group}-pipescanner-inspections`][0];
}

export async function getScannerPhotos(token, group, id, controller) {
  const { data } = await baseApi({
    url: `/pipescanner/inspections/v1/images/${id}/${group}`,
    method: "get",
    signal: controller?.signal,
    headers: { Authorization: `Bearer ${token}` },
  });

  return data;
}

export async function postScannerInspection(
  token,
  group,
  id,
  form,
  controller
) {
  const { data } = await baseApi({
    url: `/pipescanner/inspections/v1/edit-inspection/${id}/${group}`,
    method: "post",
    signal: controller?.signal,
    headers: { Authorization: `Bearer ${token}` },
    data: form,
  });

  return data;
}

export async function postScannerPhotos(
  token,
  group,
  id,
  imagesForm,
  controller
) {
  const response = await baseApi({
    url: `/pipescanner/inspections/v1/add-images/${id}/${group}`,
    method: "post",
    signal: controller?.signal,
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "multipart/form-data",
    },
    data: imagesForm,
  });

  return response;
}
