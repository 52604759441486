<template>
  <VSelect
    :model-value="wallThicknessRemaining[wtLevel].layerNum"
    @update:model-value="(newLayer) => handleLayerChange(newLayer)"
    @click.stop
    :items="items"
    variant="underlined"
    item-title="title"
    item-value="value"
    density="compact"
    hide-details
  />
</template>

<script>
import { mapState, mapMutations } from "vuex";

export default {
  name: "LayerSelect",

  props: {
    wtLevel: String,
  },

  computed: {
    ...mapState("pipeParts", ["wallThicknessRemaining"]),

    items() {
      const layersCount = this.wallThicknessRemaining[this.wtLevel].length;

      const items = Array.from({ length: layersCount }, (_, index) => ({
        value: index,
        title: `layer ${index + 1}`,
      }));

      return items;
    },
  },

  methods: {
    ...mapMutations("pipeParts", ["setWallThicknessForLevel"]),

    handleLayerChange(newLayer) {
      this.setWallThicknessForLevel({
        level: this.wtLevel,
        changes: { layerNum: newLayer },
      });
    },
  },
};
</script>
