import { getAnomaliesMapData } from "@/features/anomaly/helpers";
import {
  getAllMaterials,
  getCenterAndConvertUnits,
} from "@/pages/anomaly/helpers";
import { getPipeMapData } from "@/pages/dashboard/helpers/getPipeMapData";
import { unitsConverter } from "@/config/units";
import {
  getMinMaxValue,
  getUniqueValues,
  rangeFiltering,
  valueFiltering,
} from "@/utils/filterHelpers";

export const anomaliesGetters = {
  anomaliesTypes(state) {
    return getUniqueValues(state.anomalies, "anomaly_type");
  },

  minMaxDistance(state) {
    return getMinMaxValue(state.anomalies, "distance");
  },

  minMaxLength(state) {
    return getMinMaxValue(state.anomalies, "length");
  },

  filterMaterials(state) {
    return getAllMaterials(state.anomalies);
  },

  hasTypeFilters(state) {
    return Boolean(state.anomalyFilters.selectedAnomaliesTypes.length);
  },

  hasDistanceFilters(state, getters) {
    const [minSelectedDistance, maxSelectedDistance] =
      state.anomalyFilters.selectedDistance;

    const [minAvailableDistance, maxAvailableDistance] = getters.minMaxDistance;

    return (
      minSelectedDistance > minAvailableDistance &&
      maxSelectedDistance < maxAvailableDistance
    );
  },

  hasLengthFilters(state, getters) {
    const [minSelectedLength, maxSelectedLength] =
      state.anomalyFilters.selectedLength;

    const [minAvailableLength, maxAvailableLength] = getters.minMaxLength;

    return (
      minSelectedLength > minAvailableLength &&
      maxSelectedLength < maxAvailableLength
    );
  },

  hasActiveFilters(_, getters) {
    getters.hasTypeFilters ||
      getters.hasDistanceFilters ||
      getters.hasLengthFilters;
  },

  selectedAnomaly(state, getters) {
    return getters.anomaliesWithCenter.find(
      (anomaly) => anomaly.id === state.selectedAnomalyId
    );
  },

  reportedAnomalies(state) {
    return state.anomalies.filter((anomaly) => anomaly.is_report_anomaly);
  },

  anomaliesWithCenter(state) {
    return getCenterAndConvertUnits(state.anomalies);
  },

  filteredAnomalies(state, getters, rootState) {
    let filtered = getters.anomaliesWithCenter;

    if (state.anomalyFilters.selectedAnomaliesTypes.length) {
      filtered = valueFiltering(
        filtered,
        "anomaly_type",
        state.anomalyFilters.selectedAnomaliesTypes
      );
    }

    if (state.anomalyFilters.selectedMaterials.length && !rootState.isMapMode) {
      filtered = filtered.filter((an) => {
        const arr = an.pipe_parts.filter((pp) =>
          state.anomalyFilters.selectedMaterials.includes(pp.material)
        );
        return arr.length;
      });
    }

    filtered = rangeFiltering(
      filtered,
      "distance",
      state.anomalyFilters.selectedDistance
    );

    filtered = rangeFiltering(
      filtered,
      "length",
      state.anomalyFilters.selectedLength
    );

    return filtered;
  },

  anomalyMapData(state, getters, rootState) {
    const mapData = [];

    const pipeMapData = getPipeMapData(rootState.selectedInspectionStats);
    mapData.push(pipeMapData);

    const distancePoints = Object.entries(
      rootState.selectedInspectionStats.map_trace
    ).map(([distance, geometry]) => ({
      type: "Feature",
      properties: {
        distance: unitsConverter.instance.convert(distance, "m"),
      },
      geometry,
    }));

    const distanceDataName = "distance_points";
    mapData.push({
      id: distanceDataName,
      type: "points",
      sourceOptions: {
        type: "geojson",
        data: {
          type: "FeatureCollection",
          features: distancePoints,
        },
      },
      layerOptions: {
        id: `layer_${distanceDataName}`,
        source: distanceDataName,
        layout: {
          visibility: "visible",
        },
        type: "circle",
        paint: {
          "circle-opacity": 0,
        },
      },
    });

    const { largeAnomaliesMapData, smallAnomaliesMapData } =
      getAnomaliesMapData(
        getters.filteredAnomalies,
        getters.anomaliesTypes,
        state.checkedAnomaliesSelector
      );

    mapData.push(largeAnomaliesMapData, smallAnomaliesMapData);

    return mapData;
  },
};
