export const getPipeSegmentIdsBetweenElements = (
  pipeElementsArr,
  rangeElementsIds,
  limitCallback
) => {
  const [firstId, secondId] = rangeElementsIds;

  const indexFirst = pipeElementsArr.findIndex((part) => part.id === firstId);
  const indexSecond = pipeElementsArr.findIndex((part) => part.id === secondId);

  const [start, end] =
    indexFirst < indexSecond
      ? [indexFirst, indexSecond]
      : [indexSecond, indexFirst];

  let selectedSegment = pipeElementsArr
    .slice(start, end + 1)
    .map((part) => part.id);

  if (selectedSegment.length > 3 && limitCallback) {
    limitCallback();
  }

  if (indexFirst > indexSecond) {
    selectedSegment = selectedSegment.slice(-3);
  } else {
    selectedSegment = selectedSegment.slice(0, 3);
  }

  return selectedSegment;
};
