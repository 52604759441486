import { v4 as uuidv4 } from "uuid";

export const getSelectedSegmentsMapData = (
  pipeElementsArr,
  selectedElementsIds
) => {
  const selected_pipe_elements_id = `selected_pipe_parts_${uuidv4()}`;

  const selectedElementsFeatures = pipeElementsArr
    ?.filter(({ id }) => selectedElementsIds.includes(id))
    .map(({ id, geom }) => ({
      type: "Feature",
      properties: {
        id,
      },
      geometry: geom,
    }));

  return {
    id: selected_pipe_elements_id,
    sourceOptions: {
      type: "geojson",
      tolerance: 0,
      data: {
        type: "FeatureCollection",
        features: selectedElementsFeatures,
      },
    },
    layerOptions: {
      id: "layer_" + selected_pipe_elements_id,
      source: selected_pipe_elements_id,
      layout: {
        visibility: "visible",
      },
      type: "line",
      paint: {
        "line-color": "#02fae1",
        "line-width": 10,
        "line-opacity": 1,
      },
    },
  };
};
