<template>
  <v-card id="detailed-info-card" width="400">
    <v-card-title>
      {{ $t("pipe_scanner.info") }}
    </v-card-title>
    <v-card-text>
      <table class="popup-table">
        <tbody v-for="{ text, value } in rows" :key="text">
          <tr>
            <td>{{ text }}</td>
            <td>{{ value }}</td>
          </tr>
        </tbody>
      </table>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: "InfoCard",
  props: {
    detailed: Object,
  },
  computed: {
    rows() {
      if (!this.detailed) return [];
      const {
        address,
        material,
        constructionyear,
        diameter,
        wallthickness_expected,
        wallthickness_expected_validation,
        circumference,
        latitude,
        longitude,
        tag,
      } = this.detailed;
      const unit = ` (${this.$units.getAbbr("mm")})`;

      return [
        {
          text: this.$t("pipe_scanner.params.location"),
          value: address,
        },
        {
          text: this.$t("pipe_scanner.params.material"),
          value: material,
        },
        {
          text: this.$t("pipe_scanner.params.construction_year"),
          value: constructionyear,
        },
        {
          text: this.$t("pipe_scanner.params.diameter") + unit,
          value: diameter,
        },
        {
          text: this.$t("pipe_scanner.params.wallthickness_expected") + unit,
          value: wallthickness_expected,
        },
        {
          text:
            this.$t("pipe_scanner.params.wallthickness_expected_validation") +
            unit,
          value: wallthickness_expected_validation,
        },
        {
          text: this.$t("pipe_scanner.params.circumference") + unit,
          value: circumference,
        },
        {
          text: this.$t("pipe_scanner.params.latitude"),
          value: latitude,
        },
        {
          text: this.$t("pipe_scanner.params.longitude"),
          value: longitude,
        },
        {
          text: this.$t("pipe_scanner.params.project_number"),
          value: tag,
        },
      ];
    },
  },
};
</script>

<style lang="scss" scoped>
.v-card-text {
  padding: 0 4px 2px !important;
}
</style>
