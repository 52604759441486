import { createStore } from "vuex";

import { anomaliesModule } from "./anomalies";
import { jointsModule } from "@/store/joints";
import { pipePartsModule } from "@/store/pipeParts";
import { safetyFactorModule } from "@/store/safetyFactor";
import { pipeScannersModule } from "@/store/pipeScanners";
import { scannerControlModule } from "@/store/scannerControl";
import { settingsModule } from "@/store/settings";
import { unitsConverter } from "@/config/units";

import { STYLE_MAP } from "@/constants/mapBox";
import {
  RESULTS,
  INFO_PENDING,
  ANALYSIS_PENDING,
} from "@/features/pipescanner/constants";

export default createStore({
  modules: {
    anomalies: anomaliesModule,
    joints: jointsModule,
    pipeParts: pipePartsModule,
    safetyFactor: safetyFactorModule,
    pipeScanners: pipeScannersModule,
    scannerControl: scannerControlModule,
    settings: settingsModule,
  },

  state: {
    mapStyle: STYLE_MAP,
    isMapMode: true,

    // GROUP_SERVICES
    exportSettings: null,
    userPermissions: [],
    serviceGroups: [],
    selectedGroup: "",

    // INSPECTION
    groupPipelines: [],
    selectedPipelineId: null,
    selectedInspectionId: null,
    selectedInspectionStats: null,

    // ELEVATION & DEPTH
    pipelineElevation: [],
    pipelineDepth: [],

    // COMMENTS
    comments: [],

    // SCANNERS
    showMapScanners: false,
  },

  getters: {
    selectedPipeline(state) {
      return state.groupPipelines.find(
        ({ id }) => id === state.selectedPipelineId
      );
    },

    convertedHeightProfileDistance(state) {
      return state.selectedInspectionStats.height_profile.distance.map((item) =>
        unitsConverter.instance.convert(item, "m")
      );
    },

    convertedHeightProfileZ(state) {
      return state.selectedInspectionStats.height_profile.z.map((item) =>
        unitsConverter.instance.convert(item, "m")
      );
    },

    convertedPipelineElevation(state) {
      if (state.pipelineElevation.length === 0) {
        return [];
      }

      return state.pipelineElevation.map((item) =>
        unitsConverter.instance.convert(item, "m")
      );
    },

    convertedPipelineDepth(state) {
      if (state.pipelineDepth.length === 0) {
        return [];
      }

      return state.pipelineDepth.map((item) =>
        unitsConverter.instance.convert(item, "m")
      );
    },

    heightRange(_, getters) {
      const yAxisValues = [
        ...getters.convertedHeightProfileZ,
        ...getters.convertedPipelineElevation,
        ...getters.convertedPipelineDepth,
      ];

      const min = Math.min(...yAxisValues);
      const max = Math.max(...yAxisValues);

      return [min, max];
    },
  },

  mutations: {
    SET_MAP_STYLE(state, style) {
      state.mapStyle = style;
    },
    SWITCH_MAPMODE(state) {
      state.isMapMode = !state.isMapMode;
    },

    // GROUP_SERVICES
    SET_EXPORT_SETTINGS(state, settings) {
      state.exportSettings = settings;
    },
    SET_USER_PERMISSIONS(state, permissions) {
      state.userPermissions = permissions;
    },
    SET_SERVICE_GROUPS(state, services) {
      state.serviceGroups = services;
    },
    SET_SELECTED_GROUP(state, group) {
      state.selectedGroup = group;
    },
    // INSPECTION
    SET_GROUP_PIPELINES(state, pipelines) {
      state.groupPipelines = pipelines;
    },
    SET_SELECTED_PIPELINE_ID(state, pipeline) {
      state.selectedPipelineId = pipeline;
    },
    SET_SELECTED_INSPECTION_ID(state, inspection) {
      state.selectedInspectionId = inspection;
    },
    SET_SELECTED_INSPECTION_STATS(state, stats) {
      state.selectedInspectionStats = stats;
    },
    // ELEVATION & DEPTH
    SET_PIPELINE_ELEVATION(state, elevation) {
      state.pipelineElevation = elevation;
    },
    SET_PIPELINE_DEPTH(state, depth) {
      state.pipelineDepth = depth;
    },
    // COMMENTS
    SET_COMMENTS(state, comments) {
      state.comments = comments;
    },
    UPDATE_COMMENT(state, comment) {
      const editedIndex = state.comments.findIndex(
        ({ id }) => id === comment.id
      );
      if (editedIndex >= 0) {
        state.comments[editedIndex] = comment;
      } else {
        state.comments.push(comment);
      }
    },
    REMOVE_COMMENT(state, commentId) {
      state.comments = state.comments.filter(
        (comment) => comment.id !== commentId
      );
    },
    // SCANNERS
    SWITCH_MAP_SCANNERS(state) {
      state.showMapScanners = !state.showMapScanners;
    },

    SET_SCANNERS(state, { key, scanners }) {
      state.pipeScanners[key] = scanners;
    },

    UPDATE_SCANNER(state, { key, scannerId, updateForm }) {
      state.pipeScanners[key] = state.pipeScanners[key].map((scanner) => {
        if (scanner.id !== scannerId) {
          return scanner;
        }
        const updatedScanner = { ...scanner };
        const fields = Object.getOwnPropertyNames(updateForm);
        fields.forEach((field) => {
          if (updatedScanner.hasOwnProperty(field)) {
            updatedScanner[field] = updateForm[field];
          }
        });
        return updatedScanner;
      });
    },

    RESET_SCANNERS(state) {
      state.pipeScanners.pipeScanners = {
        [RESULTS]: [],
        [INFO_PENDING]: [],
        [ANALYSIS_PENDING]: [],
      };
    },

    // FILTERS
    CHANGE_FILTERS(state, payload) {
      const { key, filter, value } = payload;
      state[key][filter] = value;
    },
    _RESET_FILTERS(state) {
      state.jointsFilters = {
        selectedJointsTypes: [],
        selectedMaterials: [],
        selectedVerticalAngle: 0,
        selectedHorizontalAngle: 0,
        selectedGapMean: [],
        selectedGapMax: [],
        plotlyFilter: {
          property: "",
          range: [],
        },
      };
      state.pipePartsFilters = {
        selectedDistance: [],
        selectedPipePartsTypes: [],
        selectedMaterials: [],
        selectedOvalityMax: [],
        selectedOvalityMean: [],
        selectedDeformationHor: [],
        selectedDeformationVer: [],
        plotlyFilter: {
          property: "",
          range: [],
        },
      };
      state.safetyFactorFilters = {
        selectedMainUncertainties: [],
        selectedSafetyRange: [],
        selectedLowerSafetyRange: [],
        selectedUpperSafetyRange: [],
        plotlyFilter: {
          property: "",
          range: [],
        },
      };
    },
  },

  actions: {
    SET_USER_GROUPS({ commit }, groups) {
      commit("SET_SERVICE_GROUPS", groups);
      commit("SET_SELECTED_GROUP", groups[0]);
    },

    CLEAR_SELECTED_PIPE_DATA({ commit }) {
      commit("SET_SELECTED_PIPELINE_ID", null);
      commit("SET_SELECTED_INSPECTION_ID", null);
      commit("SET_SELECTED_INSPECTION_STATS", null);
      commit("SET_COMMENTS", []);
      commit("SET_PIPELINE_ELEVATION", []);
      commit("SET_PIPELINE_DEPTH", []);
    },

    CLEAR_ALL_SELECTED_DATA({ commit, dispatch }) {
      dispatch("CLEAR_SELECTED_PIPE_DATA");
      commit("SET_GROUP_PIPELINES", []);
      commit("RESET_SCANNERS");
      commit("scannerControl/resetState", null, { root: true });
    },

    RESET_FILTERS({ commit, dispatch }) {
      commit("_RESET_FILTERS");
      dispatch("resetAnomaliesFilters");
    },
  },
});
