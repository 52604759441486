import { pipePartsState } from "@/store/pipeParts/state";
import { pipePartsGetters } from "@/store/pipeParts/getters";
import { pipePartsMutations } from "@/store/pipeParts/mutations";
import { pipePartsActions } from "@/store/pipeParts/actions";

export const pipePartsModule = {
  namespaced: true,
  state: pipePartsState,
  getters: pipePartsGetters,
  mutations: pipePartsMutations,
  actions: pipePartsActions,
};
