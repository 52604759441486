import { i18n } from "@/config/i18n";

/** Color list
 * "#fff000", "#fd04fb", "#ff1f1f", "#ab63fa", "#c9b500", "#497a00",
 * "#6e4f00", "#b3ff87", "#aacc00", "#00781c", "#4fa4ff", "#0007c7",
 * "#fc614f", "#ab63fa", "#bb45ff", "#ffa8ec", "#cc0030"
 */
/** New Color list
 * "#ad0205", "#ff1f1f", "#de8385", "#f3bfc3", "#fa8925", "#d5fb00",
 * "#63db07", "#00781c", "#add2f8", "#6e4f00", "#bb45ff", "#882380",
 * "#fe00ec", "#0007c7", "#012c54", "#255b8f", "#204b75", "#4fa4ff"
 */

export const anomalyTypeColors = Object.freeze({
  "Axial Crack": "#f3bfc3",
  "Local Leaching Out": "#ff1f1f",
  "Local Leaching In": "#4fa4ff",
  Clamp: "#d5fb00",
  Leaching: "#de8385",
  "Radial Crack": "#63db07",
  Appd_TakeOff: "#bb45ff",
  Delamination: "#ad0205",
  Dirt: "#fa8925",
  Gas: "#882380",
  "Wire Break": "#6e4f00",
  "Casing Tube": "#012c54",
  H2S: "#0007c7",
  "Unknown Anomaly": "#00781c",
  "Missing Wall": "#fe00ec",
  "Increase MFW": "#add2f8",
  Other: "#204b75",
  "Air vent": "#fff000",
  "Missing FW": "#fd04fb",
  "Missing BW": "#497a00",
  Corrosion: "#b3ff87",
  Oval: "#aacc00",
  Grease: "#fc614f",
  Leakage: "#ffa8ec",
  "Damaged cement": "#255b8f",
  "Missing cement": "#ab63fa",
  "Reduced Wall Thickness": "#c9b500",
  Dent: "#cc0030",
});

export const qualityInfluenceColors = Object.freeze({
  dirt: "#fc614f",
  vuil: "#fc614f",
  gas: "#b3ff87",
  misalignment: "#0007c7",
  scheefstand: "#0007c7",
  unaffected: "#bb45ff",
  "niet beïnvloed": "#bb45ff",
});

export const jointTypeColors = Object.freeze({
  Joint: { num: 1, text: "Joint", color: "#ff1f1f" },
  Weld: { num: 2, text: "Weld", color: "#00781c" },
  "Joint-Tensile": { num: 3, text: "Joint-Tensile", color: "#ab63fa" },
  "Unknown Joint": { num: 4, text: "Unknown Joint", color: "#0007c7" },
  "Axial Crack": { num: 5, text: "Axial Crack", color: "#c9b500" },
});

export const materialColors = Object.freeze({
  // Plastics
  PVC: { num: 1, text: "PVC", color: "#882380" },
  HDPE: { num: 2, text: "HDPE", color: "#cc0030" },
  PE: { num: 3, text: "PE", color: "#ffa8ec" },
  HPPE: { num: 4, text: "HPPE", color: "#bb45ff" },
  // Metals
  "Ductile Iron": { num: 5, text: "Ductile Iron", color: "#882380" },
  Steel: { num: 6, text: "Steel", color: "#fc614f" },
  "Cast Iron": { num: 7, text: "Cast Iron", color: "#0007c7" },
  "Stainless Steel": { num: 8, text: "Stainless Steel", color: "#4fa4ff" },
  "Carbon Steel": { num: 9, text: "Carbon Steel", color: "#00781c" },
  "Mild Steel": { num: 10, text: "Mild Steel", color: "#63db07" },
  // Cement
  AC: { num: 11, text: "AC", color: "#b3ff87" },
  // Concrete
  Concrete: { num: 12, text: "Concrete", color: "#6e4f00" },
  "Reinforced Concrete": {
    num: 13,
    text: "Reinforced Concrete",
    color: "#add2f8",
  },
  "Plate reinforced Concrete": {
    num: 14,
    text: "Plate reinforced Concrete",
    color: "#c9b500",
  },
  // Composites
  GRE: { num: 15, text: "GRE", color: "#fa8925" },
  // Unknown
  Unknown: { num: 16, text: "Unknown", color: "#ff1f1f" },

  "Sidero Cement": { num: 17, text: "Sidero Cement", color: "#ad0205" },
});

export const defectColors = Object.freeze({
  h2s: "rgba(252, 97, 79, 0.5)",
  local_leaching: "rgba(171, 99, 250, 0.5)",
  deformation: "rgba(182, 232, 128, 0.5)",
  joints_angle: "rgba(245, 168, 34, 0.5)",
  joints_gap_width: "rgba(255, 102, 146, 0.5)",
});

// road_type: "p", "r" or "s" are the values and they mean "Primary road", "Road", "Street"
export const roadTypeColors = Object.freeze({
  p: { num: 1, text: "Primary road", color: "#ff1f1f" },
  r: { num: 2, text: "Road", color: "#00781c" },
  s: { num: 3, text: "Street", color: "#ab63fa" },
  other: { num: 4, text: "Other", color: "#0007c7" },
});

// eslint-disable-next-line no-irregular-whitespace
// soil_type: "Zand"​​, "Moerig op zand"​​, "Zware Zavel","Lichte Zavel", "Klei", "Lichte Klei", "Zware Klei", "Veen"
export const soilTypeColors = Object.freeze({
  Zand: { num: 1, text: "Zand", color: "#ff1f1f" },
  "Moerig op zand": { num: 2, text: "Moerig op zand", color: "#00781c" },
  "Zware Zavel": { num: 3, text: "Zware Zavel", color: "#0007c7" },
  "Lichte Zavel": { num: 4, text: "Lichte Zavel", color: "#c9b500" },
  Klei: { num: 5, text: "Klei", color: "#4fa4ff" },
  "Lichte Klei": { num: 6, text: " LichteKlei", color: "#bb45ff" },
  "Zware Klei": { num: 7, text: "Zware Klei", color: "#6e4f00" },
  Veen: { num: 8, text: "Veen", color: "#fc614f" },
});

export const getUncertaintiesWithColors = (t) =>
  Object.freeze({
    ground_level: {
      text: t("uncertainties.ground_level"),
      color: "#636efa",
    },
    soil_density_dry: {
      text: t("uncertainties.soil_density_dry"),
      color: "#ef553b",
    },
    soil_density_wet: {
      text: t("uncertainties.soil_density_wet"),
      color: "#00cc96",
    },
    ground_water_level: {
      text: t("uncertainties.ground_water_level"),
      color: "#ab63fa",
    },
    material_density: {
      text: t("uncertainties.material_density"),
      color: "#ffa15a",
    },
    tensile_strength: {
      text: t("uncertainties.tensile_strength"),
      color: "#19d3f3",
    },
    pressure: {
      text: t("uncertainties.pressure"),
      color: "#ff6692",
    },
    joint_angle_vertical: {
      text: t("uncertainties.joint_angle_vertical"),
      color: "#b6e880",
    },
    road_distance: {
      text: t("uncertainties.road_distance"),
      color: "#ff97ff",
    },
    road_wheel_load: {
      text: t("uncertainties.road_wheel_load"),
      color: "#fecb52",
    },
    wall_thickness: {
      text: t("uncertainties.wall_thickness"),
      color: "#636efa",
    },
    none: { text: t("uncertainties.none"), color: "grey" },
  });

export const getRemainingLifeColor = (t) => [
  { color: "black", text: t("color_legends.unknown") },
  { color: "green", text: `> 20 ${t("color_legends.years")}` },
  { color: "yellow", text: `10-20 ${t("color_legends.years")}` },
  { color: "orange", text: `5-10 ${t("color_legends.years")}` },
  { color: "red", text: `0-5 ${t("color_legends.years")}` },
  { color: "purple", text: `< 0 ${t("color_legends.years")}` },
];

export const getDegradationColorPP = (t) => [
  {
    color: "black",
    text: t("color_legends.unknown"),
    textForPlastic: t("color_legends.unknown"),
  },
  { color: "green", text: "0-5%", textForPlastic: "0-50%" },
  { color: "yellow", text: "5-10%", textForPlastic: "50-75%" },
  { color: "orange", text: "10-25%", textForPlastic: "75-90%" },
  { color: "red", text: "25-40%", textForPlastic: "90-100%" },
  { color: "purple", text: ">40%", textForPlastic: ">100%" },
];

export const safetyLevels = Object.freeze([
  { index: 0, text: "unknown", color: "black" },
  { index: 1, text: "0 - 1", color: "red" },
  { index: 2, text: "1 - 1.5", color: "orange" },
  { index: 3, text: "1.5 - 2", color: "yellow" },
  { index: 4, text: " > 2", color: "green" },
]);

export function getDegradationColorJoints() {
  return [
    { color: "black", text: i18n.global.t("color_legends.unknown") },
    { color: "green", text: "0-50%" },
    { color: "yellow", text: "51-75%" },
    { color: "orange", text: "76-99%" },
    { color: "red", text: ">100%" },
  ];
}

export const gradientColorArr = Object.freeze([
  "rgb(13, 8, 135)",
  "rgb(181, 18, 135)",
  "rgb(252, 230, 99)",
]);

export const gradientColorArrAngle = Object.freeze([
  "rgb(252, 230, 99)",
  "rgb(181, 18, 135)",
  "rgb(13, 8, 135)",
  "rgb(181, 18, 135)",
  "rgb(252, 230, 99)",
]);

export const gradientColorArrDegr = Object.freeze([
  "black",
  "green",
  "yellow",
  "orange",
  "red",
]);
