<template>
  <WidgetCard
    :sizeProps="{
      width,
      height,
      left,
      top,
    }"
    @minimize="onMinimize"
  >
    <template v-slot:title-text>
      <span class="title">{{ $t("wms_layers.layers") }}</span>
    </template>
    <div class="wms_legend_wrap" ref="legend_wrap">
      <div
        v-for="{ title, value, min_zoom, width, height, src } in activeLegends"
        :key="value"
      >
        <p class="name">
          {{ title }}
        </p>
        <div class="legend_item">
          <div v-if="min_zoom > currentZoom" class="inactive_message">
            <span>{{ this.$t("wms_layers.zoom") }}</span>
          </div>
          <img :style="{ width, height }" :src="src" :alt="`${value} legend`" />
        </div>
      </div>
    </div>
  </WidgetCard>
</template>

<script>
import { WidgetCard } from "@/components";
import { wmsOptions } from "./WMSWidget.config";
import { fetchLegend } from "../helpers";

export default {
  name: "WMSLegend",
  emits: ["maximize-self"],
  props: {
    wmsLayers: Array,
    offsetTop: { type: Number, default: 50 },
    onMinimize: Function,
    currentZoom: Number,
    isOpen: Boolean,
  },
  components: { WidgetCard },
  data() {
    return {
      width: 250,
      height: 365,
      left: 5,
      top: this.offsetTop,

      legends: [],
    };
  },
  mounted() {
    this.findLegends();
  },
  computed: {
    wmsTitledOptions() {
      return wmsOptions.map((option) => ({
        title: this.$t(`wms_layers.options.${option.value}`),
        ...option,
      }));
    },

    activeLegends() {
      return this.legends.filter(({ value }) => this.wmsLayers.includes(value));
    },
  },
  methods: {
    findLegends() {
      this.wmsLayers.forEach((layer) => {
        const alreadyFetched = this.legends.some(
          ({ value }) => value === layer
        );
        if (!alreadyFetched) {
          this.fetchLegendForLayer(layer);
        }
      });
    },

    async fetchLegendForLayer(layer) {
      const options = this.wmsTitledOptions.find(
        ({ value }) => value === layer
      );

      if (options) {
        const { min_zoom, title, value } = options;
        const props = await fetchLegend(options);
        this.legends.push({ value, min_zoom, title, ...props });
      }
    },

    //   async resizeController() {
    //     await this.$nextTick();
    //     const width = this.$refs.legend_wrap.offsetWidth;
    //     this.width = width ? width : 260;
    //     await this.$nextTick();
    //     const height = this.$refs.legend_wrap.offsetHeight + 48;
    //     this.height = height;
    //     this.top = this.offsetTop;
    //   },
    // },
    // watch: {
    //   wmsLayers(newStyles) {
    //     if (newStyles.length === 0) {
    //       this.$emit("maximize-self");
    //     }
    //     this.resizeController();
    //   },
    //   isOpen(open) {
    //     if (open) {
    //       this.resizeController();
    //     }
    //   },
  },
  watch: {
    wmsLayers() {
      this.findLegends();
    },
  },
};
</script>

<style lang="scss" scoped>
.wms_legend_wrap {
  width: fit-content;
  max-width: 260px;
  padding: 10px 10px 0px;

  user-select: none;

  .name {
    margin-bottom: 4px;
    font-weight: 600;
    color: darkslategrey;
  }
  .legend_item {
    position: relative;
    .inactive_message {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 10px;
      left: 0;
      display: flex;
      align-items: center;
      justify-content: center;

      background-color: rgba(255, 255, 255, 0.8);
      border-radius: 4px;

      span {
        background-color: white;
        box-shadow: 0 0 10px 2px white;
        padding: 2px 4px;
        font-weight: 600;
        font-size: 14px;
      }
    }
  }
}

.title {
  margin-right: 10px;
}
</style>
