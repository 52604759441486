<template>
  <div id="mapmod-container">
    <ModifiedMapbox
      ref="modifiedMapRef"
      :showLoader="isLoading"
      :wmsLayers="wmsLayers"
      :widgets="widgets"
      :onMaximizeWidget="maximizeWidget"
      @mb-moveend="onMoveend"
      @click="selectedWidget = null"
    >
      <template v-if="selectedPipePart">
        <MapboxMarker
          :lng-lat="selectedPipePart.geom.coordinates[0]"
          :scale="0.9"
          color="orange"
        >
        </MapboxMarker>
        <MapboxPopup :lng-lat="selectedPipePart.geom.coordinates[0]">
          <MapInfoPopup
            :data="selectedPipePart"
            type="pipepart"
            :activeValue="
              gradientOptions.find(
                (option) => option.value === selectedGradientMode
              )
            "
          />
        </MapboxPopup>
      </template>

      <template v-slot:sources="{ openDigupMenu }">
        <template
          v-for="{ id, sourceOptions, layerOptions } in pipePartsMapData"
          :key="id"
        >
          <MapboxSource :id="id" :options="sourceOptions" />
          <MapboxLayer
            :id="layerOptions.id"
            :options="layerOptions"
            @mb-mouseenter="onLayerMouseEnter"
            @mb-mouseleave="onLayerMouseLeave"
            @mb-click="onLayerClick"
            @mb-contextmenu="onLayerContextmenu(layerOptions.id, openDigupMenu)"
          />
        </template>
      </template>

      <div :class="{ hide: isLoading }">
        <WidgetCard
          :class="{ zIndex: selectedWidget === 'wmsWidget' }"
          :sizeProps="widgets.wmsWidget"
          :show="widgets.wmsWidget.isOpen"
          @drag="(e) => onDrag(e, 'wmsWidget')"
          @minimize="minimizeWidget('wmsWidget')"
          :isResizable="false"
          :sticks="[]"
        >
          <template v-slot:title-text>
            {{ $t(widgets.wmsWidget.title) }}
          </template>
          <WMSWidget
            v-model:wmsLayers="wmsLayers"
            :colorMode="selectedGradientMode"
            :colorOptions="gradientOptions"
            @select-color-mode="setSelectedGradientMode"
          />
        </WidgetCard>

        <TableWidgetCard
          :class="{ zIndex: selectedWidget === 'table' }"
          :sizeProps="widgets.table"
          :show="widgets.table.isOpen"
          @drag="(e) => onDrag(e, 'table')"
          @minimize="minimizeWidget('table'), (showFilter = false)"
        >
          <template v-slot:title-text>
            {{ $t(widgets.table.title) }}
          </template>
          <template v-slot:title-prepend>
            <FilterPopupMenu
              v-model:show="showFilter"
              :typeOptions="typeOptions"
              :materialOptions="materialOptions"
            />
          </template>
          <PipePartsTable />
        </TableWidgetCard>

        <WidgetCard
          :isActive="selectedWidget === 'histogram'"
          :class="{ zIndex: selectedWidget === 'histogram' }"
          :sizeProps="widgets.histogram"
          :show="widgets.histogram.isOpen"
          @drag="(e) => onDrag(e, 'histogram')"
          @minimize="minimizeWidget('histogram')"
        >
          <template v-slot:title-text>
            {{ histogramTitle }}
          </template>
          <PipePartsHistogram
            :title="histogramTitle"
            @graph-mousedown="selectedWidget = 'histogram'"
          />
        </WidgetCard>

        <WidgetCard
          v-if="widgets.heightProfile.isOpen"
          :isActive="selectedWidget === 'heightProfile'"
          :class="{ zIndex: selectedWidget === 'heightProfile' }"
          :sizeProps="widgets.heightProfile"
          @drag="(e) => onDrag(e, 'heightProfile')"
          @minimize="minimizeWidget('heightProfile')"
        >
          <template v-slot:title-text>
            {{ $t(widgets.heightProfile.title) }}
          </template>
          <template v-slot:title-prepend>
            <div class="anomalies-toggler" @click="toggleAnomaliesVisibility">
              <v-switch
                :model-value="showAnomalies"
                density="compact"
                hide-details
              ></v-switch>
              {{ $t("pipe_parts.widgets.heightProfile.show_anomalies") }}
            </div>
          </template>
          <PipePartsHeightChart
            :showAnomalies="showAnomalies"
            :visibleParts="visibleParts"
            @graph-mousedown="selectedWidget = 'heightProfile'"
          />
        </WidgetCard>

        <WidgetCard
          v-if="widgets.radar.isOpen"
          :isActive="selectedWidget === 'radar'"
          :class="{ zIndex: selectedWidget === 'radar' }"
          :sizeProps="widgets.radar"
          @drag="(e) => onDrag(e, 'radar')"
          @minimize="minimizeWidget('radar')"
        >
          <template v-slot:title-text>
            {{ radarName }}
          </template>
          <PipePartsRadar
            :title="radarTitle"
            @graph-mousedown="selectedWidget = 'histogram'"
          />
        </WidgetCard>

        <WidgetCard
          v-if="showGradient"
          :class="{ zIndex: selectedWidget === 'gradientLegend' }"
          :sizeProps="widgets.gradientLegend"
          heightFitContent
          disableMinimize
          :isResizable="false"
          :isDraggable="false"
          :sticks="[]"
          @click="selectedWidget = 'gradientLegend'"
        >
          <template v-slot:title-text>
            <div class="pr-2">{{ $t(widgets.gradientLegend.title) }}</div>
          </template>
          <template v-slot:title-prepend>
            <v-btn
              variant="text"
              density="compact"
              icon="mdi-information-variant-circle-outline"
              @click="openInfoDialog()"
              @mousedown.stop
            ></v-btn>
          </template>
          <PipePartsGradient :gradientColor="gradientColor" />
        </WidgetCard>

        <WidgetCard
          v-if="!showGradient"
          :class="{ zIndex: selectedWidget === 'colorsLegend' }"
          :sizeProps="widgets.colorsLegend"
          heightFitContent
          disableMinimize
          :isResizable="false"
          :isDraggable="false"
          :sticks="[]"
          @click="selectedWidget = 'colorsLegend'"
        >
          <template v-slot:title-text>
            <div class="pr-2">{{ histogramTitle }}</div>
          </template>
          <template
            v-if="selectedGradientMode === 'degradation_level'"
            v-slot:title-prepend
          >
            <v-btn
              variant="text"
              density="compact"
              icon="mdi-cog"
              @click.stop="$refs.refThresholdsModal.showModal"
              @mousedown.stop
            ></v-btn>
            <v-btn
              variant="text"
              density="compact"
              icon="mdi-information-variant-circle-outline"
              @click="openInfoDialog()"
              @mousedown.stop
            ></v-btn>
          </template>
          <PipePartsLegend :title="histogramTitle" />
        </WidgetCard>

        <WMSLegend
          :class="{ zIndex: selectedWidget === 'wmsLegend' }"
          v-show="wmsLayers.length && widgets.wmsLegend.isOpen"
          :isOpen="widgets.wmsLegend.isOpen"
          :offsetTop="240"
          :wmsLayers="wmsLayers"
          :currentZoom="currentZoom"
          @click="selectedWidget = 'wmsLegend'"
          @maximize-self="widgets.wmsLegend.isOpen = true"
          :onMinimize="() => minimizeWidget('wmsLegend')"
        />
      </div>
    </ModifiedMapbox>
    <ThresholdsModal ref="refThresholdsModal" />
    <LegendInfoModal
      ref="refLegendInfoModal"
      page="pipeParts"
      :selectMode="selectedGradientMode"
    />
  </div>
</template>

<script>
import { computed } from "vue";
import { mapState, mapGetters, mapMutations, mapActions } from "vuex";

import {
  MapboxMarker,
  MapboxPopup,
  MapboxLayer,
  MapboxSource,
} from "@studiometa/vue-mapbox-gl";
import {
  WidgetCard,
  MapInfoPopup,
  ModifiedMapbox,
  TableWidgetCard,
} from "@/components";
import { WMSLegend, WMSWidget } from "@/features/wms/components";
import { FilterPopupMenu, PipePartsLegend } from "./components";
import {
  PipePartsTable,
  PipePartsHistogram,
  PipePartsHeightChart,
  PipePartsRadar,
  PipePartsGradient,
} from "@/pages/pipe-parts/components";
import { ThresholdsModal, LegendInfoModal } from "@/components/modals";
import { initializeResizeObserver } from "@/utils/resizeObserverUtil";

export default {
  name: "PipePartsMapmode",

  components: {
    MapboxMarker,
    MapboxPopup,
    MapboxLayer,
    MapboxSource,
    MapInfoPopup,
    PipePartsLegend,
    PipePartsGradient,
    FilterPopupMenu,
    PipePartsTable,
    WidgetCard,
    ThresholdsModal,
    LegendInfoModal,
    PipePartsHistogram,
    PipePartsHeightChart,
    PipePartsRadar,
    WMSLegend,
    WMSWidget,
    ModifiedMapbox,
    TableWidgetCard,
  },

  props: {
    pipePartsMapData: Array,

    typeOptions: Array,
    materialOptions: Array,

    onChangeWTRemainingLayers: Function,

    gradientOptions: Array,
    gradientColor: Array,

    histogramTitle: String,
    radarTitle: String,
  },

  provide() {
    return {
      parentW: computed(() => this.parentW),
      parentH: computed(() => this.parentH),
    };
  },

  data() {
    const histogramTitle =
      this.gradientOptions.find(
        (opt) => opt.value === this.selectedGradientMode
      )?.title || "";

    return {
      visibleParts: [],
      parentH: null,
      parentW: null,

      widgets: {
        table: {
          title: "pipe_parts.widgets.table.maximize_title",
          width: 782,
          height: 355,
          left: 500,
          top: 150,
          isOpen: true,
        },
        histogram: {
          title: histogramTitle,
          width: 655,
          height: 400,
          left: 5,
          top: 150,
          isOpen: true,
        },
        heightProfile: {
          title: "pipe_parts.widgets.heightProfile.maximize_title",
          width: 655,
          height: 400,
          left: 5,
          top: 150,
          isOpen: false,
        },
        radar: {
          title: "pipe_parts.widgets.radar.maximize_title",
          width: 400,
          height: 400,
          left: 600,
          top: 150,
          isOpen: false,
        },
        gradientLegend: {
          title: "joints.widgets.gradient.maximize_title",
          height: "auto",
          width: 130,
          left: 5,
          top: 50,
        },
        colorsLegend: {
          title: histogramTitle,
          height: "auto",
          width: 230,
          left: 5,
          top: 50,
        },
        wmsWidget: {
          title: "pipe_parts.widgets.color.maximize_title",
          width: 350,
          height: 150,
          left: 400,
          top: 3,
          isOpen: true,
        },
        wmsLegend: {
          title: "wms_layers.layers",
          isOpen: true,
        },
      },
      selectedWidget: null,
      resizeObserver: null,
      currentZoom: null,
      wmsLayers: [],
      showFilter: false,
    };
  },

  mounted() {
    this.resizeObserver = initializeResizeObserver.call(this, [
      "table",
      "heightProfile",
      "histogram",
      "radar",
      "wmsWidget",
    ]);
    this.resizeObserver.observe(this.$el);

    this.widgets.table.left =
      this.$el.offsetWidth - this.widgets.table.width - 10;

    this.widgets.wmsWidget.left =
      (this.$el.offsetWidth - this.widgets.wmsWidget.width) / 2;

    this.widgets.histogram.top =
      this.$el.offsetHeight - this.widgets.histogram.height - 35;

    this.widgets.heightProfile.top =
      this.$el.offsetHeight - this.widgets.heightProfile.height - 180;

    this.widgets.radar.top =
      this.$el.offsetHeight - this.widgets.radar.height - 45;
    this.widgets.radar.left =
      this.$el.offsetWidth - this.widgets.radar.width - 5;
  },

  beforeUnmount() {
    this.resizeObserver?.disconnect();
  },

  computed: {
    ...mapState("pipeParts", [
      "isLoading",
      "partsSelector",
      "selectedGradientMode",
      "showAnomalies",
    ]),

    ...mapGetters("pipeParts", [
      "convertedPipeParts",
      "filteredPipeParts",
      "selectedPipePart",
      "minMaxDistance",
    ]),

    showGradient() {
      return ![
        "quality_anomaly",
        "degradation_level",
        "remaining_life_calculation.remaining_life_group",
        "material",
        "remaining_life_calculation.road_type",
        "remaining_life_calculation.soil_type",
      ].includes(this.selectedGradientMode);
    },

    radarName() {
      if (
        this.selectedGradientMode?.includes("remaining_") ||
        this.selectedGradientMode?.includes("degradated_total_")
      ) {
        return this.gradientOptions.find(
          (opt) => opt.value === this.selectedGradientMode
        ).title;
      }
      return this.gradientOptions.find((opt) => opt.value === "remaining_mean")
        .title;
    },

    map() {
      return this.$refs.modifiedMapRef.map;
    },
  },

  methods: {
    ...mapMutations("pipeParts", [
      "setSelectedPipePartId",
      "setSelectedGradientMode",
    ]),

    ...mapActions("pipeParts", ["toggleAnomaliesVisibility"]),

    openInfoDialog() {
      this.$refs.refLegendInfoModal.open();
    },

    onDrag({ left, top }, key) {
      this.selectedWidget = key;
      this.widgets[key].left = left;
      this.widgets[key].top = top;
    },

    minimizeWidget(key) {
      this.widgets[key].isOpen = false;
    },

    maximizeWidget(key) {
      this.selectedWidget = key;
      this.widgets[key].isOpen = true;
    },

    onMoveend({ target: map }) {
      this.currentZoom = map.getZoom();
      const layerId = this.pipePartsMapData.find(({ layerOptions }) =>
        layerOptions.id.includes("layer_pipe_parts")
      )?.layerOptions.id;

      if (map.getLayer(layerId)) {
        const visiblePartsIds = map
          .queryRenderedFeatures({ layers: [layerId] })
          .map((pp) => pp.properties.id);
        this.visibleParts = this.convertedPipeParts.filter((pp) =>
          visiblePartsIds.includes(pp.id)
        );
      }
    },

    onLayerContextmenu(layeerId, callback) {
      if (layeerId.includes("selected_pipe_parts")) {
        callback();
      }
    },

    onLayerClick({ features, originalEvent }) {
      const { ctrlKey, shiftKey } = originalEvent;
      if (features[0].source.includes("pipe_parts")) {
        const selectedId = features[0].properties.id;
        if (ctrlKey) {
          this.partsSelector.setByCtrl(selectedId);
        } else if (shiftKey) {
          this.partsSelector.setByShift(selectedId, this.filteredPipeParts);
        } else {
          this.setSelectedPipePartId(null);
          Promise.resolve().then(() => this.setSelectedPipePartId(selectedId));
        }
      }
    },

    onLayerMouseEnter({ features, target }) {
      if (features[0].source.includes("pipe_parts")) {
        target.getCanvas().style.cursor = "pointer";
        this.map.boxZoom.disable();
      }
    },

    onLayerMouseLeave({ target }) {
      target.getCanvas().style.cursor = "";
      this.map.boxZoom.enable();
    },
  },

  watch: {
    selectedPipePart(part) {
      if (part) {
        this.map.flyTo({
          center: part.geom.coordinates[0],
          zoom: this.currentZoom < 17 ? 17 : this.currentZoom,
        });
      }
    },

    selectedGradientMode() {
      const histogramTitle =
        this.gradientOptions.find(
          (opt) => opt.value === this.selectedGradientMode
        )?.title || "";
      this.widgets.histogram.title = histogramTitle;
      this.widgets.colorsLegend.title = histogramTitle;
    },
  },
};
</script>

<style lang="scss" scoped>
#mapmod-container {
  padding: 0;
  margin: 0;
  width: 100%;
  height: 100%;
}

.hide {
  opacity: 0;
}

.anomalies-toggler {
  height: 20px;
  display: flex;
  align-items: center;
  font-size: 1rem;
  cursor: pointer;
  .v-switch {
    margin-right: 1rem;
  }
}

.zIndex {
  z-index: 5 !important;
}

.inactive {
  opacity: 0.8;
}
</style>
