<template>
  <v-card :elevation="4">
    <h4>BScan Data</h4>
    <div v-if="progressBcans.isLoading" class="progress-loader">
      {{ `${progressBcans.percent}%` }}
    </div>
    <template v-else>
      <v-select
        v-model="selectedFileName"
        :items="allFilesOptions"
        label="File"
        variant="underlined"
      ></v-select>
      <PlotlyGraph v-if="bScans" :data="plotlyData" :layout="plotlyLayout" />
    </template>
  </v-card>
</template>

<script>
import { PlotlyGraph } from "@/components";

export default {
  name: "BScanChart",
  components: {
    PlotlyGraph,
  },
  props: {
    bScans: Object,
    progressBcans: Object,
    allFilesOptions: Array,
  },
  data() {
    return {
      selectedFileName: null,
    };
  },
  mounted() {
    if (this.allFilesOptions.length > 0) {
      this.selectedFileName = this.allFilesOptions[0];
    }
  },

  computed: {
    plotlyData() {
      if (!this.bScans || !this.selectedFileName) {
        return [];
      }
      return [
        {
          z: this.bScans[this.selectedFileName],
          type: "heatmap",
          colorscale: "Jet",
          name: "B-SCAN",
        },
      ];
    },

    plotlyLayout() {
      return {
        height: 450,
        yaxis: {
          autorange: "reversed",
          range: [50, 250],
        },
        showlegend: true,
        margin: {
          t: 10,
          b: 70,
          l: 60,
          r: 30,
        },
      };
    },
  },

  watch: {
    allFilesOptions(options) {
      this.selectedFileName = options[0];
    },
  },
};
</script>

<style lang="scss" scoped>
.v-card {
  padding: 1rem 1.5rem;

  .progress-loader {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    font-size: 1.5rem;
    font-weight: bold;
    opacity: 0.7;
  }
}
</style>
