import { mergeChanges } from "@/utils/objects";

export const safetyFactorMutations = {
  setLoading(state, isLoading) {
    state.isLoading = isLoading;
  },

  setPipeParts(state, pipeParts) {
    state.pipeParts = pipeParts;
  },

  setFilters(state, filters) {
    mergeChanges(state.filters, filters);
  },

  setSelectedPipePartId(state, pipePartId) {
    console.log("NEW PIPE PART ID", pipePartId);

    state.selectedPipePartId = pipePartId;
  },

  setAnomalies(state, anomalies) {
    state.anomalies = anomalies;
  },

  setSelectedViewFactor(state, viewFactor) {
    state.selectedViewFactor = viewFactor;
  },

  setSelectedLegend(state, selectedLegend) {
    state.selectedLegend = selectedLegend;
  },
};
