<template>
  <v-card class="files-card" :elevation="4">
    <div class="title">
      <h4>{{ $t("analyser.inspection_files.title") }}</h4>
      <v-btn
        rounded="lg"
        color="deep-orange"
        @click="onSave"
        :disabled="isNoChecked"
      >
        {{ $t("analyser.inspection_files.save") }}
      </v-btn>
    </div>
    <div class="container-table">
      <div v-if="isLoading" class="loader">
        <v-progress-circular
          color="dark-blue"
          indeterminate
          :size="52"
          :width="5"
        ></v-progress-circular>
      </div>
      <table v-else class="table">
        <thead>
          <tr>
            <th>{{ $t("analyser.inspection_files.name") }}</th>
            <th>{{ $t("analyser.inspection_files.position") }}</th>
            <th style="text-align: left">
              {{ $t("analyser.inspection_files.use") }}?
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, index) in files" :key="item.name">
            <td>{{ item.name }}</td>
            <td>{{ item.position }}</td>
            <td>
              <v-checkbox
                :model-value="item.use"
                @update:modelValue="onCheck(index)"
                :label="$t('analyser.inspection_files.checkbox')"
                color="info"
                density="compact"
                hide-details
              />
            </td>
          </tr>
        </tbody>
      </table></div
  ></v-card>
</template>

<script>
export default {
  name: "FileSelectTable",
  props: {
    files: Array,
    onCheck: Function,
    onSave: Function,
    isLoading: Boolean,
  },
  computed: {
    isNoChecked() {
      return this.files.every(({ use }) => !use);
    },
  },
};
</script>

<style lang="scss" scoped>
.files-card {
  width: 400px;
  height: 600px;
  .title {
    padding: 0.5rem 1rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .container-table {
    height: calc(100% - 50px) !important;
  }
  .loader {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
</style>
