<template>
  <div id="mapmod-container">
    <ModifiedMapbox
      ref="modifiedMapRef"
      :zoom="7"
      :showLoader="isLoadingPipelines || isLoadingStats"
      :wmsLayers="wmsLayers"
      :widgets="widgets"
      :onMaximizeWidget="maximizeWidget"
      hideBackToPipeButton
      @mb-load="onLoad"
      @mb-moveend="onMoveend"
      @click="this.selectedWidget = null"
    >
      <MapboxMarker
        v-if="mapMarker"
        :lng-lat="mapMarker"
        :scale="0.9"
        color="orange"
      />
      <template v-slot:sources>
        <MapboxSource
          v-for="source in pipelinesMapData.sources"
          :key="source.id"
          :id="source.id"
          :options="source.options"
        />
        <MapboxLayer
          v-for="layer in pipelinesMapData.layers"
          :key="layer.id"
          :id="layer.id"
          :options="layer"
          @mb-mouseenter="onLayerMouseEnter"
          @mb-mouseleave="onLayerMouseLeave"
          @mb-click="onLayerClick"
        />
        <MapboxSource
          v-for="source in statsMapData.sources"
          :key="source.id"
          :id="source.id"
          :options="source.options"
        />
        <MapboxLayer
          v-for="layer in statsMapData.layers"
          :key="layer.id"
          :id="layer.id"
          :options="layer"
        />
      </template>
      <MapboxPopup
        v-if="!!popupPipename"
        :lng-lat="popupPipename.lngLat"
        :closeButton="false"
      >
        <div class="popup-content">{{ popupPipename.name }}</div>
      </MapboxPopup>
      <template v-if="selectedPipeline && !isLoadingStats">
        <WidgetCard
          :class="{ zIndex: selectedWidget === 'stats' }"
          :sizeProps="widgets.stats"
          :show="widgets.stats.isOpen"
          @drag="(e) => onDrag(e, 'stats')"
          @minimize="minimizeWidget('stats')"
        >
          <template v-slot:title-text>
            {{ $t(widgets.stats.title) }}
          </template>
          <template v-slot:title-prepend>
            <v-btn
              variant="outlined"
              color="red"
              size="x-small"
              @click="onClosePipeline"
            >
              {{ $t("dashboard.pipelines.close") }}
            </v-btn>
            <v-btn
              :style="{ margin: '0 4px 0 10px' }"
              size="x-small"
              @click="flyToPipe"
              >{{ $t("move_to_pipe") }}</v-btn
            >
            <v-btn
              variant="text"
              density="compact"
              icon="mdi-cog"
              @click.stop="$refs.refThresholdsModal.showModal"
              @mousedown.stop
            ></v-btn>
          </template>
          <PipeStatistics />
        </WidgetCard>
        <WidgetCard
          :isActive="selectedWidget === 'heightProfile'"
          :class="{ zIndex: selectedWidget === 'heightProfile' }"
          :sizeProps="widgets.heightProfile"
          :show="widgets.heightProfile.isOpen"
          @drag="(e) => onDrag(e, 'heightProfile')"
          @minimize="minimizeWidget('heightProfile')"
        >
          <template v-slot:title-text>
            {{ $t(widgets.heightProfile.title) }}
          </template>
          <HeightChart
            @changePoint="setMapMarker"
            @graph-mousedown="selectedWidget = 'heightProfile'"
          />
        </WidgetCard>
      </template>
      <WidgetCard
        v-if="!isLoadingPipelines"
        :class="{ zIndex: selectedWidget === 'wmsWidget' }"
        :sizeProps="widgets.wmsWidget"
        :show="widgets.wmsWidget.isOpen"
        @drag="(e) => onDrag(e, 'wmsWidget')"
        @minimize="minimizeWidget('wmsWidget')"
        :isResizable="false"
        :sticks="[]"
      >
        <template v-slot:title-text>
          {{ $t(widgets.wmsWidget.title) }}
        </template>
        <WMSWidget v-model:wmsLayers="wmsLayers" />
      </WidgetCard>
      <WMSLegend
        :class="{ zIndex: selectedWidget === 'wmsLegend' }"
        v-show="wmsLayers.length && widgets.wmsLegend.isOpen"
        :isOpen="widgets.wmsLegend.isOpen"
        :wmsLayers="wmsLayers"
        :currentZoom="currentZoom"
        @click="selectedWidget = 'wmsLegend'"
        @maximize-self="widgets.wmsLegend.isOpen = true"
        :onMinimize="() => minimizeWidget('wmsLegend')"
      />
    </ModifiedMapbox>
    <ThresholdsModal ref="refThresholdsModal" @save="getInspectionStats" />
  </div>
</template>

<script>
import { computed } from "vue";
import { mapState, mapGetters, mapActions } from "vuex";
import {
  MapboxMarker,
  MapboxPopup,
  MapboxLayer,
  MapboxSource,
} from "@studiometa/vue-mapbox-gl";

import { WidgetCard, ModifiedMapbox } from "@/components";
import { WMSLegend, WMSWidget } from "@/features/wms/components";
import { ThresholdsModal } from "@/components/modals";
import { PipeStatistics, HeightChart } from "@/pages/dashboard/components";

import { fitMapToCoordinates } from "@/utils/mapHelpers.js";
import { initializeResizeObserver } from "@/utils/resizeObserverUtil.js";

import { DEFAULT_CENTER } from "@/constants/mapBox";

const geomColors = {
  h2s_geom: "#ff0303",
  local_leaching_geom: "#7c05ff",
  deformation_geom: "#fce935",
  anomaly_geom: "#3202d1",
  critical_joints_geom: "#f702e7",
  critical_joints_geom_gap: "#ff8400",
};

export default {
  name: "PipelinesMapmode",
  components: {
    WidgetCard,
    ModifiedMapbox,
    MapboxLayer,
    MapboxSource,
    MapboxMarker,
    MapboxPopup,
    PipeStatistics,
    HeightChart,
    ThresholdsModal,
    WMSLegend,
    WMSWidget,
  },
  props: {
    pipelines: Array,
    isLoadingStats: Boolean,
    isLoadingPipelines: Boolean,
    onSelectPipelineId: Function,
    getInspectionStats: Function,
  },
  provide() {
    return {
      parentW: computed(() => this.parentW),
      parentH: computed(() => this.parentH),
      statsSwitched: this.statsSwitched,
      toggleSwitches: this.toggleStatsLayer,
    };
  },

  data() {
    return {
      mapMarker: null,
      statsSwitched: [],

      popupPipename: null,

      parentH: null,
      parentW: null,
      widgets: {
        stats: {
          title: "dashboard.widgets.dashboardStats.maximize_title",
          width: 500,
          height: 670,
          left: 500,
          top: 15,
          isOpen: true,
        },
        heightProfile: {
          title: "dashboard.widgets.heightProfile.maximize_title",
          width: 700,
          height: 400,
          left: 15,
          top: 150,
          isOpen: undefined,
        },
        wmsWidget: {
          title: "dashboard.widgets.color.maximize_title",
          width: 350,
          height: 100,
          left: 400,
          top: 3,
          isOpen: true,
        },
        wmsLegend: {
          title: "wms_layers.layers",
          isOpen: true,
        },
      },
      selectedWidget: null,
      resizeObserver: null,
      currentZoom: null,
      wmsLayers: [],
    };
  },

  mounted() {
    this.resizeObserver = initializeResizeObserver.call(this, [
      "stats",
      "heightProfile",
      "wmsWidget",
    ]);
    this.resizeObserver.observe(this.$el);

    this.widgets.stats.left =
      this.$el.offsetWidth - this.widgets.stats.width - 5;
    this.widgets.stats.top =
      this.$el.offsetHeight - this.widgets.stats.height - 30;

    this.widgets.heightProfile.top =
      this.$el.offsetHeight - this.widgets.heightProfile.height - 15;

    this.widgets.wmsWidget.left =
      (this.$el.offsetWidth - this.widgets.wmsWidget.width) / 2;
  },

  beforeUnmount() {
    this.resizeObserver?.disconnect();
  },

  computed: {
    ...mapState(["selectedInspectionStats"]),
    ...mapGetters(["selectedPipeline"]),

    map() {
      return this.$refs.modifiedMapRef.map;
    },

    pipelinesMapData() {
      const sources = [];
      const layers = [];

      if (this.pipelines?.length) {
        this.pipelines.forEach((pipe) => {
          sources.push({
            id: pipe.id,
            type: "geojson",
            options: {
              type: "geojson",
              data: {
                type: "Feature",
                properties: {
                  name: pipe.pipeline_name,
                },
                geometry: {
                  type: pipe.geom.type,
                  coordinates: pipe.geom.coordinates,
                },
              },
            },
          });

          sources.push({
            id: `points_${pipe.id}`,
            type: "geojson",
            options: {
              type: "geojson",
              data: {
                type: "FeatureCollection",
                features: ["start", "end"].map((t) => ({
                  type: "Feature",
                  properties: {
                    type: t,
                    name: pipe.pipeline_name,
                  },
                  geometry: {
                    type: "Point",
                    coordinates:
                      t === "start"
                        ? pipe.geom.coordinates[0]
                        : pipe.geom.coordinates[
                            pipe.geom.coordinates.length - 1
                          ],
                  },
                })),
              },
            },
          });

          layers.push({
            id: `layer_${pipe.id}`,
            type: "line",
            source: pipe.id,
            layout: {
              visibility: "visible",
            },
            paint: {
              "line-color":
                pipe.id === this.selectedPipeline?.id ? "#0a9e87" : "#24e3c6",
              "line-width": 5,
              "line-opacity": 1,
            },
          });

          layers.push({
            id: `layer_points_${pipe.id}`,
            type: "circle",
            source: `points_${pipe.id}`,
            layout: {
              visibility: "visible",
            },
            paint: {
              "circle-color": "#FCFC7A",
              "circle-radius": [
                "interpolate",
                ["linear"],
                ["zoom"],
                7,
                3,
                22,
                6,
              ],
              "circle-opacity": 0.7,
            },
          });
        });
      }

      return { sources, layers };
    },

    statsMapData() {
      const layers = [];
      const sources = [];

      this.statsSwitched.forEach((geomName) => {
        sources.push({
          id: geomName,
          type: "geojson",
          options: {
            type: "geojson",
            data: {
              type: "FeatureCollection",
              features: this.selectedInspectionStats[geomName]?.map((x) => {
                return {
                  type: "Feature",
                  properties: {},
                  geometry: x,
                };
              }),
            },
          },
        });

        layers.push({
          id: `layer_${geomName}`,
          type: "circle",
          source: geomName,
          layout: {
            visibility: "visible",
          },
          paint: {
            "circle-radius": {
              base: 1.75,
              stops: [
                [12, 2],
                [22, 180],
              ],
            },
            "circle-color": geomColors[geomName] || "#AB63FA",
            "circle-opacity": 0.7,
          },
        });
      });

      return { sources, layers };
    },

    allPipelinesCoordianates() {
      if (!this.pipelines.length) {
        return [DEFAULT_CENTER];
      }

      const allCoordinates = this.pipelines
        .map((pipe) => pipe.geom.coordinates)
        .flat();

      return allCoordinates;
    },
  },

  methods: {
    ...mapActions(["CLEAR_SELECTED_PIPE_DATA"]),

    onClosePipeline() {
      this.setMapMarker(null);
      this.flyToPipelines();
      this.CLEAR_SELECTED_PIPE_DATA();
    },

    setMapMarker(lngLat) {
      this.mapMarker = lngLat;
    },

    onLoad() {
      if (this.selectedPipeline) {
        const coordinates = this.selectedPipeline.geom.coordinates;
        const middleIndex = Math.floor(coordinates.length / 2);
        this.setMapMarker(coordinates[middleIndex]);
        this.flyToPipe();
      } else if (this.pipelines.length) {
        this.flyToPipelines();
      }
    },

    onMoveend({ target: map }) {
      this.currentZoom = map.getZoom();
    },

    onLayerClick(target) {
      const clickedPipelineId = target.features[0].source.replace(
        "points_",
        ""
      );

      this.onSelectPipelineId(clickedPipelineId);
    },

    onLayerMouseEnter(target) {
      this.popupPipename = {
        lngLat: target.lngLat,
        name: target.features[0].properties.name,
      };
      target.target.getCanvas().style.cursor = "pointer";
    },

    onLayerMouseLeave(target) {
      this.popupPipename = null;
      target.target.getCanvas().style.cursor = "";
    },

    toggleStatsLayer(geomName) {
      if (this.statsSwitched.includes(geomName)) {
        this.statsSwitched = this.statsSwitched.filter((id) => id !== geomName);
      } else {
        this.statsSwitched.push(geomName);
      }
    },

    minimizeWidget(key) {
      this.widgets[key].isOpen = false;
    },

    maximizeWidget(key) {
      this.selectedWidget = key;
      this.widgets[key].isOpen = true;
    },

    onDrag({ left, top }, key) {
      this.selectedWidget = key;
      this.widgets[key].left = left;
      this.widgets[key].top = top;
    },

    highliteSelectedLayer(newId, prevId) {
      if (newId) {
        this.map.setPaintProperty(`layer_${newId}`, "line-color", "#0a9e87");
      }
      if (prevId) {
        this.map.setPaintProperty(`layer_${prevId}`, "line-color", "#24e3c6");
      }
    },

    flyToPipe() {
      const coordinates = this.selectedPipeline.geom.coordinates;
      const middleIndex = Math.floor(coordinates.length / 2);
      this.setMapMarker(coordinates[middleIndex]);

      fitMapToCoordinates(this.map, coordinates);
    },

    flyToPipelines() {
      fitMapToCoordinates(this.map, this.allPipelinesCoordianates);
    },
  },

  watch: {
    pipelines(newPipelines) {
      this.setMapMarker(null);
      if (newPipelines.length) {
        this.flyToPipelines();
      }
    },

    selectedPipeline(newPipeline, prevPipeline) {
      this.statsSwitched = [];
      this.highliteSelectedLayer(newPipeline?.id, prevPipeline?.id);
      if (newPipeline) {
        this.flyToPipe();
        this.widgets.heightProfile.isOpen = false;
      } else {
        this.widgets.heightProfile.isOpen = undefined;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
#mapmod-container {
  padding: 0;
  margin: 0;
  width: 100%;
  height: 100%;
}

.popup-content {
  padding: 10px;
  font-weight: 600;
}

.zIndex {
  z-index: 5 !important;
}

.inactive {
  opacity: 0.8;
}
</style>
