export const ScannerType = {
  Results: "results",
  InfoPending: "info_pending",
  AnalysisPending: "analysis_pending",
};

export const SCANNER_TAB_ICONS = {
  [ScannerType.Results]: "mdi-progress-check",
  [ScannerType.InfoPending]: "mdi-progress-clock",
  [ScannerType.AnalysisPending]: "mdi-progress-alert",
};
