import { DigUpSelector } from "@/features/dig-up/helpers";

export const INITIAL_ANOMALY_FILTERS = {
  selectedAnomaliesTypes: [],
  selectedDistance: [],
  selectedMaterials: [],
  selectedLength: [],
};

export const anomaliesState = {
  anomalies: [],
  selectedAnomalyId: null,

  anomalyFilters: INITIAL_ANOMALY_FILTERS,

  isLoadingAnomalies: true,
  isAwaitingDigup: false,

  checkedAnomaliesSelector: new DigUpSelector(),
};
