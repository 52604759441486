export const MODES_WITHOUT_ADDITIONAL_COLUMNS = [
  "material",
  "wall_thickness_original",
  "diameter_class",
];

export const MODES_WITH_LAYER_SELECT = [
  "remaining_mean",
  "remaining_min",
  "remaining_max",
  "remaining_std",
];
