import { scannerControlActions } from "@/store/scannerControl/actions";
import { scannerControlGetters } from "@/store/scannerControl/getters";
import { scannerControlMutations } from "@/store/scannerControl/mutations";
import { scannerControlState } from "@/store/scannerControl/state";

export const scannerControlModule = {
  namespaced: true,
  state: scannerControlState,
  mutations: scannerControlMutations,
  getters: scannerControlGetters,
  actions: scannerControlActions,
};
