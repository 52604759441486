<template>
  <div
    v-if="modifiedAnomalies.length > 0"
    class="container-table"
    :class="{ 'max-height-70': !isMapMode }"
  >
    <AnomaliesTableMapmode
      v-if="isMapMode"
      :modified-anomalies="modifiedAnomalies"
      :is-anomaly-active="isAnomalyActive"
      @click:row="handleAnomalySelect"
      @toggle:checkbox="handleRowCheckboxSelect"
    />
    <AnomaliesTableRegular
      v-else
      :modified-anomalies="modifiedAnomalies"
      :is-anomaly-active="isAnomalyActive"
      @click:row="handleAnomalySelect"
      @toggle:checkbox="handleRowCheckboxSelect"
    />
    <DigUpTableBtn
      v-if="checkedLength > 0"
      :badgeCount="checkedLength"
      :isMapMode="isMapMode"
    />
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from "vuex";

import AnomaliesTableMapmode from "./AnomaliesTableMapmode.vue";
import AnomaliesTableRegular from "./AnomaliesTableRegular.vue";
import { DigUpTableBtn } from "@/features/dig-up/components";

export default {
  components: { DigUpTableBtn, AnomaliesTableMapmode, AnomaliesTableRegular },

  computed: {
    ...mapState(["isMapMode"]),
    ...mapState("anomalies", ["selectedAnomalyId", "checkedAnomaliesSelector"]),
    ...mapGetters("anomalies", {
      anomalies: "filteredAnomalies",
    }),

    modifiedAnomalies() {
      return this.anomalies.map((anomaly) => {
        const pipe_book_id = anomaly.pipe_parts[0].pipe_book_id;
        const [longitude, latitude] = anomaly.centerGeom;
        return {
          ...anomaly,
          pipe_book_id,
          longitude,
          latitude,
        };
      });
    },

    checkedLength() {
      return this.checkedAnomaliesSelector.size;
    },
  },

  methods: {
    ...mapActions("anomalies", ["toggleSelectedAnomaly"]),

    handleAnomalySelect(id) {
      this.toggleSelectedAnomaly(id);
    },

    handleRowCheckboxSelect(id) {
      if (this.checkedAnomaliesSelector.has(id)) {
        this.checkedAnomaliesSelector.clear();
      } else {
        this.checkedAnomaliesSelector.set([id]);
      }
    },

    isAnomalyActive(anomaly) {
      return anomaly.id === this.selectedAnomalyId;
    },
  },
};
</script>
