<template>
  <th
    :colspan="colspan"
    :rowspan="rowspan"
    :class="classStyle"
    @click="onClick"
  >
    <div :class="{ sticky, active: isSortedColumn }">
      {{ computedTitle }}
      <v-icon
        v-if="!isGroupHeader && sortFunction"
        icon="mdi-arrow-up"
        :class="iconClass"
      />
    </div>
    <slot></slot>
  </th>
</template>

<script>
export default {
  name: "THead",

  props: {
    title: String,
    sortingKeys: String || undefined,
    unit: String,
    rowspan: Number || undefined,
    colspan: Number || undefined,
    isGroupHeader: Boolean,
    classStyle: {},
    sticky: Boolean,
  },

  inject: {
    sortedBy: {
      default: {
        keyName: null,
        order: "asc",
      },
    },
    sortFunction: {},
    localePath: {},
  },

  computed: {
    computedTitle() {
      let fullText = this.title;

      if (this.localePath) {
        fullText = this.$t(this.localePath + this.title);
      }

      if (!this.unit) {
        return fullText;
      }

      const convertable = ["km", "m", "mm", "mi", "ft", "in"];
      if (convertable.includes(this.unit)) {
        return fullText + `(${this.$units.getAbbr(this.unit)})`;
      }
      return fullText + `(${this.unit})`;
    },

    isSortedColumn() {
      return (
        this.sortedBy.keyName === this.title ||
        this.sortedBy.keyName === this.sortingKeys
      );
    },

    iconClass() {
      const rotated = this.isSortedColumn && this.sortedBy.order !== "asc";
      return { sorted: this.isSortedColumn, rotated };
    },
  },

  methods: {
    onClick() {
      if (!this.isGroupHeader && this.sortFunction) {
        this.sortFunction(this.sortingKeys || this.title);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.sticky {
  width: fit-content;
  position: sticky;
  left: 5px;
  margin-left: 21px;
}

th {
  .v-icon {
    opacity: 0;
    &.sorted {
      opacity: 1;
    }
    &.rotated {
      transform: rotate(180deg);
    }
  }
  &:hover {
    .v-icon:not(.sorted) {
      opacity: 0.4;
    }
  }
}
</style>
