<template>
  <PlotlyGraph
    :data="plotlyData"
    :layout="plotlyLayout"
    :config="{ displayModeBar: false }"
    @mousedown.stop="$emit('graph-mousedown')"
  />
</template>

<script>
import { PlotlyGraph } from "@/components";
import { mapState, mapGetters } from "vuex";
import { anomalyTypeColors } from "@/colorLegends.js";

export default {
  name: "AnomaliesHeightChart",
  props: ["visibleDistance"],
  components: {
    PlotlyGraph,
  },
  emits: ["changePoint", "graph-mousedown"],

  setup() {
    return {
      anomalyTypeColors,
    };
  },

  computed: {
    ...mapState([
      "selectedInspectionStats",
      "pipelineDepth",
      "pipelineElevation",
    ]),

    ...mapGetters("anomalies", ["filteredAnomalies"]),

    yRange() {
      const zAnomalies = this.filteredAnomalies.map((an) => an.z_coordinate);
      const zHeightProfile = this.selectedInspectionStats.height_profile.z.map(
        (value) => this.$units.convert(value, "m")
      );
      const hMax = Math.max(...zHeightProfile, ...zAnomalies);
      const hMin = Math.min(...zHeightProfile, ...zAnomalies);

      return [hMin - 5.0, hMax + 2.0];
    },

    pipeDistance() {
      return this.selectedInspectionStats.height_profile.distance.map((value) =>
        this.$units.convert(value, "m")
      );
    },

    anomaliesPlotData() {
      const data = [];
      const anomalyXPositionsByTypes = {};

      if (this.filteredAnomalies.length > 0) {
        this.filteredAnomalies.forEach(({ anomaly_type, distance }) => {
          if (!anomalyXPositionsByTypes[anomaly_type]) {
            anomalyXPositionsByTypes[anomaly_type] = [];
          }
          anomalyXPositionsByTypes[anomaly_type].push(distance);
        });
      }

      const middle = (this.yRange[1] + this.yRange[0]) / 2;

      const errorValue =
        Math.abs(this.yRange[0]) > Math.abs(this.yRange[1])
          ? this.yRange[0]
          : this.yRange[1];

      for (const type in anomalyXPositionsByTypes) {
        const x = anomalyXPositionsByTypes[type];
        const newData = {
          x,
          y: Array(x.length).fill(middle),
          mode: "markers",
          name: this.$t(`anomalies.types.${type}`),
          marker: {
            color: anomalyTypeColors[type],
          },
          error_y: {
            type: "constant",
            value: errorValue,
            width: 0,
            thickness: 1,
          },
        };

        data.push(newData);
      }

      return data;
    },

    plotlyData() {
      const data = this.anomaliesPlotData.slice();

      if (this.pipelineElevation.length > 0) {
        const y = this.pipelineElevation.map((elevation) =>
          this.$units.convert(elevation, "m")
        );

        data.push({
          x: this.pipeDistance,
          y,
          type: "scatter",
          name: this.$t("dashboard.pipelines.height_profile.elevation"),
          line: {
            color: "#6e4f00",
          },
        });
      }

      const zHeightProfile = this.selectedInspectionStats.height_profile.z.map(
        (value) => this.$units.convert(value, "m")
      );

      data.push({
        x: this.pipeDistance,
        y: zHeightProfile,
        type: "scatter",
        name: this.$t(`anomalies.details.height_profile.title`),
        line: {
          color: "#1c243d",
        },
      });

      if (this.pipelineDepth.length > 0) {
        const y = this.pipelineDepth.map((e) => this.$units.convert(e, "m"));

        data.push({
          x: this.pipeDistance,
          y,
          type: "scatter",
          fill: "tonexty",
          fillcolor: "#f78b8b",
          name: this.$t("dashboard.pipelines.height_profile.depth"),
          mode: "none",
        });
      }

      return data;
    },

    plotlyLayout() {
      let dupe = {};
      if (
        this.selectedInspectionStats &&
        this.filteredAnomalies &&
        this.filteredAnomalies.length
      ) {
        dupe = {
          xaxis: {
            title: `${this.$t(
              "anomalies.details.height_profile.xaxis_name"
            )} (${this.$units.getAbbr("m")})
              `,
            showline: true,
          },
          yaxis: {
            title: `${this.$t(
              "anomalies.details.height_profile.yaxis_name"
            )} (${this.$units.getAbbr("m")})
              `,
            showline: true,
            range: [0, 1],
          },
          margin: {
            t: 30,
            b: 70,
            l: 70,
            r: 50,
          },
        };

        if (this.visibleDistance?.length > 0) {
          const dMax = Math.max(...this.visibleDistance);
          const dMin = Math.min(...this.visibleDistance);
          dupe.xaxis.range = [dMin, dMax];
        } else if (this.pipeDistance.length > 0) {
          const dMax = Math.max(...this.pipeDistance);
          const dMin = Math.min(...this.pipeDistance);
          dupe.xaxis.range = [dMin, dMax];
        }

        dupe.yaxis.range = this.yRange;
      }
      return dupe;
    },
  },
};
</script>
