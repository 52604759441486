import { safetyFactorActions } from "@/store/safetyFactor/actions";
import { safetyFactorGetters } from "@/store/safetyFactor/getters";
import { safetyFactorMutations } from "@/store/safetyFactor/mutations";
import { safetyFactorState } from "@/store/safetyFactor/state";

export const safetyFactorModule = {
  namespaced: true,
  state: safetyFactorState,
  getters: safetyFactorGetters,
  mutations: safetyFactorMutations,
  actions: safetyFactorActions,
};
