<template>
  <!-- if height gets undefined for whatever reason, virtual scroll breaks -->
  <VirtualTable
    :height="widgetBodyHeight || 400"
    :header-slots="headerSlots"
    :item-slots="itemSlots"
    @click:row="(item) => $emit('click:row', item)"
    @toggle:checkbox="(itemId) => $emit('toggle:checkbox', itemId)"
    v-bind="$attrs"
  >
    <!-- duplicate logic for passing down dynamic slots because there's no other way to do this in Vue, except for using render functions ¯\_(ツ)_/¯  -->
    <template v-slot:[`header.select`]="props">
      <slot name="header.select" v-bind="props" />
    </template>

    <template v-for="slotName in headerSlots" v-slot:[slotName]="props">
      <slot :name="slotName" v-bind="props" />
    </template>

    <template v-slot:[`item.select`]="props">
      <slot name="item.select" v-bind="props" />
    </template>

    <template v-for="slotName in itemSlots" v-slot:[slotName]="props">
      <slot :name="slotName" v-bind="props" />
    </template>

    <template v-slot:[`item.prepend`]="props">
      <slot name="item.prepend" v-bind="props" />
    </template>

    <template v-slot:[`item.append`]="props">
      <slot name="item.append" v-bind="props" />
    </template>
  </VirtualTable>
</template>

<script>
import VirtualTable from "./VirtualTable";

export default {
  name: "ResizableVirtualTable",

  components: { VirtualTable },

  props: {
    headerSlots: Array,
    itemSlots: Array,
  },

  inject: ["widgetBodyHeight"],

  emits: ["click:row", "toggle:checkbox"],
};
</script>
