import {
  getMaxAngle,
  getMinMaxValue,
  getUniqueValues,
  rangeFiltering,
  valueFiltering,
} from "@/utils/filterHelpers.js";
import { getQuantile } from "@/pages/joints/helpers";
import { unitsConverter } from "@/config/units";

export const jointsGetters = {
  jointsWithConvertedUnits(state) {
    return state.joints.map((joint) => {
      return {
        ...joint,
        distance:
          joint.distance &&
          unitsConverter.instance.convert(joint.distance, "m"),
        gap_width_max:
          joint.gap_width_max &&
          unitsConverter.instance.convert(joint.gap_width_max, "mm"),
        gap_width_mean:
          joint.gap_width_mean &&
          unitsConverter.instance.convert(joint.gap_width_mean, "mm"),
      };
    });
  },

  jointsWithIds(_, getters) {
    const sortedJoints = getters.jointsWithConvertedUnits.sort(
      (a, b) => a.distance - b.distance
    );
    return sortedJoints.map((joint, index) => ({
      ...joint,
      joint_id: index + 1,
    }));
  },

  filteredJoints(state, getters, rootState) {
    const {
      selectedJointTypes,
      selectedMaterials,
      selectedVerticalAngle,
      selectedHorizontalAngle,
      selectedGapMax,
      selectedGapMean,
      plotlyFilter,
    } = state.jointFilters;

    let filtered = getters.jointsWithIds;

    if (selectedJointTypes.length) {
      filtered = valueFiltering(filtered, "joint_type", selectedJointTypes);
    }

    if (selectedMaterials.length) {
      filtered = valueFiltering(filtered, "material", selectedMaterials);
    }

    if (!rootState.isMapMode) {
      filtered = rangeFiltering(filtered, "angle_vertical", [
        selectedVerticalAngle * -1,
        selectedVerticalAngle,
      ]);

      filtered = rangeFiltering(filtered, "angle_horizontal", [
        selectedHorizontalAngle * -1,
        selectedHorizontalAngle,
      ]);

      filtered = rangeFiltering(filtered, "gap_width_max", selectedGapMax);
      filtered = rangeFiltering(filtered, "gap_width_mean", selectedGapMean);
    }

    if (plotlyFilter.property !== "") {
      const { property, range } = plotlyFilter;
      filtered = rangeFiltering(filtered, property, range);
    }

    return filtered;
  },

  jointTypes(state) {
    return getUniqueValues(state.joints, "joint_type");
  },

  materials(state) {
    return getUniqueValues(state.joints, "material");
  },

  maxVerticalAngle(state) {
    return getMaxAngle(state.joints, "angle_vertical");
  },

  maxHorizontalAngle(state) {
    return getMaxAngle(state.joints, "angle_horizontal");
  },

  quantileVertical(state) {
    return getQuantile(
      state.joints.map((j) => j["angle_vertical"]),
      0.99
    );
  },

  quantileHorizontal(state) {
    return getQuantile(
      state.joints.map((j) => j["angle_horizontal"]),
      0.99
    );
  },

  minMaxGapMean(state) {
    return getMinMaxValue(state.joints, "gap_width_mean");
  },

  minMaxGapMax(state) {
    return getMinMaxValue(state.joints, "gap_width_max");
  },

  selectedJoint(state, getters) {
    return getters.filteredJoints.find(
      (joint) => joint.id === state.selectedJointId
    );
  },

  hasActiveFilters(state) {
    return (
      state.jointFilters["selectedJointTypes"].length > 0 &&
      state.jointFilters["selectedMaterials"].length > 0
    );
  },
};
