import { safetyLevels } from "@/colorLegends.js";

export const getSafetyLevel = (safetyFactor, translation) => {
  if (safetyFactor === null || safetyFactor === undefined) {
    if (translation) {
      safetyLevels[0].text = translation("color_legends.unknown");
    }
    return safetyLevels[0];
  }
  if (safetyFactor <= 1) {
    return safetyLevels[1];
  }
  if (safetyFactor <= 1.5) {
    return safetyLevels[2];
  }
  if (safetyFactor <= 2) {
    return safetyLevels[3];
  }
  return safetyLevels[4];
};
