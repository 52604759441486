<template>
  <v-card :elevation="4">
    <v-form ref="calcForm" @submit.prevent="submit">
      <h3>{{ tForm("file_information") }}</h3>
      <v-row no-gutters>
        <v-col class="px-3">
          <v-select
            :model-value="calcForm.wall_thickness_original"
            @update:modelValue="
              (value) => (calcForm.wall_thickness_original = value)
            "
            :items="wallThicknessOptions"
            :label="tForm('original_wt')"
            :loading="isLoadingDetails"
            :disabled="isLoadingDetails"
            variant="underlined"
          ></v-select>
        </v-col>
        <v-col>
          <v-text-field
            v-model="calcForm.material"
            :label="tForm('material', '*')"
            variant="underlined"
            :loading="isLoadingDetails"
            disabled
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row no-gutters>
        <v-col class="px-3">
          <v-text-field
            :model-value="selectedFile?.position"
            :label="tForm('file_tag', '*')"
            variant="underlined"
            disabled
          ></v-text-field>
        </v-col>
        <v-col class="px-3">
          <v-text-field
            :model-value="selectedFile?.type"
            :label="tForm('measurement_type', '*')"
            variant="underlined"
            disabled
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row no-gutters>
        <v-col class="px-3">
          <v-text-field
            :model-value="calcForm.coating_outer"
            :label="tForm('coating_outer', '*')"
            variant="underlined"
            :loading="isLoadingDetails"
            disabled
          ></v-text-field>
        </v-col>
        <v-col class="px-3">
          <v-text-field
            :model-value="calcForm.coating_inner"
            :label="tForm('coating_inner', '*')"
            variant="underlined"
            :loading="isLoadingDetails"
            disabled
          ></v-text-field>
        </v-col>
      </v-row>

      <h3>{{ tForm("peak_detect_settings") }}</h3>
      <v-row no-gutters>
        <v-col class="px-3" lg="6">
          <v-text-field
            type="number"
            :model-value="calcForm.search_start"
            @update:modelValue="(value) => (calcForm.search_start = +value)"
            :rules="[
              (v) => v > 0 || `${tForm('search_start')} must be greater than 0`,
            ]"
            :label="tForm('search_start', '*')"
            variant="underlined"
          ></v-text-field>
        </v-col>
        <v-col class="px-3" lg="6">
          <v-text-field
            type="number"
            :model-value="calcForm.search_stop"
            @update:modelValue="(value) => (calcForm.search_stop = +value)"
            :rules="[
              (v) => v > 0 || `${tForm('search_stop')} must be greater than 0`,
              (v) =>
                v > calcForm.search_start ||
                `Must be greater than ${tForm('search_start')}`,
            ]"
            :label="tForm('search_stop', '*')"
            variant="underlined"
          ></v-text-field>
        </v-col>
        <v-col class="px-3" lg="6">
          <v-text-field
            type="number"
            :model-value="calcForm.min_distance_bw"
            @update:modelValue="(value) => (calcForm.min_distance_bw = +value)"
            :rules="[
              (v) => v > 0 || `${tForm('min_dist')} must be greater than 0`,
            ]"
            :label="tForm('min_dist', '*')"
            variant="underlined"
          ></v-text-field>
        </v-col>
        <v-col class="px-3" lg="6">
          <v-text-field
            type="number"
            :model-value="calcForm.max_distance_bw"
            @update:modelValue="(value) => (calcForm.max_distance_bw = +value)"
            :rules="[
              (v) => v > 0 || `${tForm('max_dist')} must be greater than 0`,
              (v) =>
                v > calcForm.min_distance_bw ||
                `Must be greater than ${tForm('min_dist')}`,
            ]"
            :label="tForm('max_dist', '*')"
            variant="underlined"
          ></v-text-field>
        </v-col>
      </v-row>

      <v-row no-gutters>
        <v-col class="px-3" lg="6">
          <v-text-field
            type="number"
            :model-value="calcForm.noise_lvl_fw"
            @update:modelValue="(value) => (calcForm.noise_lvl_fw = +value)"
            :rules="[
              (v) => v > 0 || `${tForm('fw_noise')} must be greater than 0`,
            ]"
            :label="tForm('fw_noise', '*')"
            variant="underlined"
          ></v-text-field>
        </v-col>
        <v-col class="px-3" lg="6">
          <v-text-field
            type="number"
            :model-value="calcForm.noise_lvl_bw"
            @update:modelValue="(value) => (calcForm.noise_lvl_bw = +value)"
            :rules="[
              (v) => v > 0 || `${tForm('bw_noise')} must be greater than 0`,
            ]"
            :label="tForm('bw_noise', '*')"
            variant="underlined"
          ></v-text-field>
        </v-col>
        <v-col class="px-3" lg="6">
          <v-select
            :model-value="calcForm.algorithm"
            @update:modelValue="(value) => (calcForm.algorithm = value)"
            :items="['correlation']"
            :label="tForm('algorithm')"
            variant="underlined"
          ></v-select>
        </v-col>
        <v-col class="px-3" lg="6">
          <v-switch
            v-model="calcForm.last_peak_preferred"
            :label="tForm('last_peak')"
          ></v-switch>
        </v-col>
      </v-row>

      <v-btn
        rounded="lg"
        color="deep-orange"
        type="submit"
        :disabled="isLoadingDetails"
        :loading="isLoadingCalcPeaks"
      >
        {{ tForm("calculate_btn") }}
      </v-btn>
    </v-form>
  </v-card>
</template>

<script>
import { mapState } from "vuex";
import { getInspectionData } from "@/features/pipescanner/api";

export default {
  name: "PeakDetectForm",
  props: {
    selectedFile: Object,
    isLoadingCalcPeaks: Boolean,
    onSubmit: Function,
  },
  data() {
    return {
      inspectionDetails: null,
      isLoadingDetails: false,
      calcForm: {
        algorithm: "correlation",
        material: null,
        wall_thickness_original: null,
        search_start: 0,
        search_stop: 0,
        min_distance_bw: 0,
        max_distance_bw: 0,
        noise_lvl_fw: 70,
        noise_lvl_bw: 50,
        last_peak_preferred: false,

        coating_outer: "",
        coating_inner: "",
      },
    };
  },

  mounted() {
    this.fetchInspectionDetails();
  },

  computed: {
    ...mapState(["selectedGroup"]),

    wallThicknessOptions() {
      if (!this.inspectionDetails) {
        return [];
      }
      return [
        {
          title: `Expected: ${this.inspectionDetails.wallthickness_expected}`,
          value: this.inspectionDetails.wallthickness_expected,
        },
        {
          title: `Validation: ${this.inspectionDetails.wallthickness_expected_validation}`,
          value: this.inspectionDetails.wallthickness_expected_validation,
        },
      ];
    },
  },

  methods: {
    async fetchInspectionDetails() {
      try {
        this.isLoadingDetails = true;
        const token = await this.$auth0.getAccessTokenSilently();
        const inspection = await getInspectionData(
          token,
          this.$route.params.id,
          this.selectedGroup
        );
        this.inspectionDetails = inspection;
        this.initForm(inspection);
      } catch (error) {
        if (error.name !== "CanceledError") {
          console.error(error);
          this.$toast.error(`Reading inspections - ${error.message}`, {
            position: "top-right",
          });
        }
      } finally {
        this.isLoadingDetails = false;
      }
    },

    async submit() {
      const { valid } = await this.$refs.calcForm.validate();

      if (valid) {
        const finalForm = {
          ...this.calcForm,
          inspection_file: this.selectedFile.name,
        };
        delete finalForm.coating_outer;
        delete finalForm.coating_inner;

        this.onSubmit(finalForm);
      }
    },

    initForm(inspection) {
      const { material, coating, coating_inner } = inspection;
      this.calcForm.material = material;
      this.calcForm.coating_outer = coating;
      this.calcForm.coating_inner = coating_inner;
    },

    tForm(field, append) {
      const appendString = append || "";
      return this.$t(`analyser.peak_detect.${field}`) + appendString;
    },
  },

  watch: {
    wallThicknessOptions(options) {
      this.calcForm.wall_thickness_original = options[0].value;
    },
  },
};
</script>
