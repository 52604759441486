import { pipeScannersActions } from "@/store/pipeScanners/actions";
import { pipeScannersGetters } from "@/store/pipeScanners/getters";
import { pipeScannersMutations } from "@/store/pipeScanners/mutations";
import { pipeScannersState } from "@/store/pipeScanners/state";

export const pipeScannersModule = {
  namespaced: true,
  state: pipeScannersState,
  mutations: pipeScannersMutations,
  getters: pipeScannersGetters,
  actions: pipeScannersActions,
};
