import { createUnitsPlugin } from "@/plugins/Units";

import { UNITS_LS_KEY } from "@/store/settings/config";

const persistedUnits = localStorage.getItem(UNITS_LS_KEY);

export const unitsConverter = createUnitsPlugin({
  units: persistedUnits,
  fallbackUnits: "metric",
});
