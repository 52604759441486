<template>
  <v-card>
    <PlotlyGraph
      :data="plotlyData"
      :layout="plotlyLayout"
      :config="{ displayModeBar: false }"
    />
  </v-card>
</template>

<script>
import { PlotlyGraph } from "@/components";

export default {
  name: "StatisticsPlotCard",
  props: ["detailed"],
  components: {
    PlotlyGraph,
  },
  computed: {
    plotlyData() {
      const data = [];
      const seriesNames = Object.keys(this.detailed.results);

      seriesNames.forEach((name) => {
        const yData = this.detailed.results[name].wt_remaining;
        data.push({
          y: yData,
          name: this.$t("pipe_scanner.details_modal.series") + `: ${name}`,
          type: "box",
        });
      });

      return data;
    },
    plotlyLayout() {
      return {
        yaxis: {
          title:
            this.$t("pipe_scanner.details_modal.thickness") +
            ` (${this.$units.getAbbr("mm")})`,
        },
        margin: {
          t: 25,
          b: 70,
          l: 80,
          r: 70,
        },
      };
    },
  },
};
</script>
