<template>
  <VDataTable
    :headers="headers"
    :items="anomaly.pipe_parts"
    disableSort
    item-keys="id"
    hide-default-footer
    density="compact"
    fixed-header
  />
</template>

<script>
export default {
  name: "PipePartsSubtable",

  props: {
    anomaly: Object,
  },

  data() {
    return {
      headers: [
        {
          title: `${this.$t(
            `pipe_parts.table_titles.distance`
          )} (${this.$units.getAbbr("m")})`,
          key: "distance",
          value: (pipePart) => this.$units.convert(pipePart.distance, "m"),
        },
        {
          title: this.$t(`pipe_parts.table_titles.pipe_part_type`),
          key: "type",
          value: (pipePart) =>
            this.$t(`pipe_parts.types.${pipePart.pipe_part_type}`),
        },
        {
          title: `${this.$t(`pipe_parts.table_titles.material`)}`,
          key: "material",
          value: (pipePart) => this.$t(`materials.${pipePart.material}`),
        },
        {
          title: `${this.$t(
            `pipe_parts.table_titles.length`
          )} (${this.$units.getAbbr("m")})`,
          key: "length",
          value: (pipePart) => this.$units.convert(pipePart.length, "m"),
        },
      ],
    };
  },
};
</script>
