<template>
  <VDataTable
    :headers="headers"
    :items="anomalies"
    disableSort
    item-keys="id"
    hide-default-footer
    density="compact"
    fixed-header
  />
</template>

<script>
export default {
  name: "AnomaliesSubtable",

  props: {
    anomalies: Array,
  },

  data() {
    return {
      headers: [
        {
          title: this.$t("anomalies.table_titles.anomaly_type"),
          key: "anomaly_type",
          value: (anomaly) =>
            this.$t(`anomalies.types.${anomaly.anomaly_type}`),
        },
        {
          title: `${this.$t(
            "anomalies.table_titles.distance"
          )} (${this.$units.getAbbr("m")})`,
          key: "distance",
          value: (anomaly) => this.$units.convert(anomaly.distance, "m"),
        },
        {
          title: `${this.$t(
            "anomalies.table_titles.length"
          )} (${this.$units.getAbbr("m")})`,
          key: "length",
          value: (anomaly) => this.$units.convert(anomaly.length, "m"),
        },
        {
          title: this.$t("anomalies.table_titles.comment"),
          key: "comment",
          value: "comment",
        },
      ],
    };
  },
};
</script>
