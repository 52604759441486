import { useToast } from "vue-toast-notification";

import { DefaultService, OpenAPI } from "@/open-api-code/ili-api";
import { generateDigupSheet } from "@/features/dig-up/api";
import { getDataForDigupRequest } from "@/features/dig-up/helpers";
import { auth0 } from "@/config/auth0";

import { INITIAL_JOINT_FILTERS } from "@/store/joints/state";

const requests = new Map();
const toast = useToast();

export const jointsActions = {
  async getDigupSheet({ commit, state, rootState }) {
    try {
      commit("setIsAwaitingDigup", true);

      const requestData = getDataForDigupRequest(
        state.jointsSelector.value,
        state.joints,
        rootState.settings.locale,
        rootState.settings.units
      );

      const token = await auth0.getAccessTokenSilently();
      await generateDigupSheet(token, rootState.selectedGroup, requestData);
    } catch (error) {
      if (error.name !== "CancelError") {
        console.error(error);
        toast.error(`Digup generating - ${error.message}`, {
          position: "top-right",
        });
      }
    } finally {
      commit("setIsAwaitingDigup", false);
    }
  },

  async getJoints({ commit, dispatch, rootState }) {
    try {
      commit("setIsLoading", true);
      OpenAPI.TOKEN = await auth0.getAccessTokenSilently();
      requests.set(
        "jointsRequest",
        DefaultService.readJointsInlineInspectionsJointsInspectionIdGroupGet(
          rootState.selectedInspectionId,
          rootState.selectedGroup
        )
      );
      const joints = await requests.get("jointsRequest");
      requests.delete("jointsRequest");
      commit("setJoints", joints);
      dispatch("initFilters");
    } catch (error) {
      if (error.name !== "CancelError") {
        console.log(error);
        toast.error(`Read Joints - ${error.message}`, {
          position: "top-right",
        });
      }
    } finally {
      commit("setIsLoading", false);
    }
  },

  initFilters({ commit, getters }) {
    commit("setFilters", {
      selectedVerticalAngle: getters.maxVerticalAngle,
      selectedHorizontalAngle: getters.maxHorizontalAngle,
      selectedGapMean: getters.minMaxGapMean,
      selectedGapMax: getters.minMaxGapMax,
    });
  },

  resetFilters({ commit }) {
    commit("setFilters", INITIAL_JOINT_FILTERS);
  },

  toggleSelectedLegend({ state, commit }, legend) {
    if (state.selectedLegend === legend) {
      commit("setSelectedLegend", null);
      return;
    }

    commit("setSelectedLegend", legend);
  },

  cancellAllRequests() {
    for (const request of requests.values()) {
      request.cancel();
    }
  },
};
