<template>
  <PipelinesMapmode
    v-if="isMapMode"
    :pipelines="groupPipelines"
    :isLoadingPipelines="isLoadingPipelines"
    :isLoadingStats="isLoadingStats"
    :onSelectPipelineId="onSelectPipelineId"
    :getInspectionStats="getInspectionStats"
  />
  <Pipelines
    v-else
    :pipelines="groupPipelines"
    :isLoadingPipelines="isLoadingPipelines"
    :isLoadingStats="isLoadingStats"
    :onSelectPipelineId="onSelectPipelineId"
  />
</template>

<script>
// @ is an alias to /src
import { mapState, mapMutations, mapGetters, mapActions } from "vuex";
import jwt_decode from "jwt-decode";
import { DefaultService } from "@/open-api-code/ili-api";
import UserCommentsAPI from "@/features/comments/api";

import { Pipelines, PipelinesMapmode } from "./views";

import { getGeoTIFF, getDepth } from "@/utils/geoTiffHelpers.js";

export default {
  components: { PipelinesMapmode, Pipelines },
  data() {
    return {
      isLoadingStats: false,
      requests: new Map(),
      userCommentsAPI: null,
    };
  },
  inject: {
    isLoadingPipelines: {},
  },

  mounted() {
    this.userCommentsAPI = new UserCommentsAPI(
      this.$auth0.getAccessTokenSilently,
      this.selectedGroup
    );
  },

  computed: {
    ...mapGetters(["selectedPipeline"]),
    ...mapState([
      "selectedGroup",
      "groupPipelines",
      "isMapMode",
      "selectedInspectionStats",
      "selectedInspectionId",
    ]),
  },

  methods: {
    ...mapMutations([
      "SET_SELECTED_PIPELINE_ID",
      "SET_SELECTED_INSPECTION_ID",
      "SET_SELECTED_INSPECTION_STATS",
      "SET_COMMENTS",
      "SET_PIPELINE_DEPTH",
      "SET_PIPELINE_ELEVATION",
    ]),
    ...mapActions(["RESET_FILTERS"]),

    onSelectPipelineId(pipelineId) {
      this.SET_SELECTED_PIPELINE_ID(pipelineId);
    },

    async getPipeComments(pipelineId) {
      try {
        this.SET_COMMENTS([]);

        const comments = await this.userCommentsAPI.getAll(pipelineId);

        this.SET_COMMENTS(comments);
      } catch (error) {
        if (error.name !== "CanceledError") {
          console.log(error);
          this.$toast.error(`Read user comments - ${error.message}`, {
            position: "top-right",
          });
        }
      }
    },

    async getInspectionStats() {
      this.isLoadingStats = true;
      this.SET_SELECTED_INSPECTION_STATS(null);
      try {
        const token = await this.$auth0.getAccessTokenSilently();
        if (token) {
          const payload = jwt_decode(token);
          const groupKey = "https://acqgroups.cloud/groups";
          if (Object.prototype.hasOwnProperty.call(payload, groupKey)) {
            this.requests.set(
              "statsRequest",
              DefaultService.readInspectionStatsInlineInspectionsInspectionStatsInspectionIdGroupGet(
                this.selectedInspectionId,
                this.selectedGroup
              )
            );
            const stats = await this.requests.get("statsRequest");
            this.SET_SELECTED_INSPECTION_STATS(stats);
            this.requests.delete("statsRequest");
            this.isLoadingStats = false;

            this.getElevation(
              stats.map_trace_geometry.coordinates,
              stats.height_profile
            );
          }
        }
      } catch (error) {
        if (error.name !== "CancelError") {
          console.error(error);
          this.$toast.error(`Read Inspection Stats - ${error.message}`, {
            position: "top-right",
          });
          this.isLoadingStats = false;
        }
      }
    },

    async getElevation(coordinates, heightProfile) {
      this.SET_PIPELINE_ELEVATION([]);
      this.SET_PIPELINE_DEPTH([]);
      try {
        const controller = new AbortController();
        this.requests.set("geoTIFFRequest", controller);
        const elevation = await getGeoTIFF(coordinates, controller);
        this.SET_PIPELINE_ELEVATION(elevation);
        const depth = getDepth(elevation, heightProfile.z);
        this.SET_PIPELINE_DEPTH(depth);
      } catch (error) {
        console.error(error);
      }
    },
  },

  watch: {
    selectedGroup(group) {
      if (this.requests.has("statsRequest")) {
        this.requests.get("statsRequest").cancel();
      }
      if (this.requests.has("geoTIFFRequest")) {
        this.requests.get("geoTIFFRequest").abort();
      }
      this.isLoadingStats = false;
      this.userCommentsAPI.updateGroup(group);
    },

    selectedPipeline(pipeline) {
      this.RESET_FILTERS();
      this.SET_SELECTED_INSPECTION_ID(pipeline?.inspections[0].id);
      if (pipeline) {
        this.getPipeComments(pipeline.id);
      }
    },

    selectedInspectionId(newId) {
      if (this.requests.has("statsRequest")) {
        this.requests.get("statsRequest").cancel();
      }
      if (this.requests.has("geoTIFFRequest")) {
        this.requests.get("geoTIFFRequest").abort();
      }
      if (newId) {
        this.getInspectionStats();
      }
    },
  },
};
</script>
