<template>
  <div id="mapmod-container">
    <ModifiedMapbox
      ref="modifiedMapRef"
      :zoom="7"
      :wmsLayers="wmsLayers"
      :filteredScannersMapData="filteredScannersMapData"
      :widgets="widgets"
      :onMaximizeWidget="maximizeWidget"
      hideBackToPipeButton
      @mb-moveend="onMoveend"
      @click="selectedWidget = null"
      @mb-load="centerMapToPipeScanners(currentFilteredScanners, tab)"
    >
      <WidgetCard
        :class="{ zIndex: selectedWidget === 'wmsWidget' }"
        :sizeProps="widgets.wmsWidget"
        :show="widgets.wmsWidget.isOpen"
        @drag="(e) => onDrag(e, 'wmsWidget')"
        @minimize="minimizeWidget('wmsWidget')"
        :isResizable="false"
        :sticks="[]"
      >
        <template v-slot:title-text>
          {{ $t(widgets.wmsWidget.title) }}
        </template>
        <WMSWidget v-model:wmsLayers="wmsLayers" />
      </WidgetCard>
      <WidgetCard
        v-if="widgets.loaders.isOpen"
        :class="{
          loaders: true,
          fade: widgets.loaders.fade,
        }"
        :sizeProps="widgets.loaders"
        :isResizable="false"
        :isDraggable="false"
        disableMinimize
        :sticks="[]"
      >
        <ScannerLoaders />
      </WidgetCard>
      <WMSLegend
        :class="{ zIndex: selectedWidget === 'wmsLegend' }"
        v-show="wmsLayers.length && widgets.wmsLegend.isOpen"
        :wmsLayers="wmsLayers"
        :currentZoom="currentZoom"
        @click="selectedWidget = 'wmsLegend'"
        @maximize-self="widgets.wmsLegend.isOpen = true"
        :onMinimize="() => minimizeWidget('wmsLegend')"
      />
      <WidgetCard
        :class="{ zIndex: selectedWidget === 'filters' }"
        :sizeProps="widgets.filters"
        :show="widgets.filters.isOpen"
        @drag="(e) => onDrag(e, 'filters')"
        @minimize="minimizeWidget('filters')"
      >
        <template v-slot:title-text>
          {{ $t(widgets.filters.title) }}
          <div v-if="hasActiveFilters" class="filter-indicator"></div>
        </template>
        <ScannerFilters />
      </WidgetCard>
      <WidgetCard
        :class="{ zIndex: selectedWidget === 'table' }"
        :sizeProps="widgets.table"
        :show="widgets.table.isOpen"
        @drag="(e) => onDrag(e, 'table')"
        @minimize="minimizeWidget('table')"
      >
        <template v-slot:title-text>
          {{ $t(widgets.table.title) }}
        </template>
        <template v-slot:title-prepend>
          <v-badge
            dot
            :color="hasActiveFilters ? '#d9534f' : '#ffffff00'"
            :offset-x="6"
          >
            <v-btn
              variant="text"
              density="compact"
              append-icon="mdi-tune"
              rounded="xl"
              @click="maximizeWidget('filters')"
              >Filters
            </v-btn>
          </v-badge>
          <ScannersTabs />
        </template>
        <div
          v-if="isLoading[selectedScannerType]"
          class="h-100 d-flex align-center justify-center"
        >
          <v-progress-circular
            color="dark-blue"
            indeterminate
            :size="52"
            :width="5"
          ></v-progress-circular>
        </div>
        <template v-else>
          <ResultsScannersTable
            v-if="selectedScannerType === ScannerType.Results"
            @scanner-edit="(id) => openEditModel(id, ScannerType.Results)"
            @scanner-details="(id) => openDetailedModal(id)"
            @scanner-select="(scanner) => handleTableItemSelect(scanner)"
          />
          <AnalysisScannersTable
            v-if="selectedScannerType === ScannerType.AnalysisPending"
            @scanner-select="(scanner) => handleTableItemSelect(scanner)"
            @scanner-edit="
              (id) => openEditModel(id, ScannerType.AnalysisPending)
            "
          />
          <InfoScannersTable
            v-if="selectedScannerType === ScannerType.InfoPending"
            @scanner-select="(scanner) => handleTableItemSelect(scanner)"
            @scanner-edit="(id) => openEditModel(id, ScannerType.InfoPending)"
          />
        </template>
      </WidgetCard>
    </ModifiedMapbox>
  </div>
</template>

<script>
import { computed } from "vue";
import { mapState, mapGetters } from "vuex";

import { ModifiedMapbox, WidgetCard } from "@/components";
import { WMSLegend, WMSWidget } from "@/features/wms/components";
import {
  ResultsScannersTable,
  AnalysisScannersTable,
  InfoScannersTable,
  ScannersTabs,
  ScannerFilters,
} from "@/pages/scanner-control/components";
import { ScannerLoaders } from "./components";

import { ScannerType } from "@/pages/scanner-control/config";
import { getScannersClusterSourceAndLayers } from "@/features/pipescanner/helpers";
import { initializeResizeObserver } from "@/utils/resizeObserverUtil";
import { fitMapToCoordinates } from "@/utils/mapHelpers";

export default {
  name: "ScannerControlMapmode",

  components: {
    WMSLegend,
    WMSWidget,
    WidgetCard,
    ModifiedMapbox,
    ScannerLoaders,
    ResultsScannersTable,
    AnalysisScannersTable,
    InfoScannersTable,
    ScannersTabs,
    ScannerFilters,
  },

  inject: {
    hasActiveFilters: { default: false },
  },

  provide() {
    return {
      parentW: computed(() => this.parentW),
      parentH: computed(() => this.parentH),
    };
  },

  setup() {
    return {
      ScannerType,
    };
  },

  data() {
    return {
      parentH: null,
      parentW: null,

      widgets: {
        loaders: {
          width: 500,
          height: 72,
          left: 400,
          top: 300,
          fade: false,
          isOpen: false,
        },
        table: {
          title: "pipe_scanner.pipescanners",
          width: 840,
          height: 450,
          left: 500,
          top: 150,
          isOpen: false,
        },
        filters: {
          title: "pipe_scanner.filters.title",
          width: 715,
          height: 180,
          left: 10,
          top: 60,
          isOpen: false,
        },
        wmsWidget: {
          title: "safety_factor.widgets.color",
          width: 350,
          height: 100,
          left: 400,
          top: 3,
          isOpen: true,
        },
        wmsLegend: {
          title: "wms_layers.layers",
          isOpen: true,
        },
      },

      selectedWidget: null,
      resizeObserver: null,
      currentZoom: null,
      wmsLayers: [],
    };
  },

  mounted() {
    this.resizeObserver = initializeResizeObserver.call(this, [
      "table",
      "filters",
      "wmsWidget",
    ]);
    this.resizeObserver.observe(this.$el);

    this.widgets.table.left =
      this.$el.offsetWidth - this.widgets.table.width - 5;
    this.widgets.table.top =
      this.$el.offsetHeight - this.widgets.table.height - 5;

    this.widgets.filters.left =
      this.$el.offsetWidth - this.widgets.filters.width - 5;

    this.widgets.wmsWidget.left =
      (this.$el.offsetWidth - this.widgets.wmsWidget.width) / 2;

    this.widgets.loaders.top =
      this.$el.offsetHeight - this.widgets.loaders.height - 5;
    this.widgets.loaders.left =
      (this.$el.offsetWidth - this.widgets.loaders.width) / 2;
  },

  beforeUnmount() {
    this.resizeObserver?.disconnect();
  },

  computed: {
    ...mapState(["mapStyle"]),

    ...mapState("pipeScanners", ["isLoading", "pipeScanners"]),

    ...mapState("scannerControl", ["selectedScannerType"]),

    ...mapGetters("scannerControl", ["currentFilteredScanners"]),

    map() {
      return this.$refs.modifiedMapRef.map;
    },

    filteredScannersMapData() {
      return getScannersClusterSourceAndLayers(
        this.currentFilteredScanners,
        this.selectedScannerType
      );
    },
  },

  methods: {
    onMoveend({ target: map }) {
      this.currentZoom = map.getZoom();
    },

    onDrag({ left, top }, key) {
      this.selectedWidget = key;
      this.widgets[key].left = left;
      this.widgets[key].top = top;
    },

    minimizeWidget(key) {
      this.widgets[key].isOpen = false;
    },

    maximizeWidget(key) {
      this.selectedWidget = key;
      this.widgets[key].isOpen = true;
    },

    openEditModel(id, status) {
      this.$refs.modifiedMapRef.openEditModel(id, status);
    },

    openDetailedModal(id) {
      this.$refs.modifiedMapRef.openDetailedModal(id);
    },

    handleTableItemSelect(scanner) {
      this.map.jumpTo({
        center: [scanner.longitude, scanner.latitude],
        zoom: 17,
      });
    },

    updateFilter(filter, value) {
      this.$emit("update:filters", { filter, value });
    },

    getPipeScannerCoordinates(pipeScanners) {
      const coordinates = pipeScanners.map((pipeScanner) => [
        pipeScanner.longitude,
        pipeScanner.latitude,
      ]);

      return coordinates;
    },

    centerMapToPipeScanners(pipeScanners) {
      const coordinates = this.getPipeScannerCoordinates(pipeScanners);

      if (coordinates.length === 0) {
        return;
      }

      fitMapToCoordinates(this.map, coordinates, { padding: 200 });
    },
  },

  watch: {
    isLoading: {
      handler() {
        if (Object.values(this.isLoading).some((value) => value === true)) {
          this.widgets.loaders.isOpen = true;
        } else {
          this.widgets.loaders.fade = true;
          setTimeout(() => {
            this.widgets.loaders.isOpen = false;
          }, 2500);
        }
      },

      deep: true,
    },

    pipeScanners: {
      handler() {
        this.centerMapToPipeScanners(this.currentFilteredScanners);
      },

      deep: true,
    },

    selectedScannerType() {
      this.centerMapToPipeScanners(this.currentFilteredScanners);
    },
  },
};
</script>

<style lang="scss" scoped>
#mapmod-container {
  padding: 0;
  margin: 0;
  width: 100%;
  height: 100%;

  .scanner-marker {
    cursor: pointer;
  }

  .loaders {
    transition: opacity 2s 0.5s;
    &.fade {
      opacity: 0;
    }
  }
  .zIndex {
    z-index: 5 !important;
  }

  .inactive {
    opacity: 0.8;
  }

  .filter-indicator {
    display: inline-block;
    width: 9px;
    height: 9px;
    border-radius: 50%;
    background-color: #d9534f;
  }
}
</style>
