import { withUnits } from "@/utils/stringHelpers";

export const GradientType = {
  DegradationLevel: "degradation_level",
  Material: "material",
  JointType: "joint_type",
  AngleVerical: "angle_vertical",
  AngleHorizontal: "angle_horizontal",
  GapWidthMean: "gap_width_mean",
  GapWidthMax: "gap_width_max",
};

export const GRADIENT_TYPES_DISPLAY_DECORATORS = {
  [GradientType.GapWidthMean]: (value) => withUnits(value, "mm"),
  [GradientType.GapWidthMax]: (value) => withUnits(value, "mm"),
};

export const GRADIENT_TYPES_DICTIONARY_KEY = "joints.details.map_select";
