/* eslint-disable */

import { LocalDegradationSpots } from "@/features/pipescanner/helpers";
import excel4node from "excel4node";
import convert from "convert-units";
import enLocale from "@/locales/en.json";
import nlLOcale from "@/locales/nl.json";

Number.prototype.toFixedNumber = function (digits, base) {
  var pow = Math.pow(base || 10, digits);
  return Math.round(this * pow) / pow;
};

/**
 * Export class for pipescanner inspections in Xlsx format
 */
class ExportXlsx {
  /**
   * class constructor
   * @param {array} export_data - array with data of to be exported inspections
   * @param {float} relativeDepthMin - local degradation spots relative depth
   * @param {float} widthMin - local degradation spots minimum width
   */
  constructor(
    export_data,
    relativeDepthMin,
    widthMin,
    settings,
    group,
    unitsSelected
  ) {
    //set the export data.
    this.group = group;
    this.locale = settings.language;
    const dictionary = this.locale === "en" ? enLocale : nlLOcale;

    this.paramTranslation = dictionary.export;
    // console.log(this.paramTranslation);

    this.settings = settings;
    // console.log(settings);
    this.relativeDepthMin = relativeDepthMin;
    this.widthMin = widthMin;
    this.exported = export_data;
    this.unitsSelected = unitsSelected;
    this.units = {
      metric: {
        mass: "kg",
        length: "mm",
        length2: "m",
      },
      imperial: {
        mass: "lbs",
        length: "in",
        length2: "ft",
      },
    };
  }
  /**
   * Sorting function by distance.
   * @param {obj} a - object a in an array
   * @param {obj} b - object b in an array
   * @return {int} sorting parameter
   */
  sort_function(a, b) {
    if (a.dist > b.dist) {
      return 1;
    } else if (b.dist > a.dist) {
      return -1;
    } else {
      return 0;
    }
  }
  /**
   * makes a promise of the export, for async purposes.
   * @return {Promise} Promise of an export.
   */
  generate_export_prom() {
    return new Promise((resolve, reject) => {
      //Make new workbook
      //console.log(JSON.stringify(this.exported));
      var wb = new excel4node.Workbook();
      var ws = wb.addWorksheet(this.paramTranslation["summary"]);

      //style for the first header group
      var headerStyle1 = wb.createStyle({
        font: {
          bold: true,
        },
        alignment: {
          wrapText: true,
          horizontal: "center",
        },
        fill: {
          type: "pattern",
          patternType: "solid",
          bgColor: "#bdd7ee",
          fgColor: "#bdd7ee",
        },
      });

      //style for the second header group
      var headerStyle2 = wb.createStyle({
        font: {
          bold: true,
        },
        alignment: {
          wrapText: true,
          horizontal: "center",
        },
        fill: {
          type: "pattern",
          patternType: "solid",
          bgColor: "#f6ffa6",
          fgColor: "#f6ffa6",
        },
      });

      //style for the third header group
      var headerStyle3 = wb.createStyle({
        font: {
          bold: true,
        },
        alignment: {
          wrapText: true,
          horizontal: "center",
        },
        fill: {
          type: "pattern",
          patternType: "solid",
          bgColor: "#59ff6d",
          fgColor: "#59ff6d",
        },
      });

      var headerStyle4 = wb.createStyle({
        font: {
          bold: true,
        },
        alignment: {
          wrapText: true,
          horizontal: "center",
        },
        fill: {
          type: "pattern",
          patternType: "solid",
          bgColor: "#D62728",
          fgColor: "#D62728",
        },
      });
      var headerStyle = [
        headerStyle1,
        headerStyle2,
        headerStyle3,
        headerStyle4,
      ];
      //style for maintaining line breaks
      var stringStyle = wb.createStyle({
        alignment: {
          wrapText: true,
        },
      });

      var boldStringStyle = wb.createStyle({
        alignment: {
          wrapText: true,
        },
        font: {
          bold: true,
        },
      });

      //build header on the summary sheet
      var headerCol = 1;

      var summaryItems = this.settings.summarySettings.items;
      var sectionAccumulate = 0;
      var sectionCount = 0;
      Object.keys(summaryItems).forEach((section) => {
        if (Object.keys(this.paramTranslation).includes(section)) {
          ws.cell(
            1,
            sectionAccumulate + 1,
            1,
            sectionAccumulate + summaryItems[section].length,
            true
          )
            .string(this.paramTranslation[section])
            .style(headerStyle[sectionCount]);
        } else {
          ws.cell(
            1,
            sectionAccumulate + 1,
            1,
            sectionAccumulate + summaryItems[section].length,
            true
          )
            .string(section)
            .style(headerStyle[sectionCount]);
        }
        var headerItems = summaryItems[section];
        headerItems.forEach((item) => {
          var unit = "";
          if (
            item.includes("wall") ||
            item.includes("diameter") ||
            item.includes("circumference") ||
            item.includes("width") ||
            item.includes("WT") ||
            item.includes("stats")
          ) {
            var unit = " [" + this.units[this.unitsSelected].length + "]";
          }
          if (Object.keys(this.paramTranslation).includes(item)) {
            ws.cell(2, headerCol)
              .string(this.paramTranslation[item] + unit)
              .style(headerStyle[sectionCount]);
          } else {
            ws.cell(2, headerCol)
              .string(item + unit)
              .style(headerStyle[sectionCount]);
          }
          headerCol++;
        });
        sectionAccumulate = sectionAccumulate + summaryItems[section].length;
        sectionCount++;
      });

      //summary info for each inspection

      var contentRow = 3;
      this.exported.forEach((element) => {
        var spots = Object.keys(element.results).map((x) => {
          var wt_spots = element.results[x].wt_remaining;
          var dist_spots = element.results[x].dist;
          var spotGenerator = new LocalDegradationSpots(
            dist_spots,
            wt_spots,
            element.wallthickness_expected_validation
          );
          var spotData = spotGenerator.filter_spots(
            this.relativeDepthMin,
            1.0,
            this.widthMin
          );
          return spotData.stats;
        });
        //console.log(spots);
        var numberOfSpots = spots
          .map((x) => x.numberOfSpots)
          .reduce((a, b) => {
            return a + b;
          });
        //console.log(numberOfSpots);
        var distanceTotal = spots
          .map((x) => x.distance)
          .reduce((a, b) => {
            return a + b;
          });

        var spotsOverDistance = (numberOfSpots / distanceTotal) * 100;

        var wallthicknessMin = Math.min(
          ...spots.map((x) => x.wallthicknessMin)
        );

        var widthMax = Math.max(...spots.map((x) => x.widthMax));

        var min_value = Object.keys(element.results).map((x) => {
          var wt_data = element.results[x].wt_remaining;
          return Math.min(...wt_data);
        });

        var max_value = Object.keys(element.results).map((x) => {
          var wt_data = element.results[x].wt_remaining;
          return Math.max(...wt_data);
        });

        var mean_array = [];

        var results = Object.keys(element.results).map((x) => {
          return element.results[x];
        });
        console.log(results);

        var mean_array = results
          .map((x) => x.wt_remaining)
          .reduce((a, b) => {
            return a.concat(b);
          });

        var mean_value = mean_array.reduce((a, b) => a + b) / mean_array.length;

        var diffs = mean_array.map((x) => x - mean_value);
        var sq_diffs = diffs.map((x) => x * x);
        var avg_sq_diffs = sq_diffs.reduce((a, b) => a + b) / sq_diffs.length;
        var stats = {
          max: Math.max(...max_value),
          mean: mean_value,
          min: Math.min(...min_value),
          std: Math.sqrt(avg_sq_diffs),
        };

        var local = {
          count: numberOfSpots,
          countOverDistance: spotsOverDistance,
          widthMax: widthMax,
          minWT: wallthicknessMin,
        };
        var contentCol = 1;

        Object.keys(summaryItems).forEach((section) => {
          var sectionItems = summaryItems[section];

          sectionItems.forEach((item) => {
            if (!item.includes(".")) {
              var value = eval("element." + item);
              if (
                item.includes("WT") ||
                item.includes("wall") ||
                item.includes("diameter") ||
                item.includes("circumference") ||
                item.includes("width") ||
                item.includes("stats")
              ) {
                value = convert(value)
                  .from("mm")
                  .to(this.units[this.unitsSelected].length)
                  .toFixedNumber(2);
              }
            } else {
              var value = eval(item);
              if (
                item.includes("WT") ||
                item.includes("wall") ||
                item.includes("diameter") ||
                item.includes("circumference") ||
                item.includes("width") ||
                item.includes("stats")
              ) {
                value = convert(value)
                  .from("mm")
                  .to(this.units[this.unitsSelected].length)
                  .toFixedNumber(2);
              }
            }
            if (item.includes("local.")) {
              if (numberOfSpots > 0 && element.material == "AC") {
                ws.cell(contentRow, contentCol).number(value);
              }
            } else {
              if (typeof value == "string") {
                ws.cell(contentRow, contentCol).string(value);
              } else if (typeof value == "undefined") {
                console.log("skip");
              } else {
                ws.cell(contentRow, contentCol).number(value);
              }
            }

            contentCol++;
          });
        });
        contentRow++;
      });

      //specify column width and row height
      ws.column(2).setWidth(50);
      ws.row(2).setHeight(60);

      //special format export

      if (this.settings.specialFormat) {
        var wsSpecialFormat = wb.addWorksheet(
          this.group.split(".")[0] + " format"
        );

        var formatHeaderRow = 1;
        var formatHeaderCol = 1;

        var formatItems = this.settings.specialFormatSettings.items;

        Object.keys(formatItems).forEach((item) => {
          wsSpecialFormat
            .cell(formatHeaderRow, formatHeaderCol)
            .string(formatItems[item])
            .style(boldStringStyle);
          formatHeaderCol++;
        });
        var formatRow = 3;
        var formatCol = 1;
        this.exported.forEach((element) => {
          Object.keys(formatItems).forEach((item) => {
            if (item != formatItems[item]) {
              if (item.includes(".")) {
                var value = eval(item);
              } else {
                var value = eval("element." + item);
              }
              if (typeof value == "string") {
                wsSpecialFormat.cell(formatRow, formatCol).string(value);
              } else if (typeof value == "undefined") {
                console.log("skip");
              } else {
                wsSpecialFormat.cell(formatRow, formatCol).number(value);
              }
            }
            formatCol++;
          });
          formatRow++;
          formatCol = 1;
        });
      }

      //sheets for each inspection
      this.exported.forEach((element, index, array) => {
        var wsTemp = wb.addWorksheet(element.tag);
        wsTemp
          .cell(1, 1, 1, 2, true)
          .string(this.paramTranslation.tabText.location)
          .style(stringStyle);
        wsTemp
          .cell(1, 3, 1, 4, true)
          .string(element.address)
          .style(stringStyle);

        wsTemp
          .cell(2, 1, 2, 2, true)
          .string(this.paramTranslation.tabText.material)
          .style(stringStyle);
        wsTemp
          .cell(2, 3, 2, 4, true)
          .string(element.material)
          .style(stringStyle);

        wsTemp
          .cell(3, 1, 3, 2, true)
          .string(this.paramTranslation.tabText.constructionyear)
          .style(stringStyle);
        wsTemp
          .cell(3, 3, 3, 4, true)
          .number(element.constructionyear)
          .style(stringStyle);

        wsTemp.cell(4, 1, 4, 2, true).string("").style(stringStyle);
        wsTemp.cell(4, 3, 4, 4, true).string("").style(stringStyle);

        wsTemp
          .cell(5, 1, 5, 2, true)
          .string(
            this.paramTranslation.tabText.circumference +
              " [" +
              this.units[this.unitsSelected].length +
              "]"
          )
          .style(stringStyle);
        wsTemp
          .cell(5, 3, 5, 4, true)
          .number(
            convert(element.circumference)
              .from("mm")
              .to(this.units[this.unitsSelected].length)
          );

        wsTemp
          .cell(6, 1, 6, 2, true)
          .string(
            this.paramTranslation.tabText.diameter +
              " [" +
              this.units[this.unitsSelected].length +
              "]"
          )
          .style(stringStyle);
        wsTemp
          .cell(6, 3, 6, 4, true)
          .number(
            convert(element.diameter)
              .from("mm")
              .to(this.units[this.unitsSelected].length)
          );

        wsTemp
          .cell(7, 1, 7, 2, true)
          .string(
            this.paramTranslation.tabText.wt +
              " [" +
              this.units[this.unitsSelected].length +
              "]"
          )
          .style(stringStyle);
        wsTemp
          .cell(7, 3, 7, 4, true)
          .number(
            convert(element.wallthickness_expected_validation)
              .from("mm")
              .to(this.units[this.unitsSelected].length)
          );
        if (this.locale == "nl") {
          wsTemp
            .cell(8, 1, 8, 2, true)
            .string(this.paramTranslation.tabText.rdx)
            .style(stringStyle);
          wsTemp.cell(8, 3, 8, 4, true).number(element.RDX);

          wsTemp
            .cell(9, 1, 9, 2, true)
            .string(this.paramTranslation.tabText.rdy)
            .style(stringStyle);
          wsTemp.cell(9, 3, 9, 4, true).number(element.RDY);
        } else {
          wsTemp
            .cell(8, 1, 8, 2, true)
            .string(this.paramTranslation.tabText.latitude)
            .style(stringStyle);
          wsTemp.cell(8, 3, 8, 4, true).number(element.latitude);

          wsTemp
            .cell(9, 1, 9, 2, true)
            .string(this.paramTranslation.tabText.longitude)
            .style(stringStyle);
          wsTemp.cell(9, 3, 9, 4, true).number(element.longitude);
        }

        wsTemp
          .cell(10, 1, 10, 2, true)
          .string(this.paramTranslation.tabText.coverage)
          .style(stringStyle);
        wsTemp
          .cell(10, 3, 10, 4, true)
          .number(
            convert(element.coverage)
              .from("m")
              .to(this.units[this.unitsSelected].length2)
          );

        //export measurement series
        var detailHeaderCol = 1;
        Object.keys(element.results).forEach((resultKey) => {
          var entry = element.results[resultKey];
          wsTemp
            .cell(21, detailHeaderCol)
            .string(resultKey)
            .style(boldStringStyle);
          wsTemp
            .cell(22, detailHeaderCol)
            .string(
              this.paramTranslation.tabText.distance +
                " [" +
                this.units[this.unitsSelected].length +
                "]"
            )
            .style(boldStringStyle);
          wsTemp
            .cell(22, detailHeaderCol + 1)
            .string(this.paramTranslation.tabText.roll)
            .style(boldStringStyle);
          wsTemp
            .cell(22, detailHeaderCol + 2)
            .string(
              this.paramTranslation.tabText.remaining_wt +
                " [" +
                this.units[this.unitsSelected].length +
                "]"
            )
            .style(boldStringStyle);
          if (element.material == "AC") {
            wsTemp
              .cell(22, detailHeaderCol + 3)
              .string(
                this.paramTranslation.tabText.leaching +
                  " [" +
                  this.units[this.unitsSelected].length +
                  "]"
              )
              .style(boldStringStyle);
          }

          var dataRow = 23;

          var i;
          for (i = 0; i < entry.wt_remaining.length; i++) {
            wsTemp
              .cell(dataRow, detailHeaderCol)
              .number(
                convert(entry.dist[i])
                  .from("mm")
                  .to(this.units[this.unitsSelected].length)
                  .toFixedNumber(3)
              );
            wsTemp
              .cell(dataRow, detailHeaderCol + 1)
              .number(((entry.roll[i] / Math.PI) * 180.0).toFixedNumber(3));
            wsTemp
              .cell(dataRow, detailHeaderCol + 2)
              .number(
                convert(entry.wt_remaining[i])
                  .from("mm")
                  .to(this.units[this.unitsSelected].length)
                  .toFixedNumber(2)
              );
            if (element.material == "AC") {
              wsTemp.cell(dataRow, detailHeaderCol + 3).number(
                convert(
                  element.wallthickness_expected_validation -
                    entry.wt_remaining[i]
                )
                  .from("mm")
                  .to(this.units[this.unitsSelected].length)
                  .toFixedNumber(2)
              );
            }
            dataRow++;
          }

          detailHeaderCol = detailHeaderCol + 7;
        });

        wsTemp.row(1).setHeight(30);

        if (index === array.length - 1) resolve(wb);
      });
    });
  }
}

export default ExportXlsx;
