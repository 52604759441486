<template>
  <div
    v-if="filteredJoints.length > 0"
    class="container-table"
    :class="{ 'max-height-70': !isMapMode }"
  >
    <JointsTableMapmode
      v-if="isMapMode"
      :checked-length="checkedLength"
      :is-joint-active="isJointActive"
      @click:row="onSelect"
    />
    <JointsTableRegular
      v-else
      :checked-length="checkedLength"
      :is-joint-active="isJointActive"
      @click:row="onSelect"
    />
    <DigUpTableBtn
      v-if="checkedLength > 0"
      :badgeCount="checkedLength"
      :isMapMode="isMapMode"
    />
  </div>
</template>

<script>
import { mapState, mapGetters, mapMutations } from "vuex";

import JointsTableRegular from "./JointsTableRegular.vue";
import JointsTableMapmode from "./JointsTableMapmode.vue";

import { DigUpTableBtn } from "@/features/dig-up/components";

export default {
  name: "JointsTable",

  components: { DigUpTableBtn, JointsTableRegular, JointsTableMapmode },

  provide() {
    return {
      localePath: "joints.table_titles.",
    };
  },

  computed: {
    ...mapState(["isMapMode"]),

    ...mapState("joints", ["selectedGradientMode", "jointsSelector"]),

    ...mapGetters("joints", ["filteredJoints", "selectedJoint"]),

    computedCellUnit() {
      if (this.selectedGradientMode.includes("angle")) {
        return "°";
      }
      if (this.selectedGradientMode.includes("gap_width")) {
        return "mm";
      }

      return undefined;
    },

    checkedLength() {
      return this.jointsSelector.size;
    },
  },

  methods: {
    ...mapMutations("joints", ["setSelectedJointId"]),

    isJointActive(joint) {
      return joint.id === this.selectedJoint?.id;
    },

    onSelect(id) {
      this.setSelectedJointId(id);
    },
  },
};
</script>
