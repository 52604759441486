<template>
  <PlotlyGraph
    :data="plotlyData"
    :layout="plotlyLayout"
    :config="{ displayModeBar: false }"
    @click.self="onClickHistogram"
    @relayout="onHistogramZoom"
    @mousedown.stop="$emit('graph-mousedown')"
  />
</template>

<script>
import { mapMutations, mapState, mapActions, mapGetters } from "vuex";

import { PlotlyGraph } from "@/components";

import {
  materialColors,
  roadTypeColors,
  soilTypeColors,
  getRemainingLifeColor,
  getDegradationColorPP,
  qualityInfluenceColors,
} from "@/colorLegends.js";

export default {
  name: "PipePartsHistogram",

  emits: ["graph-mousedown"],

  props: ["title"],

  components: {
    PlotlyGraph,
  },

  computed: {
    ...mapState("pipeParts", ["selectedGradientMode"]),

    ...mapGetters("pipeParts", ["filteredPipeParts"]),

    plotlyData() {
      const data = [];

      if (this.filteredPipeParts && this.filteredPipeParts.length) {
        let xdata = [];
        let colorData = [];
        if (
          [
            "remaining_life_calculation.remaining_life_group",
            "remaining_life_calculation.soil_type",
            "remaining_life_calculation.road_type",
            "remaining_life_calculation.road_distance",
            "remaining_life_calculation.degradation_rate",
            "remaining_life_calculation.ground_water_level",
          ].includes(this.selectedGradientMode)
        ) {
          const keys = this.selectedGradientMode.split(".");
          xdata = this.filteredPipeParts
            .map((pp) => pp[keys[0]][keys[1]])
            .filter((value) => value !== 0);
          if (
            this.selectedGradientMode ===
            "remaining_life_calculation.remaining_life_group"
          ) {
            xdata = xdata
              .sort((a, b) => a - b)
              .map((value) => getRemainingLifeColor()[value].text);
          }
          colorData = xdata;
        } else if (this.selectedGradientMode === "material") {
          xdata = this.filteredPipeParts.map((pp) =>
            this.$t(`materials.${pp[this.selectedGradientMode]}`)
          );
          colorData = this.filteredPipeParts.map(
            (pp) => pp[this.selectedGradientMode]
          );
        } else if (this.selectedGradientMode === "quality_anomaly") {
          this.filteredPipeParts.forEach(({ quality_anomaly }) => {
            const influences = quality_anomaly.split(", ");
            xdata.push(...influences);
          });
          colorData = [...xdata];
        } else {
          xdata = this.filteredPipeParts.map(
            (pp) => pp[this.selectedGradientMode]
          );
          if (this.selectedGradientMode === "degradation_level") {
            xdata = xdata
              .sort((a, b) => a - b)
              .map((value) => getDegradationColorPP(this.$t)[value].text);
          } else {
            xdata = xdata.filter((value) => value !== 0);
          }
          colorData = xdata;
        }

        data.push({
          x: xdata,
          name: this.title,
          showlegend: false,
          histnorm: "count",
          type: "histogram",
          autobinx: true,
          marker: {
            color: this.getMarkerColor(colorData),
          },
        });
      }

      return data;
    },

    plotlyLayout() {
      const dupe = {
        bargap: 0.05,
        bargroupgap: 0.2,
        barmode: "overlay",
        xaxis: {
          title: this.title,
        },
        yaxis: {
          title: this.$t("pipe_parts.details.plotly.yaxis_name"),
          type: "log",
          tick0: 100,
          dtick: "L200",
        },
        margin: {
          t: 30,
          b: 70,
          l: 70,
          r: 50,
        },
      };

      return dupe;
    },

    isGradient() {
      return ![
        "degradation_level",
        "quality_anomaly",
        "material",
        "remaining_life_calculation.remaining_life_group",
        "remaining_life_calculation.road_type",
        "remaining_life_calculation.soil_type",
      ].includes(this.selectedGradientMode);
    },
  },

  methods: {
    ...mapMutations(["CHANGE_FILTERS"]),

    ...mapMutations("pipeParts", ["setFilters"]),

    ...mapActions("pipeParts", ["toggleSelectedLegend", "resetPlotlyFilter"]),

    onClickHistogram(e) {
      if (this.isGradient) return;
      this.toggleSelectedLegend(e.points[0].x);
    },

    onHistogramZoom(ev) {
      const gm = this.selectedGradientMode;

      const plotlyFilterValues = [
        "remaining_mean",
        "remaining_min",
        "remaining_max",
        "remaining_std",
        "degradated_total_mean",
        "degradated_total_min",
        "degradated_total_max",
        "degradated_total_std",
        "ovality_mean",
        "ovality_max",
        "horizontal_out_of_straight",
        "vertical_out_of_straight",
        "remaining_life_calculation.road_distance",
        "remaining_life_calculation.degradation_rate",
        "remaining_life_calculation.ground_water_level",
      ];

      if (plotlyFilterValues.includes(gm)) {
        if (ev["xaxis.autorange"]) {
          this.resetPlotlyFilter();
        } else {
          if (ev.hasOwnProperty("xaxis.range[0]")) {
            const min = ev["xaxis.range[0]"];
            const max = ev["xaxis.range[1]"];
            const value = { property: gm, range: [min, max] };
            this.setFilters({ plotlyFilter: value });
          }
        }
      }
    },

    getMarkerColor(colorData) {
      if (this.isGradient) {
        return null;
      }
      return this.getLegendColorsForHistogram(
        colorData,
        this.selectedGradientMode
      );
    },

    getLegendColorsForHistogram(values, mode) {
      let arr = Array.from(new Set(values));
      if (Number.isInteger(arr[0])) {
        arr = arr.sort((a, b) => a - b);
      }

      switch (mode) {
        case "quality_anomaly": {
          return arr.map(
            (influence) => qualityInfluenceColors[influence.toLowerCase()]
          );
        }
        case "degradation_level":
          return arr.map(
            (el) =>
              getDegradationColorPP(this.$t).find((l) => el === l.text).color
          );

        case "remaining_life_calculation.remaining_life_group":
          return arr.map(
            (el) => getRemainingLifeColor().find((l) => el === l.text).color
          );

        case "material":
          return arr.map((el) => materialColors[el].color);

        case "remaining_life_calculation.soil_type":
          return arr.map((el) => soilTypeColors[el].color);

        case "remaining_life_calculation.road_type":
          return arr.map((el) => {
            return roadTypeColors.hasOwnProperty(el)
              ? roadTypeColors[el].color
              : roadTypeColors["other"].color;
          });

        default:
          return null;
      }
    },
  },
};
</script>
