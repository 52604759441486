import { mergeChanges } from "@/utils/objects";

export const pipeScannersMutations = {
  setPipeScanners(state, changes) {
    mergeChanges(state.pipeScanners, changes);
  },

  setIsLoading(state, changes) {
    mergeChanges(state.isLoading, changes);
  },
};
