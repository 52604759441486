<template>
  <v-menu
    :model-value="show"
    @update:modelValue="(value) => $emit('update:show', value)"
    :close-on-content-click="false"
    location="bottom"
  >
    <template v-slot:activator="{ props }">
      <v-badge
        dot
        :color="hasActiveFilters ? '#d9534f' : '#ffffff00'"
        :offset-x="6"
      >
        <v-btn
          v-bind="props"
          variant="text"
          density="compact"
          append-icon="mdi-tune"
          >{{ $t("pipe_parts.filters.title") }}
        </v-btn>
      </v-badge>
    </template>
    <v-card class="filter-content" :width="300" @mousedown.stop>
      <v-card-text>
        <v-select
          :label="$t('safety_factor.filters.main_contributor')"
          :model-value="filters.selectedMainUncertainties"
          @update:modelValue="
            (value) => setFilters({ selectedMainUncertainties: value })
          "
          :items="uncertaintiesOptions"
          item-title="text"
          item-value="text"
          multiple
          variant="underlined"
          hide-details
        ></v-select>
        <div class="slider">
          <p>{{ $t("safety_factor.filters.safety_factor") }}</p>
          <RangeSlider
            :model-value="filters.selectedSafetyRange"
            @update:modelValue="
              (value) => setFilters({ selectedSafetyRange: value })
            "
            :min="minMaxSafetyRange[0]"
            :max="minMaxSafetyRange[1]"
            :step="0.01"
          ></RangeSlider>
        </div>
        <div class="slider">
          <p>{{ $t("safety_factor.filters.safety_factor_lower") }}</p>
          <RangeSlider
            :model-value="filters.selectedLowerSafetyRange"
            @update:modelValue="
              (value) => setFilters({ selectedLowerSafetyRange: value })
            "
            :min="minMaxLowerSafetyRange[0]"
            :max="minMaxLowerSafetyRange[1]"
            :step="0.01"
          ></RangeSlider>
        </div>
        <div class="slider">
          <p>{{ $t("safety_factor.filters.safety_factor_upper") }}</p>
          <RangeSlider
            :model-value="filters.selectedUpperSafetyRange"
            @update:modelValue="
              (value) => setFilters({ selectedUpperSafetyRange: value })
            "
            :min="minMaxUpperSafetyRange[0]"
            :max="minMaxUpperSafetyRange[1]"
            :step="0.01"
          ></RangeSlider>
        </div>
      </v-card-text>
    </v-card>
  </v-menu>
</template>
<script>
import { mapState, mapGetters, mapMutations } from "vuex";

import { RangeSlider } from "@/components";

export default {
  name: "FilterPopupMenu",

  emits: ["update:show"],

  props: {
    show: Boolean,
    uncertaintiesOptions: Array,
  },

  components: {
    RangeSlider,
  },

  computed: {
    ...mapState("safetyFactor", ["filters"]),

    ...mapGetters("safetyFactor", [
      "minMaxSafetyRange",
      "minMaxLowerSafetyRange",
      "minMaxUpperSafetyRange",
      "hasActiveFilters",
    ]),
  },

  methods: {
    ...mapMutations("safetyFactor", ["setFilters"]),
  },
};
</script>

<style lang="scss" scoped>
.slider {
  width: 258px;
  padding: 8px 0px 10px;

  p {
    font-size: 1rem;
    margin-bottom: 6px;
  }
}
</style>
