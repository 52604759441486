<template>
  <div id="mapmod-container">
    <ModifiedMapbox
      ref="modifiedMapRef"
      :showLoader="isLoading"
      :wmsLayers="wmsLayers"
      :widgets="widgets"
      :onMaximizeWidget="maximizeWidget"
      forceHideScanners
      @mb-moveend="onMoveend"
      @click="selectedWidget = null"
    >
      <template v-if="selectedJoint">
        <MapboxMarker
          :lng-lat="selectedJoint.geom.coordinates"
          :scale="0.9"
          color="orange"
        >
        </MapboxMarker>
        <MapboxPopup :lng-lat="selectedJoint.geom.coordinates">
          <MapInfoPopup :data="selectedJoint" type="joint" />
        </MapboxPopup>
      </template>

      <template v-slot:sources="{ openDigupMenu }">
        <template
          v-for="{ id, sourceOptions, layerOptions } in jointsMapData"
          :key="id"
        >
          <MapboxSource :id="id" :options="sourceOptions" />
          <MapboxLayer
            :id="layerOptions.id"
            :options="layerOptions"
            @mb-mouseenter="onLayerMouseEnter"
            @mb-mouseleave="onLayerMouseLeave"
            @mb-click="onLayerClick"
            @mb-contextmenu="onLayerContextmenu(layerOptions.id, openDigupMenu)"
          />
        </template>
      </template>

      <div :class="{ hide: isLoading }">
        <WidgetCard
          :class="{ zIndex: selectedWidget === 'wmsWidget' }"
          :sizeProps="widgets.wmsWidget"
          :show="widgets.wmsWidget.isOpen"
          @drag="(e) => onDrag(e, 'wmsWidget')"
          @minimize="minimizeWidget('wmsWidget')"
          :isResizable="false"
          :sticks="[]"
        >
          <template v-slot:title-text>
            {{ $t(widgets.wmsWidget.title) }}
          </template>
          <WMSWidget
            v-model:wmsLayers="wmsLayers"
            :colorMode="selectedGradientMode"
            :colorOptions="gradientOptions"
            @select-color-mode="onSelectGradientMode"
          />
        </WidgetCard>
        <TableWidgetCard
          :class="{ zIndex: selectedWidget === 'table' }"
          :sizeProps="widgets.table"
          :show="widgets.table.isOpen"
          @drag="(e) => onDrag(e, 'table')"
          @minimize="minimizeWidget('table'), (showFilter = false)"
        >
          <template v-slot:title-text>
            {{ $t(widgets.table.title) }}
          </template>
          <template v-slot:title-prepend>
            <FilterPopupMenu v-model:show="showFilter"
          /></template>
          <JointsTable />
        </TableWidgetCard>
        <WidgetCard
          :isActive="selectedWidget === 'heightProfile'"
          :class="{ zIndex: selectedWidget === 'heightProfile' }"
          :sizeProps="widgets.heightProfile"
          :show="widgets.heightProfile.isOpen"
          @drag="(e) => onDrag(e, 'heightProfile')"
          @minimize="minimizeWidget('heightProfile')"
        >
          <template v-slot:title-text>
            {{ $t(widgets.heightProfile.title) }}
          </template>
          <JointsHeightChart
            :visibleDistance="visibleDistance"
            @graph-mousedown="selectedWidget = 'heightProfile'"
          />
        </WidgetCard>
        <WidgetCard
          :isActive="selectedWidget === 'histogram'"
          :class="{ zIndex: selectedWidget === 'histogram' }"
          :sizeProps="widgets.histogram"
          :show="widgets.histogram.isOpen"
          @drag="(e) => onDrag(e, 'histogram')"
          @minimize="minimizeWidget('histogram')"
        >
          <template v-slot:title-text>
            {{ histogramTitle }}
          </template>
          <JointsHistogramChart
            :joints="jointsWithIds"
            :gradientMode="selectedGradientMode"
            :chartName="histogramTitle"
            @graph-mousedown="selectedWidget = 'histogram'"
          />
        </WidgetCard>
        <WidgetCard
          v-if="showGradient"
          :class="{ zIndex: selectedWidget === 'gradientLegend' }"
          :sizeProps="widgets.gradientLegend"
          heightFitContent
          disableMinimize
          :isResizable="false"
          :isDraggable="false"
          :sticks="[]"
          @click="selectedWidget = 'gradientLegend'"
        >
          <template v-slot:title-text>
            <div class="pr-2">{{ $t(widgets.gradientLegend.title) }}</div>
          </template>
          <template v-slot:title-prepend>
            <v-btn
              variant="text"
              density="compact"
              icon="mdi-information-variant-circle-outline"
              @click="openInfoDialog()"
              @mousedown.stop
            ></v-btn>
          </template>
          <JointsGradient
            :gradientMode="selectedGradientMode"
            :gradientColor="gradientColor"
          />
        </WidgetCard>
        <WidgetCard
          v-if="!showGradient"
          :class="{ zIndex: selectedWidget === 'colorsLegend' }"
          :sizeProps="widgets.colorsLegend"
          heightFitContent
          disableMinimize
          :isResizable="false"
          :isDraggable="false"
          :sticks="[]"
          @click="selectedWidget = 'colorsLegend'"
        >
          <template v-slot:title-text>
            <div class="pr-2">{{ histogramTitle }}</div>
          </template>
          <template
            v-if="selectedGradientMode === 'degradation_level'"
            v-slot:title-prepend
          >
            <v-btn
              variant="text"
              density="compact"
              icon="mdi-cog"
              @click.stop="$refs.refThresholdsModal.showModal"
              @mousedown.stop
            ></v-btn>
            <v-btn
              variant="text"
              density="compact"
              icon="mdi-information-variant-circle-outline"
              @click="openInfoDialog()"
              @mousedown.stop
            ></v-btn>
          </template>
          <JointsLegend
            :gradientMode="selectedGradientMode"
            :selectedLegend="selectedLegend"
          />
        </WidgetCard>
        <WMSLegend
          :class="{ zIndex: selectedWidget === 'wmsLegend' }"
          v-show="wmsLayers.length && widgets.wmsLegend.isOpen"
          :isOpen="widgets.wmsLegend.isOpen"
          :offsetTop="240"
          :wmsLayers="wmsLayers"
          :currentZoom="currentZoom"
          @click="selectedWidget = 'wmsLegend'"
          @maximize-self="widgets.wmsLegend.isOpen = true"
          :onMinimize="() => minimizeWidget('wmsLegend')"
        />
      </div>
    </ModifiedMapbox>
    <ThresholdsModal ref="refThresholdsModal" />
    <LegendInfoModal
      ref="refLegendInfoModal"
      page="jointsWithIds"
      :selectMode="selectedGradientMode"
    />
  </div>
</template>
<script>
import { computed } from "vue";
import { mapState, mapGetters, mapMutations } from "vuex";

import {
  MapboxMarker,
  MapboxPopup,
  MapboxLayer,
  MapboxSource,
} from "@studiometa/vue-mapbox-gl";
import {
  JointsHeightChart,
  JointsHistogramChart,
  JointsLegend,
} from "@/pages/joints/components";
import {
  WidgetCard,
  MapInfoPopup,
  ModifiedMapbox,
  TableWidgetCard,
} from "@/components";
import { WMSLegend, WMSWidget } from "@/features/wms/components";
import { FilterPopupMenu } from "./components";
import { ThresholdsModal, LegendInfoModal } from "@/components/modals";
import { JointsGradient, JointsTable } from "@/pages/joints/components";

import { initializeResizeObserver } from "@/utils/resizeObserverUtil";

export default {
  name: "JointsMapmode",

  components: {
    ModifiedMapbox,
    MapboxMarker,
    MapboxPopup,
    MapboxLayer,
    MapboxSource,
    MapInfoPopup,
    WidgetCard,
    JointsHeightChart,
    JointsHistogramChart,
    JointsLegend,
    ThresholdsModal,
    LegendInfoModal,
    WMSLegend,
    WMSWidget,
    JointsGradient,
    JointsTable,
    FilterPopupMenu,
    TableWidgetCard,
  },

  props: {
    gradientOptions: Array,
    gradientColor: Array,
    jointsMapData: Array,
  },

  provide() {
    return {
      parentW: computed(() => this.parentW),
      parentH: computed(() => this.parentH),
    };
  },

  inject: ["histogramTitle"],

  data() {
    return {
      visibleDistance: [],
      parentH: null,
      parentW: null,
      widgets: {
        table: {
          title: "joints.widgets.table.maximize_title",
          width: 600,
          height: 355,
          left: 500,
          top: 100,
          isOpen: true,
        },
        heightProfile: {
          title: "joints.widgets.heightProfile.maximize_title",
          width: 655,
          height: 400,
          left: 750,
          top: 150,
          isOpen: false,
        },
        // TODO: Fix props being mirrored into state
        histogram: {
          title: this.histogramTitle,
          width: 655,
          height: 400,
          left: 5,
          top: 150,
          isOpen: true,
        },
        gradientLegend: {
          title: "joints.widgets.gradient.maximize_title",
          height: "auto",
          width: 130,
          left: 5,
          top: 50,
        },
        colorsLegend: {
          title: this.histogramTitle,
          height: "auto",
          width: 230,
          left: 5,
          top: 50,
        },
        wmsWidget: {
          title: "dashboard.widgets.color.maximize_title",
          width: 350,
          height: 150,
          left: 400,
          top: 3,
          isOpen: true,
        },
        wmsLegend: {
          title: "wms_layers.layers",
          isOpen: true,
        },
      },
      selectedWidget: null,
      resizeObserver: null,
      currentZoom: null,
      wmsLayers: [],
      showFilter: false,
    };
  },

  mounted() {
    this.resizeObserver = initializeResizeObserver.call(this, [
      "table",
      "heightProfile",
      "histogram",
      "wmsWidget",
    ]);
    this.resizeObserver.observe(this.$el);

    this.widgets.table.left =
      this.$el.offsetWidth - this.widgets.table.width - 10;

    this.widgets.wmsWidget.left =
      (this.$el.offsetWidth - this.widgets.wmsWidget.width) / 2;

    this.widgets.heightProfile.top =
      this.$el.offsetHeight - this.widgets.heightProfile.height - 40;
    this.widgets.heightProfile.left =
      this.$el.offsetWidth - this.widgets.heightProfile.width - 30;

    this.widgets.histogram.top =
      this.$el.offsetHeight - this.widgets.histogram.height - 40;
  },

  beforeUnmount() {
    this.resizeObserver?.disconnect();
  },

  computed: {
    ...mapState(["mapStyle"]),

    ...mapState("joints", [
      "jointsSelector",
      "selectedGradientMode",
      "selectedLegend",
      "isLoading",
    ]),

    ...mapGetters("joints", [
      "filteredJoints",
      "jointsWithIds",
      "selectedJoint",
    ]),

    map() {
      return this.$refs.modifiedMapRef.map;
    },

    showGradient() {
      return !["degradation_level", "material", "joint_type"].includes(
        this.selectedGradientMode
      );
    },
  },

  methods: {
    ...mapMutations("joints", [
      "setSelectedJointId",
      "setSelectedGradientMode",
    ]),

    openInfoDialog() {
      this.$refs.refLegendInfoModal.open();
    },

    onMoveend({ target: map }) {
      this.currentZoom = map.getZoom();
      if (map.getLayer("layer_joints")) {
        this.visibleDistance = map
          .queryRenderedFeatures({ layers: ["layer_joints"] })
          .map((joint) => joint.properties.distance);
      }
    },

    onLayerContextmenu(layeerId, callback) {
      if (layeerId.includes("selected_joints")) {
        callback();
      }
    },

    onLayerClick({ features, originalEvent }) {
      const { ctrlKey, shiftKey } = originalEvent;

      if (features[0].source.includes("joints")) {
        const selectedId = features[0].properties?.id;
        if (ctrlKey) {
          this.jointsSelector.setByCtrl(selectedId);
        } else if (shiftKey) {
          this.jointsSelector.setByShift(selectedId, this.filteredJoints);
        } else {
          this.setSelectedJointId(null);
          Promise.resolve().then(() => this.setSelectedJointId(selectedId));
        }
      }
    },

    onLayerMouseEnter({ features, target }) {
      if (features[0].source.includes("joints")) {
        target.getCanvas().style.cursor = "pointer";
        this.map.boxZoom.disable();
      }
    },

    onLayerMouseLeave({ target }) {
      target.getCanvas().style.cursor = "";
      this.map.boxZoom.enable();
    },

    onDrag({ left, top }, key) {
      this.selectedWidget = key;
      this.widgets[key].left = left;
      this.widgets[key].top = top;
    },

    minimizeWidget(key) {
      this.widgets[key].isOpen = false;
    },

    maximizeWidget(key) {
      this.selectedWidget = key;
      this.widgets[key].isOpen = true;
    },

    onSelectGradientMode(gradientMode) {
      this.setSelectedGradientMode(gradientMode);
    },
  },

  watch: {
    selectedJoint(joint) {
      if (joint) {
        this.map.flyTo({
          center: joint.geom.coordinates,
          zoom: this.currentZoom < 17 ? 17 : this.currentZoom,
        });
      }
    },

    histogramTitle(next) {
      this.widgets.histogram.title = next;
      this.widgets.colorsLegend.title = next;
    },
  },
};
</script>

<style lang="scss" scoped>
#mapmod-container {
  padding: 0;
  margin: 0;
  width: 100%;
  height: 100%;
}

.hide {
  opacity: 0;
}

.zIndex {
  z-index: 5 !important;
}

.inactive {
  opacity: 0.8;
}
</style>
