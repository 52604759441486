<template>
  <v-list density="compact">
    <v-list-item @click="onGenerateDigUp">
      <v-list-item-title style="display: flex; align-items: flex-end">
        <v-icon icon="mdi-shovel" style="margin-right: 5px;"></v-icon>
        {{ $t("context_menu.generate_dig_up") }}
      </v-list-item-title>
    </v-list-item>
    <v-list-item @click="cancelDigUp">
      <v-list-item-title style="display: flex; align-items: flex-end">
        <v-icon icon="mdi-shovel-off" style="margin-right: 5px;"></v-icon>
        {{ $t("context_menu.cancel_dig_up") }}
      </v-list-item-title>
    </v-list-item>
  </v-list>
</template>

<script>
export default {
  name: "DigUpMenu",
  inject: ["onGenerateDigUp", "cancelDigUp"],
};
</script>
