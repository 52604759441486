export function getPipeMapData(stats) {
  return {
    id: "geoJsonSourcePipelines",
    sourceOptions: {
      type: "geojson",
      data: {
        type: "FeatureCollection",
        features: [
          {
            type: "Feature",
            geometry: stats?.map_trace_geometry,
          },
        ],
      },
    },
    layerOptions: {
      id: "layer_geoJsonSourcePipelines",
      source: "geoJsonSourcePipelines",
      layout: {
        visibility: "visible",
      },
      type: "line",
      paint: {
        "line-color": "#24e3c6",
        "line-width": 3,
        "line-opacity": 0.6,
      },
    },
  };
}
