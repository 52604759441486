export const getSafetyFactorOptions = (t) => [
  // Safety factor:
  {
    props: {
      header: t("safety_factor.details.map_select.safety_factor.title"),
    },
  },
  {
    value: "safety_factor",
    title: t("safety_factor.details.map_select.safety_factor.safety_factor"),
  },
  {
    value: "safety_factor_lower",
    title: t(
      "safety_factor.details.map_select.safety_factor.safety_factor_lower"
    ),
  },
  {
    value: "safety_factor_upper",
    title: t(
      "safety_factor.details.map_select.safety_factor.safety_factor_upper"
    ),
  },
  {
    value: "main_contributor",
    title: t("safety_factor.details.map_select.safety_factor.main_contributor"),
  },
  // Input parameters:
  {
    props: {
      header: t("safety_factor.details.map_select.input_parameters.title"),
    },
  },
  {
    value: "ground_level_mean",
    title: t(
      "safety_factor.details.map_select.input_parameters.ground_level_mean"
    ),
  },
  {
    value: "ground_level_std",
    title: t(
      "safety_factor.details.map_select.input_parameters.ground_level_std"
    ),
  },
  {
    value: "ground_water_level_min",
    title: t(
      "safety_factor.details.map_select.input_parameters.ground_water_level_min"
    ),
  },
  {
    value: "ground_water_level_max",
    title: t(
      "safety_factor.details.map_select.input_parameters.ground_water_level_max"
    ),
  },
  {
    value: "soil_density_dry_mean",
    title: t(
      "safety_factor.details.map_select.input_parameters.soil_density_dry_mean"
    ),
  },
  {
    value: "soil_density_dry_std",
    title: t(
      "safety_factor.details.map_select.input_parameters.soil_density_dry_std"
    ),
  },
  {
    value: "soil_density_wet_mean",
    title: t(
      "safety_factor.details.map_select.input_parameters.soil_density_wet_mean"
    ),
  },
  {
    value: "soil_density_wet_std",
    title: t(
      "safety_factor.details.map_select.input_parameters.soil_density_wet_std"
    ),
  },
  {
    value: "material_density_mean",
    title: t(
      "safety_factor.details.map_select.input_parameters.material_density_mean"
    ),
  },
  {
    value: "material_density_std",
    title: t(
      "safety_factor.details.map_select.input_parameters.material_density_std"
    ),
  },
  {
    value: "tensile_strength_mean",
    title: t(
      "safety_factor.details.map_select.input_parameters.tensile_strength_mean"
    ),
  },
  {
    value: "tensile_strength_std",
    title: t(
      "safety_factor.details.map_select.input_parameters.tensile_strength_std"
    ),
  },
  {
    value: "pressure_mean",
    title: t("safety_factor.details.map_select.input_parameters.pressure_mean"),
  },
  {
    value: "pressure_std",
    title: t("safety_factor.details.map_select.input_parameters.pressure_std"),
  },
  {
    value: "joint_angle_vertical_mean",
    title: t(
      "safety_factor.details.map_select.input_parameters.joint_angle_vertical_mean"
    ),
  },
  {
    value: "joint_angle_vertical_std",
    title: t(
      "safety_factor.details.map_select.input_parameters.joint_angle_vertical_std"
    ),
  },
  {
    value: "road_distance_mean",
    title: t(
      "safety_factor.details.map_select.input_parameters.road_distance_mean"
    ),
  },
  {
    value: "road_distance_std",
    title: t(
      "safety_factor.details.map_select.input_parameters.road_distance_std"
    ),
  },
  {
    value: "road_wheel_load_mean",
    title: t(
      "safety_factor.details.map_select.input_parameters.road_wheel_load_mean"
    ),
  },
  {
    value: "road_wheel_load_std",
    title: t(
      "safety_factor.details.map_select.input_parameters.road_wheel_load_std"
    ),
  },
  {
    value: "soil_type",
    title: t("safety_factor.details.map_select.input_parameters.soil_type"),
  },

  // Uncertainty contribution

  {
    props: {
      header: t(
        "safety_factor.details.map_select.uncertainty_contribution.title"
      ),
    },
  },
  {
    value: "ground_level_uncertainty_contribution",
    title: t(
      "safety_factor.details.map_select.uncertainty_contribution.ground_level_uncertainty_contribution"
    ),
  },
  {
    value: "soil_density_dry_uncertainty_contribution",
    title: t(
      "safety_factor.details.map_select.uncertainty_contribution.soil_density_dry_uncertainty_contribution"
    ),
  },
  {
    value: "soil_density_wet_uncertainty_contribution",
    title: t(
      "safety_factor.details.map_select.uncertainty_contribution.soil_density_wet_uncertainty_contribution"
    ),
  },
  {
    value: "ground_water_level_uncertainty_contribution",
    title: t(
      "safety_factor.details.map_select.uncertainty_contribution.ground_water_level_uncertainty_contribution"
    ),
  },
  {
    value: "material_density_uncertainty_contribution",
    title: t(
      "safety_factor.details.map_select.uncertainty_contribution.material_density_uncertainty_contribution"
    ),
  },
  {
    value: "tensile_strength_uncertainty_contribution",
    title: t(
      "safety_factor.details.map_select.uncertainty_contribution.tensile_strength_uncertainty_contribution"
    ),
  },
  {
    value: "pressure_uncertainty_contribution",
    title: t(
      "safety_factor.details.map_select.uncertainty_contribution.pressure_uncertainty_contribution"
    ),
  },
  {
    value: "joint_angle_vertical_uncertainty_contribution",
    title: t(
      "safety_factor.details.map_select.uncertainty_contribution.joint_angle_vertical_uncertainty_contribution"
    ),
  },
  {
    value: "road_distance_uncertainty_contribution",
    title: t(
      "safety_factor.details.map_select.uncertainty_contribution.road_distance_uncertainty_contribution"
    ),
  },
  {
    value: "road_wheel_load_uncertainty_contribution",
    title: t(
      "safety_factor.details.map_select.uncertainty_contribution.road_wheel_load_uncertainty_contribution"
    ),
  },
  {
    value: "wall_thickness_uncertainty_contribution",
    title: t(
      "safety_factor.details.map_select.uncertainty_contribution.wall_thickness_uncertainty_contribution"
    ),
  },
];

export const VIEW_FACTORS_WITHOUT_GRADIENT = [
  "safety_factor",
  "safety_factor_lower",
  "safety_factor_upper",
  "main_contributor",
  "soil_type",
];
