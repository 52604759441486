export const findMainUncertainty = (pipePart) => {
  if (pipePart.safety_factor_calculation) {
    const keys = Object.getOwnPropertyNames(pipePart.safety_factor_calculation);
    const uncertaintyKeys = keys.filter((key) =>
      key.includes("_uncertainty_contribution")
    );

    const { maxValueKey } = uncertaintyKeys.reduce(
      (acc, key) => {
        const currentValue = pipePart.safety_factor_calculation[key];
        if (!currentValue) return acc;

        if (acc.maxValue === null || currentValue > acc.maxValue) {
          acc.maxValue = currentValue;
          acc.maxValueKey = key;
          return acc;
        }
        return acc;
      },
      {
        maxValue: null,
        maxValueKey: null,
      }
    );

    if (maxValueKey) {
      return maxValueKey.replace("_uncertainty_contribution", "");
    }

    return "none";
  }
  return "none";
};
