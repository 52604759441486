export const TILE_SIZE = 256;
const props = {
  request: "GetMap",
  bbox: "{bbox-epsg-3857}",
  service: "WMS",
  FORMAT: "image/png",
  TRANSPARENT: "true",
  CRS: "EPSG:3857",
  WIDTH: TILE_SIZE,
  HEIGHT: TILE_SIZE,
};
const commonUrlParams = `request=${props.request}&service=${props.service}&HEIGHT=${props.HEIGHT}&WIDTH=${props.WIDTH}&BBOX=${props.bbox}&CRS=${props.CRS}&TRANSPARENT=${props.TRANSPARENT}&FORMAT=${props.FORMAT}`;

// https://service.pdok.nl demands: version, layers, styles

export const createLayerMapUrl = (layer) => {
  const option = wmsOptions.find(({ value }) => value === layer);

  if (option) {
    const { base_url, layer_name } = option;
    let fullUrl = `${base_url}?LAYERS=${layer_name}&${commonUrlParams}`;
    if (base_url.includes("https://service.pdok.nl")) {
      fullUrl += "&version=1.3.0&STYLES=";
    }

    return fullUrl;
  }
};

export const wmsOptions = [
  {
    value: "risk_differential",
    min_zoom: undefined,
    layer_name: "risicoverschilzetting_2050hoog",
    base_url:
      "https://apps.geodan.nl/public/data/org/gws/YWFMLMWERURF/kea_public/wms",
  },
  {
    value: "high_risk",
    min_zoom: undefined,
    layer_name: "bl_buisleidingreferentie",
    base_url:
      "https://apps.geodan.nl/public/data/org/gws/IENW7924REVX/rev_public/wms",
  },
  {
    value: "protection_zone",
    min_zoom: 12.5,
    layer_name: "beschermingszone",
    base_url: "https://service.pdok.nl/hwh/keringenimwa/wms/v1_0",
  },
  {
    value: "crop_plots",
    min_zoom: 12.5,
    layer_name: "BrpGewas",
    base_url: "https://service.pdok.nl/rvo/brpgewaspercelen/wms/v1_0",
  },
  {
    value: "urban_water",
    min_zoom: 13.5,
    layer_name: "BeheerLeiding",
    base_url: "https://service.pdok.nl/rioned/beheerstedelijkwater/wms/v1_0",
  },
  {
    value: "nl_elevation",
    min_zoom: undefined,
    layer_name: "dsm_05m",
    base_url: "https://service.pdok.nl/rws/ahn/wms/v1_0",
  },
];
