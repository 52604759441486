import { jointsActions } from "@/store/joints/actions";
import { jointsGetters } from "@/store/joints/getters";
import { jointsMutations } from "@/store/joints/mutations";
import { jointsState } from "@/store/joints/state";

export const jointsModule = {
  namespaced: true,
  state: jointsState,
  mutations: jointsMutations,
  getters: jointsGetters,
  actions: jointsActions,
};
