import { useToast } from "vue-toast-notification";

import { auth0 } from "@/config/auth0";
import { generateDigupSheet } from "@/features/dig-up/api";
import { getDataForDigupRequest } from "@/features/dig-up/helpers";
import { DefaultService, OpenAPI } from "@/open-api-code/ili-api";
import { unitsConverter } from "@/config/units";
import { i18n } from "@/config/i18n";

const requests = new Map();
const toast = useToast();

function getLocale() {
  return i18n.global.locale;
}

export const anomaliesActions = {
  async getDigupSheet({ commit, state, rootState, getters }) {
    try {
      commit("setAwaitingDigup", true);

      const requestData = getDataForDigupRequest(
        state.checkedAnomaliesSelector.value,
        getters.anomaliesWithCenter,
        getLocale(),
        unitsConverter.instance.current.value
      );

      const accessToken = await auth0.getAccessTokenSilently();

      await generateDigupSheet(
        accessToken,
        rootState.selectedGroup,
        requestData
      );
    } catch (error) {
      if (error.name !== "CancelError") {
        console.error(error);
        toast.error(`Digup generating - ${error.message}`, {
          position: "top-right",
        });
      }
    } finally {
      commit("setAwaitingDigup", false);
    }
  },

  async getAnomalies({ commit, rootState, dispatch }) {
    try {
      commit("setLoadingAnomalies", true);
      commit("setAnomalies", []);
      commit("resetFilter");

      const accessToken = await auth0.getAccessTokenSilently();
      OpenAPI.TOKEN = accessToken;

      requests.set(
        "anomaliesRequest",
        DefaultService.readAnomaliesInlineInspectionsAnomaliesInspectionIdGroupGet(
          rootState.selectedInspectionId,
          rootState.selectedGroup
        )
      );

      const anomalies = await requests.get("anomaliesRequest");
      requests.delete("anomaliesRequest");

      commit("setAnomalies", anomalies);
      dispatch("initFilters");
    } catch (error) {
      if (error.name !== "CancelError") {
        console.error(error);
        toast.error(`Read Anomalies - ${error.message}`, {
          position: "top-right",
        });
      }
    } finally {
      commit("setLoadingAnomalies", false);
    }
  },

  cancellAllRequests() {
    for (const request of requests.values()) {
      request.cancel();
    }
  },

  toggleSelectedAnomaly({ state, commit }, id) {
    if (state.selectedAnomalyId === id) {
      commit("setSelectedAnomalyId", null);
      return;
    }

    commit("setSelectedAnomalyId", id);
  },

  resetAnomaliesFilters: {
    root: true,
    handler({ commit }) {
      commit("resetFilter");
    },
  },

  initFilters({ commit, getters }) {
    commit("setFilters", {
      selectedDistance: getters.minMaxDistance,
      selectedLength: getters.minMaxLength,
    });
  },
};
