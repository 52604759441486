import { useToast } from "vue-toast-notification";

import { auth0 } from "@/config/auth0";
import { DefaultService, OpenAPI } from "@/open-api-code/ili-api";
import { INITIAL_PLOTLY_FILTER } from "@/store/safetyFactor/state";

const toast = useToast();
const requests = new Map();

export const safetyFactorActions = {
  async getPipeParts({ commit, rootState, dispatch }) {
    try {
      commit("setLoading", true);

      OpenAPI.TOKEN = await auth0.getAccessTokenSilently();

      requests.set(
        "pipePartsRequest",
        DefaultService.readPipePartsInlineInspectionsPipePartsInspectionIdGroupGet(
          rootState.selectedInspectionId,
          rootState.selectedGroup
        )
      );

      const pipeParts = await requests.get("pipePartsRequest");
      commit("setPipeParts", pipeParts);
      dispatch("initFilters");
      requests.delete("pipePartsRequest");
    } catch (error) {
      if (error.name !== "CancelError") {
        console.log(error);
        toast.error(`Read PipeParts - ${error.message}`, {
          position: "top-right",
        });
      }
    } finally {
      commit("setLoading", false);
    }
  },

  setSelectedViewFactor({ commit }, viewFactor) {
    commit("setSelectedLegend", null);
    commit("setSelectedViewFactor", viewFactor);
  },

  toggleSelectedLegend({ commit, state }, legend) {
    if (state.selectedLegend === legend) {
      commit("setSelectedLegend", null);
      return;
    }

    commit("setSelectedLegend", legend);
  },

  initFilters({ commit, getters }) {
    commit("setFilters", {
      selectedSafetyRange: getters.minMaxSafetyRange,
      selectedLowerSafetyRange: getters.minMaxLowerSafetyRange,
      selectedUpperSafetyRange: getters.minMaxUpperSafetyRange,
    });
  },

  cancelAllRequests() {
    for (const request of requests.values()) {
      request.cancel();
    }
  },

  resetPlotlyFilter({ commit }) {
    commit("setFilters", { plotlyFilter: INITIAL_PLOTLY_FILTER });
  },
};
