import { i18n } from "@/config/i18n";

import { getSafetyFactorOptions } from "@/pages/safety-factor/config";

export const INITIAL_PLOTLY_FILTER = { property: "", range: [] };

export const INITIAL_SAFETY_FACTOR_FILTERS = {
  selectedMainUncertainties: [],
  selectedSafetyRange: [],
  selectedLowerSafetyRange: [],
  selectedUpperSafetyRange: [],
  plotlyFilter: {
    property: "",
    range: [],
  },
};

export const safetyFactorState = {
  pipeParts: [],
  isLoading: false,
  selectedPipePartId: null,
  filters: INITIAL_SAFETY_FACTOR_FILTERS,
  selectedViewFactor: getSafetyFactorOptions(i18n.global.t)[1].value,
  selectedLegend: null,
};
