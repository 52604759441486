/**
 * This function searches for all materials of anomalies and returns an array of unique materials
 * @param {object[]} anomalies - array of anomalies
 * @return {string[]} - array of anomalies materials
 */
export function getAllMaterials(anomalies) {
  const allMaterials = [];
  anomalies.forEach((an) => {
    an.pipe_parts.forEach((pp) => allMaterials.push(pp.material));
  });
  return Array.from(new Set(allMaterials));
}
