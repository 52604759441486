<template>
  <v-layout class="layout">
    <v-navigation-drawer
      :rail="rail"
      @update:rail="onRail"
      expand-on-hover
      v-model="drawer"
      permanent
      class="drawer"
      theme="dark"
    >
      <v-list nav>
        <v-img
          :width="300"
          aspect-ratio="16/9"
          cover
          src="img/no-payoff.png"
          class="logo"
        ></v-img>
        <v-btn
          class="rail-toggler"
          variant="text"
          :icon="rail ? 'mdi-view-list' : 'mdi-dots-vertical'"
          @click.stop="rail = !rail"
        />
        <v-divider :thickness="2"></v-divider>
        <v-list-item
          prepend-icon="mdi-view-dashboard"
          :title="$t('side_bar.routes_name.dashboard')"
          to="/"
        ></v-list-item>
        <template v-if="selectedPipeline">
          <v-list-item
            :disabled="!selectedInspectionStats"
            prepend-icon="mdi-chart-box-outline"
            :title="$t('side_bar.routes_name.anomalies')"
            to="/anomalies"
          ></v-list-item>
          <v-list-item
            :disabled="!selectedInspectionStats"
            prepend-icon="mdi-chart-box-outline"
            :title="$t('side_bar.routes_name.joints')"
            to="/joints"
          ></v-list-item>
          <v-list-item
            :disabled="!selectedInspectionStats"
            prepend-icon="mdi-chart-box-outline"
            :title="$t('side_bar.routes_name.pipe_parts')"
            to="/pipe-parts"
          ></v-list-item>
          <v-list-item
            :disabled="
              !selectedInspectionStats ||
              !selectedInspectionStats.safety_factor_available
            "
            prepend-icon="mdi-chart-box-outline"
            :title="$t('side_bar.routes_name.safety_factor')"
            to="/safety-factor"
          ></v-list-item>
        </template>
        <v-list-item
          v-if="scannersService"
          prepend-icon="mdi-map-marker-multiple-outline"
          :title="$t('side_bar.routes_name.scanner_control')"
          to="/scanner-control"
        ></v-list-item>
        <v-list-item
          prepend-icon="mdi-cog"
          :title="$t('side_bar.routes_name.settings')"
          to="/settings"
        ></v-list-item>
        <v-list-item @click="onClickShowScanners" :active="false">
          <template v-slot:prepend>
            <div class="switch">
              <v-switch
                :model-value="showMapScanners"
                :hide-details="true"
                :loading="
                  isLoadingPipeScanners[ScannerType.Results] ? 'warning' : false
                "
                :disabled="isLoadingPipeScanners[ScannerType.Results]"
                density="compact"
              ></v-switch>
            </div>
          </template>
          <template v-slot:title>{{ $t("side_bar.show_scanners") }} </template>
        </v-list-item>
        <v-list-item @click="SWITCH_MAPMODE" :active="false">
          <template v-slot:prepend>
            <div class="switch">
              <v-switch
                :model-value="isMapMode"
                :hide-details="true"
                density="compact"
              ></v-switch>
            </div>
          </template>
          <template v-slot:title>{{ $t("side_bar.map_mode") }} </template>
        </v-list-item>
        <NavBarGroupItem
          :showNested="showNested"
          :serviceGroups="serviceGroups"
          :selectedGroup="selectedGroup"
          @onSelect="onNavigationGroupSelect"
        ></NavBarGroupItem>
        <v-divider :thickness="2"></v-divider>
        <NavBarAdminItem
          v-if="isAdmin"
          :showNested="showNested"
        ></NavBarAdminItem>
        <v-list-item
          prepend-icon="mdi-login-variant"
          title="Logout"
          @click="logout"
        ></v-list-item>
      </v-list>
    </v-navigation-drawer>
    <v-main :class="{ expanded: rail }">
      <slot></slot>
    </v-main>
  </v-layout>
</template>

<script>
import { computed } from "vue";
import { mapState, mapGetters, mapActions, mapMutations } from "vuex";
import jwt_decode from "jwt-decode";

import NavBarGroupItem from "./NavBarGroupItem.vue";
import NavBarAdminItem from "./NavBarAdminItem.vue";

import { OpenAPI, DefaultService } from "@/open-api-code/ili-api";
import {
  INLINE_INSPECTIONS,
  PIPESCANNER_INSPECTIONS,
} from "@/features/administration/constants";
import { getGroupAvailableServices } from "./client.api.js";

import { RESULTS } from "@/features/pipescanner/constants";
import { ScannerType } from "@/pages/scanner-control/config";

export default {
  inject: ["isAdmin"],

  components: {
    NavBarGroupItem,
    NavBarAdminItem,
  },

  provide() {
    return {
      isLoadingPipelines: computed(() => this.isLoadingPipelines),
    };
  },

  setup() {
    return {
      ScannerType,
    };
  },

  data() {
    return {
      drawer: true,
      rail: false,
      showNested: false,
      scannersService: null,
      isLoadingPipelines: true,
      isLoadingResultScanners: false,
      requests: new Map(),
    };
  },

  mounted() {
    this.fetchPermissionsAndGroups();
  },

  computed: {
    ...mapState([
      "isMapMode",
      "serviceGroups",
      "selectedGroup",
      "showMapScanners",
      "selectedInspectionStats",
    ]),

    ...mapState("pipeScanners", {
      isLoadingPipeScanners: "isLoading",
    }),

    ...mapState("pipeScanners", ["pipeScanners"]),

    ...mapGetters(["selectedPipeline"]),
  },

  methods: {
    ...mapMutations([
      "SET_USER_PERMISSIONS",
      "SET_SELECTED_GROUP",
      "SET_GROUP_PIPELINES",
      "SET_EXPORT_SETTINGS",
      "SWITCH_MAPMODE",
      "SWITCH_MAP_SCANNERS",
      "SET_SCANNERS",
    ]),

    ...mapActions(["CLEAR_ALL_SELECTED_DATA", "SET_USER_GROUPS"]),

    ...mapActions("pipeScanners", ["fetchResultsScanners"]),

    logout() {
      this.$auth0.logout({
        logoutParams: { returnTo: window.location.origin },
      });
    },

    onNavigationGroupSelect(group) {
      if (group !== this.selectedGroup) {
        this.isLoadingPipelines = true;

        this.CLEAR_ALL_SELECTED_DATA();
        this.SET_SELECTED_GROUP(group);

        if (this.$router.currentRoute.value.path !== "/") {
          this.$router.push("/");
        }

        this.getGroupData();
      }
    },

    onRail(value) {
      if (this.rail && value) {
        this.showNested = false;
      } else {
        this.showNested = true;
      }
    },

    onClickShowScanners() {
      if (this.isLoadingPipeScanners[ScannerType.Results]) {
        return;
      }

      this.SWITCH_MAP_SCANNERS();

      if (this.showMapScanners && this.pipeScanners[RESULTS].length === 0) {
        this.fetchResultsScanners();
      }
    },

    async fetchPermissionsAndGroups() {
      const token = await this.$auth0.getAccessTokenSilently();
      if (token) {
        OpenAPI.TOKEN = token;
        const payload = jwt_decode(token);
        this.SET_USER_PERMISSIONS(payload.permissions);
        const groupKey = "https://acqgroups.cloud/groups";
        if (Object.prototype.hasOwnProperty.call(payload, groupKey)) {
          this.SET_USER_GROUPS(payload[groupKey]);
          this.getGroupData();
        }
      } else {
        console.log("No token, try to log in!");
      }
    },

    async getGroupData() {
      try {
        if (this.requests.has("availableServicesRequest")) {
          this.requests.get("availableServicesRequest").abort();
        }
        if (this.requests.has("pipelinesRequest")) {
          this.requests.get("pipelinesRequest").cancel();
        }
        if (this.requests.has("scannersRequest")) {
          this.requests.get("scannersRequest").abort();
        }

        const token = await this.$auth0.getAccessTokenSilently();
        const controller = new AbortController();
        this.requests.set("availableServicesRequest", controller);
        const services = await getGroupAvailableServices(
          token,
          this.selectedGroup,
          controller
        );

        const pipesAccess = services.some(
          ({ service }) => service === INLINE_INSPECTIONS
        );
        if (pipesAccess) {
          this.getPipelines();
        } else {
          this.isLoadingPipelines = false;
        }

        this.scannersService = services.find(
          ({ service }) => service === PIPESCANNER_INSPECTIONS
        );
        if (this.scannersService) {
          this.SET_EXPORT_SETTINGS(this.scannersService.exportSettings);
          if (this.showMapScanners) {
            this.fetchResultsScanners();
          }
        }
      } catch (error) {
        if (error.name !== "CanceledError") {
          console.error(error);
          this.$toast.error(`Read services - ${error.message}`, {
            position: "top-right",
          });
          this.isLoadingPipelines = false;
        }
      }
    },

    async getPipelines() {
      try {
        const token = await this.$auth0.getAccessTokenSilently();
        if (token) {
          this.requests.set(
            "pipelinesRequest",
            DefaultService.readPipelinesInlineInspectionsPipelinesGroupGet(
              this.selectedGroup
            )
          );
          const pipelines = await this.requests.get("pipelinesRequest");
          this.SET_GROUP_PIPELINES(pipelines);
          this.isLoadingPipelines = false;
        } else {
          console.error("No token, try to log in!");
        }
      } catch (error) {
        if (error.name !== "CancelError") {
          console.error(error);
          this.$toast.error(`Read Inspection Stats - ${error.message}`, {
            position: "top-right",
          });
          this.isLoadingPipelines = false;
        }
      }
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/scss/variables.scss";

.layout {
  height: 100vh;

  .v-main {
    transition: width 0.5s;
    padding: 0 !important;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 256px;
    right: 0;

    &.expanded {
      left: 56px;
    }
  }
}

.drawer {
  background: $black-primary !important;
  position: relative;
  z-index: 10000 !important;

  .logo {
    margin: 15px 0;
  }

  div.switch {
    width: 56px;
  }

  :deep(.rail-toggler) {
    position: absolute;
    top: 15px;
    left: 200px;
  }

  :deep(.v-list-item) {
    border-radius: 10px;
    margin-top: 6px;
    cursor: pointer;
    &:hover {
      background: #3b4151;
    }
  }

  :deep(.v-list-item--active) {
    background: linear-gradient(60deg, $cyan-main, $cyan-dark);
  }

  :deep(.v-list-item-title) {
    font-size: 14px !important;
  }
}
</style>
