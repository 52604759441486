<template>
  <div>
    <h3>{{ $t("analyser.mapping.measured_distance") }}</h3>
    <div v-if="isLoading" class="loader">
      <v-progress-circular
        color="dark-blue"
        indeterminate
        :size="52"
        :width="5"
      ></v-progress-circular>
    </div>
    <PlotlyGraph
      v-else
      :data="plotlyData"
      :layout="{
        height: 450,
        showlegend: true,
        margin: {
          t: 20,
          b: 70,
          l: 60,
          r: 30,
        },
      }"
    />
  </div>
</template>

<script>
import { PlotlyGraph } from "@/components";

export default {
  name: "OdoDistanceChart",
  components: {
    PlotlyGraph,
  },
  props: {
    isLoading: Boolean,
    odoDistance: Array,
  },

  computed: {
    plotlyData() {
      if (this.isLoading || this.odoDistance.length === 0) {
        return [];
      }
      return this.odoDistance.map(({ distance, name }) => {
        return {
          y: distance,
          mode: "lines",
          type: "scatter",
          name: name,
        };
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.loader {
  height: 450px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
