<template>
  <div class="wrap">
    <div class="cylinder-actions">
      <v-switch
        :model-value="showCylinder"
        density="compact"
        :label="$t('anomalies.details.cylinder.show')"
        hide-details
        @update:modelValue="toggleDisplayCylinder"
      ></v-switch>
      <v-select
        v-model="plotDataModel"
        :items="plotDataOptions"
        variant="underlined"
        item-title="title"
        item-value="value"
        density="compact"
        hide-details
        :disabled="!selectedAnomaly || isCylinderLoading || !showCylinder"
      ></v-select>
    </div>
    <div v-if="!selectedAnomaly" class="blank">
      <p>{{ $t("anomalies.details.cylinder.no_selected") }}</p>
    </div>
    <div v-else-if="isCylinderLoading" class="blank">
      <v-progress-circular
        color="dark-blue"
        indeterminate
        :size="52"
        :width="5"
      ></v-progress-circular>
    </div>
    <div v-else-if="!showCylinder" class="blank">
      <p>{{ $t("anomalies.details.cylinder.hidden") }}</p>
    </div>
    <div v-else-if="!anomalyCylinderData.length" class="blank">
      <p>{{ $t("anomalies.details.cylinder.bad_data") }}</p>
    </div>
    <div v-else class="cylinder-container" @mousedown.stop>
      <PlotlyGraph
        :data="anomalyCylinderData"
        :layout="cylinderLayout"
        :config="{ displayModeBar: false }"
      />
    </div>
  </div>
</template>

<script>
import { PlotlyGraph } from "@/components";
import { mapState, mapGetters } from "vuex";
import { OpenAPI, DefaultService } from "@/open-api-code/ili-api";
import { anomalyTypeColors } from "@/colorLegends.js";
import {
  reorderingArray,
  splitCalc,
  fillGaps,
  addGradient,
  upDistance,
  getClockPosition,
} from "@/utils/cylinder.js";

export default {
  name: "AnomaliesCylinder",
  components: {
    PlotlyGraph,
  },
  emits: ["changePoint"],
  setup() {
    return {
      anomalyTypeColors,
    };
  },
  data() {
    return {
      anomalyDetails: null,
      anomalyCylinderData: [],
      selectPlotData: "wall_thickness_remaining",
      showCylinder: true,
      isCylinderLoading: false,
    };
  },
  mounted() {
    if (this.selectedAnomaly) {
      this.getAnomalyDetailed(this.selectedAnomaly.id);
    }
  },
  computed: {
    ...mapState(["selectedInspectionStats", "selectedGroup"]),
    ...mapGetters("anomalies", ["selectedAnomaly", "filteredAnomalies"]),

    plotDataModel: {
      get() {
        return this.selectPlotData;
      },
      set(plotType) {
        this.selectPlotData = plotType;
        this.getCylinderData();
      },
    },

    plotDataOptions() {
      return [
        {
          value: "wall_thickness_remaining",
          title: this.$t(
            "anomalies.details.plotly_select.wall_thickness.title"
          ),
        },
        {
          value: "inner_radius",
          title: this.$t("anomalies.details.plotly_select.inner_radius.title"),
        },
      ];
    },

    cylinderLayout() {
      let zaxis = [0];

      if (this.selectedAnomaly && this.anomalyCylinderData.length) {
        zaxis = this.anomalyCylinderData[0].z;
      }

      return {
        width: 400,
        margin: {
          l: 0,
          r: 10,
          b: 10,
          t: 10,
          pad: 0,
        },
        scene: {
          hovermode: false,
          xaxis: {
            color: "rgba(255, 255, 255, 0)",
            range: [-4, 4],
          },
          yaxis: {
            color: "rgba(255, 255, 255, 0)",
            range: [-4, 4],
          },
          zaxis: {
            title: `${this.$t(
              "anomalies.details.cylinder.zaxis_name"
            )} (${this.$units.getAbbr("m")})
                `,
            color: "rgba(0, 0, 0, 1)",
            range: [[zaxis[0], zaxis[zaxis.length - 2]]],
          },
        },
      };
    },
  },

  methods: {
    toggleDisplayCylinder() {
      this.showCylinder = !this.showCylinder;
      if (!this.showCylinder) {
        this.isCylinderLoading = false;
      }
      if (
        this.showCylinder &&
        this.selectedAnomaly &&
        this.anomalyDetails?.id !== this.selectedAnomaly.id
      ) {
        this.getAnomalyDetailed(this.selectedAnomaly.id);
      }
    },

    useCylinderWorker(data) {
      return new Promise((resolve) => {
        const worker = new Worker(
          new URL("@/pages/anomaly/workers/createCylinder.js", import.meta.url)
        );
        worker.postMessage(data);
        worker.onmessage = function (event) {
          worker.terminate();
          resolve(event.data);
        };
      });
    },

    async getCylinderData() {
      if (
        !this.anomalyDetails ||
        !this.anomalyDetails.anomaly_detail.inner_radius ||
        !this.anomalyDetails.anomaly_detail.wall_thickness_remaining
      ) {
        this.anomalyCylinderData = [
          {
            type: "mesh3d",
            intensitymode: "cell",
            colorscale: "Jet",
            x: [],
            y: [],
            z: [],
            i: [],
            j: [],
            k: [],
            intensity: [],
          },
        ];
      }

      const { inner_radius, wall_thickness_remaining, distance } =
        this.anomalyDetails.anomaly_detail;

      const arrayToOrder =
        this.selectPlotData === "inner_radius"
          ? inner_radius
          : wall_thickness_remaining;

      if (arrayToOrder.length === 0) {
        this.anomalyCylinderData = [];
      } else {
        // reordering the "wall_thickness_remaining" or "inner_radius" array
        const orderedArr = reorderingArray(arrayToOrder);
        const { splitByCols, splitByRows } = splitCalc(orderedArr);
        const noGapsData = fillGaps(orderedArr);

        const convertDistance = distance?.map((d) =>
          this.$units.convert(d, "m")
        );
        const newDistance = upDistance(convertDistance, splitByCols);

        const withGradientData = addGradient(
          noGapsData,
          splitByCols,
          splitByRows
        );

        const cylinderData = {
          withGradientData,
          radius: 3,
          numOfDegrees: 360,
          newDistance,
        };
        const cylinder = await this.useCylinderWorker(cylinderData);

        const clockPosition = getClockPosition(
          cylinder,
          splitByRows,
          splitByCols,
          noGapsData[0].length + 1
        );
        this.anomalyCylinderData = [
          {
            type: "mesh3d",
            intensitymode: "cell",
            colorscale: "Jet",
            ...cylinder,
          },
          {
            type: "scatter3d",
            textposition: "top center",
            hoverinfo: "none",
            mode: "markers+text",
            marker: {
              color: "black",
              size: 1.5,
              symbol: "x",
            },
            ...clockPosition,
          },
        ];
      }
    },

    async getAnomalyDetailed(id) {
      try {
        this.isCylinderLoading = true;

        const token = await this.$auth0.getAccessTokenSilently();
        OpenAPI.TOKEN = token;
        const details =
          await DefaultService.readAnomalyDetailedInlineInspectionsAnomaliesDetailedAnomalyIdGroupGet(
            id,
            this.selectedGroup
          );

        this.anomalyDetails = details;
        await this.getCylinderData();
        this.isCylinderLoading = false;
      } catch (error) {
        console.log(error);
        this.$toast.error(`Read Anomaly detail - ${error.message}`, {
          position: "top-right",
        });
      }
    },
  },

  watch: {
    selectedAnomaly(anomaly) {
      if (anomaly && this.showCylinder) {
        this.getAnomalyDetailed(anomaly.id);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.wrap {
  flex-grow: 1;
  height: 450px;
  .cylinder-actions {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 0 6px 6px 14px;
    .v-select {
      width: 250px;
    }
    .v-switch {
      position: relative;
      top: 6px;
    }
  }
}
.cylinder-container {
  width: 400px;
  margin: 0 auto;
}
.cylinder-container,
.blank {
  height: calc(100% - 47px);
}

.blank {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem;

  text-align: center;
  font-size: 1.2rem;
  font-weight: bold;
  color: darkgrey;
  background-color: whitesmoke;
  border-radius: 10px;
}
</style>
