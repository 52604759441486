export function shouldDisableCheckbox(
  currentId,
  elementsArray,
  checkedSelector
) {
  if (checkedSelector.size === 0) {
    return false;
  }
  const currentIndex = elementsArray.findIndex(({ id }) => id === currentId);

  const firstSelectedId = checkedSelector.value[0];
  const firstSelectedIndex = elementsArray.findIndex(
    ({ id }) => id === firstSelectedId
  );
  if (firstSelectedIndex === -1) {
    return false;
  }

  if (checkedSelector.size === 1) {
    const inRange =
      (currentIndex <= firstSelectedIndex && currentIndex > firstSelectedIndex - 3) ||
      (currentIndex >= firstSelectedIndex && currentIndex < firstSelectedIndex + 3);
    return !inRange;
  }

  const secondSelectedId = checkedSelector.value[1];
  const secondSelectedIndex = elementsArray.findIndex(
    ({ id }) => id === secondSelectedId
  );
  if (secondSelectedIndex === -1) {
    return false;
  }

  if (checkedSelector.size === 2) {
    const difference = firstSelectedIndex - secondSelectedIndex;
    if (difference === 1) {
      const inRange =
        (currentIndex <= firstSelectedIndex && currentIndex > firstSelectedIndex - 3) ||
        (currentIndex >= firstSelectedIndex && currentIndex < firstSelectedIndex + 2);
      return !inRange;
    }

    if (difference === -1) {
      const inRange =
        (currentIndex <= secondSelectedIndex && currentIndex > secondSelectedIndex - 3) ||
        (currentIndex >= secondSelectedIndex && currentIndex < secondSelectedIndex + 2);
      return !inRange;
    }

    if (difference === 2) {
      const inRange =
        (currentIndex <= firstSelectedIndex && currentIndex > firstSelectedIndex - 3) ||
        (currentIndex >= firstSelectedIndex && currentIndex < firstSelectedIndex + 1);
      return !inRange;
    }

    if (difference === -2) {
      const inRange =
        (currentIndex <= secondSelectedIndex && currentIndex > secondSelectedIndex - 3) ||
        (currentIndex >= secondSelectedIndex && currentIndex < secondSelectedIndex + 1);
      return !inRange;
    }
  }

  const thirdSelectedId = checkedSelector.value[2];
  const thirdSelectedIndex = elementsArray.findIndex(
    ({ id }) => id === thirdSelectedId
  );
  if (thirdSelectedIndex === -1) {
    return false;
  }

  return ![
    firstSelectedIndex,
    secondSelectedIndex,
    thirdSelectedIndex,
  ].includes(currentIndex);
}
