import { useToast } from "vue-toast-notification";
import { auth0 } from "@/config/auth0";
import { DefaultService, OpenAPI } from "@/open-api-code/ili-api";

import { INITIAL_PLOTLY_FILTER } from "@/store/pipeParts/state";

const toast = useToast();
const requests = new Map();

export const pipePartsActions = {
  async getPipeParts({ commit, dispatch, rootState }) {
    try {
      commit("setLoading", true);

      OpenAPI.TOKEN = await auth0.getAccessTokenSilently();

      requests.set(
        "pipePartsRequest",
        DefaultService.readPipePartsInlineInspectionsPipePartsInspectionIdGroupGet(
          rootState.selectedInspectionId,
          rootState.selectedGroup
        )
      );

      const pipeParts = await requests.get("pipePartsRequest");
      requests.delete("pipePartsRequest");
      commit("setPipeParts", pipeParts);
      dispatch("initFilters");
      dispatch("checkWTRemainigLevels");
    } catch (error) {
      if (error.name !== "CancelError") {
        console.log(error);
        toast.error(`Read PipeParts - ${error.message}`, {
          position: "top-right",
        });
      }
    } finally {
      commit("setLoading", false);
    }
  },

  async getAnomalies({ commit, rootState }) {
    try {
      OpenAPI.TOKEN = await auth0.getAccessTokenSilently();

      requests.set(
        "anomaliesRequest",
        DefaultService.readAnomaliesInlineInspectionsAnomaliesInspectionIdGroupGet(
          rootState.selectedInspectionId,
          rootState.selectedGroup
        )
      );

      const anomalies = await requests.get("anomaliesRequest");
      requests.delete("anomaliesRequest");
      commit("setAnomalies", anomalies);
    } catch (error) {
      if (error.name !== "CancelError") {
        console.error(error);
        toast.error(`Read Anomalies - ${error.message}`, {
          position: "top-right",
        });
      }
    }
  },

  toggleSelectedLegend({ commit, state }, legend) {
    if (state.selectedLegend === legend) {
      commit("setSelectedLegend", null);
      return;
    }

    commit("setSelectedLegend", legend);
  },

  checkWTRemainigLevels({ state, commit }) {
    const pipeParts = state.pipeParts;

    for (const level in state.wallThicknessRemaining) {
      const levelKey = `wall_thickness_remaining_${level}`;

      const pipePartWithMultipleLayers = pipeParts.find(
        (pipePart) =>
          pipePart.hasOwnProperty(levelKey) &&
          pipePart[levelKey][0].length > 1 &&
          pipePart.material_layers.length
      );

      const levelHasMultipleLayers = pipePartWithMultipleLayers !== undefined;
      let newLevelLength = 1;

      if (levelHasMultipleLayers) {
        newLevelLength = pipePartWithMultipleLayers[levelKey][0].length;
      }

      const levelChanges = {
        isMultipleLayers: levelHasMultipleLayers,
        length: newLevelLength,
      };

      commit("setWallThicknessForLevel", { level, changes: levelChanges });
    }
  },

  resetPlotlyFilter({ commit }) {
    commit("setFilters", { plotlyFilter: INITIAL_PLOTLY_FILTER });
  },

  toggleAnomaliesVisibility({ state, commit }) {
    commit("setShowAnomalies", !state.showAnomalies);
  },

  initFilters({ commit, getters }) {
    commit("setFilters", {
      selectedDistance: getters.minMaxDistance,
      selectedOvalityMax: getters.minMaxOvalityMax,
      selectedOvalityMean: getters.minMaxOvalityMean,
      selectedDeformationHor: getters.minMaxDeformationHor,
      selectedDeformationVer: getters.minMaxDeformationVer,
    });
  },

  cancelAllRequests() {
    for (const request of requests.values()) {
      request.cancel();
    }
  },
};
