<template>
  <div class="wrapper">
    <div class="map-block">
      <v-select
        :model-value="selectedGradientMode"
        @update:modelValue="(value) => setSelectedGradientMode(value)"
        :items="gradientOptions"
        item-title="title"
        item-value="value"
        variant="underlined"
        hide-details
      >
      </v-select>
      <div style="height: 450px">
        <ModifiedMapbox
          ref="modifiedMapRef"
          hideMapControls
          style="border-radius: 4px"
        >
          <MapboxMarker
            v-if="selectedJoint"
            :lng-lat="selectedJoint.geom.coordinates"
            color="orange"
          />
          <template v-slot:sources>
            <template
              v-for="{ id, sourceOptions, layerOptions } in jointsMapData"
              :key="id"
            >
              <MapboxSource :id="id" :options="sourceOptions" />
              <MapboxLayer
                :id="layerOptions.id"
                :options="layerOptions"
                @mb-mouseenter="onLayerMouseEnter"
                @mb-mouseleave="onLayerMouseLeave"
                @mb-click="onLayerClick"
              />
            </template>
          </template>
          <JointsLegend
            v-if="!showGradient"
            class="legend"
            :title="legendTitle"
          />
          <MapInfoPopup
            v-if="selectedJoint && showMapInfo"
            :closePopup="closeMapInfo"
            class="map-info"
            :data="selectedJoint"
            type="joint"
          />
          <v-btn
            v-if="selectedJoint && !showMapInfo"
            class="open-info-btn"
            icon="mdi-open-in-new"
            size="x-small"
            @click="openMapInfo"
          ></v-btn>
        </ModifiedMapbox>
      </div>
    </div>
    <div v-if="showGradient">
      <JointsGradient :gradientColor="gradientColor" />
    </div>
  </div>
</template>

<script>
import { ref } from "vue";

import { mapState, mapMutations, mapGetters } from "vuex";

import {
  MapboxMarker,
  MapboxLayer,
  MapboxSource,
} from "@studiometa/vue-mapbox-gl";
import { MapInfoPopup, ModifiedMapbox } from "@/components";
import { JointsGradient, JointsLegend } from "@/pages/joints/components";

export default {
  name: "JointsMap",

  props: {
    jointsMapData: Array,
    gradientOptions: Array,
    gradientColor: Array,
  },

  components: {
    MapboxMarker,
    MapboxLayer,
    MapboxSource,
    MapInfoPopup,
    JointsGradient,
    JointsLegend,
    ModifiedMapbox,
  },

  setup() {
    const showMapInfo = ref(true);
    const openMapInfo = () => {
      showMapInfo.value = true;
    };
    const closeMapInfo = () => {
      showMapInfo.value = false;
    };

    return {
      showMapInfo,
      openMapInfo,
      closeMapInfo,
    };
  },

  computed: {
    ...mapState("joints", ["selectedGradientMode", "selectedLegend"]),

    ...mapGetters("joints", ["selectedJoint"]),

    showGradient() {
      return !["degradation_level", "material", "joint_type"].includes(
        this.selectedGradientMode
      );
    },

    legendTitle() {
      return this.gradientOptions.find(
        (opt) => opt.value === this.selectedGradientMode
      )?.title;
    },

    map() {
      return this.$refs.modifiedMapRef.map;
    },
  },

  methods: {
    ...mapMutations("joints", [
      "setSelectedGradientMode",
      "setSelectedJointId",
    ]),

    onLayerClick({ features }) {
      if (features && features[0].source.includes("joints")) {
        const selectedId = features[0]?.properties?.id;
        this.setSelectedJointId(selectedId);
      }
    },

    onLayerMouseEnter({ features, target }) {
      if (features && features[0].source.includes("joints")) {
        target.getCanvas().style.cursor = "pointer";
      }
    },

    onLayerMouseLeave({ target }) {
      target.getCanvas().style.cursor = "";
    },
  },

  watch: {
    selectedJoint(joint) {
      if (joint) {
        this.map.flyTo({
          center: joint.geom.coordinates,
          zoom: 17,
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.wrapper {
  display: flex;
  .map-block {
    width: 100%;
    position: relative;
    margin-bottom: 1.2rem;
    .v-select {
      padding: 0 0.4rem;
      margin-bottom: 0.8rem;
    }
    .legend {
      position: absolute;
      z-index: 5;
      bottom: 25px;
      right: 5px;
      opacity: 0.9;
      user-select: none;
    }
    .map-info {
      position: absolute;
      bottom: 6px;
      left: 5px;
      border-radius: 6px;
      background-color: white;
      opacity: 0.9;
    }
    .open-info-btn {
      position: absolute;
      bottom: 6px;
      left: 5px;
      opacity: 0.8;
      &:hover {
        opacity: 1;
      }
    }
  }
}
</style>
