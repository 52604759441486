<template>
  <div v-if="selectedPipeline" class="container">
    <v-card rounded="lg">
      <v-card-title class="font-weight-thin">
        {{ $t("joints.table_name") }}
        <i>
          {{ inspectionTitle }}
        </i>
      </v-card-title>
      <v-card-text :class="[isLoading ? '' : 'pt-6px']">
        <div v-if="isLoading" class="text-center">
          <v-progress-circular
            color="dark-blue"
            indeterminate
            :size="52"
            :width="5"
          ></v-progress-circular>
        </div>
        <div v-else>
          <div class="filters-containers">
            <div class="filter-selectors">
              <v-select
                :label="$t('joints.filters.all_types')"
                :model-value="jointFilters.selectedJointTypes"
                @update:modelValue="
                  (value) => setFilters({ selectedJointTypes: value })
                "
                :items="jointTypeOptions"
                multiple
                variant="underlined"
                hide-details
              >
              </v-select>
              <v-select
                :label="$t('joints.filters.materials')"
                :model-value="jointFilters.selectedMaterials"
                @update:modelValue="
                  (value) => setFilters({ selectedMaterials: value })
                "
                :items="materialOptions"
                multiple
                variant="underlined"
                hide-details
              >
              </v-select>
            </div>
            <div class="filter-sliders">
              <div class="slider" style="padding-right: 0.7rem">
                <RangeSlider
                  :title="$t('joints.filters.vertical_angle')"
                  :model-value="jointFilters.selectedVerticalAngle"
                  @update:modelValue="
                    (value) => setFilters({ selectedVerticalAngle: value })
                  "
                  :max="maxVerticalAngle"
                  :min="0"
                  :step="0.01"
                  lazy
                ></RangeSlider>
              </div>
              <div class="slider" style="padding-right: 1rem">
                <RangeSlider
                  :title="$t('joints.filters.horizontal_angle')"
                  :model-value="jointFilters.selectedHorizontalAngle"
                  @update:modelValue="
                    (value) => setFilters({ selectedHorizontalAngle: value })
                  "
                  :max="maxHorizontalAngle"
                  :min="0"
                  :step="0.01"
                  lazy
                ></RangeSlider>
              </div>
              <div class="slider">
                <RangeSlider
                  :title="$t('joints.filters.gap_width_mean')"
                  :model-value="jointFilters.selectedGapMean"
                  @update:modelValue="
                    (value) => setFilters({ selectedGapMean: value })
                  "
                  :min="jointFilters.selectedGapMean[0]"
                  :max="jointFilters.selectedGapMean[1]"
                  :step="$units.current === 'metric' ? 1 : 0.01"
                ></RangeSlider>
              </div>
              <div class="slider">
                <RangeSlider
                  :title="$t('joints.filters.gap_width_max')"
                  :model-value="jointFilters.selectedGapMax"
                  @update:modelValue="
                    (value) => setFilters({ selectedGapMax: value })
                  "
                  :min="jointFilters.selectedGapMax[0]"
                  :max="jointFilters.selectedGapMax[1]"
                  :step="$units.current === 'metric' ? 1 : 0.01"
                ></RangeSlider>
              </div>
            </div>
          </div>
          <JointsTable />
        </div>
      </v-card-text>
    </v-card>

    <v-card v-if="!isLoading" rounded="lg">
      <v-card-title class="font-weight-thin">
        {{ $t("joints.details.title") }}
      </v-card-title>
      <v-card-text>
        <p class="text-h6">{{ $t("joints.details.map") }}</p>
        <JointsMap
          :jointsMapData="jointsMapData"
          :gradientOptions="gradientOptions"
          :gradientColor="gradientColor"
        />
        <p class="text-h6">
          {{ $t("joints.details.height_profile.title") }}
        </p>
        <JointsHeightChart />
        <JointsHistogramChart />
      </v-card-text>
    </v-card>
  </div>
</template>
<script>
import { mapState, mapGetters, mapMutations } from "vuex";

import {
  JointsTable,
  JointsHeightChart,
  JointsHistogramChart,
} from "@/pages/joints/components";
import { JointsMap } from "./components";
import { RangeSlider } from "@/components";

export default {
  name: "JointsRegular",

  props: {
    gradientOptions: Array,

    gradientColor: Array,
    jointsMapData: Array,
  },

  components: {
    JointsTable,
    RangeSlider,
    JointsMap,
    JointsHeightChart,
    JointsHistogramChart,
  },

  computed: {
    ...mapState(["selectedInspectionStats", "selectedInspectionId"]),

    ...mapGetters(["selectedPipeline"]),

    ...mapState("joints", [
      "isLoading",
      "jointsSelector",
      "selectedGradientMode",
      "selectedLegend",
      "jointFilters",
    ]),

    ...mapGetters("joints", [
      "jointTypes",
      "materials",
      "maxVerticalAngle",
      "maxHorizontalAngle",
      "minMaxGapMean",
      "minMaxGapMax",
    ]),

    inspectionTitle() {
      if (!this.selectedPipeline) return "";
      const inspectionName = this.selectedPipeline.inspections.find(
        (ins) => ins.id === this.selectedInspectionId
      )?.project_reference;
      return `- ${this.selectedPipeline.client_pipeline_id} : ${this.selectedPipeline.pipeline_name} - ${inspectionName}`;
    },

    jointTypeOptions() {
      return this.jointTypes.map((type) => ({
        value: type,
        title: this.$t(`joints.types.${type}`),
      }));
    },

    materialOptions() {
      return this.materials.map((material) => ({
        value: material,
        title: this.$t(`materials.${material}`),
      }));
    },
  },

  methods: {
    ...mapMutations("joints", ["setFilters"]),
  },
};
</script>

<style lang="scss" scoped>
.pt-6px {
  padding-top: 6px !important;
}

.filters-containers {
  .filter-selectors {
    display: flex;
    .v-select {
      padding: 0 1rem;
      width: 50%;
    }
  }
  .filter-sliders {
    display: flex;
    text-align: center;
    margin: 1rem 0;
    .slider {
      width: 25%;
    }
  }
}
</style>
