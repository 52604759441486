export function convertResultsScanners(scanners, convertator) {
  if (convertator.current.value === "metric") {
    return scanners;
  }

  const convertedScanners = scanners.map((scan) => {
    const convered = structuredClone(scan);
    ["diameter", "wallthickness_expected_validation"].forEach((key) => {
      convered[key] = convertator.convert(scan[key], "mm");
    });
    ["max", "mean", "min", "std"].forEach((key) => {
      convered.stats[key] = convertator.convert(scan.stats[key], "mm");
    });

    return convered;
  });

  return convertedScanners;
}
