<template>
  <v-list-group id="admin-nav">
    <template v-slot:activator="{ props }">
      <v-list-item
        :active="false"
        v-bind="props"
        prepend-icon="mdi-shield-account"
        title="Admin:"
      ></v-list-item>
    </template>
    <template v-if="showNested">
      <v-list-item
        prepend-icon="mdi-account-group"
        title="Client administration"
        to="/client-administration"
      ></v-list-item>
      <v-list-item
        prepend-icon="mdi-ballot-recount"
        title="Project administration"
        to="/project-administration"
      ></v-list-item>
    </template>
  </v-list-group>
</template>

<script>
export default {
  props: ["showNested"],
};
</script>

<style lang="scss">
#admin-nav {
  .v-list-group__items .v-list-item {
    padding-left: 0 !important;
    i {
      margin-left: 8px;
    }
  }
  
  .selected {
    background: #3b4151;
  }
}
</style>
