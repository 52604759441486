export const pipeScannersGetters = {
  mergedPipeScanners(state) {
    let merged = [];

    for (const item of Object.values(state.pipeScanners)) {
      merged = merged.concat(item);
    }

    return merged;
  },
};
