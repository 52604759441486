<template>
  <SubtablePopupContainer>
    <PipePartsSubtable :anomaly="anomaly" />
  </SubtablePopupContainer>
</template>

<script>
import { SubtablePopupContainer } from "@/components";
import PipePartsSubtable from "./PipePartsSubtable.vue";

export default {
  name: "PipePartsSubtablePopup",

  props: {
    anomaly: Object,
  },

  components: { SubtablePopupContainer, PipePartsSubtable },
};
</script>
