export const reorderingArray = (arr = []) => {
  if (arr.length === 0) {
    return [];
  }

  const customOrder = [8, 9, 10, 11, 12, 13, 14, 15, 0, 1, 2, 3, 4, 5, 6, 7];
  const arrWTR = arr[0].map((_, colIndex) => arr.map((row) => row[colIndex]));
  const orderedArr = customOrder.map((index) => arrWTR[index]);

  return orderedArr;
};

export const splitCalc = (arr) => {
  if (!arr || !arr.length || !arr[0].length) {
    return { splitByCols: 0, splitByRows: 0 };
  }

  const length = arr[0].length;
  const ranges = [
    { max: 2700, cols: 4, rows: 2 },
    { max: 2000, cols: 5, rows: 3 },
    { max: 1000, cols: 8, rows: 4 },
    { max: 500, cols: 10, rows: 5 },
    { max: 200, cols: 12, rows: 6 },
    { max: 100, cols: 15, rows: 10 },
  ];

  const matchedRange = ranges.find((range) => length > range.max);
  if (matchedRange) {
    return { splitByCols: matchedRange.cols, splitByRows: matchedRange.rows };
  }

  return { splitByCols: 20, splitByRows: 20 };
};

export const fillGaps = (arrayWithGaps) => {
  const array = structuredClone(arrayWithGaps);
  const allValues = array.flat().filter((el) => el !== null);
  const min = Math.min(...allValues);
  const max = Math.max(...allValues);
  const ROWS = array.length;
  const COLS = array[0].length;

  for (let i = 0; i < ROWS; i++) {
    for (let j = 0; j < COLS; j++) {
      if (array[i][j] === null) {
        let sum = 0;
        let count = 0;

        for (let m = i - 1; m <= i + 1; m++) {
          for (let n = j - 1; n <= j + 1; n++) {
            if (m >= 0 && m < ROWS && n >= 0 && n < COLS) {
              if (array[m][n] !== null) {
                sum += array[m][n];
                count++;
              }
            }
          }
        }

        if (count > 0) {
          array[i][j] = sum / count;
        } else {
          array[i][j] = (min + max) / 2;
        }
      }
    }
  }

  return array;
};

export const upDistance = (arrDistance, splitByCols) => {
  const newDistance = [];
  arrDistance.forEach((dis, j) => {
    if (j < arrDistance.length - 1) {
      const nextDis = arrDistance[j + 1];
      const step = (nextDis - dis) / splitByCols;
      newDistance.push(dis);
      for (let n = 1; n < splitByCols; n++) {
        newDistance.push(dis + step * n);
      }
    } else {
      newDistance.push(dis);
    }
  });
  return newDistance;
};

export const addGradient = (arr, splitByCols, splitByRows) => {
  const expByCol = [];
  const expByRow = [];

  arr.forEach((row) => {
    const newRow = [];
    row.forEach((cell, j) => {
      if (j < row.length - 1) {
        const nextCell = row[j + 1];
        const step = (nextCell - cell) / splitByCols;
        newRow.push(cell);
        for (let n = 1; n < splitByCols; n++) {
          newRow.push(cell + step * n);
        }
      } else {
        newRow.push(cell);
      }
    });
    expByCol.push(newRow);
  });

  expByCol.forEach((row, i) => {
    const newRows = [];
    for (let n = 0; n < splitByRows; n++) {
      newRows.push([]);
    }

    row.forEach((cell, j) => {
      const nextCell =
        i === expByCol.length - 1 ? expByCol[0][j] : expByCol[i + 1][j];
      const step = (nextCell - cell) / splitByRows;
      newRows[0].push(cell);
      for (let n = 1; n < splitByRows; n++) {
        newRows[n].push(cell + step * n);
      }
    });
    expByRow.push(...newRows);
  });

  return expByRow;
};

const DEGREES_IN_CIRCLE = 360;
const RADIANS_IN_DEGREE = Math.PI / 180;

const degreesToRadians = (degrees) => degrees * RADIANS_IN_DEGREE;

export const createCylinder = (
  heightMapData,
  radius,
  maxRotationDegrees,
  zDistances
) => {
  const cylinderTrace = {
    x: [],
    y: [],
    z: [],
    i: [],
    j: [],
    k: [],
    intensity: [],
  };

  const rotationStep = DEGREES_IN_CIRCLE / heightMapData.length;
  const numZPoints = heightMapData[0].length + 1;

  for (
    let rotationAngle = rotationStep;
    rotationAngle <= DEGREES_IN_CIRCLE;
    rotationAngle += rotationStep
  ) {
    const radianAngle = degreesToRadians(rotationAngle);
    const xCoordinate = radius * Math.cos(radianAngle);
    const yCoordinate = radius * Math.sin(radianAngle);

    for (let zIndex = 0; zIndex <= numZPoints - 1; zIndex++) {
      cylinderTrace.x.push(xCoordinate);
      cylinderTrace.y.push(yCoordinate);
      cylinderTrace.z.push(zDistances[zIndex]);
    }

    if (rotationAngle !== rotationStep && rotationAngle <= maxRotationDegrees) {
      const rotationMultiplier = rotationAngle / rotationStep;
      const zPointsStep = numZPoints;
      const startingIndex = zPointsStep * (rotationMultiplier - 2);

      for (let zIndex = 0; zIndex < numZPoints; zIndex++) {
        if (zIndex === 0) {
          cylinderTrace.i.push(startingIndex);
          cylinderTrace.j.push(startingIndex + 1);
          cylinderTrace.k.push(startingIndex + zPointsStep);
        } else if (zIndex === numZPoints - 1) {
          cylinderTrace.i.push(startingIndex + zPointsStep - 1);
          cylinderTrace.j.push(startingIndex + zPointsStep * 2 - 1);
          cylinderTrace.k.push(startingIndex + zPointsStep * 2 - 2);
        } else {
          cylinderTrace.i.push(startingIndex + zIndex, startingIndex + zIndex);
          cylinderTrace.j.push(
            zIndex + startingIndex + zPointsStep,
            startingIndex + zIndex + 1
          );
          cylinderTrace.k.push(
            startingIndex + zIndex + zPointsStep - 1,
            zIndex + startingIndex + zPointsStep
          );
        }
      }

      if (rotationAngle === maxRotationDegrees) {
        const lastZPointIndex =
          (maxRotationDegrees / rotationStep) * numZPoints;

        for (let zIndex = 0; zIndex < numZPoints; zIndex++) {
          if (zIndex === 0) {
            cylinderTrace.i.push(lastZPointIndex - numZPoints);
            cylinderTrace.j.push(lastZPointIndex - numZPoints + 1);
            cylinderTrace.k.push(0);
          } else if (zIndex === numZPoints - 1) {
            cylinderTrace.i.push(lastZPointIndex - 1);
            cylinderTrace.j.push(numZPoints - 1);
            cylinderTrace.k.push(numZPoints - 2);
          } else {
            cylinderTrace.i.push(
              startingIndex + zPointsStep + zIndex,
              startingIndex + zPointsStep + zIndex
            );
            cylinderTrace.j.push(
              zIndex,
              startingIndex + zPointsStep + zIndex + 1
            );
            cylinderTrace.k.push(zIndex - 1, zIndex);
          }
        }
      }
    }
  }

  const numFaces = maxRotationDegrees / rotationStep;

  for (let faceIndex = 0; faceIndex < numFaces; faceIndex++) {
    for (let zIndex = 0; zIndex < numZPoints - 1; zIndex++) {
      cylinderTrace.intensity.push(heightMapData[faceIndex][zIndex]);
      cylinderTrace.intensity.push(heightMapData[faceIndex][zIndex]);
    }
  }

  return cylinderTrace;
};

export const getClockPosition = (cylinder) => {
  const clockMarkers = {
    z: [],
    x: [],
    y: [],
    text: [],
  };
  const clockPosition = ["6 o'clock", "3 o'clock", "12 o'clock", "9 o'clock"];

  const highestZ = cylinder.z[cylinder.z.length - 2];

  const allHighZ = cylinder.z
    .map((el, i) => {
      if (el === highestZ) {
        return i;
      }
      return false;
    })
    .filter((el) => el);
  const step = allHighZ.length / 4;
  let pos = 0;
  allHighZ.forEach((num, i) => {
    if (i === 0 || i % step === 0) {
      clockMarkers.x.push(cylinder.x[num]);
      clockMarkers.y.push(cylinder.y[num]);
      clockMarkers.z.push(cylinder.z[num]);
      clockMarkers.text.push(clockPosition[pos]);
      pos++;
    }
  });

  return clockMarkers;
};
