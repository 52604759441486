<template>
  <ScannerControlMapmode v-if="isMapMode" />
  <ScannerControl v-else />
</template>

<script>
import { computed } from "vue";
import { mapState, mapActions, mapGetters } from "vuex";

import { ScannerControl, ScannerControlMapmode } from "./views";

export default {
  name: "ScannerControlPage",

  components: {
    ScannerControl,
    ScannerControlMapmode,
  },

  provide() {
    return {
      hasActiveFilters: computed(() => this.hasFilteredScanners),
    };
  },

  mounted() {
    this.fetchPipeScanners();
  },

  beforeUnmount() {
    this.cancelAllPipeScannerRequests();
    this.cancelAllScannerControlRequests();
  },

  computed: {
    ...mapState(["isMapMode"]),

    ...mapState("pipeScanners", ["pipeScanners"]),

    ...mapGetters("scannerControl", ["hasFilteredScanners"]),
  },

  methods: {
    ...mapActions("pipeScanners", {
      cancelAllPipeScannerRequests: "cancelAllRequests",
    }),

    ...mapActions("scannerControl", ["fetchPipeScanners"]),

    ...mapActions("scannerControl", {
      cancelAllScannerControlRequests: "cancelAllRequests",
    }),
  },
};
</script>
