<template>
  <v-menu
    :model-value="show"
    @update:modelValue="(value) => $emit('update:show', value)"
    :close-on-content-click="false"
    location="bottom"
  >
    <template v-slot:activator="{ props }">
      <v-badge
        dot
        :color="hasActiveFilters ? '#d9534f' : '#ffffff00'"
        :offset-x="6"
      >
        <v-btn
          v-bind="props"
          variant="text"
          density="compact"
          append-icon="mdi-tune"
          >{{ $t("anomalies.filters.title") }}
        </v-btn>
      </v-badge>
    </template>
    <v-card class="filter-content" :width="300" @mousedown.stop>
      <v-card-text>
        <v-select
          :label="$t('anomalies.filters.all_types')"
          :model-value="anomalyFilters.selectedAnomaliesTypes"
          @update:modelValue="
            (value) => setFilters({ selectedAnomaliesTypes: value })
          "
          :items="anomaliesTypesOptions"
          multiple
          variant="underlined"
        ></v-select>
        <div class="slider">
          <p>{{ $t("anomalies.filters.distance") }}</p>
          <RangeSlider
            :model-value="anomalyFilters.selectedDistance"
            @update:modelValue="
              (value) => setFilters({ selectedDistance: value })
            "
            :min="minMaxDistance[0]"
            :max="minMaxDistance[1]"
            :step="0.01"
          ></RangeSlider>
        </div>
        <div class="slider">
          <p>{{ $t("anomalies.filters.length") }}</p>
          <RangeSlider
            :model-value="anomalyFilters.selectedLength"
            @update:modelValue="
              (value) => setFilters({ selectedLength: value })
            "
            :min="minMaxLength[0]"
            :max="minMaxLength[1]"
            :step="0.01"
          ></RangeSlider>
        </div>
      </v-card-text>
    </v-card>
  </v-menu>
</template>
<script>
import { RangeSlider } from "@/components";
import { mapState, mapMutations, mapGetters } from "vuex";

export default {
  name: "FilterPopupMenu",
  emits: ["update:show"],

  props: {
    show: Boolean,
  },

  computed: {
    ...mapState("anomalies", ["anomalyFilters"]),
    ...mapGetters("anomalies", [
      "anomaliesTypes",
      "minMaxDistance",
      "minMaxLength",
      "hasActiveFilters",
    ]),

    anomaliesTypesOptions() {
      return this.anomaliesTypes.map((type) => ({
        value: type,
        title: this.$t(`anomalies.types.${type}`),
      }));
    },
  },

  methods: {
    ...mapMutations("anomalies", ["setFilters"]),
  },

  components: {
    RangeSlider,
  },
};
</script>

<style lang="scss" scoped>
.slider {
  width: 258px;
  padding: 8px 0px 10px;
  p {
    font-size: 1rem;
    margin-bottom: 6px;
  }
}
</style>
