import axios from "axios";

function findImagePropsInXml(xmlString, wmsLayer) {
  const legendProps = {
    src: undefined,
    width: 0,
    height: 0,
  };
  const parsedDoc = new DOMParser().parseFromString(xmlString, "text/xml");

  const layers = parsedDoc.querySelectorAll("Layer");

  for (const layer of layers) {
    const layerName = layer.querySelector("Name").textContent;
    if (layerName === wmsLayer) {
      const legendUrlElement = layer
        .querySelector("Style")
        .querySelector("LegendURL");

      legendProps.width = legendUrlElement.getAttribute("width");
      legendProps.height = legendUrlElement.getAttribute("height");

      const onlineResourceElement =
        legendUrlElement.querySelector("OnlineResource");
      legendProps.src = onlineResourceElement.getAttribute("xlink:href");

      break;
    }
  }

  return legendProps;
}

export const fetchLegend = async (option) => {
  try {
    const { base_url, layer_name } = option;
    const { data } = await axios(
      `${base_url}?request=GetCapabilities&service=WMS`
    );

    return findImagePropsInXml(data, layer_name);
  } catch (error) {
    console.error(error);
  }
};
