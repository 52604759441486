<template>
  <VDialog :attach="parent" absolute contained v-bind="$attrs">
    <slot />
  </VDialog>
</template>

<script>
import { MAP_CONTAINER_SELECTOR } from "@/constants/mapBox";

export default {
  name: "ContainedModal",

  props: {
    parent: {
      type: String,
      default: MAP_CONTAINER_SELECTOR,
    },
  },
};
</script>
