<template>
  <div v-if="selectedPipeline" class="container">
    <v-card rounded="lg">
      <v-card-title class="font-weight-thin">
        {{ $t("pipe_parts.table_name") }}
        <i>
          {{ inspectionTitle }}
        </i>
      </v-card-title>
      <v-card-text :class="[isLoading ? '' : 'pt-6px']">
        <div v-if="isLoading" class="text-center">
          <v-progress-circular
            color="dark-blue"
            indeterminate
            :size="52"
            :width="5"
          ></v-progress-circular>
        </div>
        <div v-else>
          <div class="filters-containers">
            <div class="filter-selectors">
              <v-select
                :label="$t('pipe_parts.filters.all_types')"
                :model-value="filters.selectedPipePartTypes"
                @update:modelValue="
                  (value) => setFilters({ selectedPipePartTypes: value })
                "
                :items="typeOptions"
                multiple
                variant="underlined"
                hide-details
              >
              </v-select>
              <v-select
                :label="$t('pipe_parts.filters.materials')"
                :model-value="filters.selectedMaterials"
                @update:modelValue="
                  (value) => setFilters({ selectedMaterials: value })
                "
                :items="materialOptions"
                multiple
                variant="underlined"
                hide-details
              >
              </v-select>
            </div>
            <div class="filter-sliders">
              <div class="slider">
                <RangeSlider
                  :title="$t('pipe_parts.filters.distance')"
                  :model-value="filters.selectedDistance"
                  @update:modelValue="
                    (value) => setFilters({ selectedDistance: value })
                  "
                  :min="minMaxDistance[0]"
                  :max="minMaxDistance[1]"
                  :step="0.01"
                ></RangeSlider>
              </div>
              <div class="slider">
                <RangeSlider
                  :title="$t('pipe_parts.filters.ovality_mean')"
                  :model-value="filters.selectedOvalityMean"
                  @update:modelValue="
                    (value) => setFilters({ selectedOvalityMean: value })
                  "
                  :min="minMaxOvalityMean[0]"
                  :max="minMaxOvalityMean[1]"
                  :step="0.01"
                ></RangeSlider>
              </div>
              <div class="slider">
                <RangeSlider
                  :title="$t('pipe_parts.filters.ovality_max')"
                  :model-value="filters.selectedOvalityMax"
                  @update:modelValue="
                    (value) => setFilters({ selectedOvalityMax: value })
                  "
                  :min="minMaxOvalityMax[0]"
                  :max="minMaxOvalityMax[1]"
                  :step="0.01"
                ></RangeSlider>
              </div>
              <div class="slider">
                <RangeSlider
                  :title="$t('pipe_parts.filters.axial_deform_hor')"
                  :model-value="filters.selectedDeformationHor"
                  @update:modelValue="
                    (value) => setFilters({ selectedDeformationHor: value })
                  "
                  :min="minMaxDeformationHor[0]"
                  :max="minMaxDeformationHor[1]"
                  :step="0.01"
                ></RangeSlider>
              </div>
              <div class="slider">
                <RangeSlider
                  :title="$t('pipe_parts.filters.axial_deform_ver')"
                  :model-value="filters.selectedDeformationVer"
                  @update:modelValue="
                    (value) => setFilters({ selectedDeformationVer: value })
                  "
                  :min="minMaxDeformationVer[0]"
                  :max="minMaxDeformationVer[1]"
                  :step="0.01"
                ></RangeSlider>
              </div>
            </div>
          </div>
          <PipePartsTable
            :wallThicknessRemaining="wallThicknessRemaining"
            :onChangeWTRemainingLayers="onChangeWTRemainingLayers"
          />
        </div>
      </v-card-text>
    </v-card>

    <v-card v-if="!isLoading" rounded="lg">
      <v-card-title class="font-weight-thin">
        {{ $t("pipe_parts.details.title") }}
      </v-card-title>
      <v-card-text>
        <p class="text-h6">{{ $t("pipe_parts.details.map") }}</p>
        <PipePartsMap
          :gradientColor="gradientColor"
          :gradientOptions="gradientOptions"
          :pipePartsMapData="pipePartsMapData"
          :legendTitle="histogramTitle"
        />
        <v-divider color="black" :thickness="2"></v-divider>
        <div class="d-flex">
          <PipePartsHistogram :title="histogramTitle" />
          <PipePartsRadar :title="radarTitle" />
        </div>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { mapState, mapGetters, mapMutations } from "vuex";
import {
  PipePartsTable,
  PipePartsHistogram,
  PipePartsRadar,
} from "@/pages/pipe-parts/components";
import { RangeSlider } from "@/components";
import { PipePartsMap } from "./components";

export default {
  name: "PipeParts",
  props: {
    wallThicknessRemaining: Object,
    onChangeWTRemainingLayers: Function,

    gradientOptions: Array,
    gradientColor: Array,

    pipePartsMapData: Array,

    histogramTitle: String,
    radarTitle: String,

    typeOptions: Array,
    materialOptions: Array,
  },

  components: {
    PipePartsTable,
    RangeSlider,
    PipePartsMap,
    PipePartsHistogram,
    PipePartsRadar,
  },

  computed: {
    ...mapState(["selectedInspectionId"]),

    ...mapGetters(["selectedPipeline"]),

    ...mapState("pipeParts", ["isLoading", "filters", "selectedGradientMode"]),

    ...mapGetters("pipeParts", [
      "pipePartsTypes",
      "materials",
      "minMaxDistance",
      "minMaxOvalityMean",
      "minMaxOvalityMax",
      "minMaxDeformationHor",
      "minMaxDeformationVer",
    ]),

    inspectionTitle() {
      if (!this.selectedPipeline) return "";
      const inspectionName = this.selectedPipeline.inspections.find(
        (ins) => ins.id === this.selectedInspectionId
      )?.project_reference;
      return `- ${this.selectedPipeline.client_pipeline_id} : ${this.selectedPipeline.pipeline_name} - ${inspectionName}`;
    },
  },

  methods: {
    ...mapMutations("pipeParts", ["setFilters"]),
  },
};
</script>

<style lang="scss" scoped>
.d-flex {
  display: flex;
  & > div:first-child {
    width: calc(100% - 400px);
  }
}
.pt-6px {
  padding-top: 6px !important;
}

.filters-containers {
  .filter-selectors {
    display: flex;
    .v-select {
      padding: 0 1rem;
      width: 50%;
    }
  }
  .filter-sliders {
    display: flex;
    text-align: center;
    margin: 1rem 0;
    .slider {
      width: 20%;
    }
  }
}
</style>
