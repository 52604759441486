<template>
  <div v-if="isLoading" class="loader">
    <v-progress-circular
      color="dark-blue"
      indeterminate
      :size="52"
      :width="5"
    ></v-progress-circular>
  </div>
  <PlotlyGraph v-else :data="plotlyData" :layout="plotlyLayout" />
</template>

<script>
import { PlotlyGraph } from "@/components";

export default {
  name: "FilterDistanceChart",
  components: {
    PlotlyGraph,
  },
  props: {
    isLoading: Boolean,
    currentFile: String,
  },

  computed: {
    plotlyData() {
      if (!this.currentFile) {
        return [];
      }

      const { s, wt } = this.currentFile.results;

      return [
        {
          x: s,
          y: wt,
          mode: "markers",
          type: "scatter",
          name: "WT (mm)",
        },
      ];
    },

    plotlyLayout() {
      return {
        height: 450,
        xaxis: {
          title: {
            text: "Distance (mm)",
            font: {
              family: "Nunito, sans-serif",
              size: 16,
              color: "#7f7f7f",
            },
          },
        },
        yaxis: {
          title: {
            text: "Wallthickness (mm)",
            font: {
              family: "Nunito, sans-serif",
              size: 16,
              color: "#7f7f7f",
            },
          },
        },
        margin: {
          t: 30,
          b: 50,
          l: 70,
          r: 30,
        },
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.loader {
  height: 450px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
