import { baseApi } from "@/baseApi";

import CancaledAPI from "@/utils/CanceledApi.js";

export default class UserCommentsAPI extends CancaledAPI {
  constructor(getAccessTokenSilently, groupSelected) {
    super(getAccessTokenSilently, groupSelected);
  }

  async getAll(pipelineId) {
    if (!this.token) {
      await this.updateToken();
    }
    const { signal } = this.refrershController("getAll");

    const { data } = await baseApi({
      url: `/inline-inspections/user-comment/${pipelineId}/${this.group}`,
      method: "get",
      signal,
      headers: { Authorization: `Bearer ${this.token}` },
    });

    return data;
  }

  async post(form) {
    if (!this.token) {
      await this.updateToken();
    }
    const { signal } = this.refrershController("post");

    const { data } = await baseApi({
      url: `/inline-inspections/user-comment/${this.group}`,
      method: "post",
      signal,
      headers: { Authorization: `Bearer ${this.token}` },
      data: form,
    });

    return data;
  }

  async delete(commentId) {
    if (!this.token) {
      await this.updateToken();
    }
    const { signal } = this.refrershController("delete");

    const response = await baseApi({
      url: `/inline-inspections/user-comment/${commentId}/${this.group}`,
      method: "delete",
      signal,
      headers: { Authorization: `Bearer ${this.token}` },
    });

    return response;
  }

  async getDocuments(pipelineId) {
    if (!this.token) {
      await this.updateToken();
    }
    const { signal } = this.refrershController("getDocuments");

    const { data } = await baseApi({
      url: `/inline-inspections/documents/${pipelineId}/${this.group}`,
      method: "get",
      signal,
      headers: { Authorization: `Bearer ${this.token}` },
    });

    return data;
  }

  async uploadDocument(pipelineId, file, onUploadProgress) {
    if (!this.token) {
      await this.updateToken();
    }
    const { signal } = this.refrershController("uploadDocuments");

    const response = await baseApi({
      url: `/inline-inspections/documents/${pipelineId}/${this.group}`,
      method: "post",
      signal,
      onUploadProgress,
      headers: { Authorization: `Bearer ${this.token}` },
      data: file,
    });

    return response;
  }
}
