<template>
  <AnomaliesMapmode v-if="isMapMode" />
  <Anomalies v-else />
</template>

<script>
import { computed } from "vue";
import { mapState, mapActions, mapGetters } from "vuex";
import { Anomalies, AnomaliesMapmode } from "./views";

export default {
  name: "AnomaliesPage",
  components: {
    Anomalies,
    AnomaliesMapmode,
  },
  provide() {
    return {
      hasActiveFilters: computed(() => this.hasActiveFilters), // WidgetBar
      onGenerateDigUp: this.getDigupSheet, // ContextMenu + DigUpTableBtn
      cancelDigUp: () => {
        this.checkedAnomaliesSelector.clear();
      }, // ContextMenu
      isAwaitingDigup: computed(() => this.isAwaitingDigup), // DigUpTableBtn + DigUpLoader
    };
  },

  created() {
    if (!this.selectedInspectionId) {
      this.$router.push({ name: "dashboard" });
    } else {
      this.getAnomalies();
    }
  },

  beforeUnmount() {
    this.cancellAllRequests();
  },

  computed: {
    ...mapState([
      "isMapMode",
      "selectedInspectionId",
      "selectedInspectionStats",
      "selectedGroup",
    ]),
    ...mapGetters("anomalies", ["hasActiveFilters"]),
    ...mapState("anomalies", ["checkedAnomaliesSelector", "isAwaitingDigup"]),
  },

  methods: {
    ...mapActions("anomalies", [
      "getAnomalies",
      "cancellAllRequests",
      "toggleSelectedAnomaly",
      "getDigupSheet",
    ]),
  },
};
</script>
