<template>
  <ContainedModal v-model="open" width="auto">
    <v-card>
      <v-card-title>
        {{ $t("dashboard.critical_thresholds.title") }}
      </v-card-title>
      <v-card-text v-if="loadingThresholds" class="loader-container">
        <AcqLoader />
      </v-card-text>
      <v-card-text v-else>
        <v-form ref="form">
          <div
            class="threshold-row"
            v-for="(threshold, index) in arrayThresholds"
            :key="threshold.id"
          >
            <div>
              <v-row>
                <v-col>
                  <v-select
                    :label="
                      $t(`dashboard.critical_thresholds.labels.materials`)
                    "
                    :model-value="threshold.material"
                    @update:modelValue="(value) => updateMaterial(index, value)"
                    :rules="[(v) => !!v || 'Field is required']"
                    :items="materialsOptions"
                    variant="underlined"
                    required
                  >
                  </v-select>
                </v-col>
                <v-col>
                  <v-select
                    :label="
                      $t(`dashboard.critical_thresholds.labels.diameters`)
                    "
                    v-model="threshold.diameter_class"
                    :items="selectedPipeline.diameters"
                    :rules="[(v) => !!v || 'Field is required']"
                    variant="underlined"
                    required
                  >
                  </v-select>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-text-field
                    type="number"
                    :model-value="threshold.max_gap_width"
                    @update:modelValue="
                      (value) => updateNumber('max_gap_width', index, value)
                    "
                    :label="`${$t(
                      'dashboard.critical_thresholds.labels.max_gap_width'
                    )}
            (${$units.getAbbr('mm')})`"
                    min="0"
                    :step="$units.current === 'imperial' ? '0.01' : '1'"
                    variant="underlined"
                    :rules="numberRules"
                    required
                  />
                </v-col>
                <v-col>
                  <v-text-field
                    type="number"
                    :label="
                      $t(
                        'dashboard.critical_thresholds.labels.max_angular_displacement'
                      )
                    "
                    :model-value="threshold.max_angular_displacement"
                    @update:modelValue="
                      (value) =>
                        updateNumber('max_angular_displacement', index, value)
                    "
                    min="0"
                    variant="underlined"
                    :rules="numberRules"
                    required
                  />
                </v-col>
                <v-col>
                  <v-text-field
                    type="number"
                    :label="
                      $t(
                        'dashboard.critical_thresholds.labels.max_tangential_deformation'
                      )
                    "
                    :model-value="threshold.max_tangential_deformation"
                    @update:modelValue="
                      (value) =>
                        updateNumber('max_tangential_deformation', index, value)
                    "
                    min="0"
                    variant="underlined"
                    :rules="numberRules"
                    required
                  />
                </v-col>
              </v-row>
            </div>
            <div class="delete-button">
              <v-btn
                icon="mdi-close-thick"
                @click="deleteRow(threshold)"
                size="x-small"
              >
              </v-btn>
            </div>
          </div>
        </v-form>
        <v-btn
          class="add-btn"
          icon="mdi-plus"
          @click="addRow"
          size="small"
        ></v-btn>
        <div :class="{ nonallowed: sendingThresholds }"></div>
      </v-card-text>
      <v-card-actions>
        <v-btn @click="onSave" size="large" rounded="lg">{{
          $t("common.save")
        }}</v-btn>
        <v-btn @click="open = false" size="large" rounded="lg">{{
          $t("common.cancel")
        }}</v-btn>
      </v-card-actions>
    </v-card>
  </ContainedModal>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import { OpenAPI, DefaultService } from "@/open-api-code/ili-api";
import { v4 as uuidv4 } from "uuid";

import { AcqLoader } from "@/components";
import ContainedModal from "../ContainedModal.vue";

export default {
  name: "ThresholdsModal",

  emits: ["save"],

  components: {
    AcqLoader,
    ContainedModal,
  },

  setup() {
    const EMPTY_THRESHOLD = {
      id: null,
      pipeline_id: null,
      material: "",
      diameter_class: "",
      max_angular_displacement: 0,
      max_axial_deformation: 0,
      max_gap_width: 0,
      max_tangential_deformation: 0,
      min_geographic_depth: 0,
      min_wall_thickness_relative: 0,
    };

    return {
      EMPTY_THRESHOLD,
    };
  },

  data: () => ({
    open: false,
    arrayThresholds: [],
    actualThresholdIds: [],
    loadingThresholds: false,
    sendingThresholds: false,
  }),

  computed: {
    ...mapState(["selectedGroup"]),
    ...mapGetters(["selectedPipeline"]),

    materialsOptions() {
      return this.selectedPipeline.materials.map((material) => ({
        title: this.$t(`materials.${material}`),
        value: material,
      }));
    },

    numberRules() {
      return [(v) => !!v || typeof +v === "number" || "Field is required"];
    },
  },

  methods: {
    showModal() {
      this.open = true;
      this.getCriticalThresholds();
    },

    updateMaterial(index, value) {
      this.arrayThresholds[index].material = value;
    },

    updateNumber(name, index, value) {
      this.arrayThresholds[index][name] = value || 0;
    },

    async getCriticalThresholds() {
      this.loadingThresholds = true;
      try {
        OpenAPI.TOKEN = await this.$auth0.getAccessTokenSilently();
        const thresholds =
          await DefaultService.readCriticalThresholdsInlineInspectionsCriticalThresholdsPipelineIdGroupGet(
            this.selectedPipeline.id,
            this.selectedGroup
          );
        if (thresholds?.length) {
          this.arrayThresholds = this.convertGapWidth(thresholds);
          this.actualThresholdIds = thresholds.map((t) => t.id);
        }
        this.loadingThresholds = false;
      } catch (error) {
        console.log(error);
        this.$toast.warning(`Read Critical Thresholds - ${error.message}`, {
          position: "top-right",
        });
        this.$emit("close");
      }
    },
    /**
     * This function updates the list of Critical Thresholds on the API.
     */
    async updateCriticalThresholds(delThresholdIds) {
      this.sendingThresholds = true;
      try {
        OpenAPI.TOKEN = await this.$auth0.getAccessTokenSilently();
        if (delThresholdIds.length) {
          for (const id of delThresholdIds) {
            await DefaultService.deleteCriticalThresholdInlineInspectionsCriticalThresholdsIdGroupDelete(
              id,
              this.selectedGroup
            );
          }
        }
        const convertTH = this.convertGapWidth(this.arrayThresholds, true);
        for (const threshold of convertTH) {
          if (!threshold.id) threshold.id = uuidv4();
          await DefaultService.createCriticalThresholdsInlineInspectionsCriticalThresholdsGroupPost(
            this.selectedGroup,
            threshold
          );
        }
        this.sendingThresholds = false;
        this.$emit("save");
        this.open = false;
      } catch (error) {
        this.$toast.warning(
          `Update Critical Thresholds list - ${error.message}`,
          { position: "top-right" }
        );
        this.sendingThresholds = false;
      }
    },
    /**
     * Event handler function of the "Save" button, which checks if there are
     * changes in the list of critical thresholds and then sends these changes to the api.
     */
    async onSave() {
      const { valid } = await this.$refs.form.validate();

      if (valid) {
        const ids = this.arrayThresholds.map((t) => t.id);
        const delThresholdIds = this.actualThresholdIds.filter(
          (id) => !ids.includes(id)
        );
        this.updateCriticalThresholds(delThresholdIds);
      }
    },

    /**
     * Delete button event handler function that delete critical thresholds from the list
     */
    deleteRow(threshold) {
      this.arrayThresholds = this.arrayThresholds.filter(
        (t) => t !== threshold
      );
    },
    /**
     * "Add" button event handler function that adds new critical thresholds to the list
     */
    addRow() {
      const emptyThreshold = { ...this.EMPTY_THRESHOLD };
      emptyThreshold.pipeline_id = this.selectedPipeline.id;
      this.arrayThresholds.push(emptyThreshold);
    },

    convertGapWidth(thresholdList, sendToAPI = false) {
      if (this.$units.current.value === "metric") return thresholdList;

      const convertFrom = sendToAPI ? "in" : "mm";
      return thresholdList.map((th) => ({
        ...th,
        max_gap_width: this.$units.convert(th.max_gap_width, convertFrom),
      }));
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/variables.scss";

.v-card {
  .v-card-title {
    color: $cyan-main;
    background: $black-primary;
    font-size: 1.1rem;
  }

  .v-card-text {
    position: relative;
    padding: 1.1rem 1.5rem !important;
    display: flex;
    flex-direction: column;
    align-items: center;
    max-height: 70vh;
    overflow: auto;

    &.loader-container {
      overflow: hidden;
      min-height: 220px;
      width: 694px;
    }

    & .nonallowed {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background: white;
      opacity: 0.5;
      cursor: not-allowed;
    }

    .threshold-row {
      display: flex;
      padding-bottom: 12px;
      margin-bottom: 12px;
      border-bottom: 1px dashed $gray-light;

      .v-col {
        padding: 6px 12px 0;
      }

      .delete-button {
        margin-left: 8px;
        display: flex;
        justify-content: center;
        align-items: center;

        .v-btn {
          background: $brand-danger;
          color: white;
        }
      }
    }

    .add-btn {
      background: $cyan-dark;
      color: white;
    }
  }

  .v-card-actions {
    padding: 1.1rem 1.5rem !important;
    display: flex;
    justify-content: flex-end;

    border-top: 1px solid $gray-lighter;

    .v-btn {
      background: $black-primary !important;
      color: white !important;
      font-size: 0.8rem;
      padding: 0 20px !important;
    }
  }
}
</style>
