import { settingsState } from "./state";
import { settingsMutations } from "./mutations";
import { settingsActions } from "./actions";

export const settingsModule = {
  namespaced: true,
  state: settingsState,
  mutations: settingsMutations,
  actions: settingsActions,
};
