<template>
  <VirtualTable
    :headers="headers"
    :items="filteredJoints"
    :is-item-active="isJointActive"
    @click:row="(joint) => $emit('click:row', joint.id)"
  >
    <template v-slot:[`header.select`]>
      <v-icon
        v-if="checkedLength > 0"
        icon="mdi-minus-box-outline"
        size="small"
        @click.stop="jointsSelector.clear()"
      />
    </template>
    <template v-slot:[`item.select`]="{ item: joint }">
      <input
        :value="joint.id"
        :disabled="
          shouldDisableCheckbox(joint.id, filteredJoints, jointsSelector)
        "
        :checked="jointsSelector.has(joint.id)"
        @input="jointsSelector.setByCtrl(joint.id)"
        @click.stop
        type="checkbox"
      />
    </template>
  </VirtualTable>
</template>

<script>
import { mapState, mapGetters } from "vuex";

import { VirtualTable } from "@/components";

import { shouldDisableCheckbox } from "@/features/dig-up/helpers";

export default {
  name: "JointsTableRegular",

  props: {
    checkedLength: Number,
    isJointActive: Function,
  },

  components: { VirtualTable },

  emits: ["click:row"],

  setup() {
    return {
      shouldDisableCheckbox,
    };
  },

  data() {
    return {
      headers: [
        {
          title: this.$t("joints.table_titles.joint_id"),
          key: "joint_id",
          value: "joint_id",
        },
        {
          title: this.$t("joints.table_titles.joint_type"),
          key: "joint_type",
          value: (joint) => this.$t(`joints.types.${joint.joint_type}`),
        },
        {
          title: this.$t("joints.table_titles.material"),
          key: "material",
          value: (joint) => this.$t(`materials.${joint.material}`),
        },
        {
          title: this.$t("joints.table_titles.angle_vertical"),
          key: "angle_vertical",
          value: (joint) => Number(joint.angle_vertical).toFixed(2),
          units: "°",
        },
        {
          title: this.$t("joints.table_titles.angle_horizontal"),
          key: "angle_horizontal",
          value: (joint) => Number(joint.angle_horizontal).toFixed(2),
          units: "°",
        },
        {
          title: this.$t("joints.table_titles.gap_width_mean"),
          key: "gap_width_mean",
          value: "gap_width_mean",
          units: "mm",
        },
        {
          title: this.$t("joints.table_titles.gap_width_max"),
          key: "gap_width_max",
          value: "gap_width_max",
          units: "mm",
        },
        {
          title: this.$t("joints.table_titles.degradation_level"),
          key: "degradation_level",
          value: "degradation_level",
        },
      ],
    };
  },

  computed: {
    ...mapGetters("joints", ["filteredJoints"]),
    ...mapState("joints", ["jointsSelector"]),

    headerSlots() {
      return ["header.select"];
    },

    itemSlots() {
      return ["item.select"];
    },
  },
};
</script>
