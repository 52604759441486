// Small mathematical helping functions
const asc = (arr) => arr.sort((a, b) => a - b);

const quantile = (arr, q) => {
  const sorted = asc(arr);
  const pos = (sorted.length - 1) * q;
  const base = Math.floor(pos);
  const rest = pos - base;
  if (sorted[base + 1] !== undefined) {
    return sorted[base] + rest * (sorted[base + 1] - sorted[base]);
  } else {
    return sorted[base];
  }
};

const q75 = (arr) => quantile(arr, 0.75);

/**
 * Local degradation spots class
 */
class LocalDegradationSpots {
  /**
   * class constructor calculating all potential spots.
   * @param {Array} distance - measurement distance
   * @param {Array} wallthickness - measurement remaining wallthickness
   * @param {Float} wallthicknessOriginal - original wallthickness
   */
  constructor(distance, wallthickness, wallthicknessOriginal) {
    this.wallthicknessOriginal = wallthicknessOriginal;
    this.wallthickness = wallthickness;
    this.distance = distance;

    var wt = wallthickness;

    //clone wt
    var wtClone = JSON.parse(JSON.stringify(wt));

    //find threshold
    var threshold = q75(wtClone);
    this.threshold = threshold;

    //calculate difference and signs
    var difference = wt.map((x) => x - threshold);

    var signs = difference.map((x) => Math.sign(x));

    //find intersection points

    var crossings = [];

    if (signs[0] < 0) {
      crossings.push(0);
    }

    var arrayLength = signs.length;

    for (var i = 0; i < arrayLength; i++) {
      if (i != 0) {
        if (signs[i] != signs[i - 1]) {
          crossings.push(i - 1);
        }
      }
    }

    //maybe add the end
    if (signs[signs.length - 1] == -1) {
      crossings.push(signs.length - 1);
    }

    var lastSpot = 1;
    var spots = [];
    for (var i = 0; i < crossings.length; i++) {
      if (i < crossings.length - 1) {
        var condition = difference
          .slice(crossings[i], crossings[i + 1])
          .reduce((a, b) => a + b, 0);
        if (Math.sign(condition) == -1) {
          if (lastSpot == -1) {
            spots[spots.length - 1][1] = crossings[i + 1];
          } else {
            spots.push([crossings[i], crossings[i + 1]]);
          }
        }
        lastSpot = Math.sign(condition);
      }
    }

    this.spotsProperties = spots.map((x) => {
      return {
        wallthicknessMin: Math.min(...wt.slice(x[0], x[1])),
        distanceStart: distance[x[0]],
        distanceStop: distance[x[1]],
        indexStartStop: x,
        depthToThreshold: threshold - Math.min(...wt.slice(x[0], x[1])),
        depthToOriginal:
          wallthicknessOriginal - Math.min(...wt.slice(x[0], x[1])),
        width: distance[x[1]] - distance[x[0]],
      };
    });
  }
  /**
   * filter local degradation spots based on depth and width.
   * @summary Filters the local degrdation spots based on depth and width and calculates statistics of these spots.
   * @param {Float} depthMinRelative - Minimum relative depth with respect to original wallthickness
   * @param {Float} depthMinAbsolute - Minimum absolute depth
   * @param {Float} widthMin - Minimum width
   * @return {Object} contains stats about local degradation spots and the resulting spots.
   */
  filter_spots(depthMinRelative, depthMinAbsolute, widthMin) {
    var depthRequirement = Math.max(
      depthMinAbsolute,
      depthMinRelative * this.wallthicknessOriginal
    );
    var filteredSpots = this.spotsProperties
      .filter((x) => x.width > widthMin)
      .filter((x) => x.depthToThreshold > depthRequirement);
      
    this.filteredSpots = filteredSpots;

    this.stats = {
      numberOfSpots: this.filteredSpots.length,
      distance: this.distance[this.distance.length - 1] - this.distance[0],
      numberOfSpotsDistance:
        (this.filteredSpots.length * 100) /
        (this.distance[this.distance.length - 1] - this.distance[0]),
      constantDegradationEstimate: this.threshold,
      depthMaxToThreshold: Math.max(
        ...filteredSpots.map((x) => x.depthToThreshold)
      ),
      depthMaxToWallthicknessOriginal: Math.max(
        ...filteredSpots.map((x) => x.depthToOriginal)
      ),
      wallthicknessMin: Math.min(
        ...filteredSpots.map((x) => x.wallthicknessMin)
      ),
      widthMax: Math.max(...filteredSpots.map((x) => x.width)),
    };
    return {
      stats: this.stats,
      filteredSpots: this.filteredSpots,
    };
  }
}

export default LocalDegradationSpots;
