<template>
  <div class="container-popup" @click.stop>
    <div v-if="closePopup" class="close-btn" @click="closePopup"></div>
    <div class="popup-tabs">
      <div class="btn-tabs-list">
        <button
          class="btn-tabs"
          :class="activeTab === 'info' ? 'active-tabs' : ''"
          @click="activeTab = 'info'"
        >
          <span>{{ $t("map_popup.tabs.information") }}</span>
        </button>
      </div>
      <div class="content-tabs">
        <table v-show="activeTab === 'info'" class="popup-table">
          <tbody>
            <tr v-for="{ value, title } in columns" :key="title">
              <td>{{ title }}</td>
              <td>{{ value }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import {
  getRemainingLifeColor,
  getDegradationColorPP,
  getDegradationColorJoints,
} from "@/colorLegends.js";
export default {
  name: "MapInfoPopup",
  props: {
    data: Object,
    closePopup: Function,
    type: String,
    activeValue: Object,
  },

  data: () => ({
    activeTab: "info",
    isHoverActions: false,
    validationType: "wallThickness",
    source: "",
    wallThickness: 0,
    circumference: 0,
    wtFile: null,
    cFile: null,
  }),

  computed: {
    columns() {
      switch (this.type) {
        case "joint":
          return [
            {
              value: this.$t(`joints.types.${this.data["joint_type"]}`),
              title: this.$t("joints.table_titles.joint_type"),
            },
            {
              value: this.$t(`materials.${this.data["material"]}`),
              title: this.$t("joints.table_titles.material"),
            },
            {
              value: this.data["angle_vertical"],
              title: this.$t("joints.table_titles.angle_vertical"),
            },
            {
              value: this.data["angle_horizontal"],
              title: this.$t("joints.table_titles.angle_horizontal"),
            },
            {
              value: this.data["gap_width_mean"],
              title: `${this.$t(
                "joints.table_titles.gap_width_mean"
              )} (${this.$units.getAbbr("mm")})`,
            },
            {
              value: this.data["gap_width_max"],
              title: `${this.$t(
                "joints.table_titles.gap_width_max"
              )} (${this.$units.getAbbr("mm")})`,
            },
            {
              value: getDegradationColorJoints(this.$t)[
                this.data["degradation_level"]
              ].text,
              title: this.$t("joints.table_titles.degradation_level"),
            },
            {
              value: this.data["geom"]["coordinates"][0],
              title: this.$t("joints.table_titles.longitude"),
            },
            {
              value: this.data["geom"]["coordinates"][1],
              title: this.$t("joints.table_titles.latitude"),
            },
          ];

        case "anomaly":
          return [
            {
              value:
                this.data.pipe_parts.length > 1
                  ? `${this.data.pipe_parts[0].pipe_book_id} - ${
                      this.data.pipe_parts[this.data.pipe_parts.length - 1]
                        .pipe_book_id
                    }`
                  : this.data.pipe_parts[0].pipe_book_id,
              title: this.$t("anomalies.table_titles.pipe_book_id"),
            },
            {
              value: this.$t(`anomalies.types.${this.data.anomaly_type}`),
              title: this.$t("anomalies.table_titles.anomaly_type"),
            },
            {
              value: this.data.comment,
              title: this.$t("anomalies.table_titles.comment"),
            },
            {
              value: this.data.distance,
              title: `${this.$t(
                "anomalies.table_titles.distance"
              )} (${this.$units.getAbbr("m")})`,
            },
            {
              value: this.data.centerGeom[1],
              title: this.$t("anomalies.table_titles.latitude"),
            },
            {
              value: this.data.centerGeom[0],
              title: this.$t("anomalies.table_titles.longitude"),
            },
            {
              value: this.data.z_coordinate,
              title: `${this.$t(
                "anomalies.table_titles.z_coordinate"
              )} (${this.$units.getAbbr("m")})`,
            },
            {
              value: this.data.length,
              title: `${this.$t(
                "anomalies.table_titles.length"
              )} (${this.$units.getAbbr("m")})`,
            },
            {
              value:
                this.data.local_degradation_spots &&
                this.data.local_degradation_spots.width,
              title: `${this.$t(
                "anomalies.table_titles.width"
              )} (${this.$units.getAbbr("m")})`,
            },
            {
              value:
                this.data.local_degradation_spots &&
                this.data.local_degradation_spots.depth,
              title: `${this.$t(
                "anomalies.table_titles.depth"
              )} (${this.$units.getAbbr("m")})`,
            },
            {
              value:
                this.data.clock_positions.length && this.data.clock_positions,
              title: this.$t("anomalies.table_titles.clock"),
            },
          ];

        case "pipepart": {
          let activeValue = null;
          const keys = this.activeValue.value.split(".");
          if (keys.length > 1) {
            if (keys[1] === "remaining_life_group") {
              activeValue =
                getRemainingLifeColor()[this.data[keys[0]][keys[1]]].text;
            } else {
              activeValue = this.data[keys[0]][keys[1]];
            }
          } else {
            if (keys[0] === "degradation_level") {
              activeValue = getDegradationColorPP(this.$t)[this.data[keys[0]]]
                .text;
            } else {
              activeValue = this.data[keys[0]];
            }
          }
          const list = [
            {
              value: this.data["distance"],
              title: `${this.$t(
                "pipe_parts.table_titles.distance"
              )} (${this.$units.getAbbr("m")})`,
            },
            {
              value: this.$t(`pipe_parts.types.${this.data["pipe_part_type"]}`),
              title: this.$t("pipe_parts.table_titles.pipe_part_type"),
            },
            {
              value: this.$t(`materials.${this.data["material"]}`),
              title: this.$t("pipe_parts.table_titles.material"),
            },
            {
              value: this.data["length"],
              title: `${this.$t(
                "pipe_parts.table_titles.length"
              )} (${this.$units.getAbbr("m")})`,
            },
            {
              value: this.data["geom"]["coordinates"][0][0],
              title: this.$t("pipe_parts.table_titles.longitude"),
            },
            {
              value: this.data["geom"]["coordinates"][0][1],
              title: this.$t("pipe_parts.table_titles.latitude"),
            },
          ];

          if (keys[0] !== "material") {
            list.push({ value: activeValue, title: this.activeValue.title });
          }

          return list;
        }

        default:
          return [];
      }
    },
  },
};
</script>

<style lang="scss">
.container-popup {
  position: relative;

  .close-btn {
    position: absolute;
    width: 20px;
    height: 20px;
    top: 3px;
    right: 3px;
    background: url("~@/assets/img/cancel-white.png") no-repeat center;
    background-size: contain;
    border: none;
    z-index: 10;
    cursor: pointer;
  }

  .btn-tabs {
    border-radius: 6px 6px 0 0;
    background-color: #1c243d;
    color: #24e3c6;
    border: 0px;
    box-shadow: none;
    box-sizing: content-box;
    font-size: 0.875rem;
    text-transform: uppercase;
    font-family: "Nunito", sans-serif;
    cursor: pointer;
    z-index: 1;
  }

  .popup-tabs {
    width: 300px;
    height: 100%;

    .btn-tabs-list {
      width: 100%;
      height: 35px;
      display: flex;
      flex-direction: row;
      align-items: stretch;
      background-color: #1c243d;
      border-radius: 6px 6px 0 0;

      button {
        position: relative;
        flex-grow: 1;

        &.active-tabs {
          border-bottom: 2px solid #ffffff;
        }

        .hover-dropdown {
          position: absolute;
          left: -35px;
          top: 50px;
          padding-top: 10px;
          display: flex;
          flex-direction: column;
          width: 160px;
          height: 100px;
          border-radius: 5px;
          overflow: hidden;

          &::before {
            content: "";
            position: absolute;
            transform: rotate(45deg);
            width: 14px;
            height: 14px;
            top: 7px;
            right: 10%;
            z-index: 0;
            background-color: #1c243d;
          }

          button {
            &.active-hover-tabs {
              text-shadow: 0.7px 0.7px #45ffe3;
            }
          }

          :first-child {
            border-top-left-radius: 5px;
            border-top-right-radius: 5px;
          }
        }
      }
    }

    .content-tabs {
      margin: 10px;
      min-height: 200px;

      .btn-block {
        width: 100%;
        margin: 25px 0px 0px 0px;
        padding: 10px 0px;
        display: flex;
        flex-direction: row;
        justify-content: center;
        border-top: 1px solid #eeeeee;
      }
    }
  }
}

.popup-table {
  width: 100%;
  border-collapse: collapse;

  tbody {
    tr {
      font-size: 0.8rem;
      border-top: 1px solid #dddddd !important;

      td {
        cursor: default;
        padding: 8px 10px;
      }

      &:first-of-type {
        border: none;
      }
    }
  }
}
</style>
