<template>
  <PlotlyGraph
    :data="plotlyData"
    :layout="plotlyLayout"
    :config="{ displayModeBar: false }"
    @hover="hoverHeightProfile"
    @mousedown.stop="$emit('graph-mousedown')"
  />
</template>

<script>
import { mapState, mapGetters, mapMutations } from "vuex";

import { PlotlyGraph } from "@/components";

export default {
  name: "JointsHeightChart",

  props: ["visibleDistance"],

  components: {
    PlotlyGraph,
  },

  emits: ["changePoint", "graph-mousedown"],

  computed: {
    ...mapState([
      "isMapMode",
      "selectedInspectionStats",
      "pipelineDepth",
      "pipelineElevation",
    ]),

    ...mapGetters([
      "heightRange",
      "convertedHeightProfileDistance",
      "convertedPipelineElevation",
      "convertedPipelineDepth",
      "convertedHeightProfileZ",
    ]),

    ...mapGetters("joints", ["filteredJoints", "selectedJoint"]),

    jointsTrace() {
      const [minHeight, maxHeight] = this.heightRange;

      let errorValue = 100;

      if (Math.abs(maxHeight) > Math.abs(minHeight)) {
        errorValue = maxHeight;
      }

      const jointColor = "#fc614f33";
      const selectedJointColor = "#6b0b0b33";

      let jointsToPlot = this.filteredJoints;

      if (this.isMapMode) {
        jointsToPlot = this.selectedJoint ? [this.selectedJoint] : [];
      }

      if (jointsToPlot.length === 0) {
        return null;
      }

      const xValues = jointsToPlot.map((joint) => joint.distance);
      const yValues = Array(jointsToPlot.length).fill(minHeight - 5);

      const colors = jointsToPlot.map((joint) => {
        if (joint.id === this.selectedJoint?.id) {
          return selectedJointColor;
        }

        return jointColor;
      });

      const trace = {
        x: xValues,
        y: yValues,
        mode: "markers",
        name: this.$t("joints.details.plotly.joint"),
        marker: {
          color: colors,
        },
        error_y: {
          type: "constant",
          value: errorValue,
          width: 0,
          thickness: 1,
          color: jointColor,
        },
      };

      return trace;
    },

    elevationTrace() {
      if (this.convertedPipelineElevation.length === 0) {
        return null;
      }

      const trace = {
        x: this.convertedHeightProfileDistance,
        y: this.convertedPipelineElevation,
        type: "scatter",
        name: this.$t("dashboard.pipelines.height_profile.elevation"),
        line: {
          color: "#6e4f00",
        },
      };

      return trace;
    },

    heightProfileTrace() {
      if (this.convertedHeightProfileZ.length === 0) {
        return null;
      }

      const trace = {
        x: this.convertedHeightProfileDistance,
        y: this.convertedHeightProfileZ,
        type: "scatter",
        name: this.$t("joints.details.height_profile.title"),
        line: {
          color: "#1c243d",
        },
      };

      return trace;
    },

    depthTrace() {
      if (this.convertedPipelineDepth.length === 0) {
        return null;
      }

      const trace = {
        x: this.convertedHeightProfileDistance,
        y: this.convertedPipelineDepth,
        type: "scatter",
        name: this.$t("dashboard.pipelines.height_profile.depth"),
        fill: "tonexty",
        fillcolor: "#f78b8b",
        mode: "none",
      };

      return trace;
    },

    plotlyData() {
      const data = [
        this.jointsTrace,
        this.elevationTrace,
        this.heightProfileTrace,
        this.depthTrace,
      ];
      const filtered = data.filter((trace) => trace !== null);

      return filtered;
    },

    plotlyLayout() {
      const dupe = {
        xaxis: {
          title: `${this.$t(
            "joints.details.height_profile.xaxis_name"
          )} (${this.$units.getAbbr("m")})`,
          showline: true,
        },
        yaxis: {
          title: `${this.$t(
            "joints.details.height_profile.yaxis_name"
          )} (${this.$units.getAbbr("m")})`,
          showline: true,
          range: [0, 1],
        },
        margin: {
          t: 30,
          b: 70,
          l: 70,
          r: 50,
        },
      };
      if (this.selectedInspectionStats) {
        const zHeightProfile =
          this.selectedInspectionStats.height_profile.z.map((val) =>
            this.$units.convert(val, "m")
          );
        const plElevation = this.pipelineElevation.length
          ? this.pipelineElevation.map((e) => this.$units.convert(e, "m"))
          : [];
        const plDepth = this.pipelineDepth.length
          ? this.pipelineDepth.map((e) => this.$units.convert(e, "m"))
          : [];
        const hMax = Math.max(...zHeightProfile, ...plElevation, ...plDepth);
        const hMin = Math.min(...zHeightProfile, ...plElevation, ...plDepth);
        dupe.yaxis["range"] = [hMin - 5.0, hMax + 2.0];
      }

      if (this.visibleDistance && this.visibleDistance.length) {
        const dMax = Math.max(...this.visibleDistance);
        const dMin = Math.min(...this.visibleDistance);
        dupe.xaxis["range"] = [dMin, dMax];
      }

      return dupe;
    },
  },

  methods: {
    ...mapMutations("joints", ["setSelectedJointId"]),

    hoverHeightProfile(event) {
      if (event.points[0].data.jointId) {
        this.setSelectedJointId(event.points[0].data.jointId);
      } else {
        const pointIndex = event.points[0].pointIndex;
        this.setSelectedJointId(this.filteredJoints[pointIndex]?.id);
      }
    },
  },
};
</script>
