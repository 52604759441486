import { i18n } from "@/config/i18n";
import { DigUpSelector } from "@/features/dig-up/helpers";

export const INITIAL_JOINT_FILTERS = {
  selectedJointTypes: [],
  selectedMaterials: [],
  selectedVerticalAngle: 0,
  selectedHorizontalAngle: 0,
  selectedGapMean: [],
  selectedGapMax: [],
  plotlyFilter: {
    property: "",
    range: [],
  },
};

export const jointsState = {
  joints: [],
  jointFilters: INITIAL_JOINT_FILTERS,
  selectedJointId: null,
  isLoading: true,
  isAwaitingDigup: false,
  jointsSelector: new DigUpSelector(i18n.global.locale),
  selectedGradientMode: "degradation_level",
  selectedLegend: null,
};
