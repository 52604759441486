import { i18n } from "@/config/i18n";
import { unitsConverter } from "@/config/units";
import {
  getMinMaxValue,
  getUniqueValues,
  rangeFiltering,
  valueFiltering,
} from "@/utils/filterHelpers";
import { getMinMaxWallThickness } from "@/pages/pipe-parts/helpers";

export const pipePartsGetters = {
  convertedPipeParts(state) {
    const conversionConfig = {
      distance: "m",
      length: "m",
      horizontal_out_of_straight: "m",
      vertical_out_of_straight: "m",
      remaining_mean: "mm",
      remaining_min: "mm",
      remaining_max: "mm",
      remaining_std: "mm",
      degradated_total_mean: "mm",
      degradated_total_min: "mm",
      degradated_total_max: "mm",
      degradated_total_std: "mm",
      remaining_life_calculation: {
        road_distance: "m",
        ground_water_level: "m",
      },
      wall_thickness_original: "mm",
      diameter_class: "mm",
    };

    return state.pipeParts.map((pipePart) => {
      const converted = { ...pipePart };

      for (const key in conversionConfig) {
        const unit = conversionConfig[key];
        if (key in pipePart && pipePart[key] !== null) {
          converted[key] = unitsConverter.instance.convert(pipePart[key], unit);
        }
      }

      if (pipePart.remaining_life_calculation) {
        converted.remaining_life_calculation = {
          ...pipePart.remaining_life_calculation,
        };

        for (const key in conversionConfig.remaining_life_calculation) {
          const unit = conversionConfig.remaining_life_calculation[key];
          if (
            key in pipePart.remaining_life_calculation &&
            pipePart.remaining_life_calculation[key] !== null
          ) {
            converted.remaining_life_calculation[key] =
              unitsConverter.instance.convert(
                pipePart.remaining_life_calculation[key],
                unit
              );
          }
        }
      }

      return converted;
    });
  },

  pipePartsWithWallThickness(state, getters) {
    const convertedPipeParts = getters.convertedPipeParts;

    return convertedPipeParts.map((pipePart) => {
      const {
        wall_thickness_remaining_mean,
        wall_thickness_remaining_min,
        wall_thickness_remaining_max,
        wall_thickness_remaining_std,
        wall_thickness_degradated_total_mean,
        wall_thickness_degradated_total_min,
        wall_thickness_degradated_total_max,
        wall_thickness_degradated_total_std,
      } = pipePart;

      const getRemainingValue = (thicknessArray, prop) => {
        const layerNum = state.wallThicknessRemaining[prop].layerNum;
        const fullPipe = thicknessArray[0];
        return fullPipe.length > 1 ? fullPipe[layerNum] : fullPipe[0];
      };

      const remaining_mean = getRemainingValue(
        wall_thickness_remaining_mean,
        "mean"
      );
      const remaining_min = getRemainingValue(
        wall_thickness_remaining_min,
        "min"
      );
      const remaining_max = getRemainingValue(
        wall_thickness_remaining_max,
        "max"
      );
      const remaining_std = getRemainingValue(
        wall_thickness_remaining_std,
        "std"
      );

      return {
        ...pipePart,
        remaining_mean,
        remaining_min,
        remaining_max,
        remaining_std,
        degradated_total_mean: wall_thickness_degradated_total_mean[0],
        degradated_total_min: wall_thickness_degradated_total_min[0],
        degradated_total_max: wall_thickness_degradated_total_max[0],
        degradated_total_std: wall_thickness_degradated_total_std[0],
      };
    });
  },

  pipePartsWithTranslatedQualityAnomaly(_, getters) {
    const influenceTypes = {
      dirt: i18n.global.t(`quality_influences.dirt`),
      gas: i18n.global.t(`quality_influences.gas`),
      misalignment: i18n.global.t(`quality_influences.misalignment`),
      unaffected: i18n.global.t(`quality_influences.unaffected`),
    };

    const pipeParts = getters.pipePartsWithWallThickness;

    return pipeParts.map((pipePart) => {
      if (!pipePart.quality_anomaly) {
        return { ...pipePart, quality_anomaly: null };
      }

      let influences = pipePart.quality_anomaly.split(", ");

      influences = influences
        .map((type) => influenceTypes[type.toLowerCase()])
        .join(", ");
      return { ...pipePart, quality_anomaly: influences };
    });
  },

  filteredPipeParts(state, getters, rootState) {
    const {
      selectedDistance,
      selectedPipePartTypes,
      selectedMaterials,
      selectedOvalityMax,
      selectedOvalityMean,
      selectedDeformationHor,
      selectedDeformationVer,
      plotlyFilter,
    } = state.filters;

    let filtered = getters.pipePartsWithTranslatedQualityAnomaly;

    if (selectedPipePartTypes.length) {
      filtered = valueFiltering(
        filtered,
        "pipe_part_type",
        selectedPipePartTypes
      );
    }

    if (selectedMaterials.length) {
      filtered = valueFiltering(filtered, "material", selectedMaterials);
    }

    filtered = rangeFiltering(filtered, "distance", selectedDistance);

    if (!rootState.isMapMode) {
      filtered = rangeFiltering(filtered, "ovality_max", selectedOvalityMax);
      filtered = rangeFiltering(filtered, "ovality_mean", selectedOvalityMean);
      filtered = rangeFiltering(
        filtered,
        "horizontal_out_of_straight",
        selectedDeformationHor
      );
      filtered = rangeFiltering(
        filtered,
        "vertical_out_of_straight",
        selectedDeformationVer
      );
    }

    if (plotlyFilter.property !== "") {
      const { property, range } = plotlyFilter;
      filtered = rangeFiltering(filtered, property, range);
    }

    return filtered;
  },

  minMaxDistance(_, getters) {
    return getMinMaxValue(getters.convertedPipeParts, "distance");
  },

  minMaxOvalityMax(_, getters) {
    return getMinMaxValue(getters.convertedPipeParts, "ovality_max");
  },

  minMaxOvalityMean(_, getters) {
    return getMinMaxValue(getters.convertedPipeParts, "ovality_mean");
  },

  minMaxDeformationHor(_, getters) {
    return getMinMaxValue(
      getters.convertedPipeParts,
      "horizontal_out_of_straight"
    );
  },

  minMaxDeformationVer(_, getters) {
    return getMinMaxValue(
      getters.convertedPipeParts,
      "vertical_out_of_straight"
    );
  },

  minMaxRoadDistance(_, getters) {
    if (getters.convertedPipeParts[0].remaining_life_calculation) {
      return getMinMaxValue(
        getters.convertedPipeParts,
        "remaining_life_calculation.road_distance"
      );
    }

    return [];
  },

  minMaxDegradationRate(_, getters) {
    if (getters.convertedPipeParts[0].remaining_life_calculation) {
      return getMinMaxValue(
        getters.convertedPipeParts,
        "remaining_life_calculation.degradation_rate"
      );
    }

    return [];
  },

  minMaxGroundWaterLevel(_, getters) {
    if (getters.convertedPipeParts[0].remaining_life_calculation) {
      return getMinMaxValue(
        getters.convertedPipeParts,
        "remaining_life_calculation.road_distance"
      );
    }

    return [];
  },

  pipePartTypes(state) {
    return getUniqueValues(state.pipeParts, "pipe_part_type");
  },

  materials(state) {
    return getUniqueValues(state.pipeParts, "material");
  },

  hasActiveFilters(state, getters) {
    return Object.entries(state.filters).some(([key, value]) => {
      if (["selectedPipePartTypes", "selectedMaterials"].includes(key)) {
        return value.length > 0;
      }

      if (key === "selectedDistance") {
        return (
          getters.minMaxDistance[0] !== value[0] ||
          getters.minMaxDistance[1] !== value[1]
        );
      }
    });
  },

  selectedPipePart(state, getters) {
    if (!state.selectedPipePartId) return;

    return getters.convertedPipeParts.find(
      ({ id }) => id === state.selectedPipePartId
    );
  },

  anomaliesTypes(state) {
    return getUniqueValues(state.anomalies, "anomaly_type");
  },

  anomaliesByPipePart(state, getters) {
    if (!getters.selectedPipePart) {
      return [];
    }

    return state.anomalies.filter((anomaly) =>
      anomaly.pipe_parts.some(
        (pipePart) => pipePart.id === getters.selectedPipePart.id
      )
    );
  },

  minMaxWallThickness(_, getters) {
    return getMinMaxWallThickness(getters.pipePartsWithWallThickness);
  },
};
