<template>
  <PlotlyGraph
    :data="plotlyData"
    :layout="plotlyLayout"
    :config="{ displayModeBar: false }"
    @click.self="onClickHistogram"
    @relayout="onHistogramZoom"
    @mousedown.stop="$emit('graph-mousedown')"
  />
</template>

<script>
import { mapMutations, mapState, mapActions } from "vuex";

import { PlotlyGraph } from "@/components";

import { GradientType } from "@/pages/joints/config";

import {
  materialColors,
  jointTypeColors,
  getDegradationColorJoints,
} from "@/colorLegends.js";

export default {
  name: "JointsHistogramChart",

  emits: ["selectColumn", "graph-mousedown"],

  inject: ["histogramTitle"],

  components: {
    PlotlyGraph,
  },

  setup() {
    return {
      getDegradationColorJoints,
      materialColors,
      jointTypeColors,
    };
  },

  computed: {
    ...mapState("joints", ["joints", "selectedGradientMode"]),

    plotlyData() {
      const data = [];

      if (this.joints && this.joints.length) {
        let xdata = [];

        let colorData = this.joints.map((j) => j[this.selectedGradientMode]);

        if (this.selectedGradientMode === GradientType.Material) {
          xdata = this.joints.map((j) =>
            this.$t(`materials.${j[this.selectedGradientMode]}`)
          );
        } else if (this.selectedGradientMode === GradientType.JointType) {
          xdata = this.joints.map((j) =>
            this.$t(`joints.types.${j[this.selectedGradientMode]}`)
          );
        } else {
          xdata = this.joints.map((j) => j[this.selectedGradientMode]);
        }

        if (this.selectedGradientMode === GradientType.DegradationLevel) {
          xdata = xdata
            .sort((a, b) => a - b)
            .map((value) => getDegradationColorJoints(this.$t)[value].text);
          colorData = xdata;
        } else {
          xdata = xdata.filter((value) => value !== 0);
        }
        data.push({
          x: xdata,
          name: this.histogramTitle,
          showlegend: false,
          histnorm: "count",
          type: "histogram",
          autobinx: true,
          marker: {
            color: this.getMarkerColor(colorData),
          },
        });
      }

      return data;
    },

    plotlyLayout() {
      const dupe = {
        bargap: 0.05,
        bargroupgap: 0.2,
        barmode: "overlay",
        xaxis: {
          title: this.histogramTitle,
        },
        yaxis: {
          title: this.$t("joints.details.plotly.yaxis_name"),
          type: "log",
          tick0: 100,
          dtick: "L200",
        },
        margin: {
          t: 30,
          b: 70,
          l: 70,
          r: 50,
        },
      };

      return dupe;
    },

    isGradient() {
      return !["degradation_level", "material", "joint_type"].includes(
        this.selectedGradientMode
      );
    },
  },

  methods: {
    ...mapMutations(["CHANGE_FILTERS"]),

    ...mapActions("joints", ["toggleSelectedLegend"]),

    resetPlotlyFilter() {
      const value = { property: "", range: [] };
      this.CHANGE_FILTERS({
        value,
        filter: "plotlyFilter",
        key: "jointsFilters",
      });
    },

    onClickHistogram(e) {
      if (this.isGradient) return;

      this.toggleSelectedLegend(e.points[0].x);
    },

    onHistogramZoom(ev) {
      if (
        [
          "angle_vertical",
          "angle_horizontal",
          "gap_width_mean",
          "gap_width_max",
        ].includes(this.selectedGradientMode)
      ) {
        if (ev["xaxis.autorange"]) {
          this.resetPlotlyFilter();
        } else {
          if (ev.hasOwnProperty("xaxis.range[0]")) {
            const min = ev["xaxis.range[0]"];
            const max = ev["xaxis.range[1]"];
            const value = {
              property: this.selectedGradientMode,
              range: [min, max],
            };

            this.CHANGE_FILTERS({
              key: "jointsFilters",
              filter: "plotlyFilter",
              value,
            });
          }
        }
      }
    },

    getMarkerColor(colorData) {
      if (this.isGradient) {
        return null;
      }
      return this.getLegendColorsForHistogram(
        colorData,
        this.selectedGradientMode
      );
    },

    getLegendColorsForHistogram(values, mode) {
      let arr = Array.from(new Set(values));
      if (Number.isInteger(arr[0])) {
        arr = arr.sort((a, b) => a - b);
      }

      switch (mode) {
        case "degradation_level":
          return arr.map(
            (el) =>
              getDegradationColorJoints(this.$t).find((l) => el === l.text)
                .color
          );

        case "material":
          return arr.map((el) => materialColors[el].color);

        case "joint_type":
          return arr.map((el) => jointTypeColors[el].color);

        default:
          return null;
      }
    },

    getChartXData(joints) {
      switch (this.selectedGradientMode) {
        case GradientType.Material:
          return joints.map((j) =>
            this.$t(`materials.${j[this.selectedGradientMode]}`)
          );
        case GradientType.JointType:
          return joints.map((j) =>
            this.$t(`joints.types.${j[this.selectedGradientMode]}`)
          );
        case GradientType.default:
          break;
      }
    },
  },
};
</script>
