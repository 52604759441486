export function getDataForDigupRequest(selectedIds, elements, locale, units) {
  const filtered = elements.filter(({ id }) => selectedIds.includes(id));

  const data = filtered.reduce(
    (acc, { inspection_id, time_start, time_end }) => ({
      inspection_id,
      time_start: time_start < acc.time_start ? time_start : acc.time_start,
      time_end: time_end > acc.time_end ? time_end : acc.time_end,
    }),
    {
      inspection_id: undefined,
      time_start: filtered[0].time_start,
      time_end: filtered[0].time_end,
    }
  );
  
  data.locale = locale.toUpperCase();
  data.unit_system = units;

  return data;
}
