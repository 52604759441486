<template>
  <div v-if="isLoading" class="loader">
    <v-progress-circular
      color="dark-blue"
      indeterminate
      :size="52"
      :width="5"
    ></v-progress-circular>
  </div>
  <PlotlyGraph v-else :data="plotlyData" :layout="plotlyLayout" />
</template>

<script>
import { PlotlyGraph } from "@/components";

export default {
  name: "FilterWTChart",
  components: {
    PlotlyGraph,
  },
  props: {
    isLoading: Boolean,
    currentFile: String,
  },

  computed: {
    plotlyData() {
      if (!this.currentFile) {
        return [];
      }

      const { wt, wt_rolling, settings } = this.currentFile;
      const { upperOffset, lowerOffset, upperTH, lowerTH } = settings;

      const upperFilterLimit = [];
      const lowerFilterLimit = [];

      wt_rolling.forEach((x) => {
        const upperZ = x + upperOffset;
        const lowerZ = x + lowerOffset;

        upperFilterLimit.push(
          upperZ < parseFloat(upperTH) ? upperZ : parseFloat(upperTH)
        );
        lowerFilterLimit.push(
          lowerZ > parseFloat(lowerTH) ? lowerZ : parseFloat(lowerTH)
        );
      });

      return [
        {
          y: wt,
          mode: "markers",
          type: "scatter",
          name: "WT (mm)",
        },
        {
          y: upperFilterLimit,
          mode: "lines",
          type: "scatter",
          name: "Upper filter limit",
        },
        {
          y: lowerFilterLimit,
          mode: "lines",
          type: "scatter",
          name: "Lower filter limit",
        },
      ];
    },

    plotlyLayout() {
      return {
        height: 450,
        showlegend: true,
        margin: {
          t: 30,
          b: 30,
          l: 60,
          r: 30,
        },
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.loader {
  height: 450px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
