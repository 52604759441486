import { anomalyTypeColors } from "@/colorLegends.js";

export function getAnomaliesMapData(
  anomalies,
  anomaliesTypes,
  checkedAnomaliesSelector,
  isWideMode
) {
  const largeAnomalies = anomalies.filter((anomaly) => anomaly.length >= 4);
  const smallAnomalies = anomalies.filter((anomaly) => anomaly.length < 4);

  const largeAnomalyName = `anomalies_large`;

  const largeAnomaliesMapData = {
    id: largeAnomalyName,
    type: "linestring",
    sourceOptions: {
      type: "geojson",
      data: {
        type: "FeatureCollection",
        features: largeAnomalies.map(({ id, geom, anomaly_type }) => ({
          type: "Feature",
          properties: {
            id,
            isDigupSelected: checkedAnomaliesSelector?.has(id),
            color: anomalyTypeColors[anomaly_type],
          },
          geometry: geom,
        })),
      },
    },
    layerOptions: {
      id: `layer_${largeAnomalyName}`,
      source: largeAnomalyName,
      layout: {
        visibility: "visible",
      },
      type: "line",
      paint: {
        "line-color": [
          "case",
          ["==", ["get", "isDigupSelected"], true],
          "#fc388d",
          ["get", "color"],
        ],
        "line-width": isWideMode
          ? 20
          : ["case", ["==", ["get", "isDigupSelected"], true], 11, 8],
        "line-opacity": 0.8,
      },
    },
  };

  const smallAnomalyName = `anomalies_small`;

  const smallAnomaliesMapData = {
    id: smallAnomalyName,
    type: "points",
    sourceOptions: {
      type: "geojson",
      data: {
        type: "FeatureCollection",
        features: smallAnomalies.map(({ id, geom, anomaly_type }) => ({
          type: "Feature",
          properties: {
            id,
            isDigupSelected: checkedAnomaliesSelector?.has(id),
            color: anomalyTypeColors[anomaly_type],
          },
          geometry: {
            type: "Point",
            coordinates: geom.coordinates[0],
          },
        })),
      },
    },
    layerOptions: {
      id: `layer_${smallAnomalyName}`,
      source: smallAnomalyName,
      layout: {
        visibility: "visible",
      },
      type: "circle",
      paint: {
        "circle-color": [
          "case",
          ["==", ["get", "isDigupSelected"], true],
          "#fc388d",
          ["get", "color"],
        ],
        "circle-radius": [
          "interpolate",
          ["linear"],
          ["zoom"],
          10,
          2,
          22,
          ["case", ["==", ["get", "isDigupSelected"], true], 16, 14],
        ],
        "circle-opacity": 0.8,
      },
    },
  };

  return { largeAnomaliesMapData, smallAnomaliesMapData };
}
