<template>
  <div v-if="isArray">
    <p v-if="title">{{ title }}</p>
    <v-range-slider
      v-bind="{ ...$attrs, ...defaultStyle }"
      :disabled="isInvalidMaxMin"
      v-model="proxyValue"
    >
      <template v-slot:append>
        <slot name="append"></slot>
      </template>
    </v-range-slider>
  </div>
  <div v-else>
    <p v-if="title">{{ title }}</p>
    <v-slider
      v-bind="{ ...$attrs, ...defaultStyle }"
      :disabled="isInvalidMaxMin"
      v-model="proxyValue"
    >
      <template v-if="withInput" v-slot:append>
        <v-text-field
          :model-value="proxyValue"
          @update:modelValue="handleInput"
          type="number"
          style="width: 60px"
          density="compact"
          variant="outlined"
          class="slider-custom-input"
          hide-details
        ></v-text-field>
      </template>
    </v-slider>
  </div>
</template>
<script>
export default {
  name: "RangeSlider",
  emits: ["update:modelValue"],
  props: {
    modelValue: {
      required: true,
    },
    withInput: {
      type: Boolean,
      default: false,
    },
    title: String,
  },
  setup() {
    const defaultStyle = {
      color: "blue",
      ["track-color"]: "gray",
      ["thumb-color"]: "white",
      ["thumb-label"]: true,
      ["thumb-size"]: 12,
      ["track-size"]: 3,
      ["hide-details"]: true,
      ["validate-on"]: "lazy",
    };
    return {
      defaultStyle,
    };
  },
  data() {
    return {
      timer: null,
      proxyValue: this.modelValue,
    };
  },
  computed: {
    isArray() {
      return Array.isArray(this.modelValue);
    },

    isInvalidMaxMin() {
      return !isFinite(this.$attrs.min) || !isFinite(this.$attrs.max);
    },
  },
  methods: {
    handleInput(value) {
      if (value > this.$attrs.max) {
        this.proxyValue = this.$attrs.max;
      } else if (value < this.$attrs.min) {
        this.proxyValue = this.$attrs.min;
      } else {
        this.proxyValue = value;
      }
    },
  },
  watch: {
    proxyValue(value) {
      clearTimeout(this.timer);
      this.timer = setTimeout(() => {
        this.$emit("update:modelValue", value);
      }, 500);
    },
    modelValue(range) {
      this.proxyValue = range;
    },
  },
};
</script>

<style lang="scss">
.v-slider {
  .v-slider-thumb__surface {
    box-shadow: 0.5px 0.5px 2px 1px #333 !important;
  }

  &.v-input--horizontal > .v-input__control {
    min-height: 0 !important;
    margin: 0.5rem 0;
  }

  &.v-input--horizontal {
    margin-inline: 8px 0px !important;
  }
}

.slider-custom-input {
  .v-field__input {
    padding: 0 6px;
    min-height: 20px;
  }
}
</style>
