<template>
  <div>
    <v-btn
      v-for="scannerType in scannerTypes"
      :key="scannerType"
      variant="tonal"
      size="small"
      :color="selectedScannerType === scannerType ? '#24e3c6' : 'white'"
      :prepend-icon="SCANNER_TAB_ICONS[scannerType]"
      @click="setSelectedScannerType(scannerType)"
    >
      {{ $t(`pipe_scanner.types.${scannerType}`) }}
    </v-btn>
  </div>
</template>

<script>
import { mapState, mapMutations } from "vuex";

import { ScannerType, SCANNER_TAB_ICONS } from "@/pages/scanner-control/config";

export default {
  name: "ScannersTabs",

  setup() {
    const scannerTypes = Object.values(ScannerType);

    return {
      scannerTypes,
      SCANNER_TAB_ICONS,
    };
  },

  computed: {
    ...mapState("scannerControl", ["selectedScannerType"]),
  },

  methods: {
    ...mapMutations("scannerControl", ["setSelectedScannerType"]),
  },
};
</script>

<style lang="scss" scoped>
.v-btn {
  margin-left: 0.5rem;
}
</style>
