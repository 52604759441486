import { baseApi } from "@/baseApi";
import { saveAs } from "file-saver";

export async function generateDigupSheet(token, group, data, controller) {
  const response = await baseApi({
    url: `/inline-inspections/generate-digup-sheet/${group}`,
    method: "post",
    responseType: 'blob',
    signal: controller?.signal,
    headers: { Authorization: `Bearer ${token}` },
    data,
  });

  if (response.status === 200) {
    const blob = new Blob([response.data], {
      type: response.headers["content-type"],
    });
    saveAs(blob, `digup_${data.time_start}-${data.time_end}.docx`);
  }
}
